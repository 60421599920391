import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {ListGroupItem} from "reactstrap";

import APITable2 from "../../Components/APITable2";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import PageTitle from "../../Layout/AppMain/PageTitle";
import APIForm from "../../Components/APIForm";
import {useTranslation} from "react-i18next";


const API_PATH = 'store/custom_fields';


const TableHeader = () => {
    const {t, i18n} = useTranslation();
    return <ListGroupItem>
        <div className="widget-content py-1 px-0 px-md-2">
            <div className="widget-content-wrapper text-secondary">
                <div className="widget-content-left" style={{width: 150}}>
                    {t('settings.custom_fields.column.name', 'Name')}
                </div>
                <div className="widget-content-left" style={{width: 100}}>
                    {t('settings.custom_fields.column.type', 'Type')}
                </div>
                <div className="widget-content-right d-none d-sm-block" style={{width: 'calc(100% - 350px)'}}>
                    {t('settings.custom_fields.column.description', 'Description')}
                </div>
                <div className="widget-content-right" style={{width: 50}}>

                </div>
            </div>
        </div>
    </ListGroupItem>;
};


const TableRow = (
    {
        data,
        setIdSelected,
        actionClick,
        actionDelete,
    }
) => {
    /**
     * Рендеринг
     */
    return <ListGroupItem
        className='hover-bg-light'
        onClick={() => actionClick(data.id)}
    >
        <div className="widget-content py-1 px-0 px-md-2">
            <div className='widget-content-wrapper'>
                <div className="widget-content-left" style={{width: 150}}>
                    <strong>{data.name}</strong>
                </div>
                <div className="widget-content-left" style={{width: 100}}>
                    {data.dtype.name}
                </div>
                <div className="widget-content-right d-none d-sm-block" style={{width: 'calc(100% - 350px)'}}>
                    <span className="text-secondary opacity-7">{data.description}</span>
                </div>
                <div className="widget-content-right text-right" style={{width: 50, paddingTop: 4}}>
                    <i className="pe-7s-trash mr-2 fsize-2 hover-text-danger hover-pointer" onClick={(e) => {
                        e.stopPropagation();

                        setIdSelected(data.id);
                        actionDelete();
                    }}/>
                </div>
            </div>
        </div>
    </ListGroupItem>;
};


const CustomFields = (
    {
        match,
    }
) => {
    const {t, i18n} = useTranslation();
    
    return <Switch>
        <Route
            exact path={`${match.url}/create/`}
            render={
                (props) => <>
                    <AppHeader/>
                    <div className="app-main">
                        <AppSidebar/>
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <PageTitle
                                    heading={t('settings.custom_fields.page.title', 'Variables')}
                                    subheading=''
                                    icon="fa fas fa-cog icon-gradient bg-night-fade"
                                    enablePageTitleCreationButton={false}
                                />
                                <APIForm
                                    apiPath={API_PATH}
                                    modelId={undefined}
                                    method='post'
                                    actionRedirectUrl={`${match.url}/`}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        />
        <Route
            exact path={`${match.url}/:id(\\d+)/`}
            render={
                (props) => <>
                    <AppHeader/>
                    <div className="app-main">
                        <AppSidebar/>
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <PageTitle
                                    heading={t('settings.custom_fields.page.title', 'Variables')}
                                    subheading=''
                                    icon="fa fas fa-cog icon-gradient bg-night-fade"
                                    enablePageTitleCreationButton={true}
                                    headerButtonCreateUrl={`${match.url}/create/`}
                                />
                                <APIForm
                                    apiPath={API_PATH}
                                    modelId={props.match.params.id}
                                    method='patch'
                                    actionRedirectUrl={`${match.url}/`}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        />
        <Route
            exact path={match.url}
            render={
                (props) => <>
                    <AppHeader/>
                    <div className="app-main">
                        <AppSidebar/>
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <PageTitle
                                    heading={t('settings.custom_fields.page.title', 'Переменные')}
                                    subheading=''
                                    icon="fa fas fa-cog icon-gradient bg-night-fade"
                                    headerButtonCreateUrl={`${match.url}create/`}
                                    enablePageTitleCreationButton={true}
                                />
                                <APITable2
                                    HeaderComponent={TableHeader}
                                    RowComponent={TableRow}
                                    emptyMessage={t('settings.custom_fields.list.no_data', 'No variables')}
                                    deleteMessage={t('settings.custom_fields.list.confirmation.remove', 'Are you sure you want to remove the variable?')}
                                    apiPath={API_PATH}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        />
    </Switch>;
};

export default CustomFields;
