import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';


const PrivateRouteWithShops = (
    {
        component: Component,
        activeShop,
        isAuthenticated,
        ...rest
    }
) => (
      <Route
          {...rest}
          render={props => {
            
              const path = props.location.pathname;
              const pathShopId = parseInt(path.match(/^\/(\d+)\//)[1]);
              
              if (!isAuthenticated) {
                  return (<Redirect to='/auth/login' />);
              }
              
              if (pathShopId !== activeShop.id) {
                  const redirectPath = path.replace(/^\/(\d+)\//, `/${activeShop.id}/`);
                  return (<Redirect to={redirectPath}/>);
              }
      
              return (<Component {...props} />)
          }}
      />
);

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    activeShop: state.Shops.activeShop,
});

export default connect(mapStateToProps)(PrivateRouteWithShops);
