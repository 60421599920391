import React, {useState} from "react";
import {connect} from 'react-redux';
import {Button, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import {Trans, useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import get from 'lodash/get';
import has from 'lodash/has';


import {setRegisterFormErrors, touchRegisterForm, resetRegisterForm} from "../../../actions/Auth";
import {concatURL, renderErrorsAsHTML} from '../../../Components/utils'
import {register} from "../../../thunks/Auth";
import {Link} from "react-router-dom";


const FullForm = (
    {
        match,
        errors,
        phone_number,
        code,
        referral_code,
        register,
        setRegisterFormErrors,
        touchRegisterForm,
        resetRegisterForm,
    }
) => {
    const {t, i18n} = useTranslation();

    // State
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordrep, setPasswordrep] = useState('');
    const [check, setCheck] = useState(false);
    
    const isByPhone = !!(phone_number && code);
    const hasPhoneErrors = isByPhone && (has(errors, 'phone') || (has(errors, 'phone_number')));

    const sendRegisterRequest = (e) => {
        e.preventDefault();

        let errors = {};

        if (password !== passwordrep) {
            errors = {
                ...errors,
                password: [t('auth.register.full_form.error_passwords_not_equal', 'Passwords are mismatched')],
                passwordrep: true,
            };
        }

        if (!check) {
            errors = {
                ...errors,
                check: [t('auth.register.full_form.error_rules_not_agreed', 'You have to agree with the privacy policy')],
            };
        }

        if (!isEmpty(errors)) {
            setRegisterFormErrors(errors);
            return;
        }

        register(
            email,
            phone_number,
            code,
            password,
            passwordrep,
            check,
            referral_code,
            i18n.languages[0],
            (response) => {
                if (isByPhone) {
                    toast.success(t('auth.register.messages.success_toast', 'Congratulations! You have registered successfully.'));
                }
                window.location.href = concatURL(window.location.href, '../../login');
            }
        );
    };

    return <Form onSubmit={sendRegisterRequest} autoComplete="on">
        {(isByPhone) ? (
            <>
                <Row form>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="phone">
                                {t('auth.register.phone.label_phone', 'Phone number:')}
                            </Label>
                            <Input name="phone"
                                   disabled
                                   value={phone_number}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12} className='text-danger'>
                        {renderErrorsAsHTML(get(errors, 'phone'))}
                        {renderErrorsAsHTML(get(errors, 'phone_number'))}
                    </Col>
                </Row>
                <Row form className={hasPhoneErrors ? 'mt-3' : ''}>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="code">
                                {t('auth.register.verify_code.label_code', 'Verification code:')}
                            </Label>
                            <Input name="code"
                                   disabled
                                   value={code}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12} className='text-danger'>
                        {renderErrorsAsHTML(get(errors, 'code'))}
                    </Col>
                </Row>
            </>
        ) : (
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="exampleEmail">
                            <span className="text-danger">*</span>
                            {' '}{t('auth.register.full_form.label_email', 'Email')}
                        </Label>
                        <Input type="email" name="email"
                               id="exampleEmail"
                               placeholder={t('auth.register.full_form.label_email', 'Email')}
                               required
                               value={email}
                               onChange={e => setEmail(e.target.value)}
                               invalid={get(errors, 'email') !== undefined}
                               onClick={touchRegisterForm}/>
                    </FormGroup>
                </Col>
                <Col md={12} className='text-danger'>
                    {renderErrorsAsHTML(get(errors, 'email'))}
                </Col>
            </Row>
        )}
        <Row form>
            <Col md={6}>
                <FormGroup>
                    <Label for="examplePassword">
                        <span className="text-danger">*</span>
                        {' '}{t('auth.register.full_form.label_password', 'Password')}
                    </Label>
                    <Input type="password"
                           name="password"
                           id="examplePassword"
                           placeholder={t('auth.full_form.placeholder_password', 'Password')}
                           required
                           value={password}
                           onChange={e => setPassword(e.target.value)}
                           invalid={get(errors, 'password') !== undefined}
                           onClick={touchRegisterForm}/>
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup onClick={touchRegisterForm}>
                    <Label for="examplePasswordRep">
                        <span className="text-danger">*</span>
                        {' '}{t('auth.register.full_form.label_password_rep', 'Repeat password')}
                    </Label>
                    <Input type="password"
                           name="passwordrep"
                           id="examplePasswordRep"
                           placeholder={t('auth.full_form.placeholder_password_rep', "Repeat password")}
                           value={passwordrep}
                           required
                           onChange={e => setPasswordrep(e.target.value)}
                           invalid={get(errors, 'passwordrep') !== undefined}
                           onClick={touchRegisterForm}/>
                </FormGroup>
            </Col>
            <Col md={12} className='text-danger'>
                {renderErrorsAsHTML(get(errors, 'password'))}
            </Col>
        </Row>

        <FormGroup className="mt-3"
                   check
                   onClick={touchRegisterForm}>
            <Input type="checkbox"
                   name="check"
                   id="exampleCheck"
                   invalid={get(errors, 'passwordrep') !== undefined}
                   value={check}
                   onChange={e => {
                       setCheck(e.target.checked)
                   }}/>
            <Label for="exampleCheck" check>
                <Trans t={t} i18nKey='auth.register.full_form.label_rules'>
                    I agree to the {' '}
                    <a href={t('auth.register.full_form.href_terms', 'https://hightouch.ai/terms')} target="_blank">
                        Terms of Service
                    </a>
                    {' '} and {' '}
                    <a href={t('auth.register.full_form.href_privacy_policy', 'https://hightouch.ai/privacy-policy')}
                       target="_blank">
                        Privacy Policy
                    </a>
                </Trans>
            </Label>
        </FormGroup>

        <Col md={12} className='text-danger mt-1'>
            {renderErrorsAsHTML(get(errors, 'check'))}
        </Col>

        <div className="mt-4 text-danger">
            {renderErrorsAsHTML(get(errors, 'common'))}
        </div>
        <FormGroup onClick={touchRegisterForm}>
            <Button color="primary"
                    disabled={hasPhoneErrors}
                    className="btn-wide btn-pill btn-shadow btn-hover-shine"
                    size="lg">
                {t('auth.register.full_form.button_submit', 'Register')}
            </Button>
          {hasPhoneErrors && (<Link
                  onClick={resetRegisterForm}
                  to={concatURL(match.url, '../')}
                  className='ml-2'
              >
                      {t('auth.register.full_form.change_phone_number', 'Use another phone')}
              </Link>
          )}
        </FormGroup>
    </Form>;
};


const mapStateToProps = state => ({
    errors: state.Auth.register.errors,
    phone_number: state.Auth.register.phone_number,
    code: state.Auth.register.code,
    referral_code: state.Auth.register.ref
});

const mapDispatchToProps = dispatch => ({
    register: (email, phone_number, code, password, passwordrep, check, referral_code, lang_code, callback) => dispatch(register(email, phone_number, code, password, passwordrep, check, referral_code, lang_code, callback)),
    setRegisterFormErrors: (errors) => dispatch(setRegisterFormErrors(errors)),
    touchRegisterForm: () => dispatch(touchRegisterForm()),
    resetRegisterForm: () => dispatch(resetRegisterForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FullForm);
