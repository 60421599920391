import React, {useCallback, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Button} from 'reactstrap';
import {useTranslation} from "react-i18next";

import {shopsRequestStart, shopsSelectShop} from "../../../actions/Shops";
import {setEnableMobileMenuSmall} from '../../../actions/ThemeOptions'
import bg3 from '../../../assets/utils/images/dropdown-header/abstract3.jpg';
import {logoutRequestStart} from "../../../actions/Auth";
import {revokeFirebaseToken} from "../../../thunks/Notifications";

import cloneDeep from "lodash/cloneDeep";
import {updateCurrentShopAdministrator} from "../../../actions/Shops";
import {apiFormSubmitRequest} from "../../../thunks/ApiModel";
import {LANGUAGE_PROPERTIES_BY_LANG_CODE} from "../../../constants";
import Flag from "react-flagkit";


const redirectToLocale = (code) => {
    const locationParts = window.location.href.split('/');
    locationParts[3] = locationParts[3].slice(-1) === '#' ? `${code}/#` : code;
    window.location.href = locationParts.join('/');
};

const API_PATH = 'shop/shop_administrators';

const AccountMenu = (
    {
        isSendingRequest,
        shopsRequestStart,
        setEnableMobileMenuSmall,

        authUser,
        currentShopAdministrator,

        firebaseToken,
        logoutRequestStart,
        revokeFirebaseToken,
        apiFormSubmitRequest,
        updateCurrentShopAdministrator,
    }
) => {
    const {t, i18n} = useTranslation();
    const activeLang = i18n.languages[0];

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => {
        if (isSendingRequest) {
            return
        }

        if (dropdownOpen === false) {
            shopsRequestStart('');
        }
        setDropdownOpen(!dropdownOpen);
    };


    const logout = () => {
        firebaseToken && revokeFirebaseToken(firebaseToken);
        setEnableMobileMenuSmall(false);
        setTimeout(() => logoutRequestStart(), 200);
    };

    /**
     * Toggle is_active
     */
    const actionToggleIsOnline = () => {
        const apiUrl = `${API_PATH}/${currentShopAdministrator.id}/`;
        const method = 'patch';
        const formData = {
            is_online: !currentShopAdministrator.is_online,
        };

        const callback = (response) => {
            const updatedData = cloneDeep(currentShopAdministrator);
            updatedData.is_online = !currentShopAdministrator.is_online;
            updateCurrentShopAdministrator(updatedData);
            toggle();
        };

        apiFormSubmitRequest(
            apiUrl,
            method,
            formData,
            undefined,
            callback,
        );
    };


    return <>
        <Dropdown isOpen={dropdownOpen && !isSendingRequest} toggle={toggle}>
            <DropdownToggle nav>
                <div
                    className="ml-3 text-left text-dark">
                    <div className="text-nowrap d-flex align-items-center">

                        {
                            currentShopAdministrator &&
                            <div className={`mr-2 d-none d-sm-block badge badge-dot badge-dot-lg badge-${
                                currentShopAdministrator.is_online ? 'success' : 'danger'
                            }`}>
                                XXX
                            </div>
                        }

                        <span className={'d-none d-sm-flex'}>{authUser.email}</span>
                        <div className="icon-wrapper icon-wrapper-alt d-flex d-sm-none">
                            <div className="icon-wrapper-bg rounded-circle overflow-visible bg-secondary"/>
                            <i className={`fa fas fa-user text-white`}></i>
                            {
                                currentShopAdministrator &&
                                <div style={{top: '4px', right: '4px'}}
                                     className={`position-absolute badge badge-dot badge-dot-lg badge-${
                                         currentShopAdministrator.is_online ? 'success' : 'danger'
                                     }`}>
                                    XXX
                                </div>
                            }
                        </div>
                        <i className={'fas fa-angle-down ml-2'}></i>
                    </div>
                </div>
            </DropdownToggle>
            <DropdownMenu
                className="dropdown-menu-rounded dropdown-menu-lg rm-pointers"
                modifiers={{
                    setMaxHeight: {
                        enabled: true,
                        fn: (data) => {
                            let w = window,
                                d = document,
                                e = d.documentElement,
                                g = d.getElementsByTagName('body')[0],
                                y = w.innerHeight || e.clientHeight || g.clientHeight;
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    maxHeight: y - 50,
                                },
                            };
                        },
                    },
                }}
            >
                <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-dark">
                        <div className="menu-header-image opacity-1"
                             style={{
                                 backgroundImage: 'url(' + bg3 + ')'
                             }}
                        ></div>
                        <div className="menu-header-content text-left">
                            <h5 className="menu-header-title text-center">
                                <i className="nav-link-icon fa fas fa-user mr-2"> </i>
                                {t('layout.header.account_menu.title', 'My account')}
                            </h5>
                        </div>
                    </div>
                </div>

                {
                    currentShopAdministrator && <>
                        <DropdownItem header>
                            {
                                t('layout.header.online_status.title', 'Chat status')
                            }
                        </DropdownItem>
                        <DropdownItem tag={'div'}
                                      onClick={(e) => {
                                          e.stopPropagation();
                                          actionToggleIsOnline();
                                      }}
                        >
                            <div className={`mr-2 badge badge-dot badge-dot-lg badge-${
                                currentShopAdministrator.is_online ? 'success' : 'danger'
                            }`}>
                                XXX
                            </div>

                            <Button color={'transparent'}
                                    className={'pl-0'}
                                    size={'lg'}
                            >

                                {currentShopAdministrator.is_online
                                    ? t('layout.header.online_status.online', 'Online')
                                    : t('layout.header.online_status.offline', 'Offline')
                                }
                            </Button>

                        </DropdownItem>
                    </>
                }

                <DropdownItem header>
                    {
                        t('layout.header.account_menu.title', 'My account')
                    }
                </DropdownItem>
                <DropdownItem tag={'div'}
                              onClick={() => {
                                  logout()
                              }}
                >
                    <Button color={'transparent'}
                            size={'lg'}
                            className={'pl-0'}
                    >
                        <i className={`fa fas fa-sign-out-alt fa-lg mr-2 text-secondary`}></i>
                        {t('layout.header.button_logout', 'SignOut')}
                    </Button>
                </DropdownItem>
                <hr/>
                <DropdownItem header>
                    {
                        t('layout.header.choose_language.title', 'Choose language')
                    }
                </DropdownItem>
                    {i18n.options.whitelist.map((lang_code) => {
                        if (LANGUAGE_PROPERTIES_BY_LANG_CODE[lang_code]) {// && lang_code !== activeLang) {
                            const langItemConfig = LANGUAGE_PROPERTIES_BY_LANG_CODE[lang_code];
                            return (
                                <DropdownItem tag={'div'}
                                              onClick={() => redirectToLocale(lang_code)}
                                              key={lang_code}
                                              active={activeLang === lang_code}
                                >
                                    <Button color={'transparent'}
                                            size={'lg'}
                                            className={'pl-0'}
                                    >
                                        <Flag className="mr-3 opacity-8" country={langItemConfig.flag}/>
                                        {langItemConfig.name}
                                    </Button>
                                </DropdownItem>
                            )
                        }
                    })}
            </DropdownMenu>
        </Dropdown>
    </>;
};

const mapStateToProps = state => ({
    shops: state.Shops.shops,
    activeShop: state.Shops.activeShop,
    isSendingRequest: state.Shops.isSendingRequest,

    authUser: state.Auth.user,
    currentShopAdministrator: state.Shops.currentShopAdministrator,
    firebaseToken: state.Notifications.firebaseToken,
});

const mapDispatchToProps = dispatch => ({
    shopsRequestStart: (search, setIsSendingRequest) => dispatch(shopsRequestStart(search, setIsSendingRequest)),
    shopsSelectShop: shop => dispatch(shopsSelectShop(shop)),
    setEnableMobileMenuSmall: enabled => dispatch(setEnableMobileMenuSmall(enabled)),

    logoutRequestStart: () => dispatch(logoutRequestStart()),
    revokeFirebaseToken: (firebaseToken, callback) => dispatch(revokeFirebaseToken(firebaseToken, callback)),
    apiFormSubmitRequest: (apiPath, method, formData, redirectUrl, callback) => dispatch(apiFormSubmitRequest(apiPath, method, formData, redirectUrl, callback)),
    updateCurrentShopAdministrator: (currentShopAdministrator) => dispatch(updateCurrentShopAdministrator(currentShopAdministrator)),

});

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
