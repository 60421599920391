import React from 'react';
import {useTranslation} from "react-i18next";

import APIModel from "../../Components/APIModel";
import {LOGO_BY_TYPE} from '../../Components/utils';
import DefaultNormalArrayFieldTemplate from "../../Components/APIForm/Fields/DefaultNormalArrayFieldTemplate";


const HSMTemplates = (
    {
        match,
    }
) => {
    const {t, i18n} = useTranslation();

    const HSM_TEMPLATES_COLUMNS = [
        {
            name: t('hsm_templates.columns.channel', 'WABA number'),
            cell: row => <div>
                <img
                    src={LOGO_BY_TYPE[row.bot.bot_type]}
                    style={{width: '1.3rem'}}
                    className='mr-2'
                />
                {row.bot.name}
            </div>
        },
        {
            name: t('hsm_templates.columns.name', 'Name'),
            selector: "name",
        },
        {
            name: t('hsm_templates.columns.status', 'Moderation status'),
            selector: "status",
        },
        // {
        //     name: t('hsm_templates.columns.category', 'Category'),
        //     selector: "category",
        // },
        {
            name: t('hsm_templates.columns.language', 'Language'),
            selector: "language",
        },
        {
            name: t('hsm_templates.columns.created_at', 'Created at'),
            selector: "created_at",
        },
    ];

    return <APIModel
        heading={t('hsm_templates.api_model.heading', 'WhatsApp HSM templates')}
        subheading=''
        icon="fa fas fa-cog icon-gradient bg-night-fade"
        apiPath='bot/waba_templates'
        tableColumns={HSM_TEMPLATES_COLUMNS}
        tableEmptyMessage={t('hsm_templates.api_model.message_empty', 'There is no HSM templates')}
        className='waba_templates'
        match={match}
        hasFilters={true}
        deletionDisabled={false}
        creationDisabled={false}
        updateDisabled={true}
        ArrayFieldTemplate={DefaultNormalArrayFieldTemplate}
    />;
};

export default HSMTemplates;
