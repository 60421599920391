import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import {Trans, useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,} from 'reactstrap';

import {
    logInRequestStart,
    registerCloseNotificationPopup,
    touchLoginForm,
    quickLogInRequestStart
} from "../../../actions/Auth";
import {renderErrorsAsHTML} from '../../../Components/utils'
import { URLS } from "../../../router";


function Login(
    {
        errors,
        // validationEmailNotification,
        // loginRequestStart,
        quickLogInRequestStart,
        quickLoginStatus,

        touchLoginForm,
        // registerCloseNotificationPopup,
    }
) {
    const {t, i18n} = useTranslation();

    const [username, setUsername] = useState('');
    // const [password, setPassword] = useState('');

    const waitingSeconds = 30;

    const sendLogInRequest = (e) => {
        e.preventDefault();
        quickLogInRequestStart(username);
    };

    const [isTryAgainDisabled, setIsTryAgainDisabled] = useState(true);
    const [countDown, setCountDown] = useState(waitingSeconds);
    useEffect(() => {
        const interval = setInterval(() => {
            if (countDown) {
                setCountDown(countDown - 1);
            }
        }, 1000);
        if (!countDown) {
            setIsTryAgainDisabled(false)
        }
        return () => clearInterval(interval);
    }, [countDown]);

    useEffect(() => {
        setCountDown(waitingSeconds);
        setIsTryAgainDisabled(true)
    }, [quickLoginStatus]);


    return <>
        <div className="h-100 bg-dark">
            <div className="d-flex h-100 justify-content-center align-items-center">
                <Col md="8" className="mx-auto app-login-box">

                    <div className="login-form modal-dialog w-100">
                        <div className="modal-content">
                            <div className="modal-body py-4 py-sm-5">
                                <div className="login-form__logo bg-transparent w-100 justify-content-center">
                                    <div className="logo-src"/>
                                </div>
                                {quickLoginStatus !== 'sent' ? <>
                                        <Form onSubmit={sendLogInRequest} autoComplete="on">
                                            <Link to={'/auth/whatsapp'}
                                                  size={'block'}
                                                  onClick={touchLoginForm}
                                                  className={'btn btn-success btn-block d-flex align-items-center justify-content-center font-weight-bold login-form_button login-form_button__wa'}
                                            >
                                                <i className={'fa fab fa-whatsapp fa-lg pr-2'}></i>
                                                Sign in with WhatsApp
                                            </Link>
                                            <Link to={'/auth/telegram'}
                                                  onClick={touchLoginForm}
                                                  className={'btn btn-info btn-block mb-3 d-flex align-items-center justify-content-center font-weight-bold text-white login-form_button login-form_button__tg'}
                                            >
                                                <i className={'fa fab fa-telegram-plane fa-lg pr-2'}></i>
                                                Sign in with Telegram
                                            </Link>

                                            <div className={'login-form__separator mb-3'}>OR</div>

                                            <FormGroup>
                                                <Input type="text" name="username"
                                                       id="username"
                                                       className={'login-form__input'}
                                                       placeholder={'you@example.com'}
                                                       required
                                                       value={username}
                                                       onChange={e => setUsername(e.target.value)}
                                                       invalid={get(errors, 'email') !== undefined}
                                                       onClick={touchLoginForm}/>
                                            </FormGroup>
                                            {renderErrorsAsHTML(get(errors, 'email'))}
                                            <Button type="submit"
                                                    color={'warning'}
                                                    size={'block'}
                                                    onClick={touchLoginForm}
                                                    className={'login-form_button mb-3 d-flex align-items-center justify-content-center font-weight-bold '}
                                            >
                                                <i className={'fa fas fa-envelope fa-lg pr-2'}></i>
                                                Sign in with Email
                                            </Button>
                                            {renderErrorsAsHTML(get(errors, 'username'))}

                                            {/*<FormGroup>*/}
                                            {/*    <Label*/}
                                            {/*        for="examplePassword">{t('auth.login.label_password', "Password")}</Label>*/}
                                            {/*    <Input type="password" name="password" id="examplePassword"*/}
                                            {/*           placeholder={t('auth.login.placeholder_password', "My password...")}*/}
                                            {/*           required*/}
                                            {/*           value={password}*/}
                                            {/*           onChange={e => setPassword(e.target.value)}*/}
                                            {/*           invalid={get(errors, 'password') !== undefined}*/}
                                            {/*           onClick={touchLoginForm}/>*/}
                                            {/*</FormGroup>*/}
                                            {/*{renderErrorsAsHTML(get(errors, 'password'))}*/}
                                            <div className="text-danger mr-3">{get(errors, 'common')}</div>

                                            {/*<Button type="submit" color="primary" size="lg"*/}
                                            {/*        className="btn-wide mt-4 btn-pill btn-shadow btn-hover-shine">*/}
                                            {/*    {t('auth.login.button_submit', 'Log in')}*/}
                                            {/*</Button>*/}
                                        </Form>
                                        <div className={'login-form__disclaimer mt-4'}>
                                            <Trans t={t} i18nKey='auth.quick_login.label_rules'>
                                                By signing in you agree to our {' '}
                                                <a href={t('auth.register.full_form.href_terms', 'https://hightouch.ai/terms')}
                                                   target="_blank">
                                                    Terms
                                                </a>
                                                {' '} and {' '}
                                                <a href={t('auth.register.full_form.href_privacy_policy', 'https://hightouch.ai/privacy-policy')}
                                                   target="_blank">
                                                    Privacy Policy
                                                </a>
                                            </Trans>
                                        </div>
                                        <div className={'text-center mt-5'}>
                                            <Link to={URLS.AUTH.QUCK_SIGNUP}>
                                                Create a new account
                                            </Link>
                                        </div>
                                    </>
                                    : <>
                                        <div className={'text-center mt-5'}>
                                            <div className={'login-form__disclaimer mb-4'}>
                                                Check you mailbox
                                            </div>

                                            <Button size={'lg'}
                                                    color="invisible"
                                                    onClick={touchLoginForm}
                                                    disabled={isTryAgainDisabled}
                                            >Try again {countDown ? `(${countDown})` : ''}</Button>

                                        </div>
                                    </>
                                }


                            </div>

                        </div>
                    </div>
                </Col>
            </div>
        </div>

        {/*<Modal isOpen={validationEmailNotification} toggle={registerCloseNotificationPopup}>*/}
        {/*    <ModalHeader>*/}
        {/*        <i className="lnr-checkmark-circle icon-gradient bg-happy-itmeo fsize-2"> </i>*/}
        {/*        {t('auth.login.account_created.title', 'Account created!')}*/}
        {/*    </ModalHeader>*/}
        {/*    <ModalBody>*/}
        {/*        {t('auth.login.account_created.text1', 'Registration confirmation instructions were sent to you via email')}*/}
        {/*        <br/>*/}
        {/*        <br/>*/}
        {/*        <i className="pe-7s-mail-open-file icon-gradient bg-plum-plate fsize-2"> </i>*/}
        {/*        {' '} {t('auth.login.account_created.text2', 'Please, click the link in the email.')}*/}
        {/*    </ModalBody>*/}
        {/*    <ModalFooter>*/}
        {/*        <Button color="primary" onClick={registerCloseNotificationPopup}>*/}
        {/*            {t('auth.login.account_created.button', 'Ok, thanks!')}*/}
        {/*        </Button>{' '}*/}
        {/*    </ModalFooter>*/}
        {/*</Modal>*/}

    </>;
}


const mapStateToProps = state => ({
    errors: state.Auth.login.errors,
    quickLoginStatus: state.Auth.login.quickLoginStatus,
    // validationEmailNotification: state.Auth.register.validationEmailNotification,
});

const mapDispatchToProps = dispatch => ({
    // loginRequestStart: (username, password) => dispatch(logInRequestStart(username, password)),
    quickLogInRequestStart: (username) => dispatch(quickLogInRequestStart(username)),
    touchLoginForm: () => dispatch(touchLoginForm()),
    // registerCloseNotificationPopup: () => dispatch(registerCloseNotificationPopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
