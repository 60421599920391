import React, {useEffect, useRef} from "react";
import qs from 'qs';
import forIn from 'lodash/forIn';
import remove from 'lodash/remove';
import forOwn from "lodash/forOwn";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import isString from "lodash/isString";
import amplitude from 'amplitude-js';

import tgLogo from "../assets/utils/images/messenger/tg.png";
import waLogo from "../assets/utils/images/messenger/wa.png";
import fbLogo from "../assets/utils/images/messenger/facebook.svg";
import vkLogo from "../assets/utils/images/messenger/vk.png";
import avitoLogo from "../assets/utils/images/messenger/avito.png";
import viberLogo from '../assets/utils/images/messenger/viber.png';
import okLogo from '../assets/utils/images/messenger/ok.png';
import instaLogo from '../assets/utils/images/messenger/insta.png';
import expressLogo from '../assets/utils/images/messenger/express.png';
import retailcrmLogo from '../assets/utils/images/messenger/retailcrm.png';
import {
    BOT_TYPE_AVITO,
    BOT_TYPE_FB,
    BOT_TYPE_TG,
    BOT_TYPE_VIBER,
    BOT_TYPE_VK,
    BOT_TYPE_WA,
    BOT_TYPE_WABA,
    BOT_TYPE_OK,
    BOT_TYPE_EXPRESS,
    BOT_TYPE_IG,
    BOT_TYPE_RETAILCRM,
} from "../constants";

export const LOGO_BY_TYPE = {};
LOGO_BY_TYPE[BOT_TYPE_TG] = tgLogo;
LOGO_BY_TYPE[BOT_TYPE_WA] = waLogo;
LOGO_BY_TYPE[BOT_TYPE_FB] = fbLogo;
LOGO_BY_TYPE[BOT_TYPE_VK] = vkLogo;
LOGO_BY_TYPE[BOT_TYPE_AVITO] = avitoLogo;
LOGO_BY_TYPE[BOT_TYPE_WABA] = waLogo;
LOGO_BY_TYPE[BOT_TYPE_VIBER] = viberLogo;
LOGO_BY_TYPE[BOT_TYPE_OK] = okLogo;
LOGO_BY_TYPE[BOT_TYPE_EXPRESS] = expressLogo;
LOGO_BY_TYPE[BOT_TYPE_IG] = instaLogo;
LOGO_BY_TYPE[BOT_TYPE_RETAILCRM] = retailcrmLogo;


export const renderErrorsAsHTML = (errors, withTitles=false, className='text-danger') => {
    if (isEmpty(errors)) {
        return '';
    }

    if (isString(errors)) {
        return <div className={className}>{errors}</div>
    } else if (isArray(errors)) {
        return errors.map((error, i) => {
            if (error === "True" || error === true) {
                return ''
            }
            return <div className={className} key={i}>{renderErrorsAsHTML(error)}</div>
        })
    } else if (isObject(errors)) {
        const result = [];
        forOwn(errors, (value, key) => {
            if (['common', 'detail'].indexOf(key) === -1 && withTitles) {
                result.push(<div className={className} key={key}>{key}: {renderErrorsAsHTML(value, false, className)}</div>);
            } else {
                result.push(<div className={className} key={key}>{renderErrorsAsHTML(value, false, className)}</div>);
            }
        });
        return result;
    }
};

export const renderErrorsAsString = (errors, withTitles) => {
    if (isEmpty(errors)) {
        return '';
    } else if (isString(errors)) {
        return errors;
    } else if (isArray(errors)) {
        return errors.map(error => renderErrorsAsString(error)).join('\n');
    } else if (isObject(errors)) {
        const result = [];
        forOwn(errors, (value, key) => {
            if (['common', 'detail'].indexOf(key) === -1 && withTitles) {
                result.push(`${key}: ${renderErrorsAsString(value)}`);
            } else {
                result.push(renderErrorsAsString(value));
            }
        });
        return result.join('\n');
    }
};

export const isDataURLorNull = (s) => {
    return s === null || !!s.match(isDataURLorNull.regex);
};
isDataURLorNull.regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;


export const getQueryParams = () => {
    const hash = window.location.hash;
    const idx = hash.indexOf('?');

    if (idx === -1) {
        return {}
    }

    return qs.parse(
        hash.substr(idx),
        {ignoreQueryPrefix: true}
    );
};

export const replaceQueryParams = (queryParams, url) => {
    if (!url) {
        url = window.location.hash;
    }
    if (url.substr(0, 1) === '#') {
        url = url.substr(1);
    }
    const urlStart = url.split('?')[0];
    if (isEmpty(queryParams)) {
        return urlStart;
    }
    return urlStart + qs.stringify(queryParams, {prefix: true})
};

export const getCurrentPath = () => {
    return window.location.protocol + '//' + window.location.host + window.location.pathname;
};

export const concatURL = (url_full, concat) => {
    // Вычленяем протокол
    let protocol = '';
    let url = url_full;
    if (url.startsWith('http')) {
        protocol = url.split('://')[0] + '://';
        url = url.split('://')[1]
    }

    let url1 = url.split('/');
    if (url1[url1.length - 1] === '') {
        url1 = url1.slice(0, url1.length - 1)
    }
    url1 = remove(url1, part => part);

    let url2 = concat.split('/');
    url2 = remove(url2, part => part);

    let url3 = [];
    for (let i = 0, l = url1.length; i < l; i++) {
        if (url1[i] === '..') {
            url3.pop();
        } else if (url1[i] === '.') {
            // continue
        } else {
            url3.push(url1[i]);
        }
    }
    for (let i = 0, l = url2.length; i < l; i++) {
        if (url2[i] === '..') {
            url3.pop();
        } else if (url2[i] === '.') {
            // continue
        } else {
            url3.push(url2[i]);
        }
    }

    const res = url3.join('/');
    if (!res) {
        return '/';
    } else if (protocol || res.startsWith('localhost') || res.startsWith('127.0.0.1')) {
        return `${protocol}${res}/`;
    } else {
        return `/${res}/`;
    }
};


export const extractDigits = (text) => {
    if (!text) {
        return '';
    }

    let numb = text.match(/\d/g);
    return numb.join("");
};


export function* range(start, end) {
    for (let i = start; i <= end; i++) {
        yield i;
    }
}


export const filtersToRequestParams = (filters, config) => {
    const result = {};
    forIn(
        filters,
        (filterValue, key) => {
            const filterConfig = find(config, ['key', key]);
            switch (filterConfig.type) {
                case 'bool':
                    result[key] = filterValue;
                    break;
                case 'select':
                    result[key] = filterValue.id;
                    break;
                case 'select_multiple':
                    result[key] = filterValue.map(el => el.id);
                    break;
                default:
                    throw `Wrong filterConfig.type: "${filterConfig.type}"`;
            }
        }
    );
    return result;
}


export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


export const eventsInit = () => {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
}


export const eventsSetUserId = (profileId) => {
    /**
     * int - в качестве userId для Amplitude мы используем наш profile_id
     * null - убрать userId
     */
    amplitude.getInstance().setUserId(`profile_${profileId}`);
}


export const eventsLogEvent = (eventName, eventAttrs) => {
    amplitude.getInstance().logEvent(eventName, eventAttrs);
}


export const truncateString = (str, n) => {
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
}
