import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {withRouter} from 'react-router-dom';
import get from 'lodash/get';
import ResizeDetector from 'react-resize-detector';

import AppMain from '../../Layout/AppMain';
import {
  SHOP_SOURCE_CHAT_API,
  SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU,
  SHOP_SOURCE_RETAIL_CRM_WABA_RU
} from "../../constants";

const Main = (
    {
        user,
        activeShop,
        colorScheme,
        enableFixedHeader,
        enableFixedSidebar,
        enableFixedFooter,
        enableClosedSidebar,
        enableMobileMenu,
        enablePageTabsAlt,
    }
) => {
    const isChatApi = get(activeShop, 'source') === SHOP_SOURCE_CHAT_API;
    const isRetailCRM = (get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_WABA_RU
      || get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU);

    return <ResizeDetector
        handleWidth
        handleHeight={false}
        refreshMode={'throttle'}
        refreshRate={500}
        render={({width}) => (
            <Fragment>
                <div className={cx(
                    "app-container app-theme-" + colorScheme,
                    {'fixed-header': enableFixedHeader},
                    {'fixed-sidebar': enableFixedSidebar || width < 1200},
                    {'fixed-footer': enableFixedFooter},
                    {'closed-sidebar': enableClosedSidebar || width < 1200},
                    {'sidebar-mobile-open': enableMobileMenu},
                    {'body-tabs-shadow-btn': enablePageTabsAlt},
                    {'chat-api': (isChatApi || isRetailCRM) && !get(user, 'is_superuser')},
                )}>
                    <AppMain/>
                </div>
            </Fragment>
        )}
    />;
}

const mapStateToProp = state => ({
    user: state.Auth.user,
    activeShop: state.Shops.activeShop,
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

});

export default withRouter(connect(mapStateToProp)(Main));