import React from 'react';

import APIModel from "../../Components/APIModel";
import {useTranslation} from "react-i18next";


const Tags = (
    {
        match,
    }
) => {
    const {t, i18n} = useTranslation();
    const TAGS_COLUMNS = [
    {
        name: t('settings.tags.column.name', 'Name'),
        selector: "name",
    },
];
    return <APIModel
        heading={t('settings.tags.page.title', 'Tags')}
        subheading=''
        icon="fa fas fa-cog icon-gradient bg-night-fade"
        apiPath='store/tags'
        tableColumns={TAGS_COLUMNS}
        tableEmptyMessage={t('settings.tags.list.message.no_data', 'No tags')}
        match={match}
        hasFilters={true}
    />;
};

export default Tags;
