export const SET_UPGRADE_TO_PRO_MODAL_OPEN = 'BILLING/SET_UPGRADE_TO_PRO_MODAL_OPEN';

export const BILLING_CONFIG_REQUEST_START = 'BILLING/BILLING_CONFIG_REQUEST_START';
export const BILLING_CONFIG_REQUEST_SUCCESS = 'BILLING/BILLING_CONFIG_REQUEST_SUCCESS';
export const BILLING_CONFIG_REQUEST_FAIL = 'BILLING/BILLING_CONFIG_REQUEST_FAIL';

export const CHARGEBEE_CREATE_PORTAL_SESSION_REQUEST_START = 'BILLING/CHARGEBEE_CREATE_PORTAL_SESSION_REQUEST_START';
export const CHARGEBEE_CREATE_PORTAL_SESSION_REQUEST_SUCCESS = 'BILLING/CHARGEBEE_CREATE_PORTAL_SESSION_REQUEST_SUCCESS';
export const CHARGEBEE_CREATE_PORTAL_SESSION_REQUEST_FAIL = 'BILLING/CHARGEBEE_CREATE_PORTAL_SESSION_REQUEST_FAIL';

export const CHARGEBEE_GENERATE_HOSTED_PAGE_REQUEST_START = 'BILLING/CHARGEBEE_GENERATE_HOSTED_PAGE_REQUEST_START';
export const CHARGEBEE_GENERATE_HOSTED_PAGE_REQUEST_SUCCESS = 'BILLING/CHARGEBEE_GENERATE_HOSTED_PAGE_REQUEST_SUCCESS';
export const CHARGEBEE_GENERATE_HOSTED_PAGE_REQUEST_FAIL = 'BILLING/CHARGEBEE_GENERATE_HOSTED_PAGE_REQUEST_FAIL';

export const CONTACT_SALES_REQUEST_START = 'BILLING/CONTACT_SALES_REQUEST_START';
export const CONTACT_SALES_REQUEST_SUCCESS = 'BILLING/CONTACT_SALES_REQUEST_SUCCESS';
export const CONTACT_SALES_REQUEST_FAIL = 'BILLING/CONTACT_SALES_REQUEST_FAIL';


export const setUpgradeToPROModalOpen = (open) => ({
    type: SET_UPGRADE_TO_PRO_MODAL_OPEN,
    open,
});


// Chargebee

export const chargebeeCreatePortalSessionRequestStart = (apiPath, cancelTokenSource) => ({
    type: CHARGEBEE_CREATE_PORTAL_SESSION_REQUEST_START,
    apiPath,
    cancelTokenSource,
});

export const chargebeeCreatePortalSessionRequestSuccess = (result) => ({
    type: CHARGEBEE_CREATE_PORTAL_SESSION_REQUEST_SUCCESS,
    result,
});

export const chargebeeCreatePortalSessionRequestFail = (errors) => ({
    type: CHARGEBEE_CREATE_PORTAL_SESSION_REQUEST_FAIL,
    errors,
});


export const chargebeeGenerateHostedPageRequestStart = (apiPath, cancelTokenSource) => ({
    type: CHARGEBEE_GENERATE_HOSTED_PAGE_REQUEST_START,
    apiPath,
    cancelTokenSource,
});

export const chargebeeGenerateHostedPageRequestSuccess = (result) => ({
    type: CHARGEBEE_GENERATE_HOSTED_PAGE_REQUEST_SUCCESS,
    result,
});

export const chargebeeGenerateHostedPageRequestFail = (errors) => ({
    type: CHARGEBEE_GENERATE_HOSTED_PAGE_REQUEST_FAIL,
    errors,
});


export const contactSalesRequestStart = (apiPath, cancelTokenSource) => ({
    type: CONTACT_SALES_REQUEST_START,
    apiPath,
    cancelTokenSource,
});

export const contactSalesRequestSuccess = (result) => ({
    type: CONTACT_SALES_REQUEST_SUCCESS,
    result,
});

export const contactSalesRequestFail = (errors) => ({
    type: CONTACT_SALES_REQUEST_FAIL,
    errors,
});


// Конфиг

export const billingConfigRequestStart = (data) => ({
    type: BILLING_CONFIG_REQUEST_START,
    data,
});

export const billingConfigRequestSuccess = (result) => ({
    type: BILLING_CONFIG_REQUEST_SUCCESS,
    result,
});

export const billingConfigRequestFail = (errors) => ({
    type: BILLING_CONFIG_REQUEST_FAIL,
    errors,
});
