import Loader from "react-loaders/dist/react-loaders";
import React from "react";

const Preloader = () => {
    return (
        <div className="loader-container">
            <div className="loader
            -container-inner">
                <div className="text-center">
                    <Loader type="line-scale-party"/>
                </div>
                <h6 className="mt-3">
                    Launching a nuclear reactor...
                    <small>The power of emerging tech is working for You!</small>
                </h6>
            </div>
        </div>
    )
};

export default Preloader
