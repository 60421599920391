import React from "react";
import i18next from 'i18next';

import {
    ADAPTER_STATUS_STOPPED,
    ADAPTER_STATUS_VK_GROUP_NO_CALLBACK,
    ADAPTER_STATUS_VK_GROUP_NO_EVENTS,
    ADAPTER_STATUS_VK_GROUP_BOT_MESSAGES_DISABLED,
    ADAPTER_STATUS_TELEGRAM_ACCESS_TOKEN_EXPIRED,
    ADAPTER_STATUS_TELEGRAM_ACCESS_TOKEN_INVALID,
    ADAPTER_STATUS_WAITING,
    ADAPTER_STATUS_PHONE_NOT_CONNECTED,
    ADAPTER_STATUS_WAIT_FOR_MESSAGE,
    ADAPTER_STATUS_WRONG_WEBHOOK,
    ADAPTER_STATUS_RUNNING,
    BOT_STATUS_CREATED,
    BOT_STATUS_RUNNING,
    BOT_STATUS_STOPPED,
    BOT_TYPE_WA,
    ADAPTER_STATUS_ERROR,
} from "../../../../constants";

export const getStatus = (bot) => {
    let className;
    if (bot.status === BOT_STATUS_CREATED) {
        className = 'opacity-4';
    } else if (bot.status === BOT_STATUS_RUNNING) {
        className = 'text-danger';

        switch (bot.adapter_status) {
            case undefined:
                className = 'opacity-4';
                break;
            case ADAPTER_STATUS_ERROR:
                className = 'text-danger';
                break;
            case ADAPTER_STATUS_STOPPED:
                className = 'text-danger';
                break;
            case ADAPTER_STATUS_VK_GROUP_NO_CALLBACK:
                className = 'text-danger';
                break;
            case ADAPTER_STATUS_VK_GROUP_NO_EVENTS:
                className = 'text-danger';
                break;
            case ADAPTER_STATUS_VK_GROUP_BOT_MESSAGES_DISABLED:
                className = 'text-danger';
                break;
            case ADAPTER_STATUS_WAITING:
                className = 'text-warning';
                break;
            case ADAPTER_STATUS_PHONE_NOT_CONNECTED:
                className = 'text-warning';
                break;
            case ADAPTER_STATUS_WAIT_FOR_MESSAGE:
                className = 'text-warning';
                break;
            case ADAPTER_STATUS_WRONG_WEBHOOK:
                className = 'text-warning';
                break;
            case ADAPTER_STATUS_RUNNING:
                className = 'text-success';
                break;
            case ADAPTER_STATUS_TELEGRAM_ACCESS_TOKEN_EXPIRED:
                className = 'text-danger';
                break;
            case ADAPTER_STATUS_TELEGRAM_ACCESS_TOKEN_INVALID:
                className = 'text-danger';
                break;
            default:
                throw `Wrong AdapterStatus: ${bot.adapter_status}`;
        }
    } else if (bot.status === BOT_STATUS_STOPPED) {
        className = 'opacity-4';
    } else {
        throw `Wrong BotStatus: ${bot.status}`;
    }

    return <span className={className}>●</span>;
};

export const getTopLine = (bot) => {
    let className, text;
    if (bot.status === BOT_STATUS_CREATED) {
        className = 'bg-light';
        text = i18next.t('pages.dashboard.bot_cards.status_not_started', 'Not started');
    } else if (bot.status === BOT_STATUS_RUNNING) {
        switch (bot.adapter_status) {
            case undefined:
                className = undefined;
                text = undefined;
                break;
            case ADAPTER_STATUS_ERROR:
                className="bg-danger opacity-4 text-white";
                text = i18next.t('pages.dashboard.bot_cards.status_error_connecting', 'Connection error');
                break;
            case ADAPTER_STATUS_STOPPED:
                className="bg-danger opacity-4 text-white";
                text = i18next.t('pages.dashboard.bot_cards.status_stopped', 'Stopped');
                break;
            case ADAPTER_STATUS_VK_GROUP_NO_CALLBACK:
                className="bg-danger opacity-4 text-white";
                text = i18next.t('pages.dashboard.bot_cards.status_no_callback', 'Callback not set.\nReconnect required');
                break;
            case ADAPTER_STATUS_VK_GROUP_NO_EVENTS:
                className="bg-danger opacity-4 text-white";
                text = i18next.t('pages.dashboard.bot_cards.status_group_event_required', 'Messenger events are not set in group settings');
                break;
            case ADAPTER_STATUS_VK_GROUP_BOT_MESSAGES_DISABLED:
                className="bg-danger opacity-4 text-white";
                text = i18next.t('pages.dashboard.bot_cards.status_vk_bot_not_enable', 'Bot is not enabled in settings/messages');
                break;
            case ADAPTER_STATUS_WAITING:
                className = "bg-warning opacity-8";
                if (bot.bot_type === BOT_TYPE_WA) {
                    text = i18next.t('pages.dashboard.bot_cards.status_scan_qr', 'Scan QR-code');
                } else if (bot.bot_type === BOT_TYPE_WA) {
                    text = i18next.t('pages.dashboard.bot_cards.status_connecting_to_wa', 'Connecting to WhatsApp...');
                } else {
                    text = i18next.t('pages.dashboard.bot_cards.status_initiallization', 'Initialization...');
                }
                break;
            case ADAPTER_STATUS_PHONE_NOT_CONNECTED:
                className = 'bg-warning opacity-8';
                text = i18next.t('pages.dashboard.bot_cards.status_phone_not_online', 'Phone is not online');
                break;
            case ADAPTER_STATUS_WAIT_FOR_MESSAGE:
                className = 'bg-warning opacity-8';
                text = i18next.t('pages.dashboard.bot_cards.status_waiting_for_message', 'Waiting for inbound message');
                break;
            case ADAPTER_STATUS_WRONG_WEBHOOK:
                className = 'bg-warning opacity-8';
                text = i18next.t('pages.dashboard.bot_cards.status_webhook_url_invalid', 'Webhook is set to another bot');
                break;
            case ADAPTER_STATUS_RUNNING:
                className = undefined;
                text = undefined;
                break;
            case ADAPTER_STATUS_TELEGRAM_ACCESS_TOKEN_EXPIRED:
                className="bg-danger opacity-4 text-white";
                text = i18next.t('pages.dashboard.bot_cards.status_token_has_changed', 'Token has changed');
                break;
            case ADAPTER_STATUS_TELEGRAM_ACCESS_TOKEN_INVALID:
                className="bg-danger opacity-4 text-white";
                text = i18next.t('pages.dashboard.bot_cards.status_wrong_token', 'Token is incorrect');
                break;
            default:
                throw `Wrong AdapterStatus: ${bot.adapter_status}`;
        }
    } else if (bot.status === BOT_STATUS_STOPPED) {
        className = 'bg-light';
        text = i18next.t('pages.dashboard.bot_cards.status_stopped', 'Stopped');
    } else {
        throw `Wrong BotStatus: ${bot.status}`;
    }

    if (!className || !text) {
        return
    }

    return <div className={`d-flex justify-content-center align-items-center p-2 text-uppercase font-size-md ${className}`}>
        {text}
    </div>
};