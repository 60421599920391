import React from "react";
import {Col, Row, Container} from "reactstrap";
import {useTranslation, Trans} from "react-i18next";

import {
    getBannerLink,
} from "../../../../helpers/links";


const Landing = () => {
    const {t, i18n} = useTranslation();
    
    return <Container className="landing mb-5">
        <Row>
            {!!t('pages.main.landing.banners.banner1.image', '') && <Col xs="12" sm="6" xl="4">
                <a href={getBannerLink(1)} target="_blank" className="landing__link">
                    <img src={t('pages.main.landing.banners.banner1.image', '')} style={{width: '100%'}} className="mb-2"/>
                    <Trans t={t} i18nKey='pages.main.landing.banners.banner1.text'>
                        <h4 className="my-2">Learning Videos</h4>
                        <p>
                            A few short videos to quickly to get acquainted with the system
                        </p>
                    </Trans>
                </a>
            </Col>}
            {!!t('pages.main.landing.banners.banner2.image', '') && <Col xs="12" sm="6" xl="4">
                <a href={getBannerLink(2)} target="_blank" className="landing__link">
                    <img src={t('pages.main.landing.banners.banner2.image', '')} style={{width: '100%'}} className="mb-2"/>
                    <Trans t={t} i18nKey='pages.main.landing.banners.banner2.text'>
                        <h4 className="my-2">Questions?</h4>
                        <p>
                            We respond promptly on WhatsApp for any questions
                        </p>
                    </Trans>
                </a>
            </Col>}
            {!!t('pages.main.landing.banners.banner3.image', '') && <Col xs="12" sm="6" xl="4">
                <a href={getBannerLink(3)} target="_blank" className="landing__link">
                    <img src={t('pages.main.landing.banners.banner3.image', '')} style={{width: '100%'}} className="mb-2"/>
                    <Trans t={t} i18nKey='pages.main.landing.banners.banner3.text'>
                        <h4 className="my-2">15-Minute Demo</h4>
                        <p>
                            We'll give you a demonstration and answer your questions via video call
                        </p>
                    </Trans>
                </a>
            </Col>}
        </Row>
    </Container>;
};

export default Landing;
