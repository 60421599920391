import React, {useEffect} from 'react'
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import pick from 'lodash/pick'
import forIn from 'lodash/forIn'
import Form from "react-jsonschema-form";
import {Button} from "reactstrap";

import {
    apiFormDeleteSweetalertHide,
    apiFormSetFormData,
    apiFormSetFormSchema,
    apiFormSetLiveValidate,
} from '../../actions/APIModel'
import {renderErrorsAsHTML} from '../utils'
import SelectField from "../APIForm/Fields/SelectField";
import ImageWidget from "../APIForm/Fields/ImageWidget";
import MultiSelectField from "../APIForm/Fields/MultiSelectField";
import MultilangTextInputField from "../APIForm/Fields/MultilangTextInputField";
import CheckboxField from "../APIForm/Fields/CheckboxField";
import MultilangTextareaField from "../APIForm/Fields/MultilangTextareaField";
import MultilangMultiSelectField from "../APIForm/Fields/MultilangMultiSelectField";
import FileWidget from "../APIForm/Fields/FileWidget";
import VideoWidget from "../APIForm/Fields/VideoWidget";
import {
    apiFormDataRequest,
    apiFormDeleteRequest,
    apiFormSchemaRequest,
    apiFormSubmitRequest,
} from "../../thunks/ApiModel";


const APIForm2 = (
    {
        // Props
        apiPath,
        modelId,
        schemaPath,
        emptyMessage,
        actionRedirectUrl,
        afterSubmitCallback,
        apiFormAfterDeleteCallback,
        buttonSubmitDisabled,

        // State
        data,
        errors,
        formSchema,
        formUISchema,
        isSendingDataRequest,
        isSendingSchemaRequest,
        isSendingActionRequest,
        liveValidate,
        activeShop,

        // Actions
        apiFormSchemaRequest,
        apiFormDataRequest,
        apiFormSubmitRequest,
        apiFormDeleteRequest,
        apiFormDeleteSweetalertHide,
        apiFormSetFormData,
        apiFormSetFormSchema,
        apiFormSetLiveValidate,
    }
) => {
    const {t, i18n} = useTranslation();
    // --------------------------
    // Hooks
    // --------------------------
    // componentDidMount && componentWillUnmount
    useEffect(() => {
        loadData();

        // componentWillUnmount
        return () => {
            apiFormSetFormData(undefined);
            apiFormSetFormSchema(undefined, undefined);
            apiFormSetLiveValidate(false);
        };
    }, [activeShop]);


    // --------------------------
    // --------------------------
    // --------------------------

    const getFormUISchema = (uiSchema) => {
        // 1. Check uiSchema is object, null or undefined
        if (typeof uiSchema !== 'object' && uiSchema !== undefined) {
            throw 'Wrong UI Schema'
        }

        const result = {};

        for (let [key, value] of Object.entries(uiSchema || {})) {
            // Recursively exec myself
            if (typeof value === 'object' && value !== null) {
                result[key] = getFormUISchema(value);
            } else if (key === "ui:field") {
                switch (value) {
                    case 'SelectField':
                        result[key] = SelectField;
                        break;
                    case 'CheckboxField':
                        result[key] = CheckboxField;
                        break;
                    case 'MultiSelectField':
                        result[key] = MultiSelectField;
                        break;
                    case 'MultilangTextInputField':
                        result[key] = MultilangTextInputField;
                        break;
                    case 'MultilangTextareaField':
                        result[key] = MultilangTextareaField;
                        break;
                    case 'MultilangMultiSelectField':
                        result[key] = MultilangMultiSelectField;
                        break;
                    default:
                        throw `Wrong ui:field "${value}"`
                }
            } else if (key === "ui:widget") {
                switch (value) {
                    case 'ImageWidget':
                        result[key] = ImageWidget;
                        break;
                    case 'FileWidget':
                        result[key] = FileWidget;
                        break;
                    case 'VideoWidget':
                        result[key] = VideoWidget;
                        break;
                    default:
                        result[key] = value;
                }
            } else if (key === "ui:description") {
                result[key] = <div dangerouslySetInnerHTML={{__html: value}}/>;
            } else {
                result[key] = value;
            }
        }

        return result;
    };

    const loadData = () => {
        if (isSendingDataRequest || isSendingSchemaRequest) {
            return;
        }

        // ?shop_id= здесь нужен, чтобы отобразить правильные объекты в выпадающих списках
        apiFormSchemaRequest(`${apiPath}/${modelId}/${schemaPath || 'form_schema'}/?shop_id=${activeShop.id}`);

        if (modelId === undefined) {
            const defaultFormData = {
                shop_id: activeShop.id,
            };
            apiFormSetFormData(defaultFormData);
        } else {
            apiFormDataRequest(`${apiPath}/${modelId}/`);
        }
    }

    const onChange = ({formData}) => {
        apiFormSetFormData(formData);
    };

    const onSubmit = ({idSchema}) => {
        if (buttonSubmitDisabled || isSendingActionRequest) {
            return;
        }

        let formKeys = keys(idSchema);
        formKeys.push('shop_id');
        let formDataFiltered = pick(data, formKeys);

        let apiUrl = `${apiPath}/`;
        if (modelId) {
            apiUrl += `${modelId}/`;
        }

        apiFormSubmitRequest(
            apiUrl,
            modelId ? 'patch' : 'post',
            formDataFiltered,
            actionRedirectUrl,
            (response) => {
                afterSubmitCallback && afterSubmitCallback(response);
            }
        );
    };

    const onDelete = () => {
        apiFormDeleteRequest(
            `${apiPath}/${modelId}/`,
            actionRedirectUrl,
        );

        apiFormAfterDeleteCallback && apiFormAfterDeleteCallback();

        apiFormDeleteSweetalertHide();
    };


    if (isSendingDataRequest || isSendingSchemaRequest) {
        return <div></div>;
    } else if (data === undefined && !isEmpty(errors)) {
        return <div className='p-4'>
            <h4>{forIn(errors.common, (k, v) => renderErrorsAsHTML(v))}</h4>
            <h4>{forIn(errors.detail, (k, v) => renderErrorsAsHTML(v))}</h4>
            <button className="mb-2 mr-2 btn btn-light" onClick={loadData}>{t('components.api_form.button.retry', 'Try again')}</button>
        </div>
    } else if (data === undefined) {
        return <div className="p-4">
            <h4>{emptyMessage ? emptyMessage : t('components.api_form.message.no_data', 'No data')}</h4>
            <button className="mb-2 mr-2 btn btn-light" onClick={loadData}>{t('components.api_form.button.retry', 'Try again')}</button>
        </div>
    } else if (formSchema === undefined) {
        return <div className="p-4">
            <h4>💢{t('components.api_form.message.something_went_wrong', 'Something went wrong')}</h4>
            <button className="mb-2 mr-2 btn btn-light" onClick={loadData}>{t('components.api_form.button.retry', 'Try again')}</button>
        </div>
    } else {
        return <Form
            schema={formSchema}
            uiSchema={getFormUISchema(formUISchema)}
            formData={data}
            onSubmit={onSubmit}
            onChange={onChange}
            // ArrayFieldTemplate={ArrayFieldTemplate}
            // fields={FIELDS_CONFIG}
        >
            <Button
                color="primary"
                className=" btn-lg"
                type="submit"
            >
                {t('components.api_form.button.save', 'Save')}
            </Button>
        </Form>;
    }
}


const mapStateToProps = state => ({
    data: state.APIForm.data,
    errors: state.APIForm.errors,
    formSchema: state.APIForm.formSchema,
    formUISchema: state.APIForm.formUISchema,
    isSendingDataRequest: state.APIForm.isSendingDataRequest,
    isSendingSchemaRequest: state.APIForm.isSendingSchemaRequest,
    isSendingActionRequest: state.APIForm.isSendingActionRequest,
    liveValidate: state.APIForm.liveValidate,
    activeShop: state.Shops.activeShop,
});

const mapDispatchToProps = dispatch => ({
    apiFormSchemaRequest: (apiPath) => dispatch(apiFormSchemaRequest(apiPath)),
    apiFormDataRequest: (apiPath) => dispatch(apiFormDataRequest(apiPath)),
    apiFormSubmitRequest: (apiPath, method, formData, redirectUrl, callback) => dispatch(apiFormSubmitRequest(apiPath, method, formData, redirectUrl, callback)),
    apiFormDeleteRequest: (apiPath, redirectUrl, callback) => dispatch(apiFormDeleteRequest(apiPath, redirectUrl, callback)),
    apiFormDeleteSweetalertHide: () => dispatch(apiFormDeleteSweetalertHide()),

    apiFormSetFormData: (formData) => dispatch(apiFormSetFormData(formData)),
    apiFormSetFormSchema: (formSchema, formUISchema) => dispatch(apiFormSetFormSchema(formSchema, formUISchema)),
    apiFormSetLiveValidate: (liveValidate) => dispatch(apiFormSetLiveValidate(liveValidate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(APIForm2);
