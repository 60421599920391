import React, {useEffect, useState} from "react";
import {Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown,} from "reactstrap";
import {connect} from "react-redux";
import moment from 'moment';
import get from 'lodash/get';
import {useTranslation} from "react-i18next";

import StatisticCard from './StatisticCard';
import {loadChartsData} from "../../../../thunks/Statistic";
import FormDateRangePicker from "../../../../Components/DateRangePicker";


const Statistic = (
    {
        activeShop,
        chartsData,

        loadChartsData,
    }
) => {
    const {t, i18n} = useTranslation();

    const PERIODS = [
        {
            name: t('pages.main.statistic.range_7_days', '7 days'),
            period: 'day',
        },
        {
            name: t('pages.main.statistic.range_30_days', '30 days'),
            period: 'day',
        },
        {
            name: t('pages.main.statistic.range_3_mon', '3 months'),
            period: 'month',
        },
    ];
    const [activePeriod, setActivePeriod] = useState(PERIODS[0]);

    // --------------------------
    // Hooks
    // --------------------------
    // componentDidMount && componentDidUpdate
    useEffect(
        () => {
            const now = new Date();
            const endOfTheDay = moment(
                new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    now.getDate(),
                    23,
                    59,
                    59,
                )
            );
            const endOfTheMonth = moment(
                new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    0,
                    0,
                    0,
                    0,
                )
            )
                .add(1, 'months')
                .subtract(1, 'seconds');

            let ts_from = undefined;
            let ts_to = undefined;
            switch (activePeriod.name) {
                case t('pages.main.statistic.range_7_days', '7 days'):
                    ts_to = endOfTheDay.unix();
                    ts_from = endOfTheDay.subtract(7, 'days').unix();
                    break;
                case t('pages.main.statistic.range_30_days', '30 days'):
                    ts_to = endOfTheDay.unix();
                    ts_from = endOfTheDay.subtract(30, 'days').unix();
                    break;
                case t('pages.main.statistic.range_3_mon', '3 months'):
                    ts_to = endOfTheMonth.unix();
                    ts_from = endOfTheMonth.subtract(3, 'months').add(2, 'days').unix();
                    break;
                default:
                    throw `Wrong activePerion: ${activePeriod.name}`;
            }

            loadChartsData(
                activeShop.id,
                [
                    'chats__active',
                    'users__new',
                    'leads__new',
                ],
                ts_from,
                ts_to,
                activePeriod.period,
            );
        },
        [activePeriod]
    );

    return <Container>
        <h4 className='my-4'>
            {t('pages.main.statistic.title', 'Statistics')}

            <UncontrolledDropdown className='pull-right'>
                <DropdownToggle caret tag="div" className='font-size-lg'>
                    {t('pages.main.statistic.filter_date', 'Period')}:{' '}
                    <a href="" onClick={(e) => e.preventDefault()}>{activePeriod.name}</a>
                </DropdownToggle>
                <DropdownMenu style={{top: '2rem'}}>
                    {
                        PERIODS.map(
                            (period, i) => <DropdownItem
                                key={i}
                                onClick={() => setActivePeriod(period)}
                                active={period.name === activePeriod.name}
                            >
                                {period.name}
                            </DropdownItem>
                        )
                    }
                </DropdownMenu>
            </UncontrolledDropdown>

            {/* TODO: перейти на этот пикер */}
            {/*<FormDateRangePicker />*/}
        </h4>
        <Row>
            <Col xs="12" sm="6" xl="4">
                <StatisticCard
                    title={t('pages.main.statistic.title_dialogs', 'Dialogs')}
                    caption={t('pages.main.statistic.caption_dialogs', 'Number of unique chats with messages sent during selected period')}
                    data={get(chartsData, 'chats__active')}
                />
            </Col>
            <Col xs="12" sm="6" xl="4">
                <StatisticCard
                    title={t('pages.main.statistic.title_new_subscribers', 'New subscribers')}
                    caption={t('pages.main.statistic.caption_new_subscribers', 'Number of unique users, subscribed to bot first time during period')}
                    data={get(chartsData, 'users__new')}
                />
            </Col>
            <Col xs="12" sm="6" xl="4">
                <StatisticCard
                    title={t('pages.main.statistic.title_leads', 'Leads')}
                    caption={t('pages.main.statistic.caption_leads', 'Total leads count across all the channels')}
                    data={get(chartsData, 'leads__new')}
                />
            </Col>
        </Row>
    </Container>;
};

const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    chartsData: state.Statistic.chartsData,
});

const mapDispatchToProps = dispatch => ({
    loadChartsData: (shopId, metrics, ts_from, ts_to, period, callback) => dispatch(loadChartsData(shopId, metrics, ts_from, ts_to, period, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistic);

