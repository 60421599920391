import React from "react";
import {connect} from "react-redux";
import {Button, Card} from "reactstrap";
import qs from 'qs';
import {Trans, useTranslation} from "react-i18next";

import fbLogo from '../../../../assets/utils/images/messenger/facebook.svg';

const ConnectCardFacebook = (
    {
        activeShop,
        user,
    }
) => {
    const {t, i18n} = useTranslation();

    // Attention! This redirect URL is used in service_core python code! DO NOT CHANGE!
    const state = `${activeShop.id}-${user.id}`
    const redirectUri = qs.stringify({redirect_uri: `https://${window.location.hostname}/service/service_core/facebook/oauth_login`});
	 //const redirectUri = qs.stringify({redirect_uri: `https://fb.ereb.ru/service/service_core/facebook/oauth_login`});
    const oauthUrl = `https://www.facebook.com/v8.0/dialog/oauth?client_id=${process.env.REACT_APP_FB_APP_ID}&${redirectUri}&state=${state}&auth_type=rerequest&scope=pages_show_list,pages_messaging,pages_manage_metadata`;

    return <Card className="mb-3 profile-responsive">
        <div className="dropdown-menu-header text-dark">
            <div className="dropdown-menu-header-inner bg-white">
                <div className="menu-header-content btn-pane-right d-block text-center">
                    <h5 className="d-flex align-items-center justify-content-center mb-3">
                        <img src={fbLogo} alt="Facebook" style={{height: 30}} className='mr-2'/>
                        Facebook
                    </h5>
                    <h6 className='opacity-6 my-4'>
                        {t('connect_cards.facebook.caption', 'Connect your Facebook page to HighTouch and connect with your followers in Messenger')}
                    </h6>
                    <h2 className='my-4'>
                        <Trans t={t} i18nKey='connect_cards.facebook.tariff'>
                            <strong> </strong><small>Free</small>
                        </Trans>
                    </h2>
                    <div className="menu-header-btn-pane">
                        <a href={oauthUrl}>
                            <Button color="main" route='/'>
                                {t('connect_bot.common.submit', 'Connect')}
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </Card>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    user: state.Auth.user,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCardFacebook);
