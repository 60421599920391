import get from "lodash/get";

import {
    loadChartsDataRequestFail,
    loadChartsDataRequestStart,
    loadChartsDataRequestSuccess,
} from '../actions/Statistic';
import {apiAxiosInstance} from "../helpers/api";

export const loadChartsData = (shopId, metrics, ts_from, ts_to, period, callback) => {
    return function (dispatch) {
        const requestData = {
            shop_id: shopId,
            metrics,
            ts_from,
            ts_to,
            period,
        };

        dispatch(loadChartsDataRequestStart(requestData));

        apiAxiosInstance.post(`analytics/chart_data/`, requestData).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(loadChartsDataRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(loadChartsDataRequestFail(response.data.errors));
                }
            },
            error => dispatch(loadChartsDataRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    }
};
