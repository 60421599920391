import React, {useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    Card,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    ListGroup,
    ListGroupItem,
    UncontrolledDropdown
} from "reactstrap";
import get from 'lodash/get';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStop} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert-react";

import waLogo from '../../../../assets/utils/images/messenger/wa.png';
import chatbotEmpty from '../../../../assets/utils/images/chatbot-empty.svg';
import {
    BOT_STATUS_RUNNING,
    SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU,
    SHOP_SOURCE_RETAIL_CRM_WABA_RU
} from "../../../../constants";
import {deleteBot, restartBot, stopBot} from "../../../../thunks/Dashboard";
import {getStatus, getTopLine} from "./utils";
import {truncateString} from "../../../../Components/utils";


const BotCardWABA = (
    {
        activeShop,
        bot,
        restartBot,
        stopBot,
        deleteBot,
    }
) => {
    const {t, i18n} = useTranslation();

    const [showRestartAlert, setShowRestartAlert] = useState(false);

    const isRetailCRM = (get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_WABA_RU
      || get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU);
    const isTestBot = get(bot, 'is_test') === true;

    return <>
        <Card className="mb-3 profile-responsive bot-card">
            {getTopLine(bot)}

            <div className="position-relative">
                {
                    !isTestBot && <UncontrolledDropdown>
                        <DropdownToggle color="light" className='bot-card__menu-toggle text-dark'>
                            <i className="pe-7s-menu"/>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => setShowRestartAlert(true)}
                            >
                                <i className="pe-7s-plug fsize-2 text-primary"/>&nbsp;
                                {t('connect_bot.common.reconnect', 'Reconnect')}
                            </DropdownItem>
                            <DropdownItem
                                onClick={
                                    () => stopBot(
                                        bot.id,
                                        () => {
                                            window.location.reload()
                                        }
                                    )
                                }
                                disabled={bot.status !== BOT_STATUS_RUNNING}
                            >
                                <FontAwesomeIcon
                                    icon={faStop}
                                    className='text-primary'
                                />&nbsp;&nbsp;
                                {t('connect_bot.common.stop', 'Stop')}
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                }

                <div className="bot-card__status">
                    {getStatus(bot)}
                </div>

                <div className="dropdown-menu-header text-dark mt-0">
                    <div className="dropdown-menu-header-inner bg-white">
                        <div className="menu-header-content btn-pane-right d-block text-center">
                            <h5 className="d-flex align-items-center justify-content-center mb-3">
                                <img src={waLogo} alt="WhatsApp" style={{height: 30}} className='mr-2'/>
                                {bot.name}
                            </h5>

                            <div className="avatar-icon-wrapper mr-3 avatar-icon-xl btn-hover-shine">
                                <div className="avatar-icon rounded">
                                    <img src={bot.avatar_url || chatbotEmpty}/>
                                </div>
                            </div>

                            <h6>{bot.waba_phone_number}</h6>

                            <div>
                                <small>{t('bot_card.common.link_to_chat', "Link to chat")}:</small>
                                <br/>
                                <a
                                    href={`https://wa.me/${bot.waba_phone_number}${bot.testbot_jwt ? '?text=' + bot.testbot_jwt : ''}`}
                                    target='_blank'
                                >
                                    {truncateString(`https://wa.me/${bot.waba_phone_number}${bot.testbot_jwt ? '?text=' + bot.testbot_jwt : ''}`, 36)}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <ListGroup flush>
                    <ListGroupItem>
                        <div className="widget-content px-0 pb-0 pt-1">
                            <div className="text-center">
                                <a
                                    href={`https://wa.me/${bot.waba_phone_number}${bot.testbot_jwt ? '?text=' + bot.testbot_jwt : ''}`}
                                    target='_blank'
                                    className='btn btn-main btn-lg'
                                >
                                      {t('bot_card.common.test_channel', 'Test channel')}
                                </a>
                            </div>
                        </div>
                    </ListGroupItem>
                </ListGroup>
                {/*{false && <ListGroup flush>*/}
                {/*    <ListGroupItem>*/}
                {/*        <div className="widget-content px-0 pb-0 pt-1">*/}
                {/*            <div className="text-center">*/}
                {/*            <span className="widget-heading opacity-4 mb-0">*/}
                {/*                {t('bot_card.common.users', 'Subscribers')}*/}
                {/*            </span>*/}
                {/*                <h6 className="mt-2 mb-3">*/}
                {/*            <span className="pr-2">*/}
                {/*                <b className="text-primary">{get(bot, 'statistic.count_bot_users_day')}</b> {t('bot_card.common.users_new_today', 'new for totay')},*/}
                {/*            </span>*/}
                {/*                    <span>*/}
                {/*                <b className="text-info">{get(bot, 'statistic.count_bot_users_all')}</b> {t('bot_card.common.users_total', 'total')}*/}
                {/*            </span>*/}
                {/*                </h6>*/}
                {/*            </div>*/}
                {/*            {*/}
                {/*                !isRetailCRM && <div className="text-center widget-heading mb-0">*/}
                {/*                    <span className="opacity-4">*/}
                {/*                        {t('bot_card.common.leads_total', 'Leads')}:&nbsp;*/}
                {/*                    </span>*/}
                {/*                    {get(bot, 'statistic.leads_count_all')}*/}

                {/*                    /!*<Button color="primary" className="btn-wide btn-pill" outline>*!/*/}
                {/*                    /!*    Full Report*!/*/}
                {/*                    /!*</Button>*!/*/}
                {/*                </div>*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    </ListGroupItem>*/}
                {/*</ListGroup>}*/}
            </div>
        </Card>

        <SweetAlert
            title={t('bot_card.waba.reconnect', "Reinstall WABA number?")}
            confirmButtonColor="#d92550"
            show={showRestartAlert}
            text={`⚠️ Number ${bot.waba_phone_number} will be disconnected from other accounts!`}
            type="warning"
            showCancelButton
            cancelButtonText={t('bot_card.common.button_cancel', 'Cancel')}
            onConfirm={() => {
                restartBot(
                    bot.id,
                    () => {
                        window.location.reload()
                    }
                );
                setShowRestartAlert(false);
            }}
            onCancel={() => setShowRestartAlert(false)}
        />
    </>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
});

const mapDispatchToProps = dispatch => ({
    restartBot: (bot_id, callback) => dispatch(restartBot(bot_id, callback)),
    stopBot: (bot_id, callback) => dispatch(stopBot(bot_id, callback)),
    deleteBot: (bot_id, callback) => dispatch(deleteBot(bot_id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BotCardWABA);
