export const ACTIVATE_BOT_REQUEST_START = 'CONNECT/ACTIVATE_BOT_REQUEST_START';
export const ACTIVATE_BOT_REQUEST_SUCCESS = 'CONNECT/ACTIVATE_BOT_REQUEST_SUCCESS';
export const ACTIVATE_BOT_REQUEST_FAIL = 'CONNECT/ACTIVATE_BOT_REQUEST_FAIL';

export const SHUTDOWN_BOT_REQUEST_START = 'CONNECT/SHUTDOWN_BOT_REQUEST_START';
export const SHUTDOWN_BOT_REQUEST_SUCCESS = 'CONNECT/SHUTDOWN_BOT_REQUEST_SUCCESS';
export const SHUTDOWN_BOT_REQUEST_FAIL = 'CONNECT/SHUTDOWN_BOT_REQUEST_FAIL';

export const GROUPS_REQUEST_START = 'CONNECT/GROUPS_REQUEST_START';
export const GROUPS_REQUEST_SUCCESS = 'CONNECT/GROUPS_REQUEST_SUCCESS';
export const GROUPS_REQUEST_FAIL = 'CONNECT/GROUPS_REQUEST_FAIL';

export const BOT_CREATE_REQUEST_START = 'CONNECT/BOT_CREATE_REQUEST_START';
export const BOT_CREATE_REQUEST_SUCCESS = 'CONNECT/BOT_CREATE_REQUEST_SUCCESS';
export const BOT_CREATE_REQUEST_FAIL = 'CONNECT/BOT_CREATE_REQUEST_FAIL';

export const AVITO_BOT_CREATE_REQUEST_START = 'CONNECT/AVITO_BOT_CREATE_REQUEST_START';
export const AVITO_BOT_CREATE_REQUEST_SUCCESS = 'CONNECT/AVITO_BOT_CREATE_REQUEST_SUCCESS';
export const AVITO_BOT_CREATE_REQUEST_FAIL = 'CONNECT/AVITO_BOT_CREATE_REQUEST_FAIL';

export const BOT_UPDATE_REQUEST_START = 'CONNECT/BOT_UPDATE_REQUEST_START';
export const BOT_UPDATE_REQUEST_SUCCESS = 'CONNECT/BOT_UPDATE_REQUEST_SUCCESS';
export const BOT_UPDATE_REQUEST_FAIL = 'CONNECT/BOT_UPDATE_REQUEST_FAIL';

export const CLEAR_ERRORS = 'CONNECT/CLEAR_ERRORS';


export const activateBotRequestStart = () => ({
    type: ACTIVATE_BOT_REQUEST_START,
});

export const activateBotRequestSuccess = (bot_id) => ({
    type: ACTIVATE_BOT_REQUEST_SUCCESS,
    bot_id,
});

export const activateBotRequestFail = (errors) => ({
    type: ACTIVATE_BOT_REQUEST_FAIL,
    errors,
});


export const shutdownBotRequestStart = () => ({
    type: SHUTDOWN_BOT_REQUEST_START,
});

export const shutdownBotRequestSuccess = (bot_id) => ({
    type: SHUTDOWN_BOT_REQUEST_SUCCESS,
    bot_id,
});

export const shutdownBotRequestFail = (errors) => ({
    type: SHUTDOWN_BOT_REQUEST_FAIL,
    errors,
});


export const groupsRequestStart = () => ({
    type: GROUPS_REQUEST_START,
});

export const groupsRequestSuccess = (count, items) => ({
    type: GROUPS_REQUEST_SUCCESS,
    count,
    items
});

export const groupsRequestFail = (errors) => ({
    type: GROUPS_REQUEST_FAIL,
    errors,
});


export const createBotRequestStart = () => ({
    type: BOT_CREATE_REQUEST_START,
});

export const createBotRequestSuccess = (result) => ({
    type: BOT_CREATE_REQUEST_SUCCESS,
    result,
});

export const createBotRequestFail = (errors) => ({
    type: BOT_CREATE_REQUEST_FAIL,
    errors,
});


export const createAvitoBotRequestStart = (data) => ({
    type: AVITO_BOT_CREATE_REQUEST_START,
    data,
});

export const createAvitoBotRequestSuccess = (result) => ({
    type: AVITO_BOT_CREATE_REQUEST_SUCCESS,
    result,
});

export const createAvitoBotRequestFail = (errors) => ({
    type: AVITO_BOT_CREATE_REQUEST_FAIL,
    errors,
});


export const updateBotRequestStart = () => ({
    type: BOT_UPDATE_REQUEST_START,
});

export const updateBotRequestSuccess = (result) => ({
    type: BOT_UPDATE_REQUEST_SUCCESS,
    result,
});

export const updateBotRequestFail = (errors) => ({
    type: BOT_UPDATE_REQUEST_FAIL,
    errors,
});


export const clearErrors = () => ({
    type: CLEAR_ERRORS,
});
