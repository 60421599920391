import get from "lodash/get";

import {
    askPasswordResetRequestFail,
    askPasswordResetRequestStart,
    askPasswordResetRequestSuccess,

    setNewPasswordRequestStart,
    setNewPasswordRequestSuccess,
    setNewPasswordRequestFail,

    registerRequestFail,
    registerRequestStart,
    registerRequestSuccess,

    sendAuthHSMRequestFail,
    sendAuthHSMRequestStart,
    sendAuthHSMRequestSuccess,

    sendCodeRequestFail,
    sendCodeRequestStart,
    sendCodeRequestSuccess,

    verifyCodeRequestFail,
    verifyCodeRequestStart,
    verifyCodeRequestSuccess,

    getMeRequestStart,
    getMeRequestSuccess,
    getMeRequestFail,

    retailCRMRegisterRequestFail,
    retailCRMRegisterRequestStart,
    retailCRMRegisterRequestSuccess,
} from '../actions/Auth'
import {eventsSetUserId} from "../Components/utils";
import {
    serviceAuthAxiosInstance,
    apiAuthAxiosInstance
} from "../helpers/api";


export const askPasswordReset = (email) => {
    return function (dispatch) {
        dispatch(askPasswordResetRequestStart(email));

        const data = {
            email,
        };

        apiAuthAxiosInstance.post('ask_password_reset/', data).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(askPasswordResetRequestSuccess(email, response.data.result));
                } else {
                    dispatch(askPasswordResetRequestFail(response.data.errors));
                }
            },
            error => {
                dispatch(askPasswordResetRequestFail(get(error, 'response.data.errors')));
            },
        );
    }
};

export const setNewPassword = (password, token, callback) => {
    return function (dispatch) {
        dispatch(setNewPasswordRequestStart(password, token));

        const data = {
            password,
            token,
        };

        apiAuthAxiosInstance.post('password_reset/', data).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(setNewPasswordRequestSuccess(password, token, response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(setNewPasswordRequestFail(response.data.errors));
                }
            },
            error => dispatch(setNewPasswordRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    }
};

export const sendAuthHSM = (phone, lang_code, callback) => {
    return function (dispatch) {
        dispatch(sendAuthHSMRequestStart(phone));

        const data = {
            phone,
            lang_code,
        };

        serviceAuthAxiosInstance.post('auth_hsm', data).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(sendAuthHSMRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(sendAuthHSMRequestFail(response.data.errors));
                }
            },
            error => dispatch(sendAuthHSMRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    }
};

export const sendCode = (phone_number, lang_code, redirectTo) => {
    return function (dispatch) {
        dispatch(sendCodeRequestStart(phone_number));

        const data = {
            phone: phone_number,
            lang_code,
        };

        serviceAuthAxiosInstance.post('auth_hsm', data).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(sendCodeRequestSuccess(response.data.result));
                    if (redirectTo) {
                        window.location.href = redirectTo;
                    }
                } else {
                    dispatch(sendCodeRequestFail(response.data.errors));
                }
            },
            error => dispatch(sendCodeRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    }
};

export const verifyCode = (phone_number, code, callback) => {
    return function (dispatch) {
        dispatch(verifyCodeRequestStart(phone_number, code));

        const data = {
            phone: phone_number,
            code,
        };

        serviceAuthAxiosInstance.post('verify_code', data).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(verifyCodeRequestSuccess(response.data.result, phone_number, code));
                    callback && callback(response.data.result);
                } else {
                    dispatch(verifyCodeRequestFail(response.data.errors));
                }
            },
            error => dispatch(verifyCodeRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    }
};

export const register = (
    email,
    phone_number,
    code,
    password,
    passwordrep,
    check,
    referral_code,
    lang_code,
    callback,
) => {
    return function (dispatch) {
        dispatch(registerRequestStart(email, phone_number, code, password, passwordrep, check, referral_code));
        
        const data = {
            password,
            passwordrep,
            check,
            ref: referral_code,
            lang_code,
        };
        
        let showEmailNotification = false;
        
        if (phone_number && code) {
            data['phone'] = phone_number;
            data['code'] = code;
        } else if (email) {
            data['email'] = email;
            showEmailNotification = true;
        }

        serviceAuthAxiosInstance.post('register_shop', data).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(registerRequestSuccess(showEmailNotification));
                    callback && callback(response.data.result);
                } else {
                    dispatch(registerRequestFail(response.data.errors));
                }
            },
            error => dispatch(registerRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    };
};


export const retailCRMRegister = (
    module_name,
    phone,
    retailcrm_url,
    retailcrm_api_key,
    code,
    callback,
) => {
    return function (dispatch) {
        dispatch(retailCRMRegisterRequestStart(module_name, phone, retailcrm_url, retailcrm_api_key, code));

        const data = {
            module_name,
            phone,
            retailcrm_url,
            retailcrm_api_key,
            code,
        };

        serviceAuthAxiosInstance.post('register_retailcrm', data).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(retailCRMRegisterRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(retailCRMRegisterRequestFail(response.data.errors));
                }
            },
            error => dispatch(retailCRMRegisterRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    };
};

export const getMe = (authToken, callback) => {
    return function (dispatch) {
        dispatch(getMeRequestStart(authToken));

        apiAuthAxiosInstance.get('get_me/', {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        }).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(getMeRequestSuccess(authToken, response.data.result.user));
                    eventsSetUserId(response.data.result.user.id);
                    callback && callback(response.data.result);
                } else {
                    dispatch(getMeRequestFail(response.data.errors));
                }
            },
            error => dispatch(getMeRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    };
};
