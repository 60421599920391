import {
    GET_FLOW_TEMPLATE_CATEGORIES_REQUEST_START,
    GET_FLOW_TEMPLATE_REQUEST_FAIL,
    GET_FLOW_TEMPLATE_REQUEST_SUCCESS,

    SET_FLOW_TEMPLATE_REQUEST_FAIL,
    SET_FLOW_TEMPLATE_REQUEST_SUCCESS,
    SET_FLOW_TEMPLATE_REQUEST_START,
} from '../actions/FlowTemplates';


const defaultState = {
    isSendingFlowTemplateCategoriesRequest: false,
    isSendingSetFlowTemplateRequest: false,

    categoriesError: undefined,
    setTemplateError: undefined,
    categories: [],
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case GET_FLOW_TEMPLATE_CATEGORIES_REQUEST_START:
            return {
                ...state,
                isSendingFlowTemplateCategoriesRequest: true,
                categoriesError: undefined,
            };
        case GET_FLOW_TEMPLATE_REQUEST_SUCCESS:
            return {
                ...state,
                categories: action.result?.results || [],
                categoriesError: undefined,
                isSendingFlowTemplateCategoriesRequest: false,
            };
        case GET_FLOW_TEMPLATE_REQUEST_FAIL:
            return {
                ...state,
                categoriesError: action.error.message,
                isSendingFlowTemplateCategoriesRequest: false,
            };

        case SET_FLOW_TEMPLATE_REQUEST_START:
            return {
                ...state,
                isSendingSetFlowTemplateRequest: true,
            };
        case SET_FLOW_TEMPLATE_REQUEST_SUCCESS:
            return {
                ...state,
                isSendingSetFlowTemplateRequest: false,
            };
        case SET_FLOW_TEMPLATE_REQUEST_FAIL:
            return {
                ...state,
                setTemplateError: action.error.message,
                isSendingSetFlowTemplateRequest: false,
            };
    }
    return state;
}
