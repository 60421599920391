import React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import {store} from "../../../../configureStore";
import {withTranslation} from "react-i18next";


class ChoiceNodeWidget_ extends AbstractNodeWidget {
    constructor() {
        super();

        this.type = 'choice-node';
    }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <i className="pe-7s-menu"> </i> {options.step.name || this.props.t('pages.main.flow_builder.node.choice.name', 'Choice')}
        </>;
    }

    getErrors() {
        const options = this.props.node.options;
        const text = get(options.step.text, store.getState().Shops.activeShop.lang_by_default);
        const imageUrl = options.step.image_url;

        const errors = [];
        if (!text && !imageUrl) {
            errors.push(this.props.t('pages.main.flow_builder.node.choice.error.add_text_or_image', 'Please provide text or image'));
        }

        if (isEmpty(get(options.step, 'state.answer_choices'))) {
            errors.push(this.props.t('pages.main.flow_builder.node.choice.error.add_answer_options', 'Please add options'));
        }

        if (!get(options.step, 'state.answer_field')) {
            errors.push(this.props.t('pages.main.flow_builder.node.choice.error.specify_variable', 'Please specify variable to store answer'));
        }

        return errors.map((error, idx) => <div className='py-2' key={idx}>
                <i className='text-danger opacity-9'>{error}</i>
            </div>
        );
    }

    getBody() {
        const answer_choices = get(this.props.node.options.step, 'state.answer_choices');
        if (isEmpty(answer_choices)) {
            return '';
        }

        return <>
            {AbstractNodeWidget.prototype.getBody.call(this)}
            <div className="w-100 pt-2 px-3">{
                answer_choices.map(
                    (choice, idx) => {
                        const lng = Object.keys(choice.button_text)[0];
                        return <div className="d-flex align-items-center py-1" key={idx}>
                            <input type='checkbox' disabled={true}/>&nbsp;&nbsp;{choice.button_text[lng]}
                        </div>;
                    }
                )
            }</div>
        </>;
    }
}

export const ChoiceNodeWidget = withTranslation()(ChoiceNodeWidget_);

export class ChoiceNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'choice-node'
        });
    }
}


export class ChoiceNodeFactory extends AbstractReactFactory {
    constructor() {
        super('choice-node');
    }

    generateModel(event) {
        return new ChoiceNodeModel();
    }

    generateReactWidget(event) {
        return <ChoiceNodeWidget engine={this.engine} node={event.model}/>;
    }
}
