import React from 'react';
import get from "lodash/get";
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import {
    // CUTOM_FIELD_TYPE_TEXT,
    // CUTOM_FIELD_TYPE_NUMBER,
    // CUTOM_FIELD_TYPE_BOOL,
    // CUTOM_FIELD_TYPE_IMAGE,
    // CUTOM_FIELD_TYPE_FILE,
    CUTOM_FIELD_TYPE_DATE,
    CUTOM_FIELD_TYPE_DATETIME,
    // CUTOM_FIELD_TYPE_JSON,
    // CUTOM_FIELD_TYPE_LIST_TEXT,
    // CUTOM_FIELD_TYPE_LIST_NUMBER,
    CUTOM_FIELD_OPERATION_SET_EXACT,
    // CUTOM_FIELD_OPERATION_EXEC_FORMULA,
    CUTOM_FIELD_OPERATION_SET_DATETIME_NOW,
    CUTOM_FIELD_OPERATION_SET_FROM_OTHER_FIELD,
} from "../../../../constants";
import {withTranslation} from "react-i18next";


class FormulaNodeWidget_ extends AbstractNodeWidget {
    constructor(props) {
        super(props);

        this.type = 'formula-node';
    }

    // getHeaderClassName() {
    //     return 'bg-tempting-azure bg-opacity-3';
    // }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <i className="pe-7s-science"> </i> {options.step.name || this.props.t('pages.main.flow_builder.node.formula.name', 'Formula')}
        </>
    }

    getBody() {
        const options = this.props.node.options;
        const formulaField = get(options.step, 'state.formula_field');
        const operation = get(options.step, 'state.operation');
        const dateExact = get(options.step, 'state.date_exact');
        const datetimeExact = get(options.step, 'state.datetime_exact');
        const otherField = get(options.step, 'state.other_field');
        const hasOffset = get(options.step, 'state.has_offset');
        const offsetConfig = get(options.step, 'state.offset_config');
        const dateFormulaConfig = get(options.step, 'state.date_formula_config');
        const datetimeFormulaConfig = get(options.step, 'state.datetime_formula_config');
        const formulaConfig = get(options.step, 'state.formula_config');

        if (!formulaField) {
            return undefined;
        }

        switch (formulaField.dtype) {
            case CUTOM_FIELD_TYPE_DATE:
                if (!operation) {
                    return undefined;
                }

                if (operation.id === CUTOM_FIELD_OPERATION_SET_EXACT) {
                    return dateExact && <div>
                        <strong>{formulaField.name}</strong> = {dateExact}
                    </div>;
                } else if (operation.id === CUTOM_FIELD_OPERATION_SET_DATETIME_NOW) {
                    return <div>
                        <strong>{formulaField.name}</strong> = {this.props.t('pages.main.flow_builder.node.formula.text.current_date', 'current date')} {
                        hasOffset && <span>{offsetConfig.sign} {offsetConfig.amount} {offsetConfig.interval.id}</span>
                    }
                    </div>;
                } else if (operation.id === CUTOM_FIELD_OPERATION_SET_FROM_OTHER_FIELD) {
                    return otherField && <div>
                        <strong>{formulaField.name}</strong> = {otherField.name} {
                        hasOffset && <span>{offsetConfig.sign} {offsetConfig.amount} {offsetConfig.interval.id}</span>
                    }
                    </div>;
                }
                return dateFormulaConfig && <div>
                    <strong>{formulaField.name}</strong> = {dateFormulaConfig}
                </div>;
            case CUTOM_FIELD_TYPE_DATETIME:
                if (!operation) {
                    return undefined;
                }

                if (operation.id === CUTOM_FIELD_OPERATION_SET_EXACT) {
                    return datetimeExact && <div>
                        <strong>{formulaField.name}</strong> = {datetimeExact}
                    </div>;
                } else if (operation.id === CUTOM_FIELD_OPERATION_SET_DATETIME_NOW) {
                    return <div>
                        <strong>{formulaField.name}</strong> = {this.props.t('pages.main.flow_builder.node.formula.text.current_date_and_time', 'current date and time')} {
                        hasOffset && <span>{offsetConfig.sign} {offsetConfig.amount} {offsetConfig.interval.id}</span>
                    }
                    </div>;
                } else if (operation.id === CUTOM_FIELD_OPERATION_SET_FROM_OTHER_FIELD) {
                    return otherField && <div>
                        <strong>{formulaField.name}</strong> = {otherField.name} {
                        hasOffset && <span>{offsetConfig.sign} {offsetConfig.amount} {offsetConfig.interval.id}</span>
                    }
                    </div>;
                }
                return datetimeFormulaConfig && <div>
                    <strong>{formulaField.name}</strong> = {datetimeFormulaConfig}
                </div>;
            default:
                return formulaConfig && <div>
                    <strong>{formulaField.name}</strong> = {formulaConfig}
                </div>;
        }
    }

    getErrors() {
        const options = this.props.node.options;
        const formulaField = get(options.step, 'state.formula_field');
        const operation = get(options.step, 'state.operation');
        const dateExact = get(options.step, 'state.date_exact');
        const datetimeExact = get(options.step, 'state.datetime_exact');
        const otherField = get(options.step, 'state.other_field');
        const hasOffset = get(options.step, 'state.has_offset');
        const offsetConfig = get(options.step, 'state.offset_config');
        const dateFormulaConfig = get(options.step, 'state.date_formula_config');
        const datetimeFormulaConfig = get(options.step, 'state.datetime_formula_config');
        const formulaConfig = get(options.step, 'state.formula_config');

        if (!formulaField) {
            return <i className='text-danger opacity-9'>
                {this.props.t('pages.main.flow_builder.node.formula.text.formula_is_blank', 'Formula is not specified')}
            </i>;
        }

        switch (formulaField.dtype) {
            case CUTOM_FIELD_TYPE_DATE:
            case CUTOM_FIELD_TYPE_DATETIME:
                if (!operation) {
                    return <i className='text-danger opacity-9'>
                        {this.props.t('pages.main.flow_builder.node.formula.text.formula_is_blank', 'Formula is not specified')}
                    </i>;
                }
                break;
            default:
                if (!formulaConfig || !formulaField) {
                    return <i className='text-danger opacity-9'>
                        {this.props.t('pages.main.flow_builder.node.formula.text.formula_is_blank', 'Formula is not specified')}
                    </i>;
                }
        }
    }
}

export const FormulaNodeWidget = withTranslation()(FormulaNodeWidget_);

export class FormulaNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'formula-node',
            disableCascadePort: true,
        });
    }
}


export class FormulaNodeFactory extends AbstractReactFactory {
    constructor() {
        super('formula-node');
    }

    generateModel(event) {
        return new FormulaNodeModel();
    }

    generateReactWidget(event) {
        return <FormulaNodeWidget engine={this.engine} node={event.model}/>;
    }
}
