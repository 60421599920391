import {toast} from "react-toastify";
import i18next from 'i18next';
import assign from 'lodash/assign';

import {store} from "../configureStore";
import {
    getTemplatesRequestStart,
    getTemplatesRequestSuccess,
    getTemplatesRequestFail,

    installTemplateRequestStart,
    installTemplateRequestSuccess,
    installTemplateRequestFail,
} from '../actions/Templates'
import {renderErrorsAsString} from "../Components/utils";
import {apiAxiosInstance} from "../helpers/api";


export const getTemplates = (filters, callback) => {
    return function (dispatch) {
        let data = assign({
            shop_id: store.getState().Shops.activeShop.id,
        }, filters);

        dispatch(getTemplatesRequestStart(data));

        apiAxiosInstance.get('template/templates/', {params: data}).then(
            response => {
                if (response.data.results !== undefined) {
                    dispatch(getTemplatesRequestSuccess(response.data.results));
                    callback && callback(response.data.result);
                } else {
                    toast.error(renderErrorsAsString(response.data.errors));
                    dispatch(getTemplatesRequestFail(data, response.data.errors));
                }
            },
            error => {
                toast.error(i18next.t('errors.common.request_sent', 'Error sending request'));
                dispatch(getTemplatesRequestFail(data, {
                    'common': i18next.t('errors.common.request_sent', 'Error sending request'),
                }));
            },
        );
    };
};


export const installTemplate = (templateId, callback) => {
    return function (dispatch) {
        let data = {
            shop_id: store.getState().Shops.activeShop.id,
        };
        dispatch(installTemplateRequestStart(templateId, data));

        apiAxiosInstance.post(`template/templates/${templateId}/install/`, data).then(
            response => {
                if (response.data.status === 'ok') {
                    dispatch(installTemplateRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    toast.error(renderErrorsAsString(response.data.errors));
                    dispatch(installTemplateRequestFail(data, response.data.errors));
                }
            },
            error => {
                toast.error(i18next.t('errors.common.request_sent', 'Error sending request'));
                dispatch(installTemplateRequestFail(data, {
                    'common': i18next.t('errors.common.request_sent', 'Error sending request'),
                }));
            },
        );
    };
};
