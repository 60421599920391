import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap";
import {Link} from 'react-router-dom'
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import forOwn from "lodash/forOwn";
import assign from "lodash/assign";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import SweetAlert from "sweetalert-react";
import i18next from "i18next";

import MultiSelectField from "../../../Components/APIForm/Fields/MultiSelectField";
import {setActiveBotUser, setActiveBotUserSchema,} from "../../../actions/Audience";
import {
    loadBotUserById,
    loadBotUserSchemaById,
    subscribeToFlow,
    unsubscribeFromFlow,
} from "../../../thunks/Audience";
import {
    BOT_TYPE_AVITO,
    BOT_TYPE_EXPRESS,
    BOT_TYPE_FB,
    BOT_TYPE_IG,
    BOT_TYPE_RETAILCRM,
    BOT_TYPE_TG,
    BOT_TYPE_VIBER,
    BOT_TYPE_VK,
    BOT_TYPE_WA,
    BOT_TYPE_WABA,
} from "../../../constants";
import {apiFormSubmitRequest} from "../../../thunks/ApiModel";
import {
    CUTOM_FIELD_TYPE_BOOL,
    CUTOM_FIELD_TYPE_DATE,
    CUTOM_FIELD_TYPE_DATETIME,
    CUTOM_FIELD_TYPE_FILE,
    CUTOM_FIELD_TYPE_IMAGE,
    CUTOM_FIELD_TYPE_JSON,
    CUTOM_FIELD_TYPE_NUMBER,
    CUTOM_FIELD_TYPE_TEXT,
    CUTOM_FIELD_TYPE_LIST_TEXT,
    CUTOM_FIELD_TYPE_LIST_NUMBER
} from "../../../constants";


const emptyAva = <svg className='img-fluid' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212" width="212"
                      height="212">
    <path fill="#CCC"
          d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"></path>
    <g fill="#FFF">
        <path
            d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z"></path>
    </g>
</svg>;


const API_PATH = 'bot/bot_users';


const valueSafePrint = (val, dtype) => {
    if (dtype === CUTOM_FIELD_TYPE_IMAGE) {
        return <div className='mt-2'>
            <img src={val} style={{width: '100%', maxWidth: '170px', maxHeight: '170px'}}/>
        </div>;
    } else if (dtype === CUTOM_FIELD_TYPE_FILE) {
        return <a href={val}>{i18next.t('chats.detail.file_link', "link to file")}</a>;
    } else if (dtype === CUTOM_FIELD_TYPE_BOOL) {
        return val ? '✔️' : '✖️';
    } else if (dtype === CUTOM_FIELD_TYPE_LIST_NUMBER) {
        return (!Array.isArray(val) &&
          <span className='text-danger'>{i18next.t('chats.detail.list_is_none', "Not defined")}</span>)
          || (val.length ? val.join(', ') :
            <span className='text-warning'>{i18next.t('chats.detail.list_is_empty', "Empty")}</span>)
    } else if (dtype === CUTOM_FIELD_TYPE_LIST_TEXT) {
        return (!Array.isArray(val) &&
          <span className='text-danger'>{i18next.t('chats.detail.list_is_none', "Not defined")}</span>)
          || (val.length ?
            <ol start={0} style={{paddingLeft: '45px'}}>
                {val.map((item) => <li key={item.toString()}>
                    {item}
                </li>)}
            </ol> :
            <span className='text-warning'>{i18next.t('chats.detail.list_is_empty', "Empty")}</span>)
    } else if (Array.isArray(val) || (typeof val === 'object' && val !== null)) {
        return JSON.stringify(val, null, 4);
    }

    return val
};


const BotUser = (
    {
        // Props
        botUserId,

        // Redux Store
        activeChat,
        activeBotUser,
        activeBotUserSchema,

        // Redux Actions
        apiFormSubmitRequest,
        setActiveBotUser,
        setActiveBotUserSchema,
        loadBotUserById,
        loadBotUserSchemaById,
        subscribeToFlow,
        unsubscribeFromFlow,
    }
) => {
    const {t, i18n} = useTranslation();

    // State
    const [unsubscribeFromFlowPopupShown, setUnsubscribeFromFlowPopupShown] = useState(false);
    const [flowToUnsubscribe, setFlowToUnsubscribe] = useState();
    const [subscribeToFlowPopupShown, setSubscribeToFlowPopupShown] = useState(false);

    // --------------------------
    // Hooks
    // --------------------------
    // componentDidMount && componentDidUpdate
    useEffect(() => {
        reloadBotUser();
        reloadBotUserSchema();
    }, [botUserId]);

    // componentWillUnmount
    useEffect(() => {
        return clearData;
    }, []);

    // --------------------------
    // --------------------------
    // --------------------------

    const reloadBotUser = () => {
        loadBotUserById(
            botUserId,
            (newActiveBotUser) => setActiveBotUser(newActiveBotUser),
            clearData,
        );
    };

    const reloadBotUserSchema = () => {
        loadBotUserSchemaById(
            botUserId,
            (newActiveBotUserSchema) => setActiveBotUserSchema(newActiveBotUserSchema),
            clearData,
        );
    };

    const clearData = () => {
        setActiveBotUser();
        setActiveBotUserSchema();
    };

    const updateBotUser = (data) => {
        const apiUrl = `${API_PATH}/${activeBotUser.id}/`;
        const method = 'patch';
        const formData = assign(
            {
                shop_id: activeBotUser.shop_id,
            },
            data,
        );
        apiFormSubmitRequest(
            apiUrl,
            method,
            formData,
            undefined,
            (updatedBotUser) => setActiveBotUser(updatedBotUser),
        );
    };

    const unsubscribeFromFlowConfirm = () => {
        unsubscribeFromFlow(
            activeBotUser.id,
            flowToUnsubscribe.id,
            () => {
                setUnsubscribeFromFlowPopupShown(false);
                setTimeout(reloadBotUser, 150);
            },
            () => {
                setUnsubscribeFromFlowPopupShown(false);
            },
        );
    };

    const toggleSubscribeToFlowPopup = () => {
        setSubscribeToFlowPopupShown(!subscribeToFlowPopupShown);
    };

    const subscribeToFlowConfirm = (flowId) => {
        subscribeToFlow(
            activeBotUser.id,
            flowId,
            () => {
                setSubscribeToFlowPopupShown(false);
                // TODO: грязный хак, чтобы юзер побыстрее увидел новый сценарий
                setTimeout(reloadBotUser, 2000);
                setTimeout(reloadBotUser, 3000);
                setTimeout(reloadBotUser, 5000);
            },
            () => {
                setSubscribeToFlowPopupShown(false);
            },
        );
    };

    // --------------------------
    // Html
    // --------------------------

    if (isEmpty(activeBotUser) || isEmpty(activeBotUserSchema)) {
        return '';
    }

    const flowsDefinitions = activeBotUserSchema.schema.definitions.flows;
    const stepsDefinitions = activeBotUserSchema.schema.definitions.steps;
    const activeFlowsHTML = activeBotUser.store.active_flows.map(el => {
        const i = findIndex(flowsDefinitions, (flow) => (flow && flow.id === el.flow_id));
        if (i === -1) {
            return;
        }
        const flow = flowsDefinitions[i];
        const flowUrl = `/${activeBotUser.shop_id}/flows/${flow.id}/`;

        let stepName = t('chats.detail.removed_step', 'removed step');
        const j = findIndex(stepsDefinitions, (step) => step.id === el.step_id);
        if (j !== -1) {
            stepName = stepsDefinitions[j].name;
        }

        return <ListGroupItem className='justify-content-between d-flex align-items-center py-2' key={el.flow_id}>
            <span>
                <Link to={flowUrl}>
                    {flow.is_default &&
                    <i className="fa fas fa-play text-success font-weight-bold position-relative mr-1   "
                       style={{top: 1}}/>}
                    {flow.name}
                </Link>:&nbsp; {stepName}
            </span>
            <button
                className='btn-pill btn btn-outline-primary p-1 d-flex justify-content-center align-items-center'
                onClick={() => {
                    setFlowToUnsubscribe(flow);
                    setUnsubscribeFromFlowPopupShown(true);
                }}
            >
                <i className="fa fas fa-times" style={{fontSize: '1rem', width:'16px', height:'16px'}}/>
            </button>
        </ListGroupItem>
    });
    const flowsToSubscribeHTML = flowsDefinitions
        .filter(flow => (flow && findIndex(activeBotUser.store.active_flows, el => el.flow_id === flow.id) === -1))
        .map(flow => <ListGroupItem
            action
            className='hover-pointer'
            onClick={() => subscribeToFlowConfirm(flow.id)}
        >
            {flow.name}
        </ListGroupItem>);

    const customFieldsHTML = [];
    const customFieldsDefinitions = activeBotUserSchema.schema.definitions.custom_fields;
    forOwn(
        activeBotUser.store.user_vars,
        (v, k) => {
            const i = findIndex(customFieldsDefinitions, (field) => (field && field.name === k));
            if (i === -1) {
                return;
            }
            const customField = customFieldsDefinitions[i];

            let iconCls = undefined;
            switch (customField.dtype) {
                case CUTOM_FIELD_TYPE_NUMBER:
                    iconCls = 'fa fas fa-chart-bar text-black-50 opacity-8';
                    break;
                case CUTOM_FIELD_TYPE_TEXT:
                    iconCls = 'lnr-text-size';
                    break;
                case CUTOM_FIELD_TYPE_IMAGE:
                    iconCls = 'lnr-picture';
                    break;
                case CUTOM_FIELD_TYPE_FILE:
                    iconCls = 'lnr-file-empty';
                    break;
                case CUTOM_FIELD_TYPE_DATE:
                    iconCls = 'lnr-calendar-full';
                    break;
                case CUTOM_FIELD_TYPE_DATETIME:
                    iconCls = 'lnr-calendar-full';
                    break;
                case CUTOM_FIELD_TYPE_JSON:
                    iconCls = 'lnr-code';
                    break;
                case CUTOM_FIELD_TYPE_BOOL:
                    iconCls = 'lnr-checkmark-circle';
                    break;
                case CUTOM_FIELD_TYPE_LIST_NUMBER:
                    iconCls = 'lnr-list';
                    break;
                case CUTOM_FIELD_TYPE_LIST_TEXT:
                    iconCls = 'lnr-list';
                    break;
                default:
                    throw `Wrong custom field dtype: "${customField.dtype}"`;
            }

            customFieldsHTML.push(
                <ListGroupItem key={k}>
                    <strong>
                        <i className={cx(iconCls, "position-relative mr-2 font-size-lg")} style={{top: 2}}/>
                    </strong>
                    {' '}
                    {customField.name}:&nbsp; {valueSafePrint(v, customField.dtype)}
                </ListGroupItem>
            );
        }
    );

    const savedBankCardsHTML = activeBotUser.store.saved_bank_cards.map(
        (card, i) => <ListGroupItem key={i}>
            <strong>
                <i className="position-relative mr-2 font-size-lg" style={{top: 2}}/>
            </strong>
            {' '}
            {card.name}
        </ListGroupItem>
    );

    // Profile ID info
    let profileIDinfo = undefined;
    let chatInfo = undefined;
    if (activeBotUser.bot.bot_type === BOT_TYPE_WA) {
        profileIDinfo = <>
            <strong>
                <i className="pe-7s-call position-relative mr-2 font-size-lg" style={{top: 2}}/>
            </strong>{' '}
            {t('chats.detail.wa_phone', "Phone number")}:&nbsp; {activeBotUser.messenger_user_id}
        </>;
    } else if (activeBotUser.bot.bot_type === BOT_TYPE_TG) {
        profileIDinfo = <>
            <strong>
                <i className="fa fas fa-address-card text-black-50 opacity-8 position-relative mr-2 font-size-lg" style={{top: 2}}/>
            </strong>{' '}
            Telegram ID:&nbsp; {activeBotUser.messenger_user_id}
        </>;
    } else if (activeBotUser.bot.bot_type === BOT_TYPE_FB) {
        profileIDinfo = <>
            <strong>
                <i className="fa fas fa-address-card text-black-50 opacity-8 position-relative mr-2 font-size-lg" style={{top: 2}}/>
            </strong>{' '}
            Facebook ID:&nbsp; {activeBotUser.messenger_user_id}
        </>;
    } else if (activeBotUser.bot.bot_type === BOT_TYPE_VK) {
        profileIDinfo = <>
            <strong>
                <i className="fa fas fa-address-card text-black-50 opacity-8 position-relative mr-2 font-size-lg" style={{top: 2}}/>
            </strong>{' '}
            VK ID:&nbsp; {activeBotUser.messenger_user_id}
        </>;
    } else if (activeBotUser.bot.bot_type === BOT_TYPE_AVITO) {
        profileIDinfo = <>
            <strong>
                <i className="pe-7s-link position-relative mr-2 font-size-lg" style={{top: 2}}/>
            </strong>
            {
                activeBotUser.messenger_user_url && <>
                    <a href={activeBotUser.messenger_user_url} target='_blank'>
                        {t('chats.detail.avito_profile', "Avito profile")}
                    </a>{' '}&nbsp;{' '}
                    <small className='opacity-5'>ID:&nbsp;{activeBotUser.messenger_user_id.split('-')[3]}</small>
                </>
            }
            {
                !activeBotUser.messenger_user_url && <>
                    {t('chats.detail.avito_id', "Avito ID")}:&nbsp;{activeBotUser.messenger_user_id}
                </>
            }
        </>;
        if (get(activeChat, 'url_in_messenger')) {
            chatInfo = <>
                <strong>
                    <i className="pe-7s-link position-relative mr-2 font-size-lg" style={{top: 2}}/>
                </strong>
                <>
                    <a href={activeChat.url_in_messenger} target='_blank'>
                        {t('chats.detail.avito_chat', "Avito chat")}
                    </a>{' '}&nbsp;{' '}
                    <small className='opacity-5'>ID:&nbsp;{activeChat.id_in_messenger}</small>
                </>
            </>;
        }
    } else if (activeBotUser.bot.bot_type === BOT_TYPE_WABA) {
        profileIDinfo = <>
            <strong>
                <i className="pe-7s-call position-relative mr-2 font-size-lg" style={{top: 2}}/>
            </strong>{' '}
            {t('chats.detail.wa_phone', "Phone number")}:&nbsp; {activeBotUser.messenger_user_id}
        </>;
    } else if (activeBotUser.bot.bot_type === BOT_TYPE_VIBER) {
        profileIDinfo = <>
            <strong>
                <i className="fa fas fa-address-card text-black-50 opacity-8 position-relative mr-2 font-size-lg" style={{top: 2}}/>
            </strong>{' '}
            Viber ID:&nbsp; {activeBotUser.messenger_user_id}
        </>;
    } else if (activeBotUser.bot.bot_type === BOT_TYPE_IG) {
        profileIDinfo = <>
            <strong>
                <i className="fa fas fa-address-card text-black-50 opacity-8 position-relative mr-2 font-size-lg" style={{top: 2}}/>
            </strong>{' '}
            Instagram ID:&nbsp; {activeBotUser.messenger_user_id}
        </>;
    } else if (activeBotUser.bot.bot_type === BOT_TYPE_EXPRESS) {
        profileIDinfo = <>
            <strong>
                <i className="fa fas fa-address-card text-black-50 opacity-8 position-relative mr-2 font-size-lg" style={{top: 2}}/>
            </strong>{' '}
            Express HUID:&nbsp; {activeBotUser.messenger_user_id}
        </>;
    } else if (activeBotUser.bot.bot_type === BOT_TYPE_RETAILCRM) {
        profileIDinfo = <>
            <strong>
                <i className="fa fas fa-address-card text-black-50 opacity-8 position-relative mr-2 font-size-lg" style={{top: 2}}/>
            </strong>{' '}
            RetailCRM id:&nbsp; {activeBotUser.messenger_user_id}
        </>;
    }

    return <Fragment>
        <div className="app-inner-layout__top-pane border-bottom p-3">
            <div className="pane-left d-flex justify-content-between w-100">
                <h4 className="mb-0 text-nowrap">
                    {activeBotUser.name}
                </h4>
            </div>
        </div>

        <PerfectScrollbar>
            <div className='flex-column d-flex justify-content-center'>
                <div className="p-3 text-center pt-4">
                    {
                        activeBotUser.avatar_url
                            ? <img
                                src={activeBotUser.avatar_url}
                                className='img-fluid br-10'
                                style={{maxWidth: 'calc(max(150px, 50%)'}}
                            /> : emptyAva
                    }
                </div>

                <ListGroup className='p-3'>
                    <ListGroupItem className='justify-content-between d-flex align-items-center'>
                        <strong
                            className={cx({
                                'text-success': activeBotUser.store.subscribed_to_bot,
                                'text-warning': !activeBotUser.store.subscribed_to_bot
                            })}
                        >
                            <i className="fa fas fa-check-circle position-relative mr-2 font-size-lg" style={{top: 2}}/>
                            {' '}
                            {
                                activeBotUser.store.subscribed_to_bot
                                    ? t('chats.detail.subscribed_to_bot_true', 'Subscribed to bot')
                                    : t('chats.detail.subscribed_to_bot_false', 'Unsubscribed from bot')
                            }
                        </strong>{' '}
                        <span
                            className='text-muted hover-pointer'
                            onClick={() => updateBotUser({
                                store: {
                                    subscribed_to_bot: !activeBotUser.store.subscribed_to_bot,
                                }
                            })}
                        >
                            ({
                            activeBotUser.store.subscribed_to_bot
                                ? t('chats.detail.subscribe_to_bot', 'stop bot')
                                : t('chats.detail.unsubscribe_from_bot', 'run bot')
                        })
                        </span>
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>
                            <i className="fa fas fa-comments text-black-50 opacity-8 position-relative mr-2 font-size-lg" style={{top: 2}}/>
                        </strong> {t('chats.detail.channel', 'Channel')}:&nbsp; {activeBotUser.bot.name}
                    </ListGroupItem>
                    {
                        chatInfo && <ListGroupItem>
                            {chatInfo}
                        </ListGroupItem>
                    }
                    <ListGroupItem>
                        {profileIDinfo}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>
                            <i className="fa fas fa-address-card text-black-50 opacity-8 position-relative mr-2 font-size-lg" style={{top: 2}}/>
                        </strong> {t('chats.detail.bot_user_id', 'HighTouch ID')}:&nbsp; {botUserId}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>
                            <i className="fa fas fa-globe-americas text-black-50 opacity-8 position-relative mr-2 font-size-lg" style={{top: 2}}/>
                        </strong> {t('chats.detail.lang_code', 'Language')}:&nbsp; {activeBotUser.store.lang_code}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>
                            <i className="fa fas fa-calendar-day text-black-50 opacity-8 position-relative mr-2 font-size-lg" style={{top: 2}}/>
                        </strong> {t('chats.detail.created_at', 'Created at')}:&nbsp; {activeBotUser.created_at}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>
                            <i className="fa fas fa-calendar-day text-black-50 opacity-8 position-relative mr-2 font-size-lg" style={{top: 2}}/>
                        </strong> {t('chats.detail.updated_at', 'Updated at')}:&nbsp; {activeBotUser.updated_at}
                    </ListGroupItem>
                </ListGroup>

                <h6 className="px-3 pt-3 mb-0">{t('chats.detail.tags', 'Tags')}</h6>
                <div className="px-3 pb-3 pt-0">
                    <MultiSelectField
                        schema={{
                            title: undefined,
                            items: activeBotUserSchema.schema.properties.store.properties.tags.items,
                        }}
                        value={activeBotUser.store.tags}
                        onChange={(...args) => {
                            const newVal = args[0];
                            if (!isEqual(newVal, activeBotUser.store.tags)) {
                                updateBotUser({
                                    store: {
                                        tags: args[0],
                                    },
                                });
                            }
                        }}
                    />
                </div>

                <h6 className="px-3 pt-1 mb-0">{t('chats.detail.active_flows.title', 'Subscribed to flows')}</h6>
                <ListGroup className='p-3'>
                    {
                        isEmpty(activeFlowsHTML)
                            ? <span className="text-danger">{t('chats.detail.active_flows.empty', 'No flows yet')}</span>
                            : activeFlowsHTML
                    }
                </ListGroup>
                <Button className='mx-5' color='primary' onClick={toggleSubscribeToFlowPopup}>
                    {t('chats.detail.active_flows.subscribe_to_flow', 'Subscribe to flow')}
                </Button>

                <h6 className="px-3 pt-5 mb-0">{t('chats.detail.custom_fields.title', 'Custom fields')}</h6>
                <ListGroup className='p-3'>
                    {
                        isEmpty(customFieldsHTML)
                            ? <span className="text-danger">
                                {t('chats.detail.custom_fields.not_set', 'Custom fields are not set')}
                            </span> : customFieldsHTML
                    }
                </ListGroup>

                <h6 className="px-3 pt-4 mt-2 mb-0">
                    {t('chats.detail.bank_cards.title', 'Saved bank cards')}
                </h6>
                <ListGroup className='p-3'>
                    {
                        isEmpty(savedBankCardsHTML)
                            ? <span className="text-danger">{t('chats.detail.bank_cards.empty', 'No cards yet...')}</span>
                            : savedBankCardsHTML
                    }
                </ListGroup>
            </div>
        </PerfectScrollbar>

        <SweetAlert
            title={t('chats.detail.stop_flow.title', "Unsubscribe from flow?")}
            confirmButtonColor="#d92550"
            show={unsubscribeFromFlowPopupShown}
            text={
                t('chats.detail.stop_flow.alert_1', 'User')
                + ` "${activeBotUser.name}" `
                + t('chats.detail.stop_flow.alert_2', 'will be unsubscribed from flow')
                + ` "${get(flowToUnsubscribe, 'name')}"`
            }
            type="warning"
            showCancelButton
            cancelButtonText={t('chats.detail.stop_flow.alert_cancel', 'Cancel')}
            onConfirm={unsubscribeFromFlowConfirm}
            onCancel={() => setUnsubscribeFromFlowPopupShown(false)}
        />

        <Modal
            isOpen={subscribeToFlowPopupShown}
            toggle={toggleSubscribeToFlowPopup}
            className='chats-image-upload-modal'
        >
            <ModalHeader toggle={toggleSubscribeToFlowPopup}>
                {t('chats.detail.run_flow.title', 'Subscribe to flow?')}
            </ModalHeader>

            <ModalBody className='p-0'>
                <div
                    className='text-secondary py-3 px-4 text-center'
                    style={{'line-height': '1rem', 'font-size': '.8rem'}}
                >
                    {t('chats.detail.run_flow.alert_text', '⚠️ If image or text is specified in the default step, user will receive it in a message immediately.')}
                </div>

                {
                    flowsToSubscribeHTML
                        ? <ListGroup>
                            {flowsToSubscribeHTML}
                        </ListGroup>
                        : <span className="p-2 text-danger">
                            {t('chats.detail.run_flow.empty_flows', 'No flows yet')}
                        </span>
                }
            </ModalBody>
        </Modal>
    </Fragment>;
};


const mapStateToProps = state => ({
    activeChat: state.Chats.activeChat,
    activeBotUser: state.Audience.activeBotUser,
    activeBotUserSchema: state.Audience.activeBotUserSchema,
});

const mapDispatchToProps = dispatch => ({
    apiFormSubmitRequest: (apiPath, method, formData, redirectUrl, callback) => dispatch(apiFormSubmitRequest(apiPath, method, formData, redirectUrl, callback)),
    setActiveBotUser: (botUser) => dispatch(setActiveBotUser(botUser)),
    setActiveBotUserSchema: (schema) => dispatch(setActiveBotUserSchema(schema)),
    loadBotUserById: (botUserId, successCallback, errorCallback) => dispatch(loadBotUserById(botUserId, successCallback, errorCallback)),
    loadBotUserSchemaById: (botUserId, successCallback, errorCallback) => dispatch(loadBotUserSchemaById(botUserId, successCallback, errorCallback)),
    subscribeToFlow: (botUserId, flowId, successCallback, errorCallback) => dispatch(subscribeToFlow(botUserId, flowId, successCallback, errorCallback)),
    unsubscribeFromFlow: (botUserId, flowId, successCallback, errorCallback) => dispatch(unsubscribeFromFlow(botUserId, flowId, successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BotUser);
