import React from "react";
import {Link} from "react-router-dom"
import {Button, Col, Row} from "reactstrap";
import {useTranslation} from "react-i18next";

import {concatURL} from "../../../Components/utils";
import {REGISTRATION_BOT_LINK_BASE} from "../../../constants";


const MethodSelect = (
    {
        match
    }
) => {
    const {t, i18n} = useTranslation();

    return (
        <Row form>
            <Col md={12} className='mb-4'>
                <Button color='primary'
                        className='btn-wide btn-pill btn-shadow btn-hover-shine'
                        size='lg'
                        block
                        onClick={() => {window.location.href = `${t('auth.register.method_select.button.register_via_whatsapp_link', REGISTRATION_BOT_LINK_BASE)}?lang_code=${i18n.languages[0]}`}}
                        >
                    {t('auth.register.method_select.button.register_via_whatsapp', 'Register via WhatsApp')}
                </Button>
            </Col>
            <Col md={12}>
                <Link
                    to={concatURL(match.url, './phone')}
                    className='opacity-4'
                >
                    {t('auth.register.method_select.link.by_phone', 'I want register by filling the form')}
                </Link>
            </Col>
        </Row>
    )
};

export default MethodSelect;
