import cloneDeep from 'lodash/cloneDeep';

import {
    SET_ACTIVE_BOT_USER,
    SET_ACTIVE_BOT_USER_SCHEMA,
} from "../actions/Audience";


const defaultState = {
    activeBotUser: undefined,
    activeBotUserSchema: undefined,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case SET_ACTIVE_BOT_USER:
            return {
                ...state,
                activeBotUser: cloneDeep(action.botUser),
            };

        case SET_ACTIVE_BOT_USER_SCHEMA:
            return {
                ...state,
                activeBotUserSchema: cloneDeep(action.schema),
            };
    }
    return state;
}
