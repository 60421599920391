import React, {useEffect} from "react";
import {Redirect, Route, Switch} from 'react-router-dom'
import {Col} from 'reactstrap';
import {useTranslation} from "react-i18next";

import Phone from './Phone';
import VerifyCode from './VerifyCode';
import FullForm from './FullForm';
// import FullForm from './../RedesignAuth/FullForm';
import AccountActivated from './AccountActivated';
import MethodSelect from './MethodSelect';
import {setRegisterReferralCode} from "../../../actions/Auth";
import {connect} from "react-redux";
import {getQueryParams, replaceQueryParams} from "../../../Components/utils";

const Register = (
    {
        match,
        referral_code,
        setRegisterReferralCode,
    }
) => {
    const {t, i18n} = useTranslation();

    const queryParams = getQueryParams();
    const queryRef = queryParams.ref;
    
    useEffect(() => {
        if (queryRef && queryRef !== referral_code) {
            setRegisterReferralCode(queryRef);
        }
    });
    
    if (queryRef) {
        delete queryParams.ref;
        return <Redirect to={replaceQueryParams(queryParams)} />
    }
    
    return (
        <div className="h-100">
            <div className="d-flex h-100 justify-content-center align-items-center">
                <Col md="8" className="mx-auto app-login-box">
                    <div className="mx-auto mb-3 text-center">
                        <h4 className="mt-2">
                            {t('auth.register.phone.title', 'Create HighTouch account')}
                        </h4>
                    </div>
    
                    <div className="modal-dialog w-100">
                        <div className="modal-content">
                            <div className="modal-body py-5 px-4">
                                <Switch>
                                    <Route exact path={`${match.url}/`} component={MethodSelect}/>
                                    <Route exact path={`${match.url}/phone/`} component={Phone}/>
                                    <Route exact path={`${match.url}/verify-code/`} component={VerifyCode}/>
                                    <Route exact path={`${match.url}/full-form/`} component={FullForm}/>
                                    <Route exact path={`${match.url}/account-activated/`} component={AccountActivated}/>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </Col>
            </div>
        </div>
      )
};

const mapDispatchToProps = dispatch => ({
    setRegisterReferralCode: (referral_code) => dispatch(setRegisterReferralCode(referral_code)),
});

const mapStateToProps = state => ({
    referral_code: state.Auth.register.referral_code,
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
