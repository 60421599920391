import React from "react";
import MessageList from "./MessageList";
import TopPane from "./TopPane";
import SendForm from "./SendForm";


const Index = (
    {
        socket,
    }
) => {
    return <>
        <TopPane />
        <MessageList socket={socket} />
        <SendForm />
    </>;
};

export default Index;
