import React, {useState, useRef} from 'react';
import {Route, Switch} from 'react-router-dom';
import {ListGroupItem} from "reactstrap";
import cx from 'classnames';
import {useTranslation} from "react-i18next";

import APITable2 from "../../Components/APITable2";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import PageTitle from "../../Layout/AppMain/PageTitle";
import APIForm from "../../Components/APIForm";
import DefaultNormalArrayFieldTemplate from "../../Components/APIForm/Fields/DefaultNormalArrayFieldTemplate";


const API_PATH = 'growth/short_links';

// Created using http://hilite.me/
const utmScriptHtml = '&lt;script&gt;\n' +
  '  <span style="color: #aaaaaa; font-style: italic">// Protect global scope</span>\n' +
  '  (<span style="color: #0000aa">function</span>() {\n' +
  '  \n' +
  '    <span style="color: #aaaaaa; font-style: italic">// List of UTM parameters names</span>\n' +
  '    <span style="color: #0000aa">var</span> utmParametersNames = [<span style="color: #aa5500">&#39;utm_source&#39;</span>, <span style="color: #aa5500">&#39;utm_medium&#39;</span>, <span style="color: #aa5500">&#39;utm_campaign&#39;</span>, <span style="color: #aa5500">&#39;utm_content&#39;</span>, <span style="color: #aa5500">&#39;utm_term&#39;</span>, <span style="color: #aa5500">&#39;utm_referrer&#39;</span>];\n' +
  '  \n' +
  '    <span style="color: #aaaaaa; font-style: italic">// Function extracts UTM parameters from window.location.search</span>\n' +
  '    <span style="color: #aaaaaa; font-style: italic">// Returns array of URI-encoded parameters, can be replaced</span>\n' +
  '    <span style="color: #0000aa">function</span> getUtmParameters() {\n' +
  '      <span style="color: #0000aa">var</span> utmParameters = [];\n' +
  '      <span style="color: #00aaaa">window</span>.location.search.slice(<span style="color: #009999">1</span>).split(<span style="color: #aa5500">&#39;&amp;&#39;</span>).forEach(<span style="color: #0000aa">function</span>(parameter) {\n' +
  '        <span style="color: #0000aa">var</span> parameterParts = parameter.split(<span style="color: #aa5500">&#39;=&#39;</span>);\n' +
  '        <span style="color: #0000aa">var</span> parameterName = parameterParts[<span style="color: #009999">0</span>];\n' +
  '        <span style="color: #0000aa">if</span> (utmParametersNames.indexOf(parameterName) !== -<span style="color: #009999">1</span>) {\n' +
  '          utmParameters.push(parameter);\n' +
  '        }\n' +
  '      });\n' +
  '      <span style="color: #0000aa">return</span> utmParameters;\n' +
  '    }\n' +
  '  \n' +
  '    <span style="color: #aaaaaa; font-style: italic">// Function adds UTM-parameters to all links lead to domain go.hightouch.ai</span>\n' +
  '    <span style="color: #0000aa">function</span> addUTM() {\n' +
  '      <span style="color: #0000aa">var</span> utmParameters = getUtmParameters();\n' +
  '      <span style="color: #0000aa">if</span> (!utmParameters.length) {\n' +
  '        <span style="color: #0000aa">return</span>;\n' +
  '      }\n' +
  '      <span style="color: #0000aa">var</span> links = <span style="color: #00aaaa">document</span>.querySelectorAll(<span style="color: #aa5500">&#39;a&#39;</span>);\n' +
  '      <span style="color: #0000aa">var</span> processLink = <span style="color: #0000aa">function</span>(link) {\n' +
  '        <span style="color: #0000aa">var</span> hostname;\n' +
  '        <span style="color: #0000aa">if</span> (<span style="color: #0000aa">typeof</span> link.hostname !== <span style="color: #aa5500">&#39;undefined&#39;</span>) {\n' +
  '          hostname = link.hostname;\n' +
  '        } <span style="color: #0000aa">else</span> {\n' +
  '          <span style="color: #0000aa">var</span> hostnameMatch = link.href.match(<span style="color: #009999">/^https?\\:\\/\\/([^:\\/?#]*)/</span>);\n' +
  '          hostname = hostnameMatch &amp;&amp; hostnameMatch[<span style="color: #009999">1</span>];\n' +
  '        }\n' +
  '        <span style="color: #0000aa">if</span> (hostname === <span style="color: #aa5500">&#39;go.hightouch.ai&#39;</span>) {\n' +
  '          <span style="color: #0000aa">var</span> hrefParts = link.href.split(<span style="color: #aa5500">&#39;?&#39;</span>);\n' +
  '          <span style="color: #0000aa">var</span> processedHref = hrefParts[<span style="color: #009999">0</span>];\n' +
  '          <span style="color: #0000aa">if</span> (hrefParts.length &gt; <span style="color: #009999">1</span>) {\n' +
  '            <span style="color: #0000aa">var</span> oldParameters = hrefParts[<span style="color: #009999">1</span>].split(<span style="color: #aa5500">&#39;&amp;&#39;</span>);\n' +
  '            <span style="color: #0000aa">var</span> newParametersString = utmParameters.join(<span style="color: #aa5500">&#39;&amp;&#39;</span>);\n' +
  '            oldParameters.forEach(<span style="color: #0000aa">function</span>(parameter) {\n' +
  '              <span style="color: #0000aa">var</span> parameterParts = parameter.split(<span style="color: #aa5500">&#39;=&#39;</span>);\n' +
  '              <span style="color: #0000aa">var</span> parameterName = parameterParts[<span style="color: #009999">0</span>];\n' +
  '              <span style="color: #0000aa">if</span> (utmParametersNames.indexOf(parameterName) === -<span style="color: #009999">1</span>) {\n' +
  '                newParametersString += <span style="color: #aa5500">&#39;&amp;&#39;</span> + parameter;\n' +
  '              }\n' +
  '            });\n' +
  '            processedHref += <span style="color: #aa5500">&#39;?&#39;</span> + newParametersString;\n' +
  '          } <span style="color: #0000aa">else</span> {\n' +
  '            processedHref += <span style="color: #aa5500">&#39;?&#39;</span> + utmParameters.join(<span style="color: #aa5500">&#39;&amp;&#39;</span>);\n' +
  '          }\n' +
  '          link.href = processedHref;\n' +
  '        }\n' +
  '      };\n' +
  '      links.forEach(processLink);\n' +
  '    }\n' +
  '  \n' +
  '  \n' +
  '    <span style="color: #aaaaaa; font-style: italic">// Run addUTM only when document is ready.</span>\n' +
  '    <span style="color: #aaaaaa; font-style: italic">// You can replace code below using jQuery.ready(addUTM) or something similar.</span>\n' +
  '    <span style="color: #0000aa">function</span> documentReady() {\n' +
  '      <span style="color: #00aaaa">document</span>.removeEventListener(<span style="color: #aa5500">&#39;DOMContentLoaded&#39;</span>, documentReady);\n' +
  '      <span style="color: #00aaaa">window</span>.removeEventListener(<span style="color: #aa5500">&#39;load&#39;</span>, documentReady);\n' +
  '      addUTM();\n' +
  '    }\n' +
  '  \n' +
  '    <span style="color: #0000aa">if</span> (<span style="color: #00aaaa">document</span>.readyState === <span style="color: #aa5500">&#39;complete&#39;</span>\n' +
  '        || (<span style="color: #00aaaa">document</span>.readyState !== <span style="color: #aa5500">&#39;loading&#39;</span> &amp;&amp; !<span style="color: #00aaaa">document</span>.documentElement.doScroll)) {\n' +
  '      setTimeout(addUTM, <span style="color: #009999">1</span>);\n' +
  '    } <span style="color: #0000aa">else</span> {\n' +
  '      <span style="color: #00aaaa">document</span>.addEventListener(<span style="color: #aa5500">&#39;DOMContentLoaded&#39;</span>, documentReady);\n' +
  '      <span style="color: #00aaaa">window</span>.addEventListener(<span style="color: #aa5500">&#39;load&#39;</span>, documentReady);\n' +
  '    }\n' +
  '  })();\n' +
  '&lt;/script&gt;';

const copyToClipboard = (text, callback) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
            callback(true, null);
        }, (err) => {
            callback(false, err)
        });
    } else {
        let textArea = document.createElement('textarea');
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';
        
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        
        try {
            let successful = document.execCommand('copy');
            callback(successful ? 'successful' : 'unsuccessful', null);
        } catch (err) {
            callback(false, err);
        }
        document.body.removeChild(textArea);
    }
};


const TableHeader = () => {
    const {t, i18n} = useTranslation();

    return <ListGroupItem>
        <div className="widget-content py-1 px-0 px-md-2">
            <div className="widget-content-wrapper text-secondary">
                <div className='widget-content-left' style={{width: 200}}>
                    {t('growth.columns.name', 'Name')}
                </div>
                <div className='widget-content-left d-none d-md-block' style={{width: 'calc(100% - 570px)'}}>
                    {t('growth.columns.prefilled_text', 'Pre-filled message')}
                </div>
                <div className="widget-content-right d-none d-md-block" style={{width: 130}}>

                </div>
                <div className="widget-content-right" style={{width: 150}}>
                    {t('growth.columns.nickname', 'Nickname / phone')}
                </div>
                <div className="widget-content-right text-center" style={{width: 70}}>
                    {t('growth.columns.clicks', 'Clicks')}
                </div>
                <div className="widget-content-right" style={{width: 70}}>

                </div>
            </div>
        </div>
    </ListGroupItem>;
};


const TableRow = (
    {
        data,
        setIdSelected,
        actionClick,
        actionDelete,
    }
) => {
    /**
     * Рендеринг
     */
    return <ListGroupItem
        className={cx('hover-bg-light')}
        onClick={() => actionClick(data.id)}
    >
        <div className="widget-content py-1 px-0 px-md-2">
            <div className='widget-content-wrapper'>
                <div className='widget-content-left' style={{width: 200}}>
                    {data.name}
                </div>
                <div className='widget-content-left d-none d-md-block' style={{width: 'calc(100% - 570px)'}}>
                        {data.start_text}
                </div>
                <div className="widget-content-right d-none d-md-block" style={{width: 130}}>
                    {data.bot_type.name}
                </div>
                <div className="widget-content-right" style={{width: 150}}>
                    {data.id_in_messenger}
                </div>
                <div className="widget-content-right text-center" style={{width: 70}}>
                    {data.clicks}
                </div>
                <div className="widget-content-right text-right" style={{width: 70, paddingTop: 4}}>
                    <i className="pe-7s-trash mr-2 fsize-2 hover-text-danger hover-pointer" onClick={(e) => {
                        e.stopPropagation();

                        setIdSelected(data.id);
                        actionDelete();
                    }}/>
                </div>
            </div>
        </div>
    </ListGroupItem>;
};


const ShortLinks = (
    {
        match,
    }
) => {
    const {t, i18n} = useTranslation();
    const [isCopied, setIsCopied] = useState(false);
    const codeBlockRef = useRef(null);

    return <Switch>
        <Route
            exact path={`${match.url}/create/`}
            render={
                (props) => <>
                    <AppHeader/>
                    <div className="app-main">
                        <AppSidebar/>
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <PageTitle
                                    heading={t('growth.columns.chat_links', 'Short URLs')}
                                    subheading=''
                                    icon="pe-7s-link icon-gradient bg-happy-fisher"
                                    enablePageTitleCreationButton={false}
                                />
                                <APIForm
                                    apiPath={API_PATH}
                                    modelId={undefined}
                                    method='post'
                                    actionRedirectUrl={`${match.url}/`}
                                    ArrayFieldTemplate={DefaultNormalArrayFieldTemplate}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        />
        <Route
            exact path={`${match.url}/:id(\\d+)/`}
            render={
                (props) => <>
                    <AppHeader/>
                    <div className="app-main">
                        <AppSidebar/>
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <PageTitle
                                    heading={t('growth.columns.chat_links', 'Short URLs')}
                                    subheading=''
                                    icon="pe-7s-link icon-gradient bg-happy-fisher"
                                    enablePageTitleCreationButton={true}
                                    headerButtonCreateUrl={`${match.url}/create/`}
                                />
                                <APIForm
                                    apiPath={API_PATH}
                                    modelId={props.match.params.id}
                                    method='patch'
                                    actionRedirectUrl={`${match.url}/`}
                                    ArrayFieldTemplate={DefaultNormalArrayFieldTemplate}
                                />
                                <div className="main-card mb-3 card">
                                    <div className="card-body">
                                        <p>
                                            {t('growth.utm.script_description', 'To forward UTM-parameters to HighTouch copy and paste to the page following script:')}
                                        </p>
                                        <pre
                                            ref={codeBlockRef}
                                            style={{'max-height': '20em',
                                                    'overflow-y': 'scroll',
                                                    'background-color': '#F9F9F9'}}
                                            dangerouslySetInnerHTML={{'__html': utmScriptHtml}} />
                                        <button className={'btn-lg btn ' + (isCopied ? 'btn-light' : 'btn-primary')}
                                            onClick={
                                                () => copyToClipboard(codeBlockRef.current.innerText, (success, err) => {
                                                    if (success) {
                                                        setIsCopied(true);
                                                    } else {
                                                        console.error(err || 'Cannot copy to clipboard');
                                                    }
                                                })
                                            }>
                                            {isCopied ? t('growth.utm.script_button_copied', 'Copied') : t('growth.utm.script_button_copy', 'Copy')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        />
        <Route
            exact path={match.url}
            render={
                (props) => <>
                    <AppHeader/>
                    <div className="app-main">
                        <AppSidebar/>
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <PageTitle
                                    heading={t('growth.columns.chat_links', 'Short URLs')}
                                    subheading=''
                                    icon="pe-7s-link icon-gradient bg-happy-fisher"
                                    headerButtonCreateUrl={`${match.url}create/`}
                                    enablePageTitleCreationButton={true}
                                />
                                <APITable2
                                    HeaderComponent={TableHeader}
                                    RowComponent={TableRow}
                                    emptyMessage={t('growth.columns.no_links', "No URL's yet...")}
                                    deleteMessage={t('growth.columns.question_delete_link', 'Remove URL?')}
                                    apiPath={API_PATH}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        />
    </Switch>;
};

export default ShortLinks;
