import React from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import 'moment/locale/ru';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,} from "reactstrap";
import get from "lodash/get";
import clone from "lodash/clone";
import findIndex from "lodash/findIndex";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";


const FiltersDropdown = (
    {
        config,
        filters,
        setFilters,
        showTitle,
    }
) => {
    const {t, i18n} = useTranslation();
    
    const dropdownItems = [];
    config.filters.forEach(
        (filter, i) => {
            dropdownItems.push(
                <DropdownItem header key={i * 1000}>
                    {filter.name}
                </DropdownItem>
            );
            switch (filter.type) {
                case 'bool':
                    dropdownItems.push(
                        <DropdownItem
                            key={i * 1000 + 1}
                            toggle={false}
                            className={cx({
                                'active': get(filters, filter.key) === true,
                            })}
                            onClick={() => {
                                const newData = clone(filters || {});
                                newData[filter.key] = true
                                setFilters(newData);
                            }}
                        >
                            {t('components.filters_dropdown.bool.yes', 'Yes')}
                        </DropdownItem>
                    );
                    dropdownItems.push(
                        <DropdownItem
                            key={i * 1000 + 2}
                            toggle={false}
                            className={cx({
                                'active': get(filters, filter.key) === false,
                            })}
                            onClick={() => {
                                const newData = clone(filters || {});
                                newData[filter.key] = false
                                setFilters(newData);
                            }}
                        >
                            {t('components.filters_dropdown.bool.no', 'No')}
                        </DropdownItem>
                    );
                    break;
                case 'select':
                    filter.enum.forEach(
                        (el, j) => {
                            dropdownItems.push(
                                <DropdownItem
                                    key={i * 1000 + j + 1}
                                    toggle={false}
                                    className={cx({
                                        'active': get(filters, `${filter.key}.id`) === el.id,
                                    })}
                                    onClick={() => {
                                        const newData = clone(filters || {});
                                        newData[filter.key] = el
                                        setFilters(newData);
                                    }}
                                >
                                    {el.name}
                                </DropdownItem>
                            );
                        }
                    );
                    break;
                case 'select_multiple':
                    filter.enum.forEach(
                        (el, j) => {
                            dropdownItems.push(
                                <DropdownItem
                                    key={i * 1000 + j + 1}
                                    toggle={false}
                                    className={cx({
                                        'active': isArray(get(filters, filter.key)) && findIndex(filters[filter.key], ['id', el.id]) !== -1
                                    })}
                                    onClick={() => {
                                        const newData = clone(filters || {});
                                        if (!isArray(get(newData, filter.key))) {
                                            newData[filter.key] = [el]
                                        } else {
                                            const elIdx = findIndex(newData[filter.key], ['id', el.id]);
                                            if (elIdx !== -1) {
                                                newData[filter.key].splice(elIdx, 1);
                                                if (isEmpty(newData[filter.key])) {
                                                    delete newData[filter.key];
                                                }
                                            } else {
                                                newData[filter.key].push(el);
                                            }
                                        }
                                        setFilters(newData);
                                    }}
                                >
                                    {el.name}
                                </DropdownItem>
                            );
                        }
                    );
                    break;
                default:
                    throw `Wrong filter.type: "${filter.type}"`;
            }
        }
    );
    return <UncontrolledDropdown className='chat-filters-dropdown'>
        <DropdownToggle color='null py-0' style={{fontSize: '1.9rem', paddingRight: 0}}>
            <div className='d-flex align-items-center'>
                {
                    showTitle && <span className='h5 my-0 mr-2'>{t('components.filters_dropdown.toggle.title', 'Filters')}</span>
                }
                <i className="pe-7s-edit"/>
            </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-hover-link">
            {dropdownItems}
        </DropdownMenu>
    </UncontrolledDropdown>;
};


export default FiltersDropdown;
