export const LOAD_BOT_USER_BY_ID_REQUEST_START = 'STEPS/LOAD_BOT_USER_BY_ID_REQUEST_START';
export const LOAD_BOT_USER_BY_ID_REQUEST_SUCCESS = 'STEPS/LOAD_BOT_USER_BY_ID_REQUEST_SUCCESS';
export const LOAD_BOT_USER_BY_ID_REQUEST_FAIL = 'STEPS/LOAD_BOT_USER_BY_ID_REQUEST_FAIL';

export const LOAD_BOT_USER_SCHEMA_BY_ID_REQUEST_START = 'STEPS/LOAD_BOT_USER_SCHEMA_BY_ID_REQUEST_START';
export const LOAD_BOT_USER_SCHEMA_BY_ID_REQUEST_SUCCESS = 'STEPS/LOAD_BOT_USER_SCHEMA_BY_ID_REQUEST_SUCCESS';
export const LOAD_BOT_USER_SCHEMA_BY_ID_REQUEST_FAIL = 'STEPS/LOAD_BOT_USER_SCHEMA_BY_ID_REQUEST_FAIL';

export const SUBSCRIBE_TO_FLOW_REQUEST_START = 'STEPS/SUBSCRIBE_TO_FLOW_REQUEST_START';
export const SUBSCRIBE_TO_FLOW_REQUEST_SUCCESS = 'STEPS/SUBSCRIBE_TO_FLOW_REQUEST_SUCCESS';
export const SUBSCRIBE_TO_FLOW_REQUEST_FAIL = 'STEPS/SUBSCRIBE_TO_FLOW_REQUEST_FAIL';

export const UNSUBSCRIBE_FROM_FLOW_REQUEST_START = 'STEPS/UNSUBSCRIBE_FROM_FLOW_REQUEST_START';
export const UNSUBSCRIBE_FROM_FLOW_REQUEST_SUCCESS = 'STEPS/UNSUBSCRIBE_FROM_FLOW_REQUEST_SUCCESS';
export const UNSUBSCRIBE_FROM_FLOW_REQUEST_FAIL = 'STEPS/UNSUBSCRIBE_FROM_FLOW_REQUEST_FAIL';

export const SET_ACTIVE_BOT_USER = 'CHATS/SET_ACTIVE_BOT_USER';
export const SET_ACTIVE_BOT_USER_SCHEMA = 'CHATS/SET_ACTIVE_BOT_USER_SCHEMA';


export const setActiveBotUser = (botUser) => ({
    type: SET_ACTIVE_BOT_USER,
    botUser,
});

export const setActiveBotUserSchema = (schema) => ({
    type: SET_ACTIVE_BOT_USER_SCHEMA,
    schema,
});

export const loadBotUserByIdRequestStart = (data) => ({
    type: LOAD_BOT_USER_BY_ID_REQUEST_START,
    data,
});

export const loadBotUserByIdRequestSuccess = (result) => ({
    type: LOAD_BOT_USER_BY_ID_REQUEST_SUCCESS,
    result,
});

export const loadBotUserByIdRequestFail = (error) => ({
    type: LOAD_BOT_USER_BY_ID_REQUEST_FAIL,
    error,
});

export const loadBotUserSchemaByIdRequestStart = (data) => ({
    type: LOAD_BOT_USER_SCHEMA_BY_ID_REQUEST_START,
    data,
});

export const loadBotUserSchemaByIdRequestSuccess = (result) => ({
    type: LOAD_BOT_USER_SCHEMA_BY_ID_REQUEST_SUCCESS,
    result,
});

export const loadBotUserSchemaByIdRequestFail = (error) => ({
    type: LOAD_BOT_USER_SCHEMA_BY_ID_REQUEST_FAIL,
    error,
});

export const subscribeToFlowRequestStart = (data) => ({
    type: SUBSCRIBE_TO_FLOW_REQUEST_START,
    data,
});

export const subscribeToFlowRequestSuccess = (result) => ({
    type: SUBSCRIBE_TO_FLOW_REQUEST_SUCCESS,
    result,
});

export const subscribeToFlowRequestFail = (error) => ({
    type: SUBSCRIBE_TO_FLOW_REQUEST_FAIL,
    error,
});

export const unsubscribeFromFlowRequestStart = (data) => ({
    type: UNSUBSCRIBE_FROM_FLOW_REQUEST_START,
    data,
});

export const unsubscribeFromFlowRequestSuccess = (result) => ({
    type: UNSUBSCRIBE_FROM_FLOW_REQUEST_SUCCESS,
    result,
});

export const unsubscribeFromFlowRequestFail = (error) => ({
    type: UNSUBSCRIBE_FROM_FLOW_REQUEST_FAIL,
    error,
});
