import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import {Button, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {useTranslation} from "react-i18next";
import get from 'lodash/get';

import {resetRegisterForm, touchRegisterForm} from "../../../actions/Auth";
import {concatURL, renderErrorsAsHTML} from '../../../Components/utils'
import {sendCode} from "../../../thunks/Auth";


const Phone = (
    {
        match,
        errors,
        sendCode,
        touchRegisterForm,
        resetRegisterForm,
    }
) => {
    const {t, i18n} = useTranslation();

    // State
    const [phone_number, setPhoneNumber] = useState('');

    // --------------------------
    // Hooks
    // --------------------------
    // componentDidMount && componentDidUpdate
    useEffect(
        () => {
            touchRegisterForm();
        },
        [phone_number]
    );

    // componentWillUnmount
    // useEffect(() => {
    //     return clearData;
    // }, []);

    // --------------------------
    // --------------------------
    // --------------------------

    return <Form
        onSubmit={
            e => {
                e.preventDefault();
                sendCode(
                    phone_number,
                    i18n.languages[0],
                    concatURL(window.location.href, '../verify-code'),
                );
            }
        }
        autoComplete="on"
    >
        <Row form>
            <Col md={12} className='mb-4'>
                <FormGroup>
                    <Label for="exampleEmail">
                        <span className="text-danger">*</span>
                        {' '}{t('auth.register.phone.label_phone', 'Phone number:')}
                    </Label>
                    <Input name="phone_number"
                           placeholder={t('auth.register.phone.placeholder_phone', "number with country code...")}
                           required
                           value={phone_number}
                           onChange={e => {
                               setPhoneNumber(e.target.value);
                           }}
                           invalid={get(errors, 'phone_number') !== undefined}
                           onClick={touchRegisterForm}/>
                </FormGroup>
                <div className='text-danger mb-3'>
                    {renderErrorsAsHTML(get(errors, 'phone_number'))}
                    {renderErrorsAsHTML(get(errors, 'phone'))}
                    {renderErrorsAsHTML(get(errors, 'common'))}
                </div>
                <Link
                    onClick={resetRegisterForm}
                    to={concatURL(match.url, '../full-form/')}
                    className='opacity-4'
                >
                    {t('auth.register.phone.link.no_phone', 'I have no phone')}
                </Link>
            </Col>
            <Col md={12}>
                <Button color="primary"
                        className="btn-wide btn-pill btn-shadow btn-hover-shine"
                        size="lg">
                    {t('auth.register.phone.button_submit', 'Send code')}
                </Button>
            </Col>
        </Row>
    </Form>;
};


const mapStateToProps = state => ({
    errors: state.Auth.register.errors,
});

const mapDispatchToProps = dispatch => ({
    sendCode: (phone_number, lang_code, redirectTo) => dispatch(sendCode(phone_number, lang_code, redirectTo)),
    touchRegisterForm: () => dispatch(touchRegisterForm()),
    resetRegisterForm: () => dispatch(resetRegisterForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Phone);
