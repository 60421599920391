import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
// import {Button, Col, CustomInput} from "reactstrap";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    // Link,
    Redirect, withRouter} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import get from 'lodash/get';

import {renderErrorsAsHTML} from "../../../../Components/utils";
import {clearErrors} from "../../../../actions/Connect";
import {chargebeeGenerateHostedPage, contactSalesRequest} from "../../../../thunks/Billing";
import {
  // CHARGEBEE_ADDON_WABA,
  // CONTACT_SALES_REQUEST_TYPE_WABA_CONTRACT,
  CHANNEL_REGISTER_BOT_LINK,
  CHANNEL_REGISTER_WABA
} from "../../../../constants";
// import waLogo from "../../../../assets/utils/images/messenger/wa.png";
import {getRegisterChannelLink} from "../../../../helpers/links";


const WABA = (
    {
        history,
        user,
        activeShop,
        billingConfig,
        errors,
        // isSendingHostedPageRequest,
        // isSendingContactSalesRequest,

        clearErrors,
        // chargebeeGenerateHostedPage,
        // contactSalesRequest,

        handlerClose
    }
) => {
    const {t, i18n} = useTranslation();

    const [redirectTo, setRedirectTo] = useState(undefined);

    // ComponentDidMount
    useEffect(() => {
        clearErrors();
    }, []);

    if (redirectTo) {
        return <Redirect to={redirectTo}/>
    }
    
    const tariff = get(billingConfig, 'tariff');
    const activeLang = i18n.languages[0];

    return  <>
        <ModalHeader toggle={handlerClose}>
            {t('connect_card.waba.title', 'Register WhatsApp Business API')}
        </ModalHeader>

        <ModalBody>

        <Trans t={t} i18nKey='connect_card.waba.instruction_text'>
            <p>
                «‎WhatsApp&nbsp;Business&nbsp;API» - единственныый официальный способ автоматизировать
                коммуникацию в&nbsp;WhatsApp.
            </p>
            <p>
                WhatsApp Business API подключается <code>только для юр. лиц (ООО, ИП)</code> по договору-оферте.
            </p>
            <p>
                Оплачивать услугу можно с банковской карты или банковским переводом на расчетный счет.
            </p>
        </Trans>
        <div className="text-center">
            {renderErrorsAsHTML(errors)}
        </div>
        </ModalBody>
        <ModalFooter>
            <Button color="additional" onClick={handlerClose}>
                {t('bot_card.common.button_cancel', 'Cancel')}
            </Button>
            <a
                className="btn btn-main"
                title={t('connect_card.waba.waba_legal_request_link_title', 'Request WhatsApp Business')}
                href={getRegisterChannelLink(CHANNEL_REGISTER_WABA)}
                target="_blank"
                onClick={() => {history.push(`/${activeShop.id}/channels/`)}}
            >
                <Trans t={t} i18nKey='connect_card.waba.waba_legal_request_button'>
                    Register your own WABA
                </Trans>
            </a>
        </ModalFooter>
   </>;
};


const mapStateToProps = state => ({
    user: state.Auth.user,
    activeShop: state.Shops.activeShop,
    errors: state.Connect.errors,
    billingConfig: state.Billing.billingConfig,
    isSendingHostedPageRequest: state.Billing.isSendingHostedPageRequest,
    isSendingContactSalesRequest: state.Billing.isSendingContactSalesRequest,
});

const mapDispatchToProps = dispatch => ({
    clearErrors: () => dispatch(clearErrors()),
    chargebeeGenerateHostedPage: (addon, callback) => dispatch(chargebeeGenerateHostedPage(addon, callback)),
    contactSalesRequest: (request_type, callback) => dispatch(contactSalesRequest(request_type, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WABA));
