import i18next from 'i18next';

import {store} from "../configureStore";
import {
    chargebeeCreatePortalSessionRequestStart,
    chargebeeCreatePortalSessionRequestSuccess,
    chargebeeCreatePortalSessionRequestFail,

    chargebeeGenerateHostedPageRequestStart,
    chargebeeGenerateHostedPageRequestSuccess,
    chargebeeGenerateHostedPageRequestFail,

    billingConfigRequestStart,
    billingConfigRequestSuccess,
    billingConfigRequestFail,

    contactSalesRequestStart,
    contactSalesRequestSuccess,
    contactSalesRequestFail,
} from '../actions/Billing'
import {renderErrorsAsString} from "../Components/utils";
import {toast} from "react-toastify";
import {apiAxiosInstance} from "../helpers/api";


export function openInNewTab(url) {
  let win = window.open(url, '_blank');
  win.focus();
}

export const chargebeeCreatePortalSession = (callback) => {
    return function (dispatch) {
        let data = {
            shop_id: store.getState().Shops.activeShop.id,
        };
        dispatch(chargebeeCreatePortalSessionRequestStart(data));

        apiAxiosInstance.post('billing/chargebee_create_portal_session/', data).then(
            response => {
                if (response.data.status === 'ok') {
                    dispatch(chargebeeCreatePortalSessionRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    toast.error(renderErrorsAsString(response.data.errors));
                    dispatch(chargebeeCreatePortalSessionRequestFail(response.data.errors));
                }
            },
            error => {
                toast.error(i18next.t('thunks.common.error_sending_request', 'Error sending request'));
                dispatch(chargebeeCreatePortalSessionRequestFail({'common': i18next.t('errors.common.request_sent', 'Error sending request')}))
            },
        );
    };
};

export const chargebeeGenerateHostedPage = (addon, callback) => {
    return function (dispatch) {
        let data = {
            shop_id: store.getState().Shops.activeShop.id,
            addon: addon,
        };
        dispatch(chargebeeGenerateHostedPageRequestStart(data));

        apiAxiosInstance.post('billing/chargebee_generate_hosted_page/', data).then(
            response => {
                if (response.data.status === 'ok') {
                    dispatch(chargebeeGenerateHostedPageRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    toast.error(renderErrorsAsString(response.data.errors));
                    dispatch(chargebeeGenerateHostedPageRequestFail(response.data.errors));
                }
            },
            error => {
                toast.error(i18next.t('thunks.common.error_sending_request', 'Error sending request'));
                dispatch(chargebeeGenerateHostedPageRequestFail({'common': i18next.t('errors.common.request_sent', 'Error sending request')}));
            },
        );
    };
};

export const contactSalesRequest = (request_type, callback) => {
    return function (dispatch) {
        let data = {
            shop_id: store.getState().Shops.activeShop.id,
            user_id: store.getState().Auth.user.id,
            request_type: request_type,
        };
        dispatch(contactSalesRequestStart(data));

        apiAxiosInstance.post('billing/contact_sales/', data).then(
            response => {
                if (response.data.status === 'ok') {
                    dispatch(contactSalesRequestSuccess(response.data.result));
                    toast.success(i18next.t('thunks.common.request_sent', 'Request sent'));
                    callback && callback(response.data.result);
                } else {
                    toast.error(renderErrorsAsString(response.data.errors));
                    dispatch(contactSalesRequestFail(response.data.errors));
                }
            },
            error => {
                toast.error(i18next.t('thunks.common.error_sending_request', 'Error sending request'));
                dispatch(contactSalesRequestFail({'common': i18next.t('errors.common.request_sent', 'Error sending request')}));
            },
        );
    };
};

export const loadBillingConfig = (callback) => {
    return function (dispatch) {
        let data = {
            shop_id: store.getState().Shops.activeShop.id,
        };
        dispatch(billingConfigRequestStart(data));

        apiAxiosInstance.get('billing/billing_config/', {params: data}).then(
            response => {
                if (response.data.status === 'ok') {
                    dispatch(billingConfigRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(billingConfigRequestFail(response.data.errors));
                }
            },
            error => dispatch(billingConfigRequestFail({
                'common': i18next.t('errors.common.request_sent', 'Error sending request'),
            })),
        );
    };
};
