export const START_INITIALLY_LOADING_SHOPS = 'SHOPS/START_INITIALLY_LOADING_SHOPS';

export const SHOP_BY_ID_REQUEST_START = 'SHOPS/SHOP_BY_ID_REQUEST_START';
export const SHOP_BY_ID_REQUEST_SUCCESS = 'SHOPS/SHOP_BY_ID_REQUEST_SUCCESS';
export const SHOP_BY_ID_REQUEST_FAIL = 'SHOPS/SHOP_BY_ID_REQUEST_FAIL';

export const SHOPS_REQUEST_START = 'SHOPS/SHOPS_REQUEST_START';
export const SHOPS_REQUEST_SUCCESS = 'SHOPS/SHOPS_REQUEST_SUCCESS';
export const SHOPS_REQUEST_FAIL = 'SHOPS/SHOPS_REQUEST_FAIL';

export const SHOPS_SELECT_SHOP = 'SHOPS/SHOPS_SELECT_SHOP';
export const SHOPS_CLEAR_DATA = 'SHOPS/SHOPS_CLEAR_DATA';

export const LOAD_ACTIVE_SHOP_ADMINISTRATORS_REQUEST_SUCCESS = 'SHOPS/LOAD_ACTIVE_SHOP_ADMINISTRATORS_REQUEST_SUCCESS';
export const LOAD_ACTIVE_SHOP_ADMINISTRATORS_REQUEST_FAIL = 'SHOPS/LOAD_ACTIVE_SHOP_ADMINISTRATORS_REQUEST_FAIL';

export const UPDATE_CURRENT_SHOP_ADMINISTRATOR = 'SHOPS/UPDATE_CURRENT_SHOP_ADMINISTRATOR';


export const startInitiallyLoadingShops = () => ({
    type: START_INITIALLY_LOADING_SHOPS,
});


export const shopByIdRequestStart = (id) => ({
    type: SHOP_BY_ID_REQUEST_START,
    id,
});

export const shopByIdRequestSuccess = (shop) => ({
    type: SHOP_BY_ID_REQUEST_SUCCESS,
    shop,
});

export const shopByIdRequestFail = (errors) => ({
    type: SHOP_BY_ID_REQUEST_FAIL,
    errors,
});


export const shopsRequestStart = (search, setRequestSending=true) => ({
    type: SHOPS_REQUEST_START,
    search,
    setRequestSending,
});

export const shopsRequestSuccess = (shops) => ({
    type: SHOPS_REQUEST_SUCCESS,
    shops,
});

export const shopsRequestFail = (errors) => ({
    type: SHOPS_REQUEST_FAIL,
    errors,
});

export const shopsSelectShop = (shop) => ({
    type: SHOPS_SELECT_SHOP,
    shop,
});

export const shopsClearData = (errors) => ({
    type: SHOPS_CLEAR_DATA,
    errors,
});

export const loadActiveShopAdministratorsRequestSuccess = (shopAdministrators) => ({
    type: LOAD_ACTIVE_SHOP_ADMINISTRATORS_REQUEST_SUCCESS,
    shopAdministrators,
});

export const loadActiveShopAdministratorsRequestFail = errors => ({
    type: LOAD_ACTIVE_SHOP_ADMINISTRATORS_REQUEST_FAIL,
    errors,
});

export const updateCurrentShopAdministrator = currentShopAdministrator => ({
    type: UPDATE_CURRENT_SHOP_ADMINISTRATOR,
    currentShopAdministrator,
});
