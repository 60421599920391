export const LOAD_CHATS_REQUEST_START = 'CHATS/LOAD_CHATS_REQUEST_START';
export const LOAD_CHATS_REQUEST_SUCCESS = 'CHATS/LOAD_CHATS_REQUEST_SUCCESS';
export const LOAD_CHATS_REQUEST_FAIL = 'CHATS/LOAD_CHATS_REQUEST_FAIL';

export const UPDATE_CHAT_REQUEST_START = 'CHATS/UPDATE_CHAT_REQUEST_START';
export const UPDATE_CHAT_REQUEST_SUCCESS = 'CHATS/UPDATE_CHAT_REQUEST_SUCCESS';
export const UPDATE_CHAT_REQUEST_FAIL = 'CHATS/UPDATE_CHAT_REQUEST_FAIL';

export const LOAD_CHAT_BY_ID_REQUEST_START = 'CHATS/LOAD_CHAT_BY_ID_REQUEST_START';
export const LOAD_CHAT_BY_ID_REQUEST_SUCCESS = 'CHATS/LOAD_CHAT_BY_ID_REQUEST_SUCCESS';
export const LOAD_CHAT_BY_ID_REQUEST_FAIL = 'CHATS/LOAD_CHAT_BY_ID_REQUEST_FAIL';

export const LOAD_GROUP_BY_ID_REQUEST_START = 'CHATS/LOAD_GROUP_BY_ID_REQUEST_START';
export const LOAD_GROUP_BY_ID_REQUEST_SUCCESS = 'CHATS/LOAD_GROUP_BY_ID_REQUEST_SUCCESS';
export const LOAD_GROUP_BY_ID_REQUEST_FAIL = 'CHATS/LOAD_GROUP_BY_ID_REQUEST_FAIL';

export const LOAD_CHANNEL_BY_ID_REQUEST_START = 'CHATS/LOAD_CHANNEL_BY_ID_REQUEST_START';
export const LOAD_CHANNEL_BY_ID_REQUEST_SUCCESS = 'CHATS/LOAD_CHANNEL_BY_ID_REQUEST_SUCCESS';
export const LOAD_CHANNEL_BY_ID_REQUEST_FAIL = 'CHATS/LOAD_CHANNEL_BY_ID_REQUEST_FAIL';

export const LOAD_GROUP_SCHEMA_BY_ID_REQUEST_START = 'CHATS/LOAD_GROUP_SCHEMA_BY_ID_REQUEST_START';
export const LOAD_GROUP_SCHEMA_BY_ID_REQUEST_SUCCESS = 'CHATS/LOAD_GROUP_SCHEMA_BY_ID_REQUEST_SUCCESS';
export const LOAD_GROUP_SCHEMA_BY_ID_REQUEST_FAIL = 'CHATS/LOAD_GROUP_SCHEMA_BY_ID_REQUEST_FAIL';

export const LOAD_CHANNEL_SCHEMA_BY_ID_REQUEST_START = 'CHATS/LOAD_CHANNEL_SCHEMA_BY_ID_REQUEST_START';
export const LOAD_CHANNEL_SCHEMA_BY_ID_REQUEST_SUCCESS = 'CHATS/LOAD_CHANNEL_SCHEMA_BY_ID_REQUEST_SUCCESS';
export const LOAD_CHANNEL_SCHEMA_BY_ID_REQUEST_FAIL = 'CHATS/LOAD_CHANNEL_SCHEMA_BY_ID_REQUEST_FAIL';

export const SET_CHATS = 'CHATS/SET_CHATS';
export const SET_CHATS_VISIBLE_LIMIT = 'CHATS/SET_CHATS_VISIBLE_LIMIT';
export const MERGE_CHATS = 'CHATS/MERGE_CHATS';
export const SET_ACTIVE_CHAT = 'CHATS/SET_ACTIVE_CHAT';
export const UPDATE_ACTIVE_CHAT_DATA = 'CHATS/UPDATE_ACTIVE_CHAT_DATA';
export const SET_ACTIVE_CHAT_SCHEMA = 'CHATS/SET_ACTIVE_CHAT_SCHEMA';
export const SET_ALL_OLD_CHATS_LOADED = 'CHATS/SET_ALL_OLD_CHATS_LOADED';

export const SET_CHAT_FILTERS = 'CHATS/SET_CHAT_FILTERS';
export const UPDATE_CHAT_FILTERS = 'CHATS/UPDATE_CHAT_FILTERS';
export const CLEAR_CHAT_FILTERS_KEY = 'CHATS/CLEAR_CHAT_FILTERS_KEY';

export const SET_MESSAGES = 'CHATS/SET_MESSAGES';
export const SET_MESSAGES_VISIBLE_LIMIT = 'CHATS/SET_MESSAGES_VISIBLE_LIMIT';
export const RECEIVE_MESSAGE = 'CHATS/RECEIVE_MESSAGE';
export const MERGE_MESSAGES = 'CHATS/MERGE_MESSAGES';
export const SET_ALL_OLD_MESSAGES_LOADED = 'CHATS/SET_ALL_OLD_MESSAGES_LOADED';

export const LOAD_MESSAGES_REQUEST_START = 'CHATS/LOAD_MESSAGES_REQUEST_START';
export const LOAD_MESSAGES_REQUEST_SUCCESS = 'CHATS/LOAD_MESSAGES_REQUEST_SUCCESS';
export const LOAD_MESSAGES_REQUEST_FAIL = 'CHATS/LOAD_MESSAGES_REQUEST_FAIL';

export const SEND_MESSAGE_REQUEST_START = 'CHATS/SEND_MESSAGE_REQUEST_START';
export const SEND_MESSAGE_REQUEST_SUCCESS = 'CHATS/SEND_MESSAGE_REQUEST_SUCCESS';
export const SEND_MESSAGE_REQUEST_FAIL = 'CHATS/SEND_MESSAGE_REQUEST_FAIL';

export const SET_WS_CONNECTED = 'CHATS/SET_WS_CONNECTED';


export const setWsConnected = (is_connected) => ({
    type: SET_WS_CONNECTED,
    is_connected,
});

export const setChats = (chats) => ({
    type: SET_CHATS,
    chats,
});

export const setChatsVisibleLimit = (limit) => ({
    type: SET_CHATS_VISIBLE_LIMIT,
    limit,
});

export const mergeChats = (chats) => ({
    type: MERGE_CHATS,
    chats,
});

export const loadChatByIdRequestStart = (data) => ({
    type: LOAD_CHAT_BY_ID_REQUEST_START,
    data,
});

export const loadChatByIdRequestSuccess = (result) => ({
    type: LOAD_CHAT_BY_ID_REQUEST_SUCCESS,
    result,
});

export const loadChatByIdRequestFail = (error) => ({
    type: LOAD_CHAT_BY_ID_REQUEST_FAIL,
    error,
});

export const updateChatRequestStart = (chatId, data) => ({
    type: UPDATE_CHAT_REQUEST_START,
    chatId,
    data,
});

export const updateChatRequestSuccess = (chatId, result) => ({
    type: UPDATE_CHAT_REQUEST_SUCCESS,
    chatId,
    result,
});

export const updateChatRequestFail = (chatId, error) => ({
    type: UPDATE_CHAT_REQUEST_FAIL,
    chatId,
    error,
});

export const loadGroupByIdRequestStart = (data) => ({
    type: LOAD_GROUP_BY_ID_REQUEST_START,
    data,
});

export const loadGroupByIdRequestSuccess = (result) => ({
    type: LOAD_GROUP_BY_ID_REQUEST_SUCCESS,
    result,
});

export const loadGroupByIdRequestFail = (error) => ({
    type: LOAD_GROUP_BY_ID_REQUEST_FAIL,
    error,
});

export const loadChannelByIdRequestStart = (data) => ({
    type: LOAD_CHANNEL_BY_ID_REQUEST_START,
    data,
});

export const loadChannelByIdRequestSuccess = (result) => ({
    type: LOAD_CHANNEL_BY_ID_REQUEST_SUCCESS,
    result,
});

export const loadChannelByIdRequestFail = (error) => ({
    type: LOAD_CHANNEL_BY_ID_REQUEST_FAIL,
    error,
});

export const loadGroupSchemaByIdRequestStart = (data) => ({
    type: LOAD_GROUP_SCHEMA_BY_ID_REQUEST_START,
    data,
});

export const loadGroupSchemaByIdRequestSuccess = (result) => ({
    type: LOAD_GROUP_SCHEMA_BY_ID_REQUEST_SUCCESS,
    result,
});

export const loadGroupSchemaByIdRequestFail = (error) => ({
    type: LOAD_GROUP_SCHEMA_BY_ID_REQUEST_FAIL,
    error,
});

export const loadChannelSchemaByIdRequestStart = (data) => ({
    type: LOAD_CHANNEL_SCHEMA_BY_ID_REQUEST_START,
    data,
});

export const loadChannelSchemaByIdRequestSuccess = (result) => ({
    type: LOAD_CHANNEL_SCHEMA_BY_ID_REQUEST_SUCCESS,
    result,
});

export const loadChannelSchemaByIdRequestFail = (error) => ({
    type: LOAD_CHANNEL_SCHEMA_BY_ID_REQUEST_FAIL,
    error,
});

export const loadChatsRequestStart = (data) => ({
    type: LOAD_CHATS_REQUEST_START,
    data,
});

export const loadChatsRequestSuccess = (result) => ({
    type: LOAD_CHATS_REQUEST_SUCCESS,
    result,
});

export const loadChatsRequestFail = (error) => ({
    type: LOAD_CHATS_REQUEST_FAIL,
    error,
});

export const setActiveChat = (chat) => ({
    type: SET_ACTIVE_CHAT,
    chat,
});

export const updateActiveChatData = (data) => ({
    type: UPDATE_ACTIVE_CHAT_DATA,
    data,
});

export const setActiveChatSchema = (schema) => ({
    type: SET_ACTIVE_CHAT_SCHEMA,
    schema,
});

export const setChatFilters = (filters) => ({
    type: SET_CHAT_FILTERS,
    filters,
});

export const updateChatFilters = (filtersToUpdate) => ({
    type: UPDATE_CHAT_FILTERS,
    filtersToUpdate,
});

export const clearChatFiltersKey = (key) => ({
    type: CLEAR_CHAT_FILTERS_KEY,
    key,
});

export const setMessages = (messages) => ({
    type: SET_MESSAGES,
    messages,
});

export const setMessagesVisibleLimit = (limit) => ({
    type: SET_MESSAGES_VISIBLE_LIMIT,
    limit,
});

export const receiveMessage = (message) => ({
    type: RECEIVE_MESSAGE,
    message,
});

export const mergeMessages = (messages) => ({
    type: MERGE_MESSAGES,
    messages,
});

export const loadMessagesRequestStart = (data) => ({
    type: LOAD_MESSAGES_REQUEST_START,
    data,
});

export const loadMessagesRequestSuccess = (result) => ({
    type: LOAD_MESSAGES_REQUEST_SUCCESS,
    result,
});

export const loadMessagesRequestFail = (error) => ({
    type: LOAD_MESSAGES_REQUEST_FAIL,
    error,
});

export const setAllOldChatsLoaded = (loaded) => ({
    type: SET_ALL_OLD_CHATS_LOADED,
    loaded,
});

export const setAllOldMessagesLoaded = (loaded) => ({
    type: SET_ALL_OLD_MESSAGES_LOADED,
    loaded,
});

export const sendMessageRequestStart = (data) => ({
    type: SEND_MESSAGE_REQUEST_START,
    data,
});

export const sendMessageRequestSuccess = (result) => ({
    type: SEND_MESSAGE_REQUEST_SUCCESS,
    result,
});

export const sendMessageRequestFail = (error) => ({
    type: SEND_MESSAGE_REQUEST_FAIL,
    error,
});
