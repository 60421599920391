import React from "react";
import cx from "classnames";
import 'moment/locale/ru';
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import forIn from "lodash/forIn";
import {useTranslation} from "react-i18next";


const FiltersValueList = (
    {
        config,
        data,
        onFilterUnset,
    }
) => {
    const {t, i18n} = useTranslation();
    
    const content = [];
    forIn(
        data,
        (filterValue, key) => {
            const filterConfig = find(config, ['key', key]);
            let filterValueStr = '';
            switch (filterConfig.type) {
                case 'bool':
                    filterValueStr = filterValue ? t('components.filters_values_list.bool.yes', 'Yes') : t('components.filters_values_list.bool.no', 'No');
                    break;
                case 'select':
                    filterValueStr = filterValue.name;
                    break;
                case 'select_multiple':
                    const filterValueStrings = filterValue.map(el => el.name);
                    filterValueStr = filterValueStrings.join(', ')
                    break;
                default:
                    throw `Wrong filterConfig.type: "${filterConfig.type}"`;
            }

            content.push(
                <div className='d-flex align-items-center' key={key}>
                    <i
                        className="pe-7s-close"
                        onClick={() => onFilterUnset(key, filterValue)}
                    />
                    {filterConfig.name}:&nbsp; <strong>{filterValueStr}</strong>
                </div>
            );
        }
    );

    return <div
        className={cx('text-secondary chat-filters-list', {'py-3': !isEmpty(data)})}
        style={{fontSize: '.7rem'}}
    >
        {content}
    </div>;
};

export default FiltersValueList;
