import React from "react";
import {connect} from "react-redux";

import BotUser from "./BotUser";
import Group from "./Group";
import Channel from "./Channel";

const CHAT_TYPE_PERSONAL = 1;
const CHAT_TYPE_GROUP = 2;
const CHAT_TYPE_CHANNEL = 3;


const ChatDetail = (
    {
        activeChat
    }
) => {
    if (!activeChat) {
        return '';
    }

    if (activeChat.type === CHAT_TYPE_PERSONAL) {
        return <BotUser botUserId={activeChat.bot_user.id} />
    } else if (activeChat.type === CHAT_TYPE_GROUP) {
        return <Group chatId={activeChat.id} />
    } else if (activeChat.type === CHAT_TYPE_CHANNEL) {
        return <Channel chatId={activeChat.id} />
    } else {
        throw `Unsupported Chat.type: #${activeChat.type}`;
    }
};


const mapStateToProps = state => ({
    activeChat: state.Chats.activeChat,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChatDetail);
