export const GET_FLOW_TEMPLATE_CATEGORIES_REQUEST_START = 'FLOW_TEMPLATES/GET_FLOW_TEMPLATE_CATEGORIES_REQUEST_START';
export const GET_FLOW_TEMPLATE_REQUEST_SUCCESS = 'FLOW_TEMPLATES/GET_FLOW_TEMPLATE_REQUEST_SUCCESS';
export const GET_FLOW_TEMPLATE_REQUEST_FAIL = 'FLOW_TEMPLATES/GET_FLOW_TEMPLATE_REQUEST_FAIL';

export const SET_FLOW_TEMPLATE_REQUEST_START = 'FLOW_TEMPLATES/SET_FLOW_TEMPLATE_REQUEST_START';
export const SET_FLOW_TEMPLATE_REQUEST_SUCCESS = 'FLOW_TEMPLATES/SET_FLOW_TEMPLATE_REQUEST_SUCCESS';
export const SET_FLOW_TEMPLATE_REQUEST_FAIL = 'FLOW_TEMPLATES/SET_FLOW_TEMPLATE_REQUEST_FAIL';


export const getFlowTemplateCategoriesRequestStart = () => ({
    type: GET_FLOW_TEMPLATE_CATEGORIES_REQUEST_START,
    // data,
});

export const getFlowTemplateCategoriesRequestSuccess = (result) => ({
    type: GET_FLOW_TEMPLATE_REQUEST_SUCCESS,
    result,
});

export const getFlowTemplateCategoriesRequestFail = (error) => ({
    type: GET_FLOW_TEMPLATE_REQUEST_FAIL,
    error,
});

export const setFlowTemplateRequestStart = (templateId) => ({
    type: SET_FLOW_TEMPLATE_REQUEST_START,
});

export const setFlowTemplateRequestSuccess = (result) => ({
    type: SET_FLOW_TEMPLATE_REQUEST_SUCCESS,
    result,
});

export const setFlowTemplateRequestFail = (error) => ({
    type: SET_FLOW_TEMPLATE_REQUEST_FAIL,
    error,
});
