import {combineEpics} from 'redux-observable'
import {
    logInRequestEpic,
    quickLogInRequestEpic,
    logOutEpic,
    setGlobalUserData,
} from './auth'
import {
    shopsRequestSuccessEpic,
    shopsClearDataEpic,
    startInitiallyLoadingShopsEpic,
    shopsRequestStartEpic,
    shopByIdRequestStartEpic,
    shopByIdRequestSuccessEpic,
    loadShopAdministratorEpic,
} from './shops'
import {
    botPollStatusEpic,
    botsClearDataEpic,
    botsLoadEpic,
    botsRequestStartEpic,
    botUpdateStatisticEpic,
    whatsappGetQRCodeEpic,
    whatsappLogoutRequestStartEpic,
} from './dashboard'
import {
    stepMovedEpic,
    updateFlowConfigEpic,
} from './steps'
import {
    loadBillingConfigEpic,
} from './billing'


export default combineEpics(
    quickLogInRequestEpic,
    logInRequestEpic,
    logOutEpic,
    setGlobalUserData,

    startInitiallyLoadingShopsEpic,
    shopsRequestStartEpic,
    shopsRequestSuccessEpic,
    shopByIdRequestStartEpic,
    shopByIdRequestSuccessEpic,
    shopsClearDataEpic,
    updateFlowConfigEpic,
    loadShopAdministratorEpic,

    botsLoadEpic,
    botsRequestStartEpic,
    botsClearDataEpic,
    botUpdateStatisticEpic,
    botPollStatusEpic,
    whatsappGetQRCodeEpic,
    whatsappLogoutRequestStartEpic,

    stepMovedEpic,

    loadBillingConfigEpic,
)