import React, {Fragment, useEffect} from "react";
import {connect} from "react-redux";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {ListGroup, ListGroupItem,} from "reactstrap";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import isEmpty from "lodash/isEmpty";
import assign from "lodash/assign";
import isEqual from "lodash/isEqual";

import MultiSelectField from "../../../Components/APIForm/Fields/MultiSelectField";
import {apiFormSubmitRequest} from '../../../thunks/ApiModel'
import {subscribeToFlow, unsubscribeFromFlow,} from "../../../thunks/Audience";
import {setActiveChat, setActiveChatSchema} from "../../../actions/Chats";
import {loadChannelById, loadChannelSchemaById} from "../../../thunks/Chats";


const emptyAva = <svg className='img-fluid' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212" width="212"
                      height="212">
    <path fill="#CCC"
          d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"></path>
    <g fill="#FFF">
        <path
            d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z"></path>
    </g>
</svg>;


const API_PATH = 'bot/channels';


const Channel = (
    {
        // Props
        chatId,

        // Redux Store
        activeChat,
        activeChatSchema,

        // Redux Actions
        apiFormSubmitRequest,
        setActiveChat,
        setActiveChatSchema,
        loadChannelById,
        loadChannelSchemaById,
    }
) => {
    const {t, i18n} = useTranslation();

    // --------------------------
    // Hooks
    // --------------------------
    // componentDidMount && componentDidUpdate
    useEffect(() => {
        reloadChat();
        reloadChatSchema();
    }, [chatId]);

    // componentWillUnmount
    useEffect(() => {
        return clearData;
    }, []);

    // --------------------------
    // --------------------------
    // --------------------------

    const reloadChat = () => {
        loadChannelById(
            chatId,
            (newActiveChat) => setActiveChat(newActiveChat),
            clearData,
        );
    };

    const reloadChatSchema = () => {
        loadChannelSchemaById(
            chatId,
            (newActiveChatSchema) => setActiveChatSchema(newActiveChatSchema),
            clearData,
        );
    };

    const clearData = () => {
        // Этим пока занимается компонент Chats
        // setActiveChat();
        setActiveChatSchema();
    };

    const updateChat = (data) => {
        const apiUrl = `${API_PATH}/${activeChat.id}/`;
        const method = 'patch';
        const formData = assign(
            {
                shop_id: activeChat.shop_id,
            },
            data,
        );
        apiFormSubmitRequest(
            apiUrl,
            method,
            formData,
            undefined,
            (updatedChat) => setActiveChat(updatedChat),
        );
    };

    // --------------------------
    // Html
    // --------------------------

    if (isEmpty(activeChat) || isEmpty(activeChatSchema)) {
        return '';
    }

    return <Fragment>
        <div className="app-inner-layout__top-pane border-bottom p-3">
            <div className="pane-left d-flex justify-content-between w-100">
                <h4 className="mb-0 text-nowrap">
                    {activeChat.name}
                </h4>
            </div>
        </div>

        <PerfectScrollbar>
            <div className='flex-column d-flex justify-content-center'>
                <div className="p-3 text-center pt-4">
                    {
                        activeChat.avatar_url
                            ? <img
                                src={activeChat.avatar_url}
                                className='img-fluid br-50'
                                style={{maxWidth: 'calc(max(150px, 50%)'}}
                            /> : emptyAva
                    }
                </div>

                <ListGroup className='p-3'>
                    <ListGroupItem>
                        <strong><i className="pe-7s-chat position-relative mr-2 font-size-lg"
                                   style={{top: 2}}/>
                        </strong> {t('chats.detail.channel', 'Channel')}:&nbsp; {activeChat.bot.name}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>
                            <i
                                className={cx(
                                    "pe-7s-id",
                                    "position-relative",
                                    "mr-2",
                                    "font-size-lg",
                                )}
                                style={{top: 2}}
                            />
                        </strong> {t('chats.detail.id_in_messenger', 'ID in messenger')}:
                        &nbsp; {activeChat.id_in_messenger}
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong><i className="pe-7s-id position-relative mr-2 font-size-lg"
                                   style={{top: 2}}/>
                        </strong> {t('chats.detail.bot_user_id', 'HighTouch ID')}:&nbsp; {chatId}
                    </ListGroupItem>
                </ListGroup>

                <h6 className="px-3 pt-3 mb-0">{t('chats.detail.tags', 'Tags')}</h6>
                {
                    activeChat.store && <div className="px-3 pb-3 pt-0">
                        <MultiSelectField
                            schema={{
                                title: undefined,
                                items: activeChatSchema.schema.properties.store.properties.tags.items,
                            }}
                            value={activeChat.store.tags}
                            onChange={(...args) => {
                                const newVal = args[0];
                                if (!isEqual(newVal, activeChat.store.tags)) {
                                    updateChat({
                                        store: {
                                            tags: args[0],
                                        },
                                    });
                                }
                            }}
                        />
                    </div>
                }
            </div>
        </PerfectScrollbar>
    </Fragment>;
};


const mapStateToProps = state => ({
    activeChat: state.Chats.activeChat,
    activeChatSchema: state.Chats.activeChatSchema,
});

const mapDispatchToProps = dispatch => ({
    apiFormSubmitRequest: (apiPath, method, formData, redirectUrl, callback) => dispatch(apiFormSubmitRequest(apiPath, method, formData, redirectUrl, callback)),
    setActiveChat: (botUser) => dispatch(setActiveChat(botUser)),
    setActiveChatSchema: (schema) => dispatch(setActiveChatSchema(schema)),
    loadChannelById: (chatId, successCallback, errorCallback) => dispatch(loadChannelById(chatId, successCallback, errorCallback)),
    loadChannelSchemaById: (chatId, successCallback, errorCallback) => dispatch(loadChannelSchemaById(chatId, successCallback, errorCallback)),
    subscribeToFlow: (chatId, flowId, successCallback, errorCallback) => dispatch(subscribeToFlow(chatId, flowId, successCallback, errorCallback)),
    unsubscribeFromFlow: (chatId, flowId, successCallback, errorCallback) => dispatch(unsubscribeFromFlow(chatId, flowId, successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Channel);
