import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Button, Card} from "reactstrap";
import {Trans, useTranslation} from "react-i18next";

import waLogo from '../../../../assets/utils/images/messenger/wa.png';


const ConnectCardWhatsApp = (
    {
        activeShop,
    }
) => {
    const {t, i18n} = useTranslation();

    return <Card className="mb-3 profile-responsive">
        <div className="dropdown-menu-header text-dark">
            <div className="dropdown-menu-header-inner bg-white">
                <div className="menu-header-content btn-pane-right d-block text-center">
                    <h5 className="d-flex align-items-center justify-content-center mb-3">
                        <img src={waLogo} alt="WhatsApp" style={{height: 30}} className='mr-2'/>
                        WhatsApp
                    </h5>
                    <h6 className='opacity-6 my-4'>
                        {t('connect_cards.wa.caption', 'Connect your WhatsApp phone, get customer phone numbers, and launch auto sales funnels')}
                    </h6>
                    <h2 className='my-4'>
                        <Trans t={t} i18nKey='connect_cards.wa.tariff'>
                            <strong>35$</strong><small> / month</small>
                        </Trans>
                    </h2>
                    <div className="menu-header-btn-pane">
                        <Link to={`/${activeShop.id}/channels/connect/whatsapp/`}>
                            <Button color="main">
                                {t('connect_bot.common.submit', 'Connect')}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </Card>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCardWhatsApp);
