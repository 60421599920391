import {
    ADD_FIREBASE_TOKEN_REQUEST_FAIL,
    ADD_FIREBASE_TOKEN_REQUEST_START,
    ADD_FIREBASE_TOKEN_REQUEST_SUCCESS,

    REVOKE_FIREBASE_TOKEN_REQUEST_START,
    REVOKE_FIREBASE_TOKEN_REQUEST_SUCCESS,
    REVOKE_FIREBASE_TOKEN_REQUEST_FAIL,
} from "../actions/Notifications";


const defaultState = {
    firebaseTokenCancelTokenSource: undefined,
    firebaseToken: undefined,
    firebaseTokenErrors: undefined,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case ADD_FIREBASE_TOKEN_REQUEST_START:
            state.firebaseTokenCancelTokenSource && state.firebaseTokenCancelTokenSource.cancel();

            return {
                ...state,
                firebaseTokenCancelTokenSource: action.cancelTokenSource,
                firebaseTokenErrors: undefined,
            };
        case ADD_FIREBASE_TOKEN_REQUEST_SUCCESS:
            return {
                ...state,
                firebaseTokenCancelTokenSource: undefined,
                firebaseToken: action.requestData.firebase_token,
                firebaseTokenErrors: undefined,
            };
        case ADD_FIREBASE_TOKEN_REQUEST_FAIL:
            return {
                ...state,
                firebaseTokenCancelTokenSource: undefined,
                firebaseToken: undefined,
                firebaseTokenErrors: action.errors,
            };
        case REVOKE_FIREBASE_TOKEN_REQUEST_START:
            state.firebaseTokenCancelTokenSource && state.firebaseTokenCancelTokenSource.cancel();

            return {
                ...state,
                firebaseTokenCancelTokenSource: action.cancelTokenSource,
                firebaseTokenErrors: undefined,
            };
        case REVOKE_FIREBASE_TOKEN_REQUEST_SUCCESS:
            return {
                ...state,
                firebaseTokenCancelTokenSource: undefined,
                firebaseToken: undefined,
                firebaseTokenErrors: undefined,
            };
        case REVOKE_FIREBASE_TOKEN_REQUEST_FAIL:
            return {
                ...state,
                firebaseTokenCancelTokenSource: undefined,
                firebaseTokenErrors: action.errors,
            };
    }
    return state;
}
