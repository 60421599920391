import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import get from 'lodash/get';

// Auth
// TODO replacement WIP connect new auth
import WhatsAppLogin from './RedesignAuth/WhatsAppLogin';
import TelegramLogin from './RedesignAuth/TelegramLogin';
import QuickRegister from './RedesignAuth/QuickRegister';
import QuickLogin from './RedesignAuth/Login';
// TODO endof replacement WIP connect new auth

import Login from './Login';
import Register from './Register/index';
import ForgotPassword from './ForgotPassword';
import SetNewPasswordForm from './SetNewPasswordForm';
import RetailCRM from './RetailCRM'
import {SHOP_SOURCE_CHAT_API} from "../../constants";


const Auth = (
    {
        match,
        location,
        activeShop,
    }
) => {
    return (activeShop && !location.pathname.startsWith('/auth/retailcrm/register') ? (
                    (get(activeShop, 'source') === SHOP_SOURCE_CHAT_API) ?
                        <Redirect to={`/${activeShop.id}/flows/`}/>
                    :
                        <Redirect to={`/${activeShop.id}/dashboard/`}/>
                ) : (
                    <div className="app-container">
                        <Route path={`${match.url}/login-old`} component={Login}/>
                        <Route path={`${match.url}/register`} component={Register}/>
                        <Route path={`${match.url}/forgot-password`} component={ForgotPassword}/>
                        <Route path={`${match.url}/set-new-password`} component={SetNewPasswordForm}/>

                        {/* TODO replacement quick register*/}
                        <Route path={`${match.url}/login`} component={QuickLogin}/>
                        <Route path={`${match.url}/signin`} component={QuickLogin}/>
                        <Route path={`${match.url}/whatsapp`} component={WhatsAppLogin}/>
                        <Route path={`${match.url}/telegram`} component={TelegramLogin}/>
                        <Route path={`${match.url}/quick-register`} component={QuickRegister}/>
                        <Route path={`${match.url}/quick-signup`} component={QuickRegister}/>
                        <Route path={`${match.url}/quick-sign-up`} component={QuickRegister} />
                        <Route path={`${match.url}/signup`} component={QuickRegister}/>
                        <Route path={`${match.url}/sign-up`} component={QuickRegister} />
                        {/* TODO endof replacement quick register*/}

                        {/* Partners registration */}
                        <Route path={`${match.url}/retailcrm/register/:messenger`} component={RetailCRM}/>
                    </div>
                )
           )
};

const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
});

export default connect(mapStateToProps)(Auth);
