import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Card, CardBody, CardHeader, ListGroup} from "reactstrap";
import {push} from "connected-react-router";
import SweetAlert from "sweetalert-react";
import Loader from "react-loaders";
import isEmpty from 'lodash/isEmpty'
import forIn from 'lodash/forIn'
import {useTranslation} from "react-i18next";

import {renderErrorsAsHTML} from '../utils'
import {apiFormDeleteRequest, apiFormSubmitRequest, apiTableDataRequest} from "../../thunks/ApiModel";


const APITable = (
    {
        RowComponent,
        HeaderComponent,
        emptyMessage,
        deleteMessage,
        apiPath,

        // mapStateToProps
        pathname,
        tableData,
        dataRequestCancelTokenSource,
        errors,
        activeShop,

        // mapDispatchToProps
        push,
        apiTableDataRequest,
        apiFormSubmitRequest,
        apiFormDeleteRequest,
    }
) => {
    const {t, i18n} = useTranslation();

    const [idSelected, setIdSelected] = useState();

    /**
     * Загрузка данных
     */
    const loadData = () => {
        apiTableDataRequest(
            apiPath,
            {
                shop_id: activeShop.id,
            }
        );
    };

    // Similar to componentDidMount and componentDidUpdate
    useEffect(() => {
        // ComponentDidMount && ComponentDidUpdate
        loadData();
    }, [activeShop]);

    /**
     * Переход на страничку одной сущности
     */
    const actionClick = (id) => {
        let lastChar = pathname.substr(-1);
        if (lastChar !== '/') {
            pathname += '/';
        }
        push(`${pathname}${id}/`);
    };

    /**
     * Удаление
     */
    const [deletePopupShow, setDeletePopupShow] = useState(false);

    const actionDelete = () => {
        setDeletePopupShow(true);
    };

    const onDeleteConfirm = () => {
        setDeletePopupShow(false);

        // TODO: грязный хак!
        const callback = (response) => {
            window.location.reload();
        };
        apiFormDeleteRequest(
            `${apiPath}/${idSelected}/`,
            undefined,
            callback,
        );
    };

    const onDeleteCancel = () => {
        setDeletePopupShow(false);
    };

    /**
     * Рендеринг
     */
    if (dataRequestCancelTokenSource) {
        return <div
            className="d-flex justify-content-center align-items-center text-center"
            style={{height: 'calc(100vh - 270px)'}}
        >
            <Loader type="line-scale-party" active/>
        </div>;
    } else if (tableData === undefined && !isEmpty(errors)) {
        return <Card>
            <CardHeader>
                <i className="lnr-warning icon-gradient bg-sunny-morning fsize-3"> </i>
                <span className="d-none d-sm-block">&nbsp; &nbsp;</span> &nbsp;
                {forIn(errors.common, (k, v) => renderErrorsAsHTML(v))}
                {forIn(errors.detail, (k, v) => renderErrorsAsHTML(v))}
            </CardHeader>
            <CardBody>
                <button className="mb-2 mr-2 btn btn-light" onClick={loadData}>
                    {t('components.api_table.button.retry', 'Try again')}
                </button>
            </CardBody>
        </Card>
    } else if (isEmpty(tableData)) {
        return <Card>
            <CardBody className='pt-4'>
                <h4>{emptyMessage ? emptyMessage : t('components.api_table.message.no_data', 'No data')}</h4>
            </CardBody>
        </Card>;
    }

    const rows = tableData.map(rowData => <RowComponent
        key={rowData.id}
        data={rowData}
        setIdSelected={setIdSelected}
        actionClick={actionClick}
        actionDelete={actionDelete}
        apiFormSubmitRequest={apiFormSubmitRequest}
    />);
    return <Card className="main-card mb-3">
        <ListGroup flush>
            <HeaderComponent/>
            {rows}
        </ListGroup>

        <SweetAlert
            title={deleteMessage}
            confirmButtonColor="#d92550"
            show={deletePopupShow}
            text={t('components.api_table2.sweetalert_remove_agreement_caption', "It will be removed permanently.")}
            type="warning"
            showCancelButton
            cancelButtonText={t('bot_card.common.button_cancel', 'Cancel')}
            onConfirm={onDeleteConfirm}
            onCancel={onDeleteCancel}
        />
    </Card>;
};

const mapStateToProps = state => ({
    tableData: state.APITable.data,
    dataRequestCancelTokenSource: state.APITable.dataRequestCancelTokenSource,
    errors: state.APITable.errors,
    activeShop: state.Shops.activeShop,
    pathname: state.router.location.pathname,
});

const mapDispatchToProps = dispatch => ({
    push: (path) => dispatch(push(path)),
    apiTableDataRequest: (apiPath, queryParams) => dispatch(apiTableDataRequest(apiPath, queryParams)),
    apiFormSubmitRequest: (apiPath, method, formData, redirectUrl, callback) => dispatch(apiFormSubmitRequest(apiPath, method, formData, redirectUrl, callback)),
    apiFormDeleteRequest: (apiPath, redirectUrl, callback) => dispatch(apiFormDeleteRequest(apiPath, redirectUrl, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(APITable);
