import i18next from 'i18next';
import {toast} from "react-toastify";
import get from "lodash/get";

import {store} from "../configureStore";
import {
    getStepTypesRequestStart,
    getStepTypesRequestSuccess,
    getStepTypesRequestFail,

    getStepsRequestStart,
    getStepsRequestSuccess,
    getStepsRequestFail,

    getFlowRequestStart,
    getFlowRequestSuccess,
    getFlowRequestFail,

    duplicateFlowRequestStart,
    duplicateFlowRequestSuccess,
    duplicateFlowRequestFail,
} from '../actions/Steps';
import {apiAxiosInstance} from "../helpers/api";


export const getStepTypes = () => {
    return function (dispatch) {
        let data = {
            shop_id: store.getState().Shops.activeShop.id,
            limit: 100,
        };

        dispatch(getStepTypesRequestStart(data));

        apiAxiosInstance.get('step/step_types/', {params: data}).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(getStepTypesRequestSuccess(response.data.result.results));
                } else {
                    dispatch(getStepTypesRequestFail(response.data.errors));
                }
            },
            error => dispatch(getStepTypesRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    };
};


export const getSteps = (flowId) => {
    return function (dispatch) {
        let data = {
            flow: flowId,
            shop_id: store.getState().Shops.activeShop.id,
            limit: 400,
        };

        dispatch(getStepsRequestStart(data));

        apiAxiosInstance.get('step/steps/', {params: data}).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(getStepsRequestSuccess(response.data.result.results));
                } else {
                    dispatch(getStepsRequestFail(response.data.errors));
                }
            },
            error => dispatch(getStepsRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    };
};


export const getFlow = (flowId) => {
    return function (dispatch) {
        dispatch(getFlowRequestStart(flowId));

        apiAxiosInstance.get(`step/flows/${flowId}/`).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(getFlowRequestSuccess(response.data.result));
                } else {
                    dispatch(getFlowRequestFail(response.data.errors));
                }
            },
            error => dispatch(getFlowRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    };
};


export const duplicateFlow = (flowId, successCallback, errorCallback) => {
    return function (dispatch) {
        dispatch(duplicateFlowRequestStart(flowId));

        let data = {
            shop_id: store.getState().Shops.activeShop.id,
        };
        apiAxiosInstance.get(`step/flows/${flowId}/duplicate_flow/`, {params: data}).then(
            (response) => {
                if (response.data.status === 'ok') {
                    toast.success(i18next.t('thunks.audience.toast_scenario_copied', 'Flow copied'));
                    dispatch(duplicateFlowRequestSuccess(response.data.result));
                    successCallback && successCallback(response.data.result);
                } else {
                    toast.error(i18next.t('errors.common.request_sent_short', 'Error sending request!'));
                    dispatch(duplicateFlowRequestFail(response.data.errors));
                    errorCallback && errorCallback(response.data.errors);
                }
            },
            error => {
                toast.error(i18next.t('errors.common.request_sent_short', 'Error sending request!'));
                dispatch(duplicateFlowRequestFail(error));
                errorCallback && errorCallback(error);
            },
        );
    };
};
