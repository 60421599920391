import React from "react";
import isEmpty from 'lodash/isEmpty';

import {store} from "../../../configureStore";
import MultiSelectField from './MultiSelectField';


const MultilangSelectFieldListItem = ({item}) => {
    const langByDefault = store.getState().Shops.activeShop.lang_by_default;
    return <span>
        {!isEmpty(item) ? item.name[langByDefault] : '-----'}
    </span>;
};

const MultilangSelectFieldValueInput = ({item}) => {
    const langByDefault = store.getState().Shops.activeShop.lang_by_default;
    return <span>
        {!isEmpty(item) ? item.name[langByDefault] : '-----'}
    </span>;
};

export default class MultilangMultiSelectField extends MultiSelectField {
    itemComponent=MultilangSelectFieldListItem;
    tagComponent=MultilangSelectFieldValueInput;
}
