import React from 'react';
import axios, {CancelToken} from "axios";
import {push} from "connected-react-router";
import qs from 'qs';
import {toast} from "react-toastify";
import get from 'lodash/get';
import i18next from 'i18next';
import {Button} from "reactstrap";

import {store} from "../configureStore";
import {
    apiTableConfigRequestStart,
    apiTableConfigRequestSuccess,
    apiTableConfigRequestFail,

    apiTableDataRequestStart,
    apiTableDataRequestSuccess,
    apiTableDataRequestFail,

    apiTableExportRequestStart,
    apiTableExportRequestSuccess,
    apiTableExportRequestFail,

    apiFormSchemaRequestStart,
    apiFormSchemaRequestSuccess,
    apiFormSchemaRequestFail,


    apiFormDataRequestStart,
    apiFormDataRequestSuccess,
    apiFormDataRequestFail,

    apiFormDeleteRequestStart,
    apiFormDeleteRequestSuccess,
    apiFormDeleteRequestFail,


    apiFormSubmitRequestStart,
    apiFormSubmitRequestSuccess,
    apiFormSubmitRequestFail,
} from '../actions/APIModel';
import {renderErrorsAsHTML} from "../Components/utils";
import {chargebeeGenerateHostedPage} from "./Billing";
import {apiAxiosInstance} from "../helpers/api";
import {TIMEOUT_EXCEL_EXPORT} from "../constants";

export const apiTableConfigRequest = (apiPath, shopId, callback) => {
    return function (dispatch) {
        const source = CancelToken.source();
        dispatch(apiTableConfigRequestStart(apiPath, source));

        apiAxiosInstance.get(
            `${apiPath}/table_config/?shop_id=${shopId}`,
            {
                cancelToken: source.token
            }
        ).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(apiTableConfigRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(apiTableConfigRequestFail(response.data.errors));
                }
            },
            error => {
                if (axios.isCancel(error)) {
                    console.log('Request is cancelleed');
                } else {
                    dispatch(apiTableConfigRequestFail(error))
                }
            },
        );
    }
};

export const apiTableDataRequest = (apiPath, queryParams, clearOldData) => {
    return function (dispatch) {
        const source = CancelToken.source();
        dispatch(apiTableDataRequestStart(apiPath, clearOldData, source));

        const queryString = qs.stringify(
            queryParams,
            {
                encodeValuesOnly: true,
                indices: false,
            }
        );
        apiAxiosInstance.get(
            `${apiPath}/?${queryString}`,
            {
                cancelToken: source.token
            }
        ).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(apiTableDataRequestSuccess(
                        response.data.result.results,
                        response.data.result.count,
                    ));
                } else {
                    dispatch(apiTableDataRequestFail(response.data.errors));
                }
            },
            error => {
                if (axios.isCancel(error)) {
                    console.log('Request is cancelleed');
                    dispatch(apiTableDataRequestFail(undefined));
                } else {
                    dispatch(apiTableDataRequestFail(error))
                }
            },
        );
    }
};

export const apiTableExportRequest = (apiPath, queryParams, callback) => {
    return function (dispatch) {
        const source = CancelToken.source();
        dispatch(apiTableExportRequestStart(apiPath, source));

        const queryString = qs.stringify(
            queryParams,
            {
                encodeValuesOnly: true,
                indices: false,
            }
        );
        apiAxiosInstance.get(
            `${apiPath}/?${queryString}`,
            {
                cancelToken: source.token,
                timeout: TIMEOUT_EXCEL_EXPORT,
                headers: {
                    'Content-Type': 'application/xlsx',
                    'Accept': 'application/xlsx',
                },
                responseType: 'blob'
            },
        ).then(
            (response) => {
                // Этот запрос возвращает сразу бинарные данные.
                //   Не трогать!
                dispatch(apiTableExportRequestSuccess());
                callback && callback(response);
            },
            error => {
                if (axios.isCancel(error)) {
                    console.log('Request is cancelleed');
                } else {
                    dispatch(apiTableExportRequestFail(error));
                }
            }
        );
    }
};

export const apiFormSchemaRequest = (apiPath) => {
    return function (dispatch) {
        dispatch(apiFormSchemaRequestStart(apiPath));

        apiAxiosInstance.get(apiPath).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(apiFormSchemaRequestSuccess(response.data.result));
                } else {
                    dispatch(apiFormSchemaRequestFail(response.data.errors));
                }
            },
            error => {
                dispatch(apiFormSchemaRequestFail(error));
            },
        );
    }
};

export const apiFormDataRequest = (apiPath) => {
    return function (dispatch) {
        dispatch(apiFormDataRequestStart(apiPath));

        apiAxiosInstance.get(apiPath).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(apiFormDataRequestSuccess(response.data.result));
                } else {
                    dispatch(apiFormDataRequestFail(response.data.errors));
                }
            },
            error => {
                dispatch(apiFormDataRequestFail(error));
            },
        );
    }
};

export const apiFormSubmitRequest = (apiPath, method, formData, redirectUrl, callback) => {
    return function (dispatch) {
        dispatch(apiFormSubmitRequestStart(apiPath));

        // method == 'patch' or 'post'
        apiAxiosInstance[method](apiPath, formData).then(
            (response) => {
                if (response.data.status === 'ok') {
                    toast.success(i18next.t('thunks.api_model.toast_data_saved', 'Data was saved!'), {autoClose: 2000});
                    dispatch(apiFormSubmitRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                    redirectUrl && dispatch(push(redirectUrl));
                } else {
                    if (get(response.data, 'errors.upgrade_to_pro')) {
                        toast.error(<>
                            <div>{response.data.errors.upgrade_to_pro}</div>
                            <Button
                                color="light"
                                className="my-2"
                                onClick={() => {
                                    store.dispatch(chargebeeGenerateHostedPage(
                                        undefined,
                                        (hosted_page) => {
                                        const chargebeeInstance = window.Chargebee.getInstance();
                                        chargebeeInstance.openCheckout({
                                                hostedPage: () => new Promise((resolve, reject) => {
                                                    resolve(hosted_page);
                                                }),
                                                success: function (hostedPageId) {
                                                    // window.location.reload();
                                                }
                                            }
                                        );
                                    }));
                                    toast.dismiss();
                                }}
                            >
                                {i18next.t('layout.vertical_nav.button_become_pro', 'Upgrade to PRO')}
                            </Button>
                        </>);
                    } else {
                        toast.error(<>{renderErrorsAsHTML(response.data.errors, false, '')}</>);
                    }
                    dispatch(apiFormSubmitRequestFail(response.data.errors));
                }
            },
            error => {
                toast.error(i18next.t('errors.common.request_sent_short', 'Error sending request!'));
                dispatch(apiFormSubmitRequestFail(error));
            },
        );
    }
};

export const apiFormDeleteRequest = (apiPath, redirectUrl, callback) => {
    return function (dispatch) {
        dispatch(apiFormDeleteRequestStart(apiPath));

        apiAxiosInstance.delete(apiPath).then(
            (response) => {
                toast.success(i18next.t('thunks.api_model.toast_object_removed', 'Item was removed'));
                dispatch(apiFormDeleteRequestSuccess());
                callback && callback(response.data.result);
                redirectUrl && dispatch(push(redirectUrl));
            },
            error => {
                toast.error(i18next.t('errors.common.request_sent_short', 'Error sending request!'));
                dispatch(apiFormDeleteRequestFail(error));
            },
        );
    }
};
