import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom'
import {Button, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {useTranslation} from "react-i18next";
import get from 'lodash/get';

import {touchRegisterForm} from "../../../actions/Auth";
import {concatURL, renderErrorsAsHTML} from '../../../Components/utils'
import {sendCode, verifyCode} from "../../../thunks/Auth";
import { URLS } from "../../../router";


const VerifyCode = (
    {
        errors,
        phone_number,
        sendCode,
        verifyCode,
        touchRegisterForm,
    }
) => {
    const {t, i18n} = useTranslation();

    // State
    const [code, setCode] = useState('');

    // --------------------------
    // Hooks
    // --------------------------
    // componentDidMount && componentDidUpdate
    useEffect(
        () => {
            touchRegisterForm();
        },
        [code]
    );

    // componentWillUnmount
    // useEffect(() => {
    //     return clearData;
    // }, []);

    // --------------------------
    // --------------------------
    // --------------------------

    if (!phone_number) {
        return <Redirect to={URLS.AUTH.SIGNUP}/>;
    }

    return <Form
        onSubmit={
            e => {
                e.preventDefault();
                verifyCode(
                    phone_number,
                    code,
                    (result) => {
                        window.location.href = concatURL(window.location.href, '../full-form');
                    },
                );
            }
        }
        autoComplete="on"
    >
        <Row form>
            <Col md={12}>
                <FormGroup>
                    <Label for="phone">
                        {t('auth.register.phone.label_phone', 'Phone number:')}
                    </Label>
                    <Input name="phone"
                           disabled
                           value={phone_number}
                    />
                </FormGroup>
            </Col>
            <Col md={12} className='text-danger'>
                {renderErrorsAsHTML(get(errors, 'phone'))}
                {renderErrorsAsHTML(get(errors, 'phone_number'))}
            </Col>
        </Row>
        <Row form>
            <Col md={12} className='mb-4'>
                <FormGroup>
                    <Label for="exampleEmail">
                        <span className="text-danger">*</span>
                        {' '}{t('auth.register.verify_code.label_code', 'Verification code')}
                    </Label>
                    <Input name="code"
                           required
                           value={code}
                           onChange={e => {
                               setCode(e.target.value);
                           }}
                           invalid={get(errors, 'phone_number') !== undefined}
                           onClick={touchRegisterForm}/>
                </FormGroup>
                <div className='text-danger mb-3'>
                    {renderErrorsAsHTML(get(errors, 'code'))}
                    {renderErrorsAsHTML(get(errors, 'common'))}
                </div>
                <a
                    href='#'
                    className='opacity-7'
                    onClick={
                        e => {
                            e.preventDefault();
                            sendCode(phone_number, i18n.languages[0]);
                        }
                    }
                >
                    {t('auth.register.verify_code.button_try_again', 'Resend code')}
                </a>
            </Col>
            <Col md={12}>
                <Button color="primary"
                        className="btn-wide btn-pill btn-shadow btn-hover-shine"
                        size="lg">
                    {t('auth.register.verify_code.button_submit', 'Verify code')}
                </Button>
            </Col>
        </Row>
    </Form>;
};


const mapStateToProps = state => ({
    phone_number: state.Auth.register.phone_number,
    errors: state.Auth.register.errors,
});

const mapDispatchToProps = dispatch => ({
    sendCode: (phone_number, lang_code, redirectTo) => dispatch(sendCode(phone_number, lang_code, redirectTo)),
    verifyCode: (phone_number, code, callback) => dispatch(verifyCode(phone_number, code, callback)),
    touchRegisterForm: () => dispatch(touchRegisterForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCode);
