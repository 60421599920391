import React from 'react';
import {useTranslation} from "react-i18next";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import APIModel from "../../Components/APIModel";


const Leads = (
    {
        match,
    }
    ) => {
        const {t, i18n} = useTranslation();

        const LEADS_COLUMNS = [
            {
                name: t('audience.leads.columns.created_at', "Title"),
                cell: row => <div>
                    {row.lead_subject || '-'}
                </div>
            },
            {
                name: t('audience.leads.columns.created_at', "Created at"),
                selector: "created_at",
            },
            {
                name: t('audience.leads.columns.contact_human_readable', "Lead text"),
                cell: row => <div>
                    {row.lead_text || '-'}
                </div>
            },
            {
                name: t('audience.leads.columns.tags', "Tags"),
                cell: row => <div>
                    {
                        get(row, 'tags', []).map(
                            (tag, idx) => <div className='my-1' key={idx}>
                                <code className='text-secondary'>
                                    {tag.name}
                                </code>
                            </div>
                        )
                    }
                    {
                        isEmpty(row.tags) && '-'
                    }
                </div>
            },
        ];

        return <APIModel
            heading={t('audience.leads.api_model.heading', 'Leads')}
            subheading=''
            icon="fa fas fa-check-circle icon-gradient bg-night-fade"
            apiPath='shop/leads'
            tableColumns={LEADS_COLUMNS}
            tableEmptyMessage={t('audience.leads.api_model.message_empty', "No leads yet")}
            match={match}
            hasFilters={true}
            creationDisabled={true}
        />;
    }
;

export default Leads;
