import i18next from 'i18next';

import {store} from "../configureStore";
import {
    loadBotUserByIdRequestFail,
    loadBotUserByIdRequestStart,
    loadBotUserByIdRequestSuccess,

    loadBotUserSchemaByIdRequestFail,
    loadBotUserSchemaByIdRequestStart,
    loadBotUserSchemaByIdRequestSuccess,

    subscribeToFlowRequestFail,
    subscribeToFlowRequestStart,
    subscribeToFlowRequestSuccess,

    unsubscribeFromFlowRequestFail,
    unsubscribeFromFlowRequestStart,
    unsubscribeFromFlowRequestSuccess,
} from '../actions/Audience'
import {toast} from "react-toastify";
import {apiAxiosInstance} from "../helpers/api";


export const loadBotUserById = (botUserId, successCallback, errorCallback) => {
    return function (dispatch) {
        dispatch(loadBotUserByIdRequestStart(botUserId));

        apiAxiosInstance.get(`bot/bot_users/${botUserId}/`).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(loadBotUserByIdRequestSuccess(response.data.result));
                    successCallback && successCallback(response.data.result);
                } else {
                    dispatch(loadBotUserByIdRequestFail(response.data.errors));
                    errorCallback && errorCallback(response.data.errors);
                }
            },
            error => {
                dispatch(loadBotUserByIdRequestFail(error));
                errorCallback && errorCallback(error);
            },
        );
    }
};


export const loadBotUserSchemaById = (botUserId, successCallback, errorCallback) => {
    return function (dispatch) {
        dispatch(loadBotUserSchemaByIdRequestStart(botUserId));

        let data = {
            shop_id: store.getState().Shops.activeShop.id,
        };
        apiAxiosInstance.get(`bot/bot_users/${botUserId}/form_schema/`, {params: data}).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(loadBotUserSchemaByIdRequestSuccess(response.data.result));
                    successCallback && successCallback(response.data.result);
                } else {
                    dispatch(loadBotUserSchemaByIdRequestFail(response.data.errors));
                    errorCallback && errorCallback(response.data.errors);
                }
            },
            error => {
                dispatch(loadBotUserSchemaByIdRequestFail(error));
                errorCallback && errorCallback(error);
            },
        );
    }
};


export const subscribeToFlow = (botUserId, flowId, successCallback, errorCallback) => {
    return function (dispatch) {
        dispatch(subscribeToFlowRequestStart(botUserId));

        let data = {
            shop_id: store.getState().Shops.activeShop.id,
            bot_user_id: botUserId,
            flow_id: flowId,
        };
        apiAxiosInstance.post(`/bot/bot_user/subscribe_to_flow/`, data).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(subscribeToFlowRequestSuccess(response.data.result));
                    toast.success(i18next.t('thunks.audience.toast_subscribed_to_scenario', `Subscribed to flow`));
                    successCallback && successCallback(response.data.result);
                } else {
                    dispatch(subscribeToFlowRequestFail(response.data.errors));
                    errorCallback && errorCallback(response.data.errors);
                }
            },
            error => {
                dispatch(subscribeToFlowRequestFail(error));
                toast.error(i18next.t('errors.common.request_sent', 'Error sending request'));
                errorCallback && errorCallback(error);
            },
        );
    }
};


export const unsubscribeFromFlow = (botUserId, flowId, successCallback, errorCallback) => {
    return function (dispatch) {
        dispatch(unsubscribeFromFlowRequestStart(botUserId));

        let data = {
            shop_id: store.getState().Shops.activeShop.id,
            bot_user_id: botUserId,
            flow_id: flowId,
        };
        apiAxiosInstance.post(`/bot/bot_user/unsubscribe_from_flow/`, data).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(unsubscribeFromFlowRequestSuccess(response.data.result));
                    toast.success(i18next.t('thunks.audience.toast_unsubscribed_from_scenario', `Unsubscribed from flow`));
                    successCallback && successCallback(response.data.result);
                } else {
                    dispatch(unsubscribeFromFlowRequestFail(response.data.errors));
                    toast.error(i18next.t('errors.common.request_sent', 'Error sending request'));
                    errorCallback && errorCallback(response.data.errors);
                }
            },
            error => {
                dispatch(unsubscribeFromFlowRequestFail(error));
                toast.error(i18next.t('errors.common.request_sent', 'Error sending request'));
                errorCallback && errorCallback(error);
            },
        );
    }
};
