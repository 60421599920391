import React from 'react';
import {useTranslation} from "react-i18next";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import APIModel from "../../Components/APIModel";


const TaskLeads = ({match,}) => {
  const {t,} = useTranslation();

  const TASK_LEADS_COLUMNS = [
      {
        name: t('audience.task_leads.columns.created_at', "Title"),
        cell: row => (
          <div>{row.subject || '-'}</div>
        )
      },
      {
        name: t('audience.task_leads.columns.status_human_readable', "Status"),
        cell: row => (
          <div>
            {row.status == 1000 && "\u2705 "}
            {row.status == 2000 && "\u274C "}
            {row.get_status_display || '-'}
          </div>
        )
      },
      {
        name: t('audience.task_leads.columns.created_at', "Created at"),
        selector: "created_at",
      },
      {
        name: t('audience.task_leads.columns.contact_human_readable', "Text"),
        cell: row => (
          <div>{row.text || '-'}</div>
        )
      },
      {
        name: t('audience.task_leads.columns.tags', "Tags"),
        cell: row => (
          <div>
            {
              get(row, 'tags', []).map(
                  (tag, idx) => <div className='my-1' key={idx}>
                      <code className='text-secondary'>
                          {tag.name}
                      </code>
                  </div>
              )
            }
            {
              isEmpty(row.tags) && '-'
            }
          </div>
        )
      },
  ];

  return <APIModel
      heading={t('audience.task_leads.api_model.heading', 'Task Leads')}
      subheading=''
      icon="fa fas fa-check-circle icon-gradient bg-night-fade"
      apiPath='task/leads'
      tableColumns={TASK_LEADS_COLUMNS}
      tableEmptyMessage={t('audience.task_leads.api_model.message_empty', "No leads yet")}
      match={match}
      hasFilters={true}
      creationDisabled={true}
  />;
};

export default TaskLeads;
