import {
    API_FORM_DATA_REQUEST_FAIL,
    API_FORM_DATA_REQUEST_START,
    API_FORM_DATA_REQUEST_SUCCESS,
    API_FORM_DELETE_REQUEST_FAIL,
    API_FORM_DELETE_REQUEST_START,
    API_FORM_DELETE_REQUEST_SUCCESS,
    API_FORM_DELETE_SWEETALERT_HIDE,
    API_FORM_DELETE_SWEETALERT_SHOW,
    API_FORM_SCHEMA_REQUEST_FAIL,
    API_FORM_SCHEMA_REQUEST_START,
    API_FORM_SCHEMA_REQUEST_SUCCESS,
    API_FORM_SET_FORM_DATA,
    API_FORM_SET_FORM_SCHEMA,
    API_FORM_SET_LIVE_VALIDATE,
    API_FORM_SUBMIT_REQUEST_FAIL,
    API_FORM_SUBMIT_REQUEST_START,
    API_FORM_SUBMIT_REQUEST_SUCCESS,
} from '../actions/APIModel'


const defaultState = {
    isSendingSchemaRequest: false,
    isSendingDataRequest: false,
    isSendingActionRequest: false,

    formSchema: undefined,
    formUISchema: undefined,
    data: undefined,
    liveValidate: false,

    deleteSweetalertShow: false,
    idToRemove: undefined,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case API_FORM_SCHEMA_REQUEST_START:
            return {
                ...state,
                isSendingSchemaRequest: true,
                formSchema: undefined,
                formUISchema: undefined,
                liveValidate: false,
            };

        case API_FORM_SCHEMA_REQUEST_SUCCESS:
            return {
                ...state,
                isSendingSchemaRequest: false,
                formSchema: action.result.schema,
                formUISchema: action.result.ui_schema,
            };

        case API_FORM_SCHEMA_REQUEST_FAIL:
            console.log('Not implemented Exception: "API_FORM_SCHEMA_REQUEST_FAIL"');
            return {
                ...state,
                isSendingSchemaRequest: false,
                formSchema: undefined,
                formUISchema: undefined,
                errors: action.errors,
            };

        case API_FORM_DATA_REQUEST_START:
            return {
                ...state,
                isSendingDataRequest: true,
                data: undefined,
            };

        case API_FORM_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                isSendingDataRequest: false,
                data: action.result,
            };

        case API_FORM_DATA_REQUEST_FAIL:
            console.log('Not implemented Exception: "API_FORM_DATA_REQUEST_FAIL"');
            return {
                ...state,
                isSendingDataRequest: false,
                errors: action.errors,
            };

        case API_FORM_SUBMIT_REQUEST_START:
            return {
                ...state,
                isSendingActionRequest: true,
            };

        case API_FORM_SUBMIT_REQUEST_SUCCESS:
            return {
                ...state,
                isSendingActionRequest: false,
            };

        case API_FORM_SUBMIT_REQUEST_FAIL:
            return {
                ...state,
                isSendingActionRequest: false,
                errors: action.errors,
            };

        case API_FORM_DELETE_SWEETALERT_SHOW:
            return {
                ...state,
                deleteSweetalertShow: true,
                idToRemove: action.idToRemove,
            };

        case API_FORM_DELETE_SWEETALERT_HIDE:
            return {
                ...state,
                deleteSweetalertShow: false,
                idToRemove: undefined,
            };

        case API_FORM_DELETE_REQUEST_START:
            return {
                ...state,
                isSendingActionRequest: true,
            };

        case API_FORM_DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                isSendingActionRequest: false,
            };

        case API_FORM_DELETE_REQUEST_FAIL:
            return {
                ...state,
                isSendingActionRequest: false,
                errors: action.errors,
            };

        case API_FORM_SET_LIVE_VALIDATE:
            return {
                ...state,
                liveValidate: action.liveValidate,
            };

        case API_FORM_SET_FORM_SCHEMA:
            return {
                ...state,
                formSchema: action.formSchema,
                formUISchema: action.formSchema,
            };

        case API_FORM_SET_FORM_DATA:
            return {
                ...state,
                data: action.formData,
            };
    }
    return state;
}
