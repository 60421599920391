import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    Button,
    // Col,
    Input, InputGroup, InputGroupAddon, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import {
    // Link,
    Redirect} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

import {createTGBot} from "../../../../thunks/Connect";
import {renderErrorsAsHTML} from "../../../../Components/utils";
import {clearErrors} from "../../../../actions/Connect";


const TGBot = (
    {
        activeShop,
        createTGBot,
        isSendingRequest,
        errors,
        clearErrors,

        handlerClose
    }
) => {
    const {t, i18n} = useTranslation();

    const [tokenInputValue, setTokenInputValue] = useState('');
    const [redirectTo, setRedirectTo] = useState(undefined);

    // ComponentDidMount
    useEffect(() => {
        clearErrors();
    }, []);

    const channelsUrl = `/${activeShop.id}/channels/`;

    const onButtonClick = (event) => {
        createTGBot(
            activeShop.id,
            'TG Bot',
            tokenInputValue,
            (result) => setRedirectTo(channelsUrl),
        );
    };

    if (redirectTo) {
        return <Redirect to={redirectTo}/>
    }

    return <>
        <ModalHeader toggle={handlerClose}>
            {t('connect_bot.tg.title', 'Connect Telegram-bot')}
        </ModalHeader>
        <ModalBody>

        <h4 className="text-center mt-2">

            <div className='font-size-md opacity-10'>
                {t('connect_bot.tg.caption', 'Insert Telegram bot token:')}
            </div>
        </h4>


        <div className='d-flex justify-content-center mb-3'>
            <InputGroup className='col-xs-12 col-sm-10'>
                <Input
                    placeholder="Token..."
                    value={tokenInputValue}
                    onChange={(event) => setTokenInputValue(event.target.value)}
                />
                <InputGroupAddon addonType="append">
                    <Button
                        color='main'
                        onClick={onButtonClick}
                        disabled={isSendingRequest}
                    >
                        {t('connect_bot.common.submit', 'Connect')}
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        </div>

        <div className="text-center text-danger">
            {renderErrorsAsHTML(errors)}
        </div>

        <h5 className='text-center mb-3'>
            <a href={t('connect_bot.telegram.url_help', 'https://docs.hightouch.ai/kak-nachat/podklyuchenie-botov/telegram.-sozdanie-bota')} target='_blank'>
                📕 {t('connect_bot.common.title_help', 'Detailed instructions')}
            </a>
        </h5>

        <ol>
            <Trans t={t} i18nKey='connect_bot.tg.instruction'>
                <li className='mb-3'>
                    Откройте приложение Telegram и через поиск перейдите в аккаунт{' '}
                    <a href="https://t.me/BotFather" target='_blank'>@BotFather</a>,{' '}
                    напишите команду <code>/newbot</code>, и у вас появится бот вашей компании.{' '}
                    <br/>
                    Если бот у вас уже есть, то напишите команду <code>/mybots</code> и выберите{' '}
                    существующий.
                </li>
                <li className='mb-3'>
                    Задайте имя для бота. Оно должно быть на английском языке и соответствовать названию
                    вашей компании.
                </li>
                <li className='mb-3'>
                    Задайте юзернейм бота, это может быть сочетание имени вашего бота
                    и слова <strong>bot</strong>.
                    Возможны
                    вариации: <strong>NameBot</strong> или <strong>Name_bot</strong>.
                </li>
                <li className=''>
                    Ваш бот готов. С помощью команды <code>/token</code> получите ключ доступа к боту
                    (токен).
                    <br/>
                    Обычно он выглядит
                    так: <strong>3245214234:AAsdgfgre87qJddYccR-ynswefcWz21324 </strong>
                    Скопируйте его и вставьте в поле ввода выше.
                </li>
            </Trans>
        </ol>

        </ModalBody>
        <ModalFooter>
            <Button color="additional" onClick={handlerClose}>
                {t('bot_card.common.button_cancel', 'Cancel')}
            </Button>
        </ModalFooter>
    </>
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    errors: state.Connect.errors,
    isSendingRequest: state.Connect.isSendingRequest,
});

const mapDispatchToProps = dispatch => ({
    createTGBot: (shop_id, name, tg_bot_token, callback) => dispatch(createTGBot(shop_id, name, tg_bot_token, callback)),
    clearErrors: () => dispatch(clearErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TGBot);
