import React from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Nav from './VerticalNavWrapper';
import HeaderLogo from './AppLogo';
import {setEnableMobileMenu} from '../actions/ThemeOptions';


const AppSidebar = (
    {
        enableMobileMenu,
        enableBackgroundImage,
        enableSidebarShadow,
        backgroundColor,
        backgroundImage,
        backgroundImageOpacity,
        setEnableMobileMenu,
    }
) => {
    return <>
        <div className="sidebar-mobile-overlay" onClick={() => setEnableMobileMenu(!enableMobileMenu)}/>
        <div className={cx("app-sidebar", backgroundColor, {'sidebar-shadow': enableSidebarShadow})}>
            <HeaderLogo/>
            <PerfectScrollbar>
                <div className="app-sidebar__inner">
                    <Nav/>
                </div>
            </PerfectScrollbar>
            <div
                className={cx("app-sidebar-bg", backgroundImageOpacity)}
                style={{
                    backgroundImage: enableBackgroundImage ? 'url(' + backgroundImage + ')' : null
                }}>
            </div>
        </div>
    </>
}

const mapStateToProps = state => ({
    enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
    enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    backgroundColor: state.ThemeOptions.backgroundColor,
    backgroundImage: state.ThemeOptions.backgroundImage,
    backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
});

const mapDispatchToProps = dispatch => ({
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);