import React from "react";
import {Link} from 'react-router-dom'
import {Button} from 'reactstrap';
import {useTranslation} from "react-i18next";


const AccountActivated = () => {
    const {t, i18n} = useTranslation();

    return <div className="text-center">
        <h4 className="modal-title">
            ✅ {t('auth.register.account_activated.title', 'Account activated!')}
        </h4>
        <div className='mb-3'>
            {t('auth.register.account_activated.caption', 'Now you can log in using email & password')}
        </div>
        <Link to="/auth/login">
            <Button color="primary"
                    className="btn-wide btn-pill btn-shadow btn-hover-shine"
                    size="lg">
                {t('auth.login.button_submit', 'Log in')}
            </Button>
        </Link>
    </div>;
};

export default AccountActivated;
