export const ADD_FIREBASE_TOKEN_REQUEST_START = 'CHATS/ADD_FIREBASE_TOKEN_REQUEST_START';
export const ADD_FIREBASE_TOKEN_REQUEST_SUCCESS = 'CHATS/ADD_FIREBASE_TOKEN_REQUEST_SUCCESS';
export const ADD_FIREBASE_TOKEN_REQUEST_FAIL = 'CHATS/ADD_FIREBASE_TOKEN_REQUEST_FAIL';

export const REVOKE_FIREBASE_TOKEN_REQUEST_START = 'CHATS/REVOKE_FIREBASE_TOKEN_REQUEST_START';
export const REVOKE_FIREBASE_TOKEN_REQUEST_SUCCESS = 'CHATS/REVOKE_FIREBASE_TOKEN_REQUEST_SUCCESS';
export const REVOKE_FIREBASE_TOKEN_REQUEST_FAIL = 'CHATS/REVOKE_FIREBASE_TOKEN_REQUEST_FAIL';


export const addFirebaseTokenRequestStart = (data, cancelTokenSource) => ({
    type: ADD_FIREBASE_TOKEN_REQUEST_START,
    data,
    cancelTokenSource,
});

export const addFirebaseTokenRequestSuccess = (requestData, result) => ({
    type: ADD_FIREBASE_TOKEN_REQUEST_SUCCESS,
    requestData,
    result,
});

export const addFirebaseTokenRequestFail = (error) => ({
    type: ADD_FIREBASE_TOKEN_REQUEST_FAIL,
    error,
});


export const revokeFirebaseTokenRequestStart = (data, cancelTokenSource) => ({
    type: REVOKE_FIREBASE_TOKEN_REQUEST_START,
    data,
    cancelTokenSource,
});

export const revokeFirebaseTokenRequestSuccess = (requestData, result) => ({
    type: REVOKE_FIREBASE_TOKEN_REQUEST_SUCCESS,
    requestData,
    result,
});

export const revokeFirebaseTokenRequestFail = (error) => ({
    type: REVOKE_FIREBASE_TOKEN_REQUEST_FAIL,
    error,
});
