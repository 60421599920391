import React from "react";
import {Card} from "reactstrap";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";


const StatisticCard = (
    {
        title,
        caption,
        data,
    }
) => {
    return <Card className="mb-3 profile-responsive bot-card">
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                title: {
                    text: title,
                    useHTML: true,
                    style: {
                        padding: '15px 0 10px 0',
                    }
                },
                caption: {
                    text: caption,
                    useHTML: true,
                    align: 'center',
                    style: {
                        minHeight: '30px',
                        textAlign: 'center',
                    }
                },
                legend: {
                    enabled: false,
                },
                tooltip: {
                    formatter(tooltip) {
                        let cls_name = 'font-size-md';
                        if (this.y > 0) {
                            cls_name += ' text-success';
                        } else {
                            cls_name += ' text-secondary'
                        }
                        return `${this.key}: <strong class="${cls_name}">${this.y}</strong>`;
                    },
                    useHTML: true,
                },
                xAxis: {
                    labels: {
                        enabled: false,
                    },
                    startOnTick: true,
                    endOnTick: true,
                    minPadding: 0,
                    maxPadding: 0,
                    tickmarkPlacement: 'on',
                },
                yAxis: {
                    title: {
                        enabled: false,
                    }
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, 'rgba(55, 125, 255, .5)'],
                                [1, 'rgba(65, 206, 255, .0)'],
                            ]
                        },
                        marker: {
                            radius: 3,
                            fillColor: 'rgba(55, 125, 255, .5)',
                        },
                        lineColor: 'rgba(55, 125, 255, .5)',
                        lineWidth: 2,
                    }
                },
                chart: {
                    height: 300,
                    backgroundColor: 'rgba(0,0,0,0)',
                },
                series: [
                    {
                        type: 'area',
                        data: data,
                    }
                ],
                credits: {
                    enabled: false,
                },
            }}
        />
    </Card>;
};


export default StatisticCard;