import React, {Fragment} from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import get from "lodash/get";

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import waLogo from "../../../../assets/utils/images/messenger/wa.png";
import {store} from "../../../../configureStore";
import {withTranslation} from "react-i18next";


class HSMNodeWidget_ extends AbstractNodeWidget {
    constructor(props) {
        super(props);

        this.type = 'hsm-node';
    }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <img src={waLogo} alt="WhatsApp" style={{height: 26}} className='mr-2'/> {options.step.name || this.props.t('pages.main.flow_builder.node.hsm.name', 'HSM template')}
        </>;
    }

    getBody() {
        const options = this.props.node.options;
        const text = get(options.step.text, store.getState().Shops.activeShop.lang_by_default);
        const tplName = get(options.step, 'state.waba_template.name');

        if (!tplName) {
            return <h5>---</h5>
        }

        return <>
            {
                text && text.split('\n').map((item, key) => {
                    return <Fragment key={key}>{item}<br/></Fragment>
                })
            }
        </>;
    }

    getErrors() {
    }
}

export const HSMNodeWidget = withTranslation()(HSMNodeWidget_);

export class HSMNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'hsm-node',
        });
    }
}


export class HSMNodeFactory extends AbstractReactFactory {
    constructor() {
        super('hsm-node');
    }

    generateModel(event) {
        return new HSMNodeModel();
    }

    generateReactWidget(event) {
        return <HSMNodeWidget engine={this.engine} node={event.model}/>;
    }
}
