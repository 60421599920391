export const LOAD_PLUGINS_REQUEST_START = 'CHATS/LOAD_PLUGINS_REQUEST_START';
export const LOAD_PLUGINS_REQUEST_SUCCESS = 'CHATS/LOAD_PLUGINS_REQUEST_SUCCESS';
export const LOAD_PLUGINS_REQUEST_FAIL = 'CHATS/LOAD_PLUGINS_REQUEST_FAIL';

export const LOAD_PLUGIN_INSTALLATIONS_REQUEST_START = 'CHATS/LOAD_PLUGIN_INSTALLATIONS_REQUEST_START';
export const LOAD_PLUGIN_INSTALLATIONS_REQUEST_SUCCESS = 'CHATS/LOAD_PLUGIN_INSTALLATIONS_REQUEST_SUCCESS';
export const LOAD_PLUGIN_INSTALLATIONS_REQUEST_FAIL = 'CHATS/LOAD_PLUGIN_INSTALLATIONS_REQUEST_FAIL';


export const loadPluginsRequestStart = (data) => ({
    type: LOAD_PLUGINS_REQUEST_START,
    data,
});

export const loadPluginsRequestSuccess = (result) => ({
    type: LOAD_PLUGINS_REQUEST_SUCCESS,
    result,
});

export const loadPluginsRequestFail = (error) => ({
    type: LOAD_PLUGINS_REQUEST_FAIL,
    error,
});


export const loadPluginInstallationsRequestStart = (data) => ({
    type: LOAD_PLUGIN_INSTALLATIONS_REQUEST_START,
    data,
});

export const loadPluginInstallationsRequestSuccess = (result) => ({
    type: LOAD_PLUGIN_INSTALLATIONS_REQUEST_SUCCESS,
    result,
});

export const loadPluginInstallationsRequestFail = (error) => ({
    type: LOAD_PLUGIN_INSTALLATIONS_REQUEST_FAIL,
    error,
});
