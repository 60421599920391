export const LOAD_CHARTS_DATA_REQUEST_START = 'CHATS/LOAD_CHARTS_DATA_REQUEST_START';
export const LOAD_CHARTS_DATA_REQUEST_SUCCESS = 'CHATS/LOAD_CHARTS_DATA_REQUEST_SUCCESS';
export const LOAD_CHARTS_DATA_REQUEST_FAIL = 'CHATS/LOAD_CHARTS_DATA_REQUEST_FAIL';


export const loadChartsDataRequestStart = (data) => ({
    type: LOAD_CHARTS_DATA_REQUEST_START,
    data,
});

export const loadChartsDataRequestSuccess = (result) => ({
    type: LOAD_CHARTS_DATA_REQUEST_SUCCESS,
    result,
});

export const loadChartsDataRequestFail = (error) => ({
    type: LOAD_CHARTS_DATA_REQUEST_FAIL,
    error,
});
