import React, {useState} from 'react';
import {connect} from 'react-redux';
import {DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, Dropdown} from 'reactstrap';
import {faAngleDown,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from "react-i18next";

import {shopsRequestStart, shopsSelectShop} from "../../../actions/Shops";
import {setEnableMobileMenuSmall} from '../../../actions/ThemeOptions'
import bg3 from '../../../assets/utils/images/dropdown-header/abstract3.jpg';


const MegaMenu = (
    {
        shops,
        activeShop,
        shopsSelectShop,
        isSendingRequest,
        shopsRequestStart,
        setEnableMobileMenuSmall,
    }
) => {
    const {t, i18n} = useTranslation();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState();

    const toggle = () => {
        if (isSendingRequest) {
            return
        }

        if (dropdownOpen === false) {
            shopsRequestStart('');
        }
        setDropdownOpen(!dropdownOpen);
    };

    const selectShop = (shop) => {
        if (activeShop.id !== shop.id) {
            shopsSelectShop(shop);
            shopsRequestStart('');
            setEnableMobileMenuSmall(false);
        }
    };


    let dropdownShopItems = shops.map((shop) => {
        return <DropdownItem
            key={shop.id} active={shop.id === activeShop.id}
            onClick={() => selectShop(shop)}
        >
            {shop.trade_name}
        </DropdownItem>
    });

    return <>
        <Nav className="header-megamenu">
            <Dropdown nav isOpen={dropdownOpen && !isSendingRequest} toggle={toggle}>
                <DropdownToggle nav className={'text-dark'}>
                    <i className="nav-link-icon fa fas fa-briefcase mr-2"> </i>
                    {activeShop.trade_name}
                    <FontAwesomeIcon className="ml-2" icon={faAngleDown}/>
                </DropdownToggle>
                <DropdownMenu
                    className="dropdown-menu-rounded dropdown-menu-lg rm-pointers"
                    modifiers={{
                        setMaxHeight: {
                            enabled: true,
                            fn: (data) => {
                                let w = window,
                                    d = document,
                                    e = d.documentElement,
                                    g = d.getElementsByTagName('body')[0],
                                    y = w.innerHeight || e.clientHeight || g.clientHeight;
                                return {
                                    ...data,
                                    styles: {
                                        ...data.styles,
                                        // overflow: 'auto',
                                        maxHeight: y - 50,
                                    },
                                };
                            },
                        },
                    }}
                >
                    <div className="dropdown-menu-header">
                        <div className="dropdown-menu-header-inner bg-dark">
                            <div className="menu-header-image opacity-1"
                                 style={{
                                     backgroundImage: 'url(' + bg3 + ')'
                                 }}
                            ></div>
                            <div className="menu-header-content text-left">
                                <h5 className="menu-header-title">
                                    {t('layout.header.mega_menu.title', 'My shops')}
                                </h5>
                                {/*<h6 className="menu-header-subtitle">Тариф: Enterprise</h6>*/}
                                {/*<div className="menu-header-btn-pane">*/}
                                {/*<Button size="sm" color="dark" className="mr-2">*/}
                                {/*Settings*/}
                                {/*</Button>*/}
                                {/*<Button size="sm" className="btn-icon btn-icon-only" color="warning">*/}
                                {/*<i className="pe-7s-config btn-icon-wrapper"> </i>*/}
                                {/*</Button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>

                    <div className="dropdown-menu-search mb-3">
                        <Input
                            placeholder={t('layout.header.mega_menu.placeholder_search_input', "Search...")}
                            value={searchInputValue}
                            onChange={(event) => {
                                setSearchInputValue(event.target.value);
                                shopsRequestStart(event.target.value, false);
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Escape') {
                                    setSearchInputValue('');
                                    shopsRequestStart('', false);
                                }
                                event.stopPropagation();
                                event.nativeEvent.stopImmediatePropagation();
                            }}
                        />
                        {
                            searchInputValue && <i
                                className="pe-7s-close"
                                onClick={
                                    (event) => {
                                        setSearchInputValue('');
                                        shopsRequestStart('');
                                    }
                                }
                            />
                        }
                    </div>

                    {dropdownShopItems}
                </DropdownMenu>
            </Dropdown>
        </Nav>
    </>;
};

const mapStateToProps = state => ({
    shops: state.Shops.shops,
    activeShop: state.Shops.activeShop,
    isSendingRequest: state.Shops.isSendingRequest,
});

const mapDispatchToProps = dispatch => ({
    shopsRequestStart: (search, setIsSendingRequest) => dispatch(shopsRequestStart(search, setIsSendingRequest)),
    shopsSelectShop: shop => dispatch(shopsSelectShop(shop)),
    setEnableMobileMenuSmall: enabled => dispatch(setEnableMobileMenuSmall(enabled)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MegaMenu);
