import React, {useState} from 'react';
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Trans, useTranslation} from "react-i18next";
import get from 'lodash/get';

import {setUpgradeToPROModalOpen} from "../actions/Billing";
import {chargebeeGenerateHostedPage} from "../thunks/Billing";


const UpgradeToPRO = (
    {
        activeShop,
        upgradeToPROModalOpen,
        isSendingHostedPageRequest,

        setUpgradeToPROModalOpen,
        chargebeeGenerateHostedPage,
    }
) => {
    const {t, i18n} = useTranslation();

    return <>
        <Modal isOpen={upgradeToPROModalOpen}
               toggle={() => setUpgradeToPROModalOpen(false)}
               size='lg'
               // centered={true}
        >
            <ModalHeader toggle={() => setUpgradeToPROModalOpen(false)}
                         cssModule={{'modal-title': 'w-100 text-center mb-0'}}>
                {t('billing.common.pro.button', 'Upgrade to PRO')}
            </ModalHeader>
            <ModalBody>
                <Trans i18nKey='layout.billing.upgrade_to_pro_descripton'>
                    <p>HighTouch Pro gives you all the tools for engaging your audience through WhatsApp & other
                        messengers.</p>
                    <ul>
                        <li>Remove HighTouch branding</li>
                        <li>Automate WhatsApp communications</li>
                        <li>In-bot catalog and payments</li>
                        <li>Unlimited Bots, Tags and Custom Fields</li>
                        <li>... and much more!</li>
                    </ul>

                    <p>Example use cases:</p>
                    <ul>
                        <li>Automated interactive courses: education, contact onboarding, entertainment</li>
                        <li>Notifications and alerts requested by the contact</li>
                        <li>Automated keyword replies</li>
                    </ul>
                    <p>You can always stay on the Free plan if you only need the basic functionality. <a
                        href={t('layout.billing.upgrade_to_pro_pricing_link', 'https://hightouch.ai/pricing')} target='_blank'>Learn more about Free and Pro plans.</a></p>
                </Trans>
            </ModalBody>
            <ModalFooter className='justify-content-center flex-column'>
                <p className='mt-3'>
                    {t('layout.billing.upgrade_to_pro_shop_descr', 'Going to upgrade:')}
                    <strong className='ml-2'>{get(activeShop, 'trade_name')}</strong>
                </p>
                <Button
                    size="lg"
                    color="primary"
                    className="px-5 py-3 font-size-lg"
                    disabled={isSendingHostedPageRequest}
                    onClick={() => chargebeeGenerateHostedPage(
                        undefined,
                        (hosted_page) => {
                            const chargebeeInstance = window.Chargebee.getInstance();
                            chargebeeInstance.openCheckout({
                                    hostedPage: () => new Promise((resolve, reject) => {
                                        resolve(hosted_page);
                                    }),
                                    success: function (hostedPageId) {
                                        // window.location.reload();
                                    }
                                }
                            );
                        }
                    )}
                >
                    {t('layout.vertical_nav.button_become_pro', 'Upgrade to PRO')}
                </Button>
                <p className='text-secondary mt-3'>
                    <Trans i18nKey='layout.billing.upgrade_to_pro_button_caption'>
                        0 - 500 contacts for $10/month
                    </Trans>
                </p>
            </ModalFooter>
        </Modal>
    </>
}

const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    upgradeToPROModalOpen: state.Billing.upgradeToPROModalOpen,
    isSendingHostedPageRequest: state.Billing.isSendingHostedPageRequest,
});

const mapDispatchToProps = dispatch => ({
    setUpgradeToPROModalOpen: (open) => dispatch(setUpgradeToPROModalOpen(open)),
    chargebeeGenerateHostedPage: (addon, callback) => dispatch(chargebeeGenerateHostedPage(addon, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeToPRO);
