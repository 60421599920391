import React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import get from "lodash/get";
import {store} from "../../../../configureStore";
import {withTranslation} from "react-i18next";


class StoreTaskLeadNodeWidget_ extends AbstractNodeWidget {
  constructor() {
    super();

    this.type = 'store-task-lead-node';
  }

  getHeader() {
    const options = this.props.node.options;
    return <>
      <i className="pe-7s-check"> </i> {
        options.step.name ||
        this.props.t(
          'pages.main.flow_builder.node.store_task_lead.name',
          'Create task lead'
        )
      }
    </>;
  }

  getErrors() {
    const options = this.props.node.options;
    const text = get(
      options.step.text,
      store.getState().Shops.activeShop.lang_by_default
    );
    const imageUrl = options.step.image_url;

    const errors = [];
    if (!text && !imageUrl) {
      errors.push(
        this.props.t(
          'pages.main.flow_builder.node.store_task_lead.error.add_text_or_image',
          'Please provide text or image'
        )
      );
    }

    return errors.map((error, idx) => (
      <div className='py-2' key={idx}>
        <i className='text-danger opacity-9'>{error}</i>
      </div>
    ));
  }
}

export const StoreTaskLeadNodeWidget = withTranslation()(StoreTaskLeadNodeWidget_);

export class StoreTaskLeadNodeModel extends AbstractNodeModel {
  constructor(options = {}) {
    super({
        ...options,
      type: 'store-task-lead-node'
    });
  }
}


export class StoreTaskLeadNodeFactory extends AbstractReactFactory {
    constructor() {
      super('store-task-lead-node');
    }

    generateModel(event) {
      return new StoreTaskLeadNodeModel();
    }

    generateReactWidget(event) {
      return <StoreTaskLeadNodeWidget engine={this.engine} node={event.model}/>;
    }
}
