import get from "lodash/get";

import {store} from "../configureStore";
import {
    loadPluginsRequestFail,
    loadPluginsRequestStart,
    loadPluginsRequestSuccess,

    loadPluginInstallationsRequestStart,
    loadPluginInstallationsRequestSuccess,
    loadPluginInstallationsRequestFail,
} from '../actions/Plugins';
import {apiAxiosInstance} from "../helpers/api";


export const loadPlugins = (filters, callback) => {
    return function (dispatch) {
        let requestData = {
            shop_id: store.getState().Shops.activeShop.id,
        };

        dispatch(loadPluginsRequestStart(requestData));

        apiAxiosInstance.get('plugin/plugins/', {params: requestData}).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(loadPluginsRequestSuccess(response.data.result.results));
                    callback && callback(response.data.result);
                } else {
                    dispatch(loadPluginsRequestFail(response.data.errors));
                }
            },
            error => dispatch(loadPluginsRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    };
};


export const loadPluginInstallations = (filters, callback) => {
    return function (dispatch) {
        let requestData = {
            shop_id: store.getState().Shops.activeShop.id,
        };

        dispatch(loadPluginInstallationsRequestStart(requestData));

        apiAxiosInstance.get('plugin/plugin_installations/', {params: requestData}).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(loadPluginInstallationsRequestSuccess(response.data.result.results));
                    callback && callback(response.data.result);
                } else {
                    dispatch(loadPluginInstallationsRequestFail(response.data.errors));
                }
            },
            error => dispatch(loadPluginInstallationsRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    };
};
