import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Col, Row} from "reactstrap";
import isEmpty from "lodash/isEmpty";
import {useTranslation} from "react-i18next";

import {loadPluginInstallations, loadPlugins} from "../../../thunks/Plugins";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import PluginInstallationCard from "./PluginInstallationCard";
import PluginCard from "./PluginCard";
import PluginCardYandexKassa from "./PluginCards/YandexKassa";
import PluginCardRobokassa from "./PluginCards/Robokassa";


const Common = (
    {
        activeShop,
        match,
        plugins,
        pluginInstallations,
        isSendingPluginsRequest,
        isSendingPluginInstallationsRequest,
        loadPluginsErrors,
        loadPluginInstallationsErrors,

        // Dispatch
        loadPlugins,
        loadPluginInstallations,
    }
) => {
    const {t, i18n} = useTranslation();

    // ComponentDidMount
    useEffect(() => {
        loadPlugins();
        loadPluginInstallations();
    }, []);

    return <>
        <AppHeader/>
        <div className="app-main">
            <AppSidebar/>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <PageTitle
                        heading={t('settings.integrations.page.title', 'Integrations')}
                        subheading=''
                        icon="fa fas fa-cog icon-gradient bg-mean-fruit"
                    />

                    {
                        plugins && pluginInstallations && <>
                            {
                                !isEmpty(pluginInstallations) && <>
                                    <h4 className='my-4'>
                                        {t('settings.integrations.title_installed', 'Installed integrations')},
                                    </h4>
                                    <Row className='mb-5'>
                                        {
                                            pluginInstallations.map(
                                                pluginInstallation => <Col
                                                    xs="12"
                                                    sm="6"
                                                    xl="4"
                                                    style={{flex: 1}}
                                                    className='card mx-3 p-3 text-center'
                                                >
                                                    <PluginInstallationCard
                                                        pluginInstallation={pluginInstallation}
                                                    />
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </>
                            }

                            <h4 className='my-4'>
                                {t('settings.integrations.title_setup', 'Install an integration')}:
                            </h4>
                            <Row className='mb-5'>
                                {
                                    !isEmpty(plugins) && plugins.map(
                                        plugin => <Col xs="12" sm="6" xl="4"
                                                       className='p-3 text-center'>
                                            <PluginCard plugin={plugin}/>
                                        </Col>
                                    )
                                }
                                <Col xs="12" sm="6" xl="4"
                                     className='p-3 text-center'>
                                    <PluginCardYandexKassa/>
                                </Col>
                                <Col xs="12" sm="6" xl="4"
                                     className='p-3 text-center'>
                                    <PluginCardRobokassa/>
                                </Col>
                            </Row>
                        </>
                    }
                </div>
            </div>
        </div>
    </>;
};

const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    plugins: state.Plugins.plugins,
    pluginInstallations: state.Plugins.pluginInstallations,
    isSendingPluginsRequest: state.Plugins.isSendingPluginsRequest,
    isSendingPluginInstallationsRequest: state.Plugins.isSendingPluginInstallationsRequest,
    loadPluginsErrors: state.Plugins.loadPluginsErrors,
    loadPluginInstallationsErrors: state.Plugins.loadPluginInstallationsErrors,
});

const mapDispatchToProps = dispatch => ({
    loadPlugins: () => dispatch(loadPlugins()),
    loadPluginInstallations: () => dispatch(loadPluginInstallations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Common);