import {ofType} from 'redux-observable'
import {concat, merge as rxMerge, of, from} from 'rxjs';
import {catchError, debounceTime, filter, map, mergeMap, partition} from 'rxjs/operators'
import assign from "lodash/assign";
import i18next from 'i18next';

import {
    STEP_MOVED,
    UPDATE_ACTIVE_FLOW,
    updateActiveFlowRequestFail,
    updateActiveFlowRequestStart,
    updateActiveFlowRequestSuccess,
    updateStepRequestFail,
    updateStepRequestStart,
    updateStepRequestSuccess,
} from "../actions/Steps";
import {apiAxiosInstance} from "../helpers/api";


export const stepMovedEpic = (action$, state$) =>
    action$.pipe(
        ofType(STEP_MOVED),
        debounceTime(300),
        filter(action => (action.stepId > 0)),
        mergeMap(
            action => {
                const data = {
                    id: action.stepId,
                    shop_id: state$.value.Shops.activeShop.id,
                    html_position_x: action.x,
                    html_position_y: action.y,
                };

                return concat(
                    of(updateStepRequestStart(data)),
                    from(apiAxiosInstance.patch(`step/steps/${action.stepId}/`, data))
                    .pipe(
                        map(response => {
                            if (response.data.status === 'ok') {
                                return updateStepRequestSuccess(response.data.result)
                            } else {
                                return updateStepRequestFail(response.data.errors);
                            }
                        }),
                        catchError(error => {
                            return of(updateStepRequestFail({
                                'common': i18next.t('errors.common.request_sent', 'Error sending request'),
                            }));
                        }),
                    )
                );
            }
        ),
    );

export const updateFlowConfigEpic = (action$, state$) => {
    const [zoom$, position$] = action$.pipe(
        ofType(UPDATE_ACTIVE_FLOW),
        filter(
            (action) => (
                action.data.html_graph_x !== undefined
                || action.data.html_graph_y !== undefined
                || action.data.html_graph_zoom !== undefined
            )
        ),
        partition(action => (action.data.html_graph_zoom !== undefined)),
    );

    return rxMerge(
        zoom$.pipe(
            debounceTime(300),
        ),
        position$.pipe(
            debounceTime(300),
        ),
    ).pipe(
        filter(() => state$.value.Steps.activeFlow),
        mergeMap(
            action => {
                const data = assign(
                    action.data,
                    {
                        id: state$.value.Steps.activeFlow.id,
                    },
                );

                return concat(
                    of(updateActiveFlowRequestStart(data)),
                    from(apiAxiosInstance.patch(`step/flows/${data.id}/`))
                    .pipe(
                        map(response => {
                            if (response.data.status === 'ok') {
                                return updateActiveFlowRequestSuccess(response.data.result);
                            } else {
                                return updateActiveFlowRequestFail(response.data.errors);
                            }
                        }),
                        catchError(error => {
                            return of(updateActiveFlowRequestFail({
                                'common': i18next.t('errors.common.request_sent', 'Error sending request'),
                            }));
                        }),
                    )
                );
            }
        ),
    );
};
