import React, {Component, Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import Loader from "react-loaders";
import SweetAlert from "sweetalert-react";
import createEngine, {DiagramModel} from '@projectstorm/react-diagrams';
import {CanvasWidget} from '@projectstorm/react-canvas-core';
import PerfectScrollbar from "react-perfect-scrollbar";
import Hamburger from "react-hamburgers";
import Drawer from "react-motion-drawer";
import hash from 'object-hash';
import isEmpty from 'lodash/isEmpty';
import forOwn from 'lodash/forOwn';
import values from 'lodash/values';
import get from 'lodash/get';
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import {Trans, withTranslation} from "react-i18next";
import {renderToString} from 'react-dom/server'

import {isMobileDevice} from '../../../index';
import AppHeader from '../../../Layout/AppHeader';
import AppSidebar from '../../../Layout/AppSidebar';
import {TextNodeFactory, TextNodeModel} from "./Nodes/TextNode";
import {CombinedNodeFactory, CombinedNodeModel} from "./Nodes/CombinedNode";
import {PluginNodeFactory, PluginNodeModel} from "./Nodes/PluginNode";
import {HTTPRequestNodeFactory, HTTPRequestNodeModel} from "./Nodes/HTTPRequest";
import {SOAPRequestNodeFactory, SOAPRequestNodeModel} from "./Nodes/SOAPRequest";
import {FormulaNodeFactory, FormulaNodeModel} from "./Nodes/Formula";
import {QuestionNodeFactory, QuestionNodeModel} from "./Nodes/QuestionNode";
import {StoreLeadNodeFactory, StoreLeadNodeModel} from "./Nodes/StoreLead";
import {
    StoreTaskLeadNodeFactory,
    StoreTaskLeadNodeModel,
} from "./Nodes/StoreTaskLead";
import {ChooseLanguageNodeFactory, ChooseLanguageNodeModel} from "./Nodes/ChooseLanguage";
import {CatalogNodeFactory, CatalogNodeModel} from "./Nodes/Catalog";
import {BasketNodeFactory, BasketNodeModel} from "./Nodes/Basket";
import {StoreOrderNodeFactory, StoreOrderNodeModel} from "./Nodes/StoreOrder";
import {HSMNodeFactory, HSMNodeModel} from "./Nodes/HSMNode";
import {getFlow, getSteps, getStepTypes,} from "../../../thunks/Steps";
import {
    addEmptyStep,
    clearActiveFlow,
    flowBuilderSetRightMenuOpen,
    stepMenuButtonHasUpdated,
    stepInlineButtonHasUpdated,
    stepMoved,
    stepRemoved,
    updateActiveFlow,
    updateStepRequestSuccess
} from "../../../actions/Steps";
import {apiFormDeleteSweetalertHide, apiFormDeleteSweetalertShow,} from "../../../actions/APIModel";
import DefaultNormalArrayFieldTemplate from "../../../Components/APIForm/Fields/DefaultNormalArrayFieldTemplate";
import APIForm from "../../../Components/APIForm";
import {ChoiceNodeFactory, ChoiceNodeModel} from "./Nodes/ChoiceNode";
import {SmartDelayNodeFactory, SmartDelayNodeModel} from "./Nodes/SmartDelayNode";
import {ActionNodeFactory, ActionNodeModel} from "./Nodes/ActionNode";
import {ConditionNodeFactory, ConditionNodeModel} from "./Nodes/ConditionNode";
import {RandomizerNodeFactory, RandomizerNodeModel} from "./Nodes/RandomizerNode";
import {apiFormDeleteRequest, apiFormSubmitRequest} from "../../../thunks/ApiModel";
import {
    INLINE_BUTTON_TYPE_NEXT_STEP,
    MENU_BUTTON_NEXT_STEP,
    STEP_TYPE_ACTION,
    STEP_TYPE_BASKET,
    STEP_TYPE_CATALOG,
    STEP_TYPE_CHOICE,
    STEP_TYPE_CHOICE_LANGUAGE,
    STEP_TYPE_CONDITION,
    STEP_TYPE_FORMULA,
    STEP_TYPE_HSM,
    STEP_TYPE_HTTP_REQUEST,
    STEP_TYPE_SOAP_REQUEST,
    STEP_TYPE_QUESTION,
    STEP_TYPE_RANDOMIZER,
    STEP_TYPE_SMART_DELAY,
    STEP_TYPE_STORE_LEAD,
    STEP_TYPE_STORE_TASK_LEAD,
    STEP_TYPE_STORE_ORDER,
    STEP_TYPE_TEXT,
    STEP_TYPE_COMBINED
} from "../../../constants";
import {Button, UncontrolledTooltip} from "reactstrap";


const getNodeModelByStepType = (step_type_id) => {
    switch (step_type_id) {
        case STEP_TYPE_TEXT:
            return TextNodeModel;
        case STEP_TYPE_COMBINED:
            return CombinedNodeModel;
        case STEP_TYPE_HSM:
            return HSMNodeModel;
        case STEP_TYPE_QUESTION:
            return QuestionNodeModel;
        case STEP_TYPE_CHOICE:
            return ChoiceNodeModel;
        case STEP_TYPE_STORE_LEAD:
            return StoreLeadNodeModel;
        case STEP_TYPE_STORE_TASK_LEAD:
            return StoreTaskLeadNodeModel;
        case STEP_TYPE_CHOICE_LANGUAGE:
            return ChooseLanguageNodeModel;
        case STEP_TYPE_CATALOG:
            return CatalogNodeModel;
        case STEP_TYPE_BASKET:
            return BasketNodeModel;
        case STEP_TYPE_STORE_ORDER:
            return StoreOrderNodeModel;
        case STEP_TYPE_ACTION:
            return ActionNodeModel;
        case STEP_TYPE_SMART_DELAY:
            return SmartDelayNodeModel;
        case STEP_TYPE_CONDITION:
            return ConditionNodeModel;
        case STEP_TYPE_RANDOMIZER:
            return RandomizerNodeModel;
        case STEP_TYPE_HTTP_REQUEST:
            return HTTPRequestNodeModel;
        case STEP_TYPE_SOAP_REQUEST:
            return SOAPRequestNodeModel;
        case STEP_TYPE_FORMULA:
            return FormulaNodeModel;
        default:
            return PluginNodeModel;
    }
};

class FlowBuilder extends Component {
    constructor() {
        super();
        this.state = {
            stepsHash: undefined,

            // create an instance of the engine with all the defaults
            engine: createEngine(),
            model: undefined,

            nodesSelectPanelShow: false,
            linkChangedSweetalertShow: false,
            linkChangedSweetalertText: '',
            linkChangedSweetalertOnConfirm: undefined,
        };
        // Factories
        this.state.engine.getNodeFactories().registerFactory(new TextNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new CombinedNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new QuestionNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new StoreLeadNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new StoreTaskLeadNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new ChooseLanguageNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new CatalogNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new BasketNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new StoreOrderNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new ChoiceNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new ActionNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new SmartDelayNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new ConditionNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new RandomizerNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new HTTPRequestNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new SOAPRequestNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new FormulaNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new PluginNodeFactory());
        this.state.engine.getNodeFactories().registerFactory(new HSMNodeFactory());

        this.loadData = this.loadData.bind(this);
        this.initNodes = this.initNodes.bind(this);
        this.zoomToFit = this.zoomToFit.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.getLinkEventListeners = this.getLinkEventListeners.bind(this);
        this.getNodeEventListeners = this.getNodeEventListeners.bind(this);
        this.isLoading = this.isLoading.bind(this);

        this._updateMenuButtonNextStep = this._updateMenuButtonNextStep.bind(this);
        this._updateInlineButtonNextStep = this._updateInlineButtonNextStep.bind(this);
        this._updateConditionsGroupNextStep = this._updateConditionsGroupNextStep.bind(this);
        this._updateSplitGroupNextStep = this._updateSplitGroupNextStep.bind(this);
    }

    componentDidMount() {
        this.loadData();
        this.initNodes();
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeFlow && this.props.activeShop.id !== this.props.activeFlow.shop_id) {
            this.props.history.push(`/${this.props.activeShop.id}/dashboard/`);
        }

        if (this.props.match.url !== prevProps.match.url) {
            this.loadData();
            this.initNodes();
        }

        if (hash(this.props.steps || {}) !== this.state.stepsHash) {
            this.initNodes();
        }
    }

    componentWillUnmount() {
        this.props.flowBuilderSetRightMenuOpen(false);
        this.props.clearActiveFlow();
    }

    loadData() {
        if (this.isLoading()) {
            return;
        }

        const flowId = this.props.match.params.flowId;

        this.props.flowBuilderSetRightMenuOpen(false);
        this.props.getStepTypes();
        this.props.getFlow(flowId);
        this.props.getSteps(flowId);
    }

    isLoading() {
        return this.props.isSendingStepTypesRequest || this.props.isSendingStepsRequest || this.isSendingFlowRequest;
    }

    getLinkEventListeners() {
        const that = this;
        const processPortChanged = (event) => {
            // Prevent link to itself
            if (event.entity.sourcePort.parent === event.entity.targetPort.parent) {
                event.entity.remove();
                return;
            }

            // Prevent break link ot empty port
            if (!event.entity.sourcePort || !event.entity.targetPort) {
                return;
            }

            let outPort = event.entity.sourcePort;
            let inPort = event.entity.targetPort;
            if (event.entity.sourcePort.options.in) {
                outPort = event.entity.targetPort;
                inPort = event.entity.sourcePort;
            }

            // Из исходящего порта должна выходить 1 связь
            if (
                filter(
                    values(outPort.links),
                    (link) => link.sourcePort !== null && link.targetPort !== null
                ).length > 1
            ) {
                // Link is updated
                let linkChangedSweetalertText = undefined;
                let callback = undefined;

                if (outPort.options.name === 'next-step-out') {
                    // Popup on nextStep link changed
                    linkChangedSweetalertText = renderToString(<Trans t={this.props.t} i18nKey='pages.main.flow_builder.sweetalert_generic_change_next_step'>Are you sure you want to change <kbd>{{stepName: outPort.parent.options.step.name}}</kbd> next step and remove current link?</Trans>);
                    callback = () => {
                        // Udate Step
                        that.props.apiFormSubmitRequest(
                            `step/steps/${outPort.parent.options.step.id}/`,
                            'patch',
                            {
                                next_step: {
                                    id: inPort.parent.options.step.id,
                                },
                                shop_id: that.props.activeShop.id,
                            },
                            undefined,
                            that.props.updateStepRequestSuccess
                        );
                    }
                } else {
                    const menuItem = outPort.options.menuItem;
                    const inlineButton = outPort.options.inlineButton;
                    const conditionsGroupCfg = outPort.options.conditionsGroupCfg;
                    const splitGroupCfg = outPort.options.splitGroupCfg;
                    const storeOrderGroupCfg = outPort.options.storeOrderGroupCfg;

                    if (menuItem) {
                        // Popup on menuItem link changed
                        linkChangedSweetalertText = renderToString(<Trans t={this.props.t} i18nKey='pages.main.flow_builder.sweetalert_menu_change_next_step'>Are you sure you want to change <br/> <kbd>{{menuItemName: outPort.options.label}}</kbd> menu item next step and remove the current link?</Trans>);
                        callback = () => {
                            this._updateMenuButtonNextStep(
                                outPort.options.menuItem.id,
                                outPort.parent.options.step.id,
                                inPort.parent.options.step.id,
                            );
                        };
                    } else if (inlineButton) {
                        // Popup on inlineButton link changed
                        linkChangedSweetalertText = renderToString(<Trans t={this.props.t} i18nKey='pages.main.flow_builder.sweetalert_inline_button_change_next_step'>Are you sure you want to change <br/> <kbd>{{buttonName: outPort.options.label}}</kbd> button next step and remove the current link?</Trans>);
                        callback = () => {
                            this._updateInlineButtonNextStep(
                                outPort.options.inlineButton.id,
                                outPort.parent.options.step.id,
                                inPort.parent.options.step.id,
                            );
                        };
                    } else if (conditionsGroupCfg) {
                        // Popup on conditionsGroup link changed
                        linkChangedSweetalertText = that.props.t('pages.main.flow_builder.sweetalert_condition_change_next_step', 'Are you sure you want to change the next step for this condition and remove the current link?');
                        callback = () => {
                            this._updateConditionsGroupNextStep(
                                conditionsGroupCfg,
                                outPort.parent.options.step,
                                inPort.parent.options.step.id,
                            );
                        }
                    } else if (splitGroupCfg) {
                        // Popup on splitGroup link changed
                        linkChangedSweetalertText = that.props.t('pages.main.flow_builder.sweetalert_group_change_next_step', 'Are you sure you want to change the next step for this group of distribution and remove the current link?');
                        callback = () => {
                            this._updateSplitGroupNextStep(
                                splitGroupCfg,
                                outPort.parent.options.step,
                                inPort.parent.options.step.id,
                            );
                        }
                    } else if (storeOrderGroupCfg) {
                        // Popup on splitGroup link changed
                        linkChangedSweetalertText = storeOrderGroupCfg.type === 'success'
                            ? that.props.t('pages.main.flow_builder.sweetalert_change_next_step_success_payment', `Are you sure you want to change "successful payment" next step?`)
                            : that.props.t('pages.main.flow_builder.sweetalert_change_next_step_error_payment', `Are you sure you want to change "payment failed" next step?`);
                        callback = () => {
                            this._updateStoreOrderGroupNextStep(
                                storeOrderGroupCfg,
                                outPort.parent.options.step,
                                inPort.parent.options.step.id,
                            );
                        }
                    }
                }

                that.setState({
                    linkChangedSweetalertShow: true,
                    linkChangedSweetalertText: linkChangedSweetalertText,
                    linkChangedSweetalertOnConfirm: () => {
                        that.setState(
                            {
                                linkChangedSweetalertShow: false,
                                linkChangedSweetalertText: undefined,
                                linkChangedSweetalertOnConfirm: undefined,
                            },
                            callback,
                        );
                    },
                });
            } else {
                if (outPort.options.name === 'next-step-out') {
                    that.props.apiFormSubmitRequest(
                        `step/steps/${outPort.parent.options.step.id}/`,
                        'patch',
                        {
                            next_step: {
                                id: inPort.parent.options.step.id,
                            },
                            shop_id: that.props.activeShop.id,
                        },
                        undefined,
                        that.props.updateStepRequestSuccess,
                    );
                } else if (outPort.options.name === 'cascade-step-out') {
                    that.props.apiFormSubmitRequest(
                        `step/steps/${outPort.parent.options.step.id}/`,
                        'patch',
                        {
                            cascade_step: {
                                id: inPort.parent.options.step.id,
                            },
                            shop_id: that.props.activeShop.id,
                        },
                        undefined,
                        that.props.updateStepRequestSuccess,
                    );
                } else {
                    // Link is created
                    // Different types of outPort
                    const menuItem = outPort.options.menuItem;
                    const inlineButton = outPort.options.inlineButton;
                    const conditionsGroupCfg = outPort.options.conditionsGroupCfg;
                    const splitGroupCfg = outPort.options.splitGroupCfg;
                    const storeOrderGroupCfg = outPort.options.storeOrderGroupCfg;

                    if (menuItem) {
                        this._updateMenuButtonNextStep(
                            menuItem.id,
                            outPort.parent.options.step.id,
                            inPort.parent.options.step.id,
                        );
                    } else if (inlineButton) {
                        this._updateInlineButtonNextStep(
                            inlineButton.id,
                            outPort.parent.options.step.id,
                            inPort.parent.options.step.id,
                        );
                    } else if (conditionsGroupCfg) {
                        this._updateConditionsGroupNextStep(
                            conditionsGroupCfg,
                            outPort.parent.options.step,
                            inPort.parent.options.step.id,
                        );
                    } else if (splitGroupCfg) {
                        this._updateSplitGroupNextStep(
                            splitGroupCfg,
                            outPort.parent.options.step,
                            inPort.parent.options.step.id,
                        );
                    } else if (storeOrderGroupCfg) {
                        this._updateStoreOrderGroupNextStep(
                            storeOrderGroupCfg,
                            outPort.parent.options.step,
                            inPort.parent.options.step.id,
                        );
                    }
                }
            }
        };
        return {
            sourcePortChanged: processPortChanged,
            targetPortChanged: processPortChanged,
            selectionChanged: (event) => {
                /**
                 * Если сняли выделение с недостроенной связью, удалить ее
                 * Также удаляет, если соединили 2 входных или 2 выходных порта
                 */

                !event.isSelected && setTimeout(
                    () => {
                        if (!event.entity.sourcePort || !event.entity.targetPort) {
                            that.state.model.removeLink(event.entity);
                            that.state.engine.repaintCanvas();
                        }
                    },
                    150
                );
            },
            entityRemoved: (event) => {
                if (!event.entity.sourcePort || !event.entity.targetPort) {
                    // Если нет одного из портов, значит удаляли недостроенную связь и ничего делать не нужно
                    return;
                }

                if (this.props.deleteSweetalertShow) {
                    event.stopPropagation();
                    return;
                }

                let outPort = event.entity.sourcePort;
                if (event.entity.sourcePort.options.in) {
                    outPort = event.entity.targetPort;
                }

                if (outPort.options.name === 'next-step-out') {
                    // Udate Step
                    that.props.apiFormSubmitRequest(
                        `step/steps/${outPort.parent.options.step.id}/`,
                        'patch',
                        {
                            next_step: null,
                            shop_id: that.props.activeShop.id,
                        },
                        undefined,
                        that.props.updateStepRequestSuccess
                    );
                } else if (outPort.options.name === 'cascade-step-out') {
                    // Udate Step
                    that.props.apiFormSubmitRequest(
                        `step/steps/${outPort.parent.options.step.id}/`,
                        'patch',
                        {
                            cascade_step: null,
                            shop_id: that.props.activeShop.id,
                        },
                        undefined,
                        that.props.updateStepRequestSuccess
                    );
                } else {
                    // Different types of outPort
                    const menuItem = outPort.options.menuItem;
                    const inlineButton = outPort.options.inlineButton;
                    const conditionsGroupCfg = outPort.options.conditionsGroupCfg;
                    const splitGroupCfg = outPort.options.splitGroupCfg;
                    const storeOrderGroupCfg = outPort.options.storeOrderGroupCfg;

                    if (menuItem) {
                        this._updateMenuButtonNextStep(
                            outPort.options.menuItem.id,
                            outPort.parent.options.step.id,
                            null,
                        );
                    } else if (inlineButton) {
                        this._updateInlineButtonNextStep(
                            outPort.options.inlineButton.id,
                            outPort.parent.options.step.id,
                            null,
                        );
                    } else if (conditionsGroupCfg) {
                        this._updateConditionsGroupNextStep(
                            conditionsGroupCfg,
                            outPort.parent.options.step,
                            null,
                        );
                    } else if (splitGroupCfg) {
                        this._updateSplitGroupNextStep(
                            splitGroupCfg,
                            outPort.parent.options.step,
                            null,
                        );
                    } else if (storeOrderGroupCfg) {
                        this._updateStoreOrderGroupNextStep(
                            storeOrderGroupCfg,
                            outPort.parent.options.step,
                            null,
                        );
                    }
                }
            },
        };
    }

    getNodeEventListeners() {
        const that = this;
        return {
            eventDidFire: (event) => {
                if (event.function === 'positionChanged') {
                    const step = event.entity.options.step;
                    that.props.stepMoved(
                        step.id,
                        event.entity.position.x,
                        event.entity.position.y,
                    );
                } else if (event.function === 'selectionChanged') {
                    // Do nothing
                }
            },
            eventWillFire: (event) => {
                if (event.function === 'entityRemoved') {
                    event.stopPropagation();
                    that.props.apiFormDeleteSweetalertShow(event.entity.options.id);
                }
            },
        }
    }

    createNode(step) {
        let newNodeCls = getNodeModelByStepType(step.type.id);
        let newNode = undefined;
        if (newNodeCls) {
            newNode = new newNodeCls({
                id: step.id,
                step: step,
            });
        }

        if (newNode) {
            newNode.setPosition(step.html_position_x, step.html_position_y);
            newNode.registerListener(this.getNodeEventListeners());
        }

        return newNode;
    }

    initNodes() {
        if (
            !this.props.steps
            || !this.props.stepTypes
            || !this.props.activeFlow
        ) {
            return;
        }

        const that = this;
        this.setState(
            {
                model: new DiagramModel(),
            },
            () => {
                that.state.model.setGridSize(10);
                that.state.model.setZoomLevel(this.props.activeFlow.html_graph_zoom);
                that.state.model.setOffset(
                    this.props.activeFlow.html_graph_x,
                    this.props.activeFlow.html_graph_y,
                );

                that.state.model.registerListener({
                    eventDidFire: (event) => {
                        if (['zoomUpdated', 'offsetUpdated'].includes(event.function)) {
                            let dataToUpdate = {};
                            if (event.zoom) {
                                dataToUpdate.html_graph_zoom = event.zoom;
                            }
                            if (event.offsetX && event.offsetY) {
                                dataToUpdate.html_graph_x = event.offsetX;
                                dataToUpdate.html_graph_y = event.offsetY;
                            }

                            that.props.updateActiveFlow(dataToUpdate);
                        } else if (event.function === 'linksUpdated') {
                            event.link.registerListener(that.getLinkEventListeners(event.link));
                        }
                    }
                });

                // Create Nodes
                const nodes = {};
                that.props.steps.map((step) => {
                    let newNode = that.createNode(step);
                    if (newNode) {
                        nodes[newNode.options.id] = that.state.model.addNode(newNode);
                    }
                });

                // Links
                forOwn(nodes, (node) => {
                    // NextStep link
                    let nextStep = node.options.step.next_step;
                    if (!isEmpty(nextStep)) {
                        let targetNode = that.state.model.getNode(nextStep.id);
                        if (targetNode) {
                            let targetPort = targetNode.getPort('default-in');
                            let outPort = node.getPort('next-step-out');
                            if (outPort) {
                                let link = outPort.link(targetPort);
                                link.registerListener(that.getLinkEventListeners(link));

                                that.state.model.addLink(link);
                            }
                        }
                    }

                    // Cascade link
                    let cascadeStep = node.options.step.cascade_step;
                    if (!isEmpty(cascadeStep)) {
                        let targetNode = that.state.model.getNode(cascadeStep.id);
                        if (targetNode) {
                            let targetPort = targetNode.getPort('default-in');
                            let outPort = node.getPort('cascade-step-out');
                            if (outPort) {
                                let link = outPort.link(targetPort);
                                link.registerListener(that.getLinkEventListeners(link));

                                that.state.model.addLink(link);
                            }
                        }
                    }

                    // MenuButton links
                    node.options.step.menu_buttons.map((menuItem) => {
                        if (menuItem.type.id !== MENU_BUTTON_NEXT_STEP) {
                            return;
                        }

                        if (get(menuItem, 'next_step.id')) {
                            let outPort = node.getPort(`menu-item-${node.id}-${menuItem.id}`,);
                            let targetNode = that.state.model.getNode(menuItem.next_step.id);
                            if (targetNode !== undefined) {
                                let targetPort = targetNode.getPort('default-in');
                                let link = outPort.link(targetPort);
                                link.registerListener(that.getLinkEventListeners(link));

                                that.state.model.addLink(link);
                            }
                        }
                    });

                    // InlineButton links
                    node.options.step.inline_buttons.map((inlineButton) => {
                        if (inlineButton.type.id !== INLINE_BUTTON_TYPE_NEXT_STEP) {
                            return;
                        }

                        if (get(inlineButton, 'next_step.id')) {
                            let outPort = node.getPort(`inline-button-${node.id}-${inlineButton.id}`,);
                            let targetNode = that.state.model.getNode(inlineButton.next_step.id);
                            if (targetNode !== undefined) {
                                let targetPort = targetNode.getPort('default-in');
                                let link = outPort.link(targetPort);
                                link.registerListener(that.getLinkEventListeners(link));

                                that.state.model.addLink(link);
                            }
                        }
                    });

                    // Condition links
                    if (node.options.step.type.id === STEP_TYPE_CONDITION) {
                        // Fallback step_id
                        const fallbackStepId = get(node.options.step, 'state.condition_config.fallback_step_id');
                        if (fallbackStepId) {
                            let fallbackPort = node.getPort(`condition-${node.id}-fallback`);
                            let targetNode = that.state.model.getNode(fallbackStepId);
                            if (targetNode !== undefined) {
                                let targetPort = targetNode.getPort('default-in');
                                let link = fallbackPort.link(targetPort);
                                link.registerListener(that.getLinkEventListeners(link));

                                that.state.model.addLink(link);
                            }
                        }

                        // ConditionGroups
                        get(node.options.step, 'state.condition_config.condition_groups', []).map((group, idx) => {
                            if (group.next_step_id) {
                                let outPort = node.getPort(`condition-${node.id}-${idx}`,);
                                let targetNode = that.state.model.getNode(group.next_step_id);
                                if (targetNode !== undefined) {
                                    let targetPort = targetNode.getPort('default-in');
                                    let link = outPort.link(targetPort);
                                    link.registerListener(that.getLinkEventListeners(link));

                                    that.state.model.addLink(link);
                                }
                            }
                        });
                    }


                    // Randomizer links
                    if (node.options.step.type.id === STEP_TYPE_RANDOMIZER) {
                        const splitGroups = get(node.options.step, 'state.randomizer_config.split_groups', []);
                        splitGroups.map((group, idx) => {
                            if (group.next_step_id) {
                                let outPort = node.getPort(`split-${node.id}-${idx}`,);
                                let targetNode = that.state.model.getNode(group.next_step_id);
                                if (targetNode !== undefined) {
                                    let targetPort = targetNode.getPort('default-in');
                                    let link = outPort.link(targetPort);
                                    link.registerListener(that.getLinkEventListeners(link));

                                    that.state.model.addLink(link);
                                }
                            }
                        });
                    }


                    // StoreOrder links
                    if (node.options.step.type.id === STEP_TYPE_STORE_ORDER) {
                        const is_paid = get(node.options.step, 'state.store_order_config.is_paid', false);
                        if (is_paid) {
                            ['success', 'fail'].map(type => {
                                const payment_next_step_id = get(node.options.step, `state.store_order_config.payment_${type}_next_step_id`);
                                if (payment_next_step_id) {
                                    let outPort = node.getPort(`store-order-${node.id}-payment-${type}`);
                                    let targetNode = that.state.model.getNode(payment_next_step_id);
                                    if (targetNode !== undefined) {
                                        let targetPort = targetNode.getPort('default-in');
                                        let link = outPort.link(targetPort);
                                        link.registerListener(that.getLinkEventListeners(link));

                                        that.state.model.addLink(link);
                                    }
                                }
                            });
                        }
                    }
                });

                that.state.engine.setModel(that.state.model);

                that.setState({stepsHash: hash(this.props.steps || {})});
            }
        );
    }

    onDelete = () => {
        /**
         * Функция специально для всплывашки
         */
        this.props.apiFormDeleteRequest(
            `step/steps/${this.props.idToRemove}/`,
            this.props.actionRedirectUrl,
        );
        this.props.stepRemoved(this.props.idToRemove);

        this.props.apiFormDeleteSweetalertHide();
    };

    zoomIn = (event) => {
        this.zoom(event, 0.2);
    };

    zoomOut = (event) => {
        this.zoom(event, -0.2);
    };

    zoom = (event, level) => {
        const oldZoom = this.state.model.getZoomLevel();
        const newZoom = Math.max(Math.min(oldZoom * (1 + level), 100), 10);
        const actualLevel = newZoom / oldZoom - 1;

        this.state.model.setZoomLevel(newZoom);

        this.state.model.setOffset(
            this.state.model.getOffsetX() - (this.state.engine.getCanvas().offsetWidth / 2 - this.state.model.getOffsetX()) * actualLevel,
            this.state.model.getOffsetY() - (this.state.engine.getCanvas().offsetHeight / 2 - this.state.model.getOffsetY()) * actualLevel,
        );

        this.state.engine.repaintCanvas();
        event.stopPropagation();
        event.nativeEvent.stopPropagation();
    };

    zoomToFit = (event) => {
        this.state.engine.zoomToFit();
        this.state.model.setOffset(
            this.state.engine.getCanvas().offsetWidth / 2,
            this.state.engine.getCanvas().offsetHeight / 2,
        );
        event.stopPropagation();
        event.nativeEvent.stopPropagation();
    };

    _updateMenuButtonNextStep = (menuItemId, stepId, nextStepId) => {
        const that = this;
        this.props.apiFormSubmitRequest(
            `step/menu_buttons/${menuItemId}/`,
            'patch',
            {
                next_step: nextStepId ? {
                    id: nextStepId
                } : null,
            },
            undefined,
            (response) => {
                that.props.stepMenuButtonHasUpdated(stepId, response)
            },
        );
    };

    _updateInlineButtonNextStep = (menuItemId, stepId, nextStepId) => {
        const that = this;
        this.props.apiFormSubmitRequest(
            `step/inline_buttons/${menuItemId}/`,
            'patch',
            {
                next_step: nextStepId ? {
                    id: nextStepId
                } : null,
            },
            undefined,
            (response) => {
                that.props.stepInlineButtonHasUpdated(stepId, response)
            },
        );
    };

    _updateConditionsGroupNextStep = (conditionsGroupCfg, step, nextStepId) => {
        const that = this;
        const newState = cloneDeep(step.state);

        if (conditionsGroupCfg.isFallback) {
            merge(
                newState,
                {
                    condition_config: {
                        fallback_step_id: nextStepId,
                    }
                }
            );
        } else {
            newState.condition_config.condition_groups[conditionsGroupCfg.idx].next_step_id = nextStepId;
        }

        that.props.apiFormSubmitRequest(
            `step/steps/${step.id}/`,
            'patch',
            {
                shop_id: that.props.activeShop.id,
                state: newState,
            },
            undefined,
            that.props.updateStepRequestSuccess
        );
    };

    _updateSplitGroupNextStep = (splitGroupCfg, step, nextStepId) => {
        const that = this;
        const newState = cloneDeep(step.state);

        newState.randomizer_config.split_groups[splitGroupCfg.idx].next_step_id = nextStepId;

        that.props.apiFormSubmitRequest(
            `step/steps/${step.id}/`,
            'patch',
            {
                shop_id: that.props.activeShop.id,
                state: newState,
            },
            undefined,
            that.props.updateStepRequestSuccess
        );
    };

    _updateStoreOrderGroupNextStep = (storeOrderGroupCfg, step, nextStepId) => {
        const that = this;
        const newState = cloneDeep(step.state);

        newState.store_order_config[`payment_${storeOrderGroupCfg.type}_next_step_id`] = nextStepId;

        that.props.apiFormSubmitRequest(
            `step/steps/${step.id}/`,
            'patch',
            {
                shop_id: that.props.activeShop.id,
                state: newState,
            },
            undefined,
            that.props.updateStepRequestSuccess
        );
    };

    _addNewStep = (stepTypeId, name, x, y) => {
        this.setState({
            nodesSelectPanelShow: false,
        });

        const that = this;
        const newStepData = {
            name: name,
            next_step: null,
            text: {},
            shop_id: this.props.activeShop.id,
            type: {
                id: stepTypeId,
            },
            flow_id: this.props.activeFlow.id,
            html_position_x: Math.floor(x),
            html_position_y: Math.floor(y),
        };

        // Add Step to Store
        this.props.apiFormSubmitRequest(
            'step/steps/',
            'post',
            newStepData,
            undefined,
            (response) => {
                that.props.addEmptyStep(response);
                setTimeout(
                    that.forceUpdate(),
                    150
                );
            }
        );
    }

    render() {
        if (isMobileDevice()) {
            return <Fragment>
                <AppHeader/>
                <div className="app-main">
                    <AppSidebar/>
                    <div className="app-main__outer pb-0 justify-content-center align-items-center text-center"
                         style={{height: 'calc(100vh - 60px)'}}>
                        <Trans t={this.props.t} i18nKey='pages.main.flow_builder.title_mobile_not_available'>
                            <h1>😇</h1>
                            <p>
                                Visual editor is only available<br/>
                                on big screen devices for now!
                            </p>
                        </Trans>
                    </div>
                </div>
            </Fragment>;
        } else if (this.isLoading()) {
            return <Fragment>
                <AppHeader/>
                <div className="app-main">
                    <AppSidebar/>
                    <div
                        className="app-main__outer pb-0 justify-content-center align-items-center text-center"
                        style={{height: 'calc(100vh - 60px)'}}
                    >
                        <Loader type="line-scale-party" active/>
                    </div>
                </div>
            </Fragment>;
        }

        const that = this;

        return <Fragment>
            <AppHeader/>
            <div className="app-main">
                <AppSidebar/>
                <div className="app-main__outer pb-0">
                    <div className='bg-white border-bottom py-3 px-4' style={{height: '64px'}}>
                        <h5 className='m-0 d-flex align-items-center'>
                            <Link to={`/${this.props.activeShop.id}/flows/`} className='text-dark'>Flows</Link>
                            <i className="pe-7s-angle-right text-primary m-1" style={{fontSize: '1.4rem'}}/>
                            {get(this.props, 'activeFlow.name')}
                        </h5>
                    </div>
                    <div className="app-main__inner p-0" id="graph-editor"
                         onDrop={
                             (event) => {
                                 let data = JSON.parse(event.dataTransfer.getData('storm-diagram-node'));

                                 let point = this.state.engine.getRelativeMousePoint(event);
                                 point.x -= 150;
                                 point.y -= 70;

                                 that._addNewStep(data.step_type.id, data.step_type.name, point.x, point.y);
                             }
                         }
                         onDragOver={
                             (event) => {
                                 event.preventDefault();
                             }
                         }
                         onClick={() => setTimeout(
                             () => that.setState({
                                 nodesSelectPanelShow: false,
                             }),
                             150
                         )}
                    >
                        {this.state.nodesSelectPanelShow && this.props.stepTypes
                        && <NodesSelectPanel
                                    // nodesSelectPanelShow={this.state.nodesSelectPanelShow}
                                   engine={this.state.engine}
                                   stepTypes={this.props.stepTypes}
                                   _addNewStep={this._addNewStep}
                        />}
                        {
                            this.state.stepsHash && <Fragment>
                                <CanvasWidget className="canvas" engine={this.state.engine}/>
                                <div id="nodes-button-block">
                                    <Button
                                        className="add-step-button shadow"
                                        color='primary'
                                        onClick={event => {
                                            that.setState({
                                                nodesSelectPanelShow: !that.state.nodesSelectPanelShow,
                                            });
                                            event.stopPropagation();
                                        }}
                                    >
                                        <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                            <path d="M12 19V12M12 5V12M5 12H12M12 12H19" stroke="#ffffff"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"/>
                                        </svg>
                                    </Button>
                                    <Button className="canvas-control-button" id='canvas-control-button-zoom-in'
                                            color='light' onClick={this.zoomIn}>
                                        <i className="lnr-plus-circle"> </i>
                                    </Button>
                                    <UncontrolledTooltip placement="right" target='canvas-control-button-zoom-in'>
                                        {this.props.t('pages.main.flow_builder.button_zoom_in', "Zoom in")}
                                    </UncontrolledTooltip>
                                    <Button className="canvas-control-button" id='canvas-control-button-zoom-out'
                                            color='light' onClick={this.zoomOut}>
                                        <i className="lnr-circle-minus"> </i>
                                    </Button>
                                    <UncontrolledTooltip placement="right" target='canvas-control-button-zoom-out'>
                                        {this.props.t('pages.main.flow_builder.button_zoom_out', "Zoom out")}
                                    </UncontrolledTooltip>
                                    <Button className="canvas-control-button" id='canvas-control-button-recenter'
                                            color='light' onClick={this.zoomToFit}>
                                        <i className="lnr-frame-expand"> </i>
                                    </Button>
                                    <UncontrolledTooltip placement="right" target='canvas-control-button-recenter'>
                                        {this.props.t('pages.main.flow_builder.button_recenter', "Re-center view")}
                                    </UncontrolledTooltip>
                                </div>
                            </Fragment>
                        }
                        <Drawer
                            right
                            className="drawer-content-wrapper graph-drawer p-0"
                            width={500}
                            open={this.props.flowBuilderRightMenuOpen}
                            noTouchOpen={true}
                            noTouchClose={true}
                            overlayClassName='d-md-none'
                        >
                            <PerfectScrollbar>
                                <div className="drawer-nav-btn shadow p-0">
                                    <Hamburger
                                        active={this.props.flowBuilderRightMenuOpen}
                                        type="elastic"
                                        onClick={() => this.props.flowBuilderSetRightMenuOpen(false)}
                                    />
                                </div>

                                {
                                    this.props.flowBuilderRightMenuOpen && <APIForm
                                        apiPath='step/steps'
                                        modelId={this.props.flowBuilderSelectedStepId}
                                        method='patch'
                                        ArrayFieldTemplate={DefaultNormalArrayFieldTemplate}
                                        buttonSubmitDisabled={false}
                                        deletionDisabled={false}
                                        actionRedirectUrl={undefined}
                                        afterSubmitCallback={this.props.updateStepRequestSuccess}
                                        apiFormAfterDeleteCallback={() => this.props.stepRemoved(this.props.idToRemove)}
                                    />
                                }
                            </PerfectScrollbar>
                        </Drawer>
                    </div>
                </div>
            </div>

            <SweetAlert
                title={this.props.t('pages.main.flow_builder.sweetalert_remove_agreement_title', "Are you sure?")}
                confirmButtonColor="#d92550"
                show={this.props.deleteSweetalertShow}
                text={this.props.t('pages.main.flow_builder.sweetalert_remove_agreement_caption', "It will be removed permanently.")}
                type="warning"
                showCancelButton
                cancelButtonText={this.props.t('bot_card.common.button_cancel', 'Cancel')}
                onConfirm={this.onDelete}
                onCancel={this.props.apiFormDeleteSweetalertHide}
            />

            <SweetAlert
                title={this.props.t('pages.main.flow_builder.sweetalert_change_link_title', "Change link?")}
                confirmButtonColor="#d92550"
                show={this.state.linkChangedSweetalertShow}
                text={this.state.linkChangedSweetalertText}
                type="warning"
                html
                showCancelButton
                cancelButtonText={this.props.t('bot_card.common.button_cancel', 'Cancel')}
                onConfirm={this.state.linkChangedSweetalertOnConfirm}
                onCancel={
                    () => {
                        this.setState({linkChangedSweetalertShow: false});
                    }
                }
            />
        </Fragment>
    }
}

const NodesSelectPanel = ({
                              stepTypes,
                              engine,
                              _addNewStep,
                              // nodesSelectPanelShow
                          }) => {

    const [isNodesStepSelectOpen, setIsNodesStepSelectOpen] = useState(null)

    const isNodesStepSelectOpenToggle = useCallback((e) => {
        e.stopPropagation();
        setIsNodesStepSelectOpen(!isNodesStepSelectOpen)
    }, [isNodesStepSelectOpen])

    // useEffect(() => {
    //     if (!nodesSelectPanelShow) {
    //         setIsNodesStepSelectOpen(false)
    //     }
    // }, [nodesSelectPanelShow])

    const containerRef = useRef(null)
    const [nodesStepSelectContainer, setNodesStepSelectContainer] = useState(null)
    useEffect(() => {
        setNodesStepSelectContainer(containerRef)
    }, [])

    const onDragStart = useCallback((stepType) => event => {
        const data = {
            step_type: stepType,
        };
        event.dataTransfer.setData('storm-diagram-node', JSON.stringify(data));
    }, [])

    const onClick = useCallback((stepType) => event => {
        let rect = engine.canvas.getBoundingClientRect();
        let point = engine.getRelativeMousePoint({
            clientX: (rect.left + rect.right) / 2,
            clientY: (rect.top + rect.bottom) / 2,
        });
        _addNewStep(
            stepType.id,
            stepType.name,
            point.x - 150,
            point.y - 70,
        );
    }, [_addNewStep, engine])

    return <div
        className='border border-warning bg-white nodes-step-select'
        style={isNodesStepSelectOpen
            ? {width: nodesStepSelectContainer.current.offsetWidth}
            : {width: '105px'}}
    >
        <div className={'nodes-step-select__container'}
             ref={containerRef}
        >
            {
                stepTypes.map((stepType) => <StepType
                        key={stepType.id}
                        onClick={onClick(stepType)}
                        onDragStart={onDragStart(stepType)}
                        stepType={stepType}
                    />
                )
            }
        </div>
        <div
            className={'nodes-step-select__footer border-top text-right d-flex align-items-center justify-content-end'}>
            <i className={`fa fas fa-angle-double-${isNodesStepSelectOpen ? 'left' : 'right'} fa-2x pr-2`}
               onClick={isNodesStepSelectOpenToggle}
            />
        </div>


    </div>
}

const StepType = ({
                      stepType,
                      onDragStart,
                      onClick
                  }) => {

    // const [tooltipOpen,setTooltipOpen] = useState(false)
    //
    // const toggle = useCallback(() => {
    //     setTooltipOpen(!tooltipOpen)
    // }, [tooltipOpen])

    return <div
        className={'nodes-step-select__item'}
        draggable={true}
        onDragStart={onDragStart}
        onClick={onClick}
        id={'TooltipLight-' + stepType.id}

    >
        <div
            className={`nodes-step-select__icon ${stepType.html_bg_class} border-0`}
        >
                <i className={`${stepType.html_icon} icon-gradient bg-white`}> </i>
        </div>
        <div className="step-type-text">{stepType.name}</div>

        <UncontrolledTooltip
            // className="tooltip-light"
            placement={'top'}
            // isOpen={tooltipOpen}
            target={'TooltipLight-' + stepType.id}
            // toggle={toggle}
        >
            {stepType.name}
        </UncontrolledTooltip>

    </div>
}


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,

    isSendingStepTypesRequest: state.Steps.isSendingStepTypesRequest,
    isSendingStepsRequest: state.Steps.isSendingStepsRequest,
    isSendingFlowRequest: state.Steps.isSendingFlowRequest,

    deleteSweetalertShow: state.APIForm.deleteSweetalertShow,
    idToRemove: state.APIForm.idToRemove,

    stepTypes: state.Steps.stepTypes,
    steps: state.Steps.steps,
    activeFlow: state.Steps.activeFlow,

    stepTypesError: state.Steps.stepTypesError,
    stepsError: state.Steps.stepsError,

    flowBuilderRightMenuOpen: state.Steps.flowBuilderRightMenuOpen,
    flowBuilderSelectedStepId: state.Steps.flowBuilderSelectedStepId,
});

const mapDispatchToProps = dispatch => ({
    getStepTypes: () => dispatch(getStepTypes()),
    getSteps: (flowId) => dispatch(getSteps(flowId)),
    getFlow: (flowId) => dispatch(getFlow(flowId)),

    apiFormSubmitRequest: (apiPath, method, formData, redirectUrl, callback) => dispatch(apiFormSubmitRequest(apiPath, method, formData, redirectUrl, callback)),
    apiFormDeleteRequest: (apiPath, redirectUrl) => dispatch(apiFormDeleteRequest(apiPath, redirectUrl)),
    apiFormDeleteSweetalertShow: (idToRemove) => dispatch(apiFormDeleteSweetalertShow(idToRemove)),
    apiFormDeleteSweetalertHide: () => dispatch(apiFormDeleteSweetalertHide()),

    updateActiveFlow: (data) => dispatch(updateActiveFlow(data)),
    clearActiveFlow: () => dispatch(clearActiveFlow()),

    addEmptyStep: (stepData) => dispatch(addEmptyStep(stepData)),
    stepMoved: (stepId, x, y) => dispatch(stepMoved(stepId, x, y)),
    stepRemoved: (stepId) => dispatch(stepRemoved(stepId)),
    updateStepRequestSuccess: (response) => dispatch(updateStepRequestSuccess(response)),
    stepMenuButtonHasUpdated: (stepId, response) => dispatch(stepMenuButtonHasUpdated(stepId, response)),
    stepInlineButtonHasUpdated: (stepId, response) => dispatch(stepInlineButtonHasUpdated(stepId, response)),

    flowBuilderSetRightMenuOpen: (open) => dispatch(flowBuilderSetRightMenuOpen(open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(withTranslation()(FlowBuilder))
);
