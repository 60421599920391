import React, {useState} from "react";
import {connect} from "react-redux";
import 'moment/locale/ru';
import Loader from "react-loaders";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {Button, Form, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalHeader,} from "reactstrap";
import isEmpty from "lodash/isEmpty";

import {sendMessage} from "../../../thunks/Chats";
import {uploadToCdnAxiosInstance} from "../../../helpers/api";


const SendForm = (
    {
        activeChat,
        wsConnected,

        sendMessage,
    }
) => {
    const {t, i18n} = useTranslation();

    const [fileModalOpen, setFileModalOpen] = useState(false);
    const [fileIsUploading, setFileIsUploading] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(undefined);
    const [fileUploadFailed, setFileUploadFailed] = useState(false);
    const [isImage, setIsImage] = useState(false);
    // TODO: добаавить видео
    const [messageTextareaValue, setMessageTextareaValue] = useState('');
    const [fileInput, setFileInput] = useState(undefined);

    const onMessageTextareaKeyPress = (event) => {
        if (!isEmpty(activeChat) && event.charCode === 13) {
            event.preventDefault();
            event.stopPropagation();

            sendTextMessage();
        }
    };

    const onMessageTextareaSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        sendTextMessage();
    };

    const sendTextMessage = () => {
        const trimValue = String(messageTextareaValue).trim();
        if (isEmpty(activeChat) || !trimValue) {
            return;
        }

        setMessageTextareaValue('');

        sendMessage(
            activeChat.id,
            'text',
            {
                text: trimValue,
            },
            // () => {
            //     if (!wsConnected) {
            //         setTimeout(loadLastMessagesAPI, 1000);
            //     }
            // }
        )
    };

    const sendFileMessage = () => {
        const trimValue = String(messageTextareaValue).trim();
        if (isEmpty(activeChat) || fileIsUploading || fileUploadFailed) {
            return;
        }

        setFileUploaded(undefined);
        setFileModalOpen(false);
        setMessageTextareaValue('');

        const data = {
            text: trimValue,
        };
        data[isImage ? 'image_url' : 'file_url'] = fileUploaded;

        sendMessage(
            activeChat.id,
            isImage ? 'image' : 'file',
            data,
            // () => {
            //     if (!wsConnected) {
            //         setTimeout(loadLastMessagesAPI, 1000);
            //     }
            // }
        )
    };

    const toggleFileModal = () => {
        if (fileModalOpen) {
            fileInput.value = null;
        }

        setFileModalOpen(!fileModalOpen);
    };

    const onFileModalChange = (event) => {
        setFileUploaded(undefined);
        setFileIsUploading(true);

        const file = event.target.files[0];
        // Check the file type.
        console.log('Upload ' + file.type);

        const isImage = file.type.match('image.*');
        setIsImage(isImage);
        if (isImage && ['image/png', 'image/jpeg', 'image/gif'].indexOf(file.type) === -1) {
            setFileUploaded(undefined);
            setFileIsUploading(false);
            setFileUploadFailed(true);
            toast.error(t('messages.new_message.toast.error.choose_image', 'Select PNG, JPEG or GIF image'));
            return;
        }

        toggleFileModal();

        let formData = new FormData();
        formData.append('file', file);

        uploadToCdnAxiosInstance.post(`${file.name}/`, formData).then(
            (request) => {
                setFileUploaded(request.data.result.cdn_filepath);
                setFileIsUploading(false);
                setFileUploadFailed(false);
            },
            () => {
                setFileUploaded(undefined);
                setFileIsUploading(false);
                setFileUploadFailed(true);
            },
        );
    };

    const onImageTextareaKeyPress = (event) => {
        if (!isEmpty(activeChat) && event.charCode === 13) {
            event.preventDefault();
            event.stopPropagation();

            sendFileMessage();
        }
    };

    const onImageTextareaSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        sendFileMessage();
    };

    const popupImagePreview = fileIsUploading
        ? <Loader type="line-scale-party"/>
        : (
            fileUploadFailed
                ? <img src={fileUploadFailed} style={{maxHeight: 165}}/>
                : (
                    isImage
                        ? <img src={fileUploaded} className='mb-2 img-fluid'
                               style={{maxWidth: 420, maxHeight: 300}}/>
                        : <i className='pe-7s-file' style={{fontSize: '5rem'}}/>
                )
        );

    return <>
        <input
            id="fileInput"
            type="file"
            ref={(ref) => setFileInput(ref)}
            className='d-none'
            onChange={onFileModalChange}
            // accept="image/png, image/jpeg, image/gif"
        />
        <Form
            className="app-inner-layout__bottom-pane d-block text-center border-top"
            onSubmit={onMessageTextareaSubmit}
        >
            <InputGroup className='px-2 py-2'>
                <InputGroupAddon addonType="prepend">
                    <Button
                        color="light"
                        onClick={() => fileInput.click()}
                        disabled={isEmpty(activeChat)}
                    >
                        {/*<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg"*/}
                        {/*     viewBox="0 0 24 24" width="24" height="24">*/}
                        {/*    <path fill="#263238" fillOpacity=".5"*/}
                        {/*          d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"/>*/}
                        {/*</svg>*/}
                        <i className={'fa fas fa-paperclip fa-2x text-black-50 px-2'}/>
                    </Button>
                </InputGroupAddon>
                <Input
                    type="textarea"
                    style={{resize: 'none'}}
                    placeholder={t('messages.new_message.placeholder_text', "Message...")}
                    required
                    disabled={isEmpty(activeChat)}
                    onChange={(event) => setMessageTextareaValue(event.target.value)}
                    value={messageTextareaValue}
                    onKeyPress={onMessageTextareaKeyPress}
                />
                <InputGroupAddon addonType="append">
                    <Button color="primary" disabled={isEmpty(activeChat)}>
                        {t('messages.new_message.button_send_msg', "Send")}
                        <br/>
                        <span className="opacity-7">
                            (Enter)
                        </span>
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        </Form>

        <Modal
            isOpen={fileModalOpen}
            toggle={toggleFileModal}
            className='chats-image-upload-modal'
        >
            <ModalHeader toggle={toggleFileModal} className='d-flex align-items-center'
                         style={{minHeight: 200}}>
                {popupImagePreview}
            </ModalHeader>

            <ModalBody className='p-0'>
                <Form className="text-center" onSubmit={onImageTextareaSubmit}>
                    <InputGroup className='px-2 py-3 py-md-2'>
                        <Input
                            type="textarea"
                            style={{resize: 'none'}}
                            placeholder={t('messages.new_message.placeholder_text', "Message...")}
                            onChange={(event) => setMessageTextareaValue(event.target.value)}
                            value={messageTextareaValue}
                            disabled={isEmpty(activeChat) || fileIsUploading || fileUploadFailed}
                            onKeyPress={onImageTextareaKeyPress}
                        />
                        <InputGroupAddon addonType="append">
                            <Button color="primary"
                                    disabled={isEmpty(activeChat) || fileIsUploading || fileUploadFailed}>
                                {t('messages.new_message.button_send_msg', "Send")}
                                <br/>
                                <span className="opacity-7">
                                    (Enter)
                                </span>
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Form>
            </ModalBody>
        </Modal>
    </>;
}


const mapStateToProps = state => ({
    activeChat: state.Chats.activeChat,
    wsConnected: state.Chats.wsConnected,
});

const mapDispatchToProps = dispatch => ({
    sendMessage: (chat_id, contentType, payload, callback) => dispatch(sendMessage(chat_id, contentType, payload, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendForm);
