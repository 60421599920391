import React, {useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    Card,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    ListGroup,
    ListGroupItem,
    UncontrolledDropdown
} from "reactstrap";
import get from 'lodash/get';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStop} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert-react";

import avitoLogo from '../../../../assets/utils/images/messenger/avito.png';
import chatbotEmpty from '../../../../assets/utils/images/chatbot-empty.svg';
import {deleteBot, restartBot, stopBot} from "../../../../thunks/Dashboard";
import {getStatus, getTopLine} from "./utils";
import {
    BOT_STATUS_RUNNING,
    SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU,
    SHOP_SOURCE_RETAIL_CRM_WABA_RU
} from "../../../../constants";


const BotCardAvito = (
    {
        activeShop,
        bot,
        restartBot,
        stopBot,
        deleteBot,
    }
) => {
    const {t, i18n} = useTranslation();

    const [showDeleteAlert, setShowDeleteAlert] = useState(false);

    const isRetailCRM = (get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_WABA_RU
      || get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU);

    return <>
        <Card className="mb-3 profile-responsive bot-card">
            {getTopLine(bot)}

            <div className="position-relative">
                <UncontrolledDropdown>
                    <DropdownToggle color="light" className='bot-card__menu-toggle text-dark'>
                        <i className="pe-7s-menu"/>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem
                            onClick={
                                () => restartBot(
                                    bot.id,
                                    () => {
                                        window.location.reload()
                                    }
                                )
                            }
                            disabled={bot.status === BOT_STATUS_RUNNING}
                        >
                            <i className="pe-7s-plug fsize-2 text-primary"/>&nbsp;
                            {t('connect_bot.common.reconnect', 'Reconnect')}
                        </DropdownItem>
                        <DropdownItem
                            onClick={
                                () => stopBot(
                                    bot.id,
                                    () => {
                                        window.location.reload()
                                    }
                                )
                            }
                            disabled={bot.status !== BOT_STATUS_RUNNING}
                        >
                            <FontAwesomeIcon
                                icon={faStop}
                                className='text-primary'
                            />&nbsp;&nbsp;
                            {t('connect_bot.common.stop', 'Stop')}
                        </DropdownItem>
                        <DropdownItem
                            onClick={() => setShowDeleteAlert(true)}
                        >
                            <i className="pe-7s-trash fsize-2 text-primary"/>&nbsp;
                            {t('connect_bot.common.remove', 'Remove')}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>

                <div className="bot-card__status">
                    {getStatus(bot)}
                </div>

                <div className="dropdown-menu-header text-dark mt-0">
                    <div className="dropdown-menu-header-inner bg-white">
                        <div className="menu-header-content btn-pane-right d-block text-center">
                            <h5 className="d-flex align-items-center justify-content-center mb-3">
                                <img src={avitoLogo} alt="Avito" style={{height: 45}} className='mr-2'/>
                                {bot.name}
                            </h5>

                            <div className="avatar-icon-wrapper mr-3 avatar-icon-xl btn-hover-shine">
                                <div className="avatar-icon rounded">
                                    <img src={bot.avatar_url || chatbotEmpty}/>
                                </div>
                            </div>

                            <h6>{bot.avito_user_id || bot.name}</h6>
                        </div>
                    </div>
                </div>
                {/*{false && <ListGroup flush>*/}
                {/*    <ListGroupItem>*/}
                {/*        <div className="widget-content px-0 pb-0 pt-1">*/}
                {/*            <div className="text-center">*/}
                {/*            <span className="widget-heading opacity-4 mb-0">*/}
                {/*                {t('bot_card.common.users', 'Subscribers')}*/}
                {/*            </span>*/}
                {/*                <h6 className="mt-2 mb-3">*/}
                {/*            <span className="pr-2">*/}
                {/*                <b className="text-primary">{get(bot, 'statistic.count_bot_users_day')}</b> {t('bot_card.common.users_new_today', 'new for totay')},*/}
                {/*            </span>*/}
                {/*                    <span>*/}
                {/*                <b className="text-info">{get(bot, 'statistic.count_bot_users_all')}</b> {t('bot_card.common.users_total', 'total')}*/}
                {/*            </span>*/}
                {/*                </h6>*/}
                {/*            </div>*/}
                {/*            {*/}
                {/*                !isRetailCRM && <div className="text-center widget-heading mb-0">*/}
                {/*                    <span className="opacity-4">*/}
                {/*                        {t('bot_card.common.leads_total', 'Leads')}:&nbsp;*/}
                {/*                    </span>*/}
                {/*                    {get(bot, 'statistic.leads_count_all')}*/}

                {/*                    /!*<Button color="primary" className="btn-wide btn-pill" outline>*!/*/}
                {/*                    /!*    Full Report*!/*/}
                {/*                    /!*</Button>*!/*/}
                {/*                </div>*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    </ListGroupItem>*/}
                {/*</ListGroup>}*/}
            </div>
        </Card>

        <SweetAlert
            title={t('bot_card.common.title_remove_channel', "Remove channel?")}
            confirmButtonColor="#d92550"
            show={showDeleteAlert}
            text={t('bot_card.common.alert_messages_will_be_removed', '⚠️ Messages history will be permanently removed!')}
            type="warning"
            showCancelButton
            cancelButtonText={t('bot_card.common.button_cancel', 'Cancel')}
            onConfirm={() => {
                deleteBot(
                    bot.id,
                    () => {
                        window.location.reload()
                    }
                );
                setShowDeleteAlert(false);
            }}
            onCancel={() => setShowDeleteAlert(false)}
        />
    </>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
});

const mapDispatchToProps = dispatch => ({
    restartBot: (bot_id, callback) => dispatch(restartBot(bot_id, callback)),
    stopBot: (bot_id, callback) => dispatch(stopBot(bot_id, callback)),
    deleteBot: (bot_id, callback) => dispatch(deleteBot(bot_id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BotCardAvito);
