import React from "react";
import {Input} from "reactstrap";
import get from 'lodash/get';

import MultilangTextInputField from './MultilangTextInputField';


export default class MultilangTextareaField extends MultilangTextInputField {
    getLangField(lang) {
        let value = this.state.formData[lang] || '';
        return <>
            <Input
                type='textarea'
                className='mb-2'
                readOnly={get(this.props, ['uiSchema', 'ui:readonly'])}
                rows={7}
                value={value}
                lang={lang}
                onChange={(...args) => {
                    this.onLangFieldChange(lang, args[0]);
                }}
            />
        </>;
    }
}
