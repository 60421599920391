import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Row,
    Label, Card, CardHeader, CardBody,
} from 'reactstrap';
import get from 'lodash/get';
import {Link} from "react-router-dom";

import {concatURL, getQueryParams, renderErrorsAsHTML} from "../../Components/utils";
import {touchPasswordResetForm, setPasswordResetFormErrors} from "../../actions/Auth";
import {setNewPassword} from "../../thunks/Auth";
import {useTranslation, Trans} from "react-i18next";


const SetNewPasswordForm = (
    {
        errors,
        isSendingRequest,
        isRequestSent,
        setNewPassword,
        touchPasswordResetForm,
        setPasswordResetFormErrors,
    }
) => {
    const {t, i18n} = useTranslation();

    const [password, setPassword] = useState('');
    const [passwordrep, setPasswordrep] = useState('');

    // --------------------------
    // Hooks
    // --------------------------
    // componentDidMount && componentDidUpdate
    useEffect(
        () => {
            touchPasswordResetForm();
        },
        [password]
    );

    const token = getQueryParams().token;

    const sendSetPasswordRequest = (e) => {
        e.preventDefault();

        if (password !== passwordrep) {
            setPasswordResetFormErrors({
                ...errors,
                password: [t('auth.set_new_password.error_passwords_not_equal', 'Passwords are mismatched')],
                passwordrep: true,
            });

            return
        }

        setNewPassword(
            password,
            token,
            (response) => {
                setTimeout(
                    () => {
                        window.location.href = concatURL(window.location.href, '../login');
                    },
                    3000
                );
            }
        );
    };

    return <Fragment>
        <div className="h-min-100">
            <Row className="h-min-100 no-gutters">
                <Col md="12" className="h-min-100 d-flex bg-white justify-content-center align-items-center">
                    <Col lg="6" md="8" sm="12" className="mx-auto app-login-box">
                        <div className="app-logo"/>
                        {
                            !isRequestSent && <h4 className='mb-4'>
                                <Trans t={t} i18nKey='auth.set_new_password.head'>
                                    <div>Password reset</div>
                                    <span>Type new password:</span>
                                </Trans>
                            </h4>
                        }
                        {
                            isRequestSent && <>
                                <Card className='text-white bg-success'>
                                    <Trans t={t} i18nKey='auth.set_new_password.header_success'>
                                        <CardHeader>
                                            Password is set!
                                        </CardHeader>
                                        <CardBody>
                                            You will be redirected to the login page...
                                        </CardBody>
                                    </Trans>
                                </Card>
                                <h6 className="mt-4 text-center">
                                    <Link to="/auth/login">
                                        <span className="text-primary">
                                            {t('auth.forgot_password.button_back', '← go back')}
                                        </span>
                                    </Link>
                                </h6>
                            </>
                        }
                        {
                            !isRequestSent && <Form onSubmit={sendSetPasswordRequest} autoComplete="on">
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="examplePassword">
                                                <span className="text-danger">*</span>
                                                {' '}{t('auth.forgot_password.label_password', 'Password')}
                                            </Label>
                                            <Input type="password"
                                                   name="password"
                                                   id="examplePassword"
                                                   placeholder={t('auth.forgot_password.placeholder_password', 'Password')}
                                                   required
                                                   value={password}
                                                   onChange={e => setPassword(e.target.value)}
                                                   invalid={get(errors, 'password') !== undefined}
                                                   onClick={touchPasswordResetForm}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup onClick={touchPasswordResetForm}>
                                            <Label for="examplePasswordRep">
                                                <span className="text-danger">*</span>
                                                {' '}{t('auth.forgot_password.label_password_rep', 'Repeat password')}
                                            </Label>
                                            <Input type="password"
                                                   name="passwordrep"
                                                   id="examplePasswordRep"
                                                   placeholder={t('auth.forgot_password.placeholder_password_rep', "Repeat password")}
                                                   value={passwordrep}
                                                   required
                                                   onChange={e => setPasswordrep(e.target.value)}
                                                   invalid={get(errors, 'passwordrep') !== undefined}
                                                   onClick={touchPasswordResetForm}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} className='text-danger'>
                                        {renderErrorsAsHTML(get(errors, 'password'))}
                                        {renderErrorsAsHTML(get(errors, 'detail'))}
                                        {renderErrorsAsHTML(get(errors, 'common'))}
                                    </Col>
                                </Row>
                                <div className="mt-4 d-flex align-items-center bp-5">
                                    <h6 className="mb-0">
                                        <Link to="/auth/login">
                                            <span className="text-primary">
                                                {t('auth.forgot_password.button_back', '← go back')}
                                            </span>
                                        </Link>
                                    </h6>
                                    <div className="ml-auto">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="lg"
                                            className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                            disabled={isSendingRequest}
                                        >
                                            {t('auth.forgot_password.button_submit', 'Set password')}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        }
                    </Col>
                </Col>
            </Row>
        </div>
    </Fragment>;
};

const mapStateToProps = state => ({
    errors: state.Auth.passwordReset.errors,
    isSendingRequest: state.Auth.passwordReset.isSendingRequest,
    isRequestSent: state.Auth.passwordReset.isRequestSent,
});

const mapDispatchToProps = dispatch => ({
    setNewPassword: (password, token, callback) => dispatch(setNewPassword(password, token, callback)),
    touchPasswordResetForm: () => dispatch(touchPasswordResetForm()),
    setPasswordResetFormErrors: (errors) => dispatch(setPasswordResetFormErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetNewPasswordForm);
