import assign from 'lodash/assign';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';

import {
    GET_FLOW_REQUEST_START,
    GET_FLOW_REQUEST_SUCCESS,
    GET_FLOW_REQUEST_FAIL,

    UPDATE_ACTIVE_FLOW,
    CLEAR_ACTIVE_FLOW,

    GET_STEP_TYPES_REQUEST_START,
    GET_STEP_TYPES_REQUEST_SUCCESS,
    GET_STEP_TYPES_REQUEST_FAIL,

    GET_STEPS_REQUEST_START,
    GET_STEPS_REQUEST_SUCCESS,
    GET_STEPS_REQUEST_FAIL,

    ADD_EMPTY_STEP,
    STEP_MOVED,
    STEP_CLICKED,
    STEP_REMOVED,

    UPDATE_STEP_REQUEST_START,
    UPDATE_STEP_REQUEST_SUCCESS,
    UPDATE_STEP_REQUEST_FAIL,

    MENU_BUTTON_HAS_UPDATED,
    INLINE_BUTTON_HAS_UPDATED,

    FLOW_BUILDER_SET_RIGHT_MENU_OPEN,
} from '../actions/Steps';


const defaultState = {
    isSendingStepTypesRequest: false,
    isSendingStepsRequest: false,
    isSendingFlowRequest: false,

    stepTypesError: undefined,
    stepsError: undefined,
    flowError: undefined,

    stepTypes: undefined,
    steps: undefined,
    activeFlow: undefined,

    flowBuilderRightMenuOpen: false,
    flowBuilderSelectedStepId: undefined,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case GET_STEP_TYPES_REQUEST_START:
            return {
                ...state,
                isSendingStepTypesRequest: true,
                stepTypesError: undefined,
            };
        case GET_STEP_TYPES_REQUEST_SUCCESS:
            return {
                ...state,
                stepTypes: action.result,
                isSendingStepTypesRequest: false,
            };
        case GET_STEP_TYPES_REQUEST_FAIL:
            return {
                ...state,
                stepTypesError: action.error.message,
                isSendingStepTypesRequest: false,
            };

        case GET_FLOW_REQUEST_START:
            return {
                ...state,
                isSendingFlowRequest: true,
                flowError: undefined,
            };
        case GET_FLOW_REQUEST_SUCCESS:
            return {
                ...state,
                activeFlow: action.result,
                isSendingFlowRequest: false,
            };
        case GET_FLOW_REQUEST_FAIL:
            return {
                ...state,
                flowError: action.error.message,
                isSendingFlowRequest: false,
            };

        case GET_STEPS_REQUEST_START:
            return {
                ...state,
                isSendingStepsRequest: true,
                stepsError: undefined,
            };
        case GET_STEPS_REQUEST_SUCCESS:
            return {
                ...state,
                steps: action.result,
                isSendingStepsRequest: false,
            };
        case GET_STEPS_REQUEST_FAIL:
            return {
                ...state,
                stepsError: action.error.message,
                isSendingStepsRequest: false,
            };
        case ADD_EMPTY_STEP:
            const newSteps = cloneDeep(state.steps);
            newSteps.push(action.step);

            if (newSteps.length === 1) {
                newSteps[0].is_default = true;
            }

            return {
                ...state,
                steps: newSteps,
            };
        case UPDATE_STEP_REQUEST_SUCCESS:
            const idxToUpdate = findIndex(state.steps, {id: action.result.id});
            const idxPrevDefaultStep = findIndex(state.steps, {is_default: true});

            if (idxToUpdate === -1) {
                return state;
            }

            const stepsToUpdate = cloneDeep(state.steps);
            stepsToUpdate[idxToUpdate] = assign(
                stepsToUpdate[idxToUpdate],
                action.result,
            );

            if (action.result.is_default === true && idxPrevDefaultStep !== -1 && idxPrevDefaultStep !== idxToUpdate) {
                stepsToUpdate[idxPrevDefaultStep] = assign(
                    stepsToUpdate[idxPrevDefaultStep],
                    {is_default: false},
                );
            }

            return {
                ...state,
                steps: stepsToUpdate,
            };
        case MENU_BUTTON_HAS_UPDATED:
            const parentIdx = findIndex(state.steps, {id: action.stepId});
            if (parentIdx === -1) {
                return state;
            }

            if (!state.steps[parentIdx].menu_buttons) {
                return state;
            }

            const myIdx = findIndex(state.steps[parentIdx].menu_buttons, {id: action.result.id});
            if (myIdx === -1) {
                return state;
            }

            const parentsToUpdate = cloneDeep(state.steps);
            const meToUpdate = cloneDeep(parentsToUpdate[parentIdx].menu_buttons[myIdx]);
            parentsToUpdate[parentIdx].menu_buttons[myIdx] = assign(
                meToUpdate,
                action.result,
            );

            return {
                ...state,
                steps: parentsToUpdate,
            };
        case INLINE_BUTTON_HAS_UPDATED:
            const parentIdx2 = findIndex(state.steps, {id: action.stepId});
            if (parentIdx2 === -1) {
                return state;
            }

            if (!state.steps[parentIdx2].inline_buttons) {
                return state;
            }

            const myIdx2 = findIndex(state.steps[parentIdx2].inline_buttons, {id: action.result.id});
            if (myIdx2 === -1) {
                return state;
            }

            const parentsToUpdate2 = cloneDeep(state.steps);
            const meToUpdate2 = cloneDeep(parentsToUpdate2[parentIdx2].inline_buttons[myIdx2]);
            parentsToUpdate2[parentIdx2].inline_buttons[myIdx2] = assign(
                meToUpdate2,
                action.result,
            );

            return {
                ...state,
                steps: parentsToUpdate2,
            };
        case STEP_REMOVED:
            let stepsAfterRemove = state.steps;
            remove(state.steps, (el) => (el.id === action.stepId));

            return {
                ...state,
                steps: stepsAfterRemove,
                flowBuilderRightMenuOpen: action.stepId === state.flowBuilderSelectedStepId ? false : state.flowBuilderRightMenuOpen,
                flowBuilderSelectedStepId: action.stepId === state.flowBuilderSelectedStepId ? undefined : state.flowBuilderSelectedStepId,
            };
        case CLEAR_ACTIVE_FLOW:
            return {
                ...state,
                activeFlow: undefined,
            };
        case UPDATE_ACTIVE_FLOW:
            return {
                ...state,
                activeFlow: assign(
                    state.activeFlow,
                    action.data,
                ),
            };
        case STEP_CLICKED:
            return {
                ...state,
                flowBuilderRightMenuOpen: true,
                flowBuilderSelectedStepId: action.stepId,
            };
        case FLOW_BUILDER_SET_RIGHT_MENU_OPEN:
            return {
                ...state,
                flowBuilderRightMenuOpen: action.open,
                flowBuilderSelectedStepId: action.open ? state.flowBuilderSelectedStepId : undefined,
            };
    }
    return state;
}
