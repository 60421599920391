import React from 'react';
import {useTranslation} from "react-i18next";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import APIModel from "../../Components/APIModel";
import {LOGO_BY_TYPE} from '../../Components/utils';


const Groups = (
    {
        match,
    }
) => {
    const {t, i18n} = useTranslation();

    const GROUPS_COLUMNS = [
        {
            name: t('audience.groups.columns.channel', 'Channel'),
            cell: row => <div>
                <img
                    src={LOGO_BY_TYPE[row.bot.bot_type]}
                    style={{width: '1.3rem'}}
                    className='mr-2'
                />
                {row.bot.name}
            </div>
        },
        {
            name: t('audience.groups.columns.name', "Name"),
            selector: "name",
        },
        {
            name: t('audience.groups.columns.tags', "Tags"),
            cell: row => <div>
                {
                    get(row, 'store.tags', []).map(
                        (tag, idx) => <div className='my-1' key={idx}>
                            <code className='text-secondary'>
                                {tag.name}
                            </code>
                        </div>
                    )
                }
                {
                    isEmpty(row.store.tags) && '-'
                }
            </div>
        },
        {
            name: t('audience.groups.columns.last_message_time', "Last message"),
            selector: "last_message_time",
        },
    ];

    return <APIModel
        heading={t('audience.groups.api_model.heading', 'Groups')}
        subheading=''
        icon="fa fas fa-users icon-gradient bg-night-fade"
        apiPath='bot/groups'
        tableColumns={GROUPS_COLUMNS}
        tableEmptyMessage={t('audience.groups.api_model.message_empty', "No groups yet")}
        match={match}
        hasFilters={true}
        deletionDisabled={true}
        creationDisabled={true}
    />;
};

export default Groups;
