import React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import get from "lodash/get";

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import {store} from "../../../../configureStore";
import {withTranslation} from "react-i18next";


class QuestionNodeWidget_ extends AbstractNodeWidget {
    constructor() {
        super();

        this.type = 'question-node';
    }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <i className="pe-7s-help1"> </i> {options.step.name || this.props.t('pages.main.flow_builder.node.question.name', 'Question')}
        </>;
    }

    getErrors() {
        const options = this.props.node.options;
        const text = get(options.step.text, store.getState().Shops.activeShop.lang_by_default);
        const imageUrl = options.step.image_url;

        const errors = [];
        if (!text && !imageUrl) {
            errors.push(this.props.t('pages.main.flow_builder.node.question.error.add_text_or_image', 'Please provide text or image'));
        }

        if (!get(options.step, 'state.answer_field')) {
            errors.push(this.props.t('pages.main.flow_builder.node.question.error.variable_is_not_specified', 'Please specify variable to store answer'));
        }

        return errors.map((error, idx) => <div className='py-2' key={idx}>
                <i className='text-danger opacity-9'>{error}</i>
            </div>
        );
    }
}

export const QuestionNodeWidget = withTranslation()(QuestionNodeWidget_);

export class QuestionNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'question-node'
        });
    }
}


export class QuestionNodeFactory extends AbstractReactFactory {
    constructor() {
        super('question-node');
    }

    generateModel(event) {
        return new QuestionNodeModel();
    }

    generateReactWidget(event) {
        return <QuestionNodeWidget engine={this.engine} node={event.model}/>;
    }
}
