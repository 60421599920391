import React from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import {ListGroupItem} from "reactstrap";
import cx from 'classnames';
import {useTranslation} from "react-i18next";
import get from 'lodash/get';

import APITable2 from "../../Components/APITable2";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import PageTitle from "../../Layout/AppMain/PageTitle";
import APIForm from "../../Components/APIForm";
import DefaultNormalArrayFieldTemplate from "../../Components/APIForm/Fields/DefaultNormalArrayFieldTemplate";


export const ACTION_TYPE_ADD_TAG = 1;
export const ACTION_TYPE_REMOVE_TAG = 2;
export const ACTION_TYPE_SUBSCRIBE_TO_FLOW = 3;
export const ACTION_TYPE_UNSUBSCRIBE_FROM_FLOW = 4;
export const ACTION_TYPE_MARK_THREAD_OPEN = 5;
export const ACTION_TYPE_MARK_THREAD_DONE = 6;
export const ACTION_TYPE_NOTIFY_ADMINS = 7;
export const ACTION_TYPE_SET_CUSTOM_FIELD = 8;
export const ACTION_TYPE_CLEAR_CUSTOM_FIELD = 9;
export const ACTION_TYPE_SUBSCRIBE_TO_BOT = 10;
export const ACTION_TYPE_UNSUBSCRIBE_FROM_BOT = 11;
export const ACTION_TYPE_HTTP_REQUEST = 12;


const API_PATH = 'automation/keywords';

const TableHeader = () => {
    const {t, i18n} = useTranslation();

    return <ListGroupItem>
        <div className="widget-content py-1 px-0 px-md-2">
            <div className="widget-content-wrapper text-secondary">
                <div className="widget-content-right" style={{width: 100}}>
                    {t('automation.keywords.columns.is_active', "Is active")}
                </div>
                <div className="widget-content-right" style={{width: 100}}>
                    {t('automation.keywords.columns.works_in_thread', "Works in thread")}
                </div>
                <div className="widget-content-left d-none d-md-block" style={{width: 'calc(100% - 400px)'}}>
                    {t('automation.keywords.columns.rule', "Rule")}
                </div>
                <div className="widget-content-right d-none d-md-block" style={{width: 250}}>
                    {t('automation.keywords.columns.actions', "Actions")}
                </div>
                <div className="widget-content-right d-block d-md-none" style={{width: 'calc(100% - 150px)'}}>
                    {t('automation.keywords.columns.rule_actions', "Rule, actions")}
                </div>
                <div className="widget-content-right" style={{width: 50}}>

                </div>
            </div>
        </div>
    </ListGroupItem>;
};


const TableRow = (
    {
        data,
        setIdSelected,
        actionClick,
        actionDelete,
        apiFormSubmitRequest,
    }
) => {
    /**
     * Toggle is_active
     */
    const actionToggleIsActive = () => {
        const apiUrl = `${API_PATH}/${data.id}/`;
        const method = 'patch';
        const formData = {
            shop_id: data.shop_id,
            is_active: !data.is_active,
        };
        const callback = (response) => {
            // TODO: грязный хак!
            // setMakeDefaultPopupShown(false);
            window.location.reload();
        };
        apiFormSubmitRequest(
            apiUrl,
            method,
            formData,
            undefined,
            callback,
        );
    };

    /**
     * Toggle works_in_thread
     */
    const actionToggleWorksInThread = () => {
        const apiUrl = `${API_PATH}/${data.id}/`;
        const method = 'patch';
        const formData = {
            shop_id: data.shop_id,
            works_in_thread: !data.works_in_thread,
        };
        const callback = (response) => {
            // TODO: грязный хак!
            // setMakeDefaultPopupShown(false);
            window.location.reload();
        };
        apiFormSubmitRequest(
            apiUrl,
            method,
            formData,
            undefined,
            callback,
        );
    };

    const ruleHTML = <>
        {data.type.name}:
        {' '}&nbsp;{' '}
        <strong>{data.keywords}</strong>
    </>;

    const actionsHTML = data.actions && data.actions.map((action, idx) => {
        switch (action.type.id) {
            case ACTION_TYPE_ADD_TAG:
            case ACTION_TYPE_REMOVE_TAG:
                return <div className='mt-2' key={idx}>
                    <span className='text-secondary'>
                        {get(action, 'type.name')}:
                    </span>{' '}&nbsp;
                    <strong>
                        🔖&nbsp;{action.tag.name}
                    </strong>
                </div>;
            case ACTION_TYPE_SUBSCRIBE_TO_FLOW:
            case ACTION_TYPE_UNSUBSCRIBE_FROM_FLOW:
                const link = action.flow
                    ? `/${data.shop_id}/flows/${action.flow.id}/`
                    : `/${data.shop_id}/flows/`;
                const name = action.flow
                    ? action.flow.name
                    : 'flows';
                return <div className='mt-2' key={idx}>
                    <span className='text-secondary'>
                        {get(action, 'type.name')}:
                    </span>{' '}&nbsp;
                    <Link to={link} onClick={e => e.stopPropagation()}>
                        {name}
                    </Link>
                </div>;
            default:
                return <div className='mt-2' key={idx}>
                    <span className="text-secondary">
                        {get(action, 'type.name')}
                    </span>
                </div>;
        }
    });

    /**
     * Рендеринг
     */
    return <ListGroupItem
        className={cx('hover-bg-light')}
        onClick={() => actionClick(data.id)}
    >
        <div className="widget-content py-1 px-0 px-md-2">
            <div className='widget-content-wrapper'>
                <div className="widget-content-right d-flex align-items-center" style={{width: 100}}>
                    <div
                        className="switch has-switch"
                        data-on-label="ON"
                        data-off-label="OFF"
                        onClick={(e) => {
                            e.stopPropagation();
                            actionToggleIsActive();
                        }}
                    >
                        <div className={cx("switch-animate", {
                            'switch-on': data.is_active,
                            'switch-off': !data.is_active
                        })}>
                            <input type="checkbox"/>
                            <span className="switch-left bg-success">ON</span>
                            <label>&nbsp;</label>
                            <span className="switch-right bg-success">OFF</span>
                        </div>
                    </div>
                </div>
                <div className="widget-content-right d-flex align-items-center" style={{width: 100}}>
                    <div
                        className="switch has-switch"
                        data-on-label="ON"
                        data-off-label="OFF"
                        onClick={(e) => {
                            e.stopPropagation();
                            actionToggleWorksInThread();
                        }}
                    >
                        <div className={cx("switch-animate", {
                            'switch-on': data.works_in_thread,
                            'switch-off': !data.works_in_thread
                        })}>
                            <input type="checkbox"/>
                            <span className="switch-left bg-success">ON</span>
                            <label>&nbsp;</label>
                            <span className="switch-right bg-success">OFF</span>
                        </div>
                    </div>
                </div>
                <div className='widget-content-left d-none d-md-block' style={{width: 'calc(100% - 400px)'}}>
                    {ruleHTML}
                </div>
                <div className="widget-content-right d-none d-md-block" style={{width: 250}}>
                    {actionsHTML}
                </div>
                <div className="widget-content-right d-block d-md-none" style={{width: 'calc(100% - 150px)'}}>
                    {ruleHTML}
                    <div className="mt-3">
                        {actionsHTML}
                    </div>
                </div>
                <div className="widget-content-right text-right" style={{width: 50, paddingTop: 4}}>
                    <i className="fa fas fa-trash mr-2 fsize-2 hover-text-danger hover-pointer" onClick={(e) => {
                        e.stopPropagation();

                        setIdSelected(data.id);
                        actionDelete();
                    }}/>
                </div>
            </div>
        </div>
    </ListGroupItem>;
};


const Keywords = (
    {
        match,
    }
) => {
    const {t, i18n} = useTranslation();

    return <Switch>
        <Route
            exact path={`${match.url}/create/`}
            render={
                (props) => <>
                    <AppHeader/>
                    <div className="app-main">
                        <AppSidebar/>
                        <div className="app-main__outer">
                            <div className="app-main__inner keywords">
                                <PageTitle
                                    heading={t('automation.keywords.api_model.heading', 'Keywords')}
                                    subheading=''
                                    icon="fa fas fa-cog icon-gradient bg-happy-fisher"
                                    enablePageTitleCreationButton={false}
                                />
                                <APIForm
                                    apiPath={API_PATH}
                                    modelId={undefined}
                                    method='post'
                                    actionRedirectUrl={`${match.url}/`}
                                    ArrayFieldTemplate={DefaultNormalArrayFieldTemplate}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        />
        <Route
            exact path={`${match.url}/:id(\\d+)/`}
            render={
                (props) => <>
                    <AppHeader/>
                    <div className="app-main">
                        <AppSidebar/>
                        <div className="app-main__outer">
                            <div className="app-main__inner keywords">
                                <PageTitle
                                    heading={t('automation.keywords.api_model.heading', 'Keywords')}
                                    subheading=''
                                    icon="fa fas fa-cog icon-gradient bg-happy-fisher"
                                    enablePageTitleCreationButton={true}
                                    headerButtonCreateUrl={`${match.url}/create/`}
                                />
                                <APIForm
                                    apiPath={API_PATH}
                                    modelId={props.match.params.id}
                                    method='patch'
                                    actionRedirectUrl={`${match.url}/`}
                                    ArrayFieldTemplate={DefaultNormalArrayFieldTemplate}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        />
        <Route
            exact path={match.url}
            render={
                (props) => <>
                    <AppHeader/>
                    <div className="app-main">
                        <AppSidebar/>
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <PageTitle
                                    heading={t('automation.keywords.api_model.heading', 'Keywords')}
                                    subheading=''
                                    icon="fa fas fa-cog icon-gradient bg-happy-fisher"
                                    headerButtonCreateUrl={`${match.url}create/`}
                                    enablePageTitleCreationButton={true}
                                />
                                <APITable2
                                    HeaderComponent={TableHeader}
                                    RowComponent={TableRow}
                                    emptyMessage={t('automation.keywords.api_model.message_empty', "No keywords yet")}
                                    deleteMessage={t('automation.keywords.api_model.message_remove', 'Delete keyword?')}
                                    apiPath={API_PATH}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        />
    </Switch>;
};

export default Keywords;
