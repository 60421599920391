import React from 'react';
import { connect } from "react-redux";

import APIModel from "../../Components/APIModel";
import DefaultNormalArrayFieldTemplate from "../../Components/APIForm/Fields/DefaultNormalArrayFieldTemplate";
import { useTranslation } from "react-i18next";


const CreateWabaAPIKey = (
  {
    match,
    isSendingRequest,
  }
) => {
  const t = useTranslation()[0];

  const WABA_API_KEY_COLUMNS = [
    {
      name: t('integrations.columns.channel_id', 'Channel ID'),
      selector: "waba_client_id",
    },
    {
      name: t('integrations.columns.api_key', 'Api Key'),
      selector: "api_key",
    },
  ];

  return <APIModel
    heading={t('broadcasts.api_model.heading', 'Create Waba API Key')}
    subheading=''
    icon="fa fas fa-bullhorn icon-gradient bg-sunny-morning"
    apiPath='integrations/waba-api-key'
    tableColumns={WABA_API_KEY_COLUMNS}
    tableEmptyMessage={t('integrations.api_model.message_empty', "No api keys yet...")}
    match={match}
    hasFilters={false}
    creationDisabled={isSendingRequest}
    updateDisabled={true}
    deletionDisabled={true}
    ArrayFieldTemplate={DefaultNormalArrayFieldTemplate}
    actionRedirectUrl={match.url}
    afterSubmitCallback={(result => {
      console.log('submitted ', result);
    })}
  />;
};

const mapStateToProps = state => ({
  isSendingRequest: state.Integration.isSendingRequest,
});

export default connect(mapStateToProps)(CreateWabaAPIKey);
