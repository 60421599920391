import React, {useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import cx from 'classnames';
import {connect} from "react-redux";
import SweetAlert from "sweetalert-react";
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {
    Button,
    Card,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledButtonDropdown,
} from "reactstrap";

import APITable2 from "../../../Components/APITable2";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import APIForm from "../../../Components/APIForm";
import FlowBuilder from "./FlowBuilder";
import {duplicateFlow} from "../../../thunks/Steps";
import {getTemplates, installTemplate} from "../../../thunks/Templates";
import noImage from '../../../assets/utils/images/avatars/ava.jpg';
import {SHOP_SOURCE_CHAT_API} from "../../../constants";
import YouTube from "react-youtube";


const API_PATH = 'step/flows';
const CHAT_API_VIDEOS_IDS_BY_LANG_CODE = {
  'ru': ['8gouBg32xcQ', '4n8n41NfKVM']
};

const TableHeader = () => {
    const {t, i18n} = useTranslation();
    
    return <ListGroupItem className='pl-5'>
        <div className="widget-content py-1 px-0 px-md-2">
            <div className="widget-content-wrapper text-secondary">
                <div className="widget-content-left" style={{width: 'calc(100% - 250px)'}}>
                    {t('flows.table_header.column.name', 'Name')}
                </div>
                <div className="widget-content-right" style={{width: 150}}>
                    {t('flows.table_header.column.changed_at', 'Changed at')}
                </div>
                <div className="widget-content-right" style={{width: 100}}>

                </div>
            </div>
        </div>
    </ListGroupItem>;
};

const TableRow = (
    {
        data,
        setIdSelected,
        actionClick,
        actionDelete,
        apiFormSubmitRequest,
        duplicateFlow,
    }
) => {
    const {t, i18n} = useTranslation();
    /**
     * Переименовать
     */
    const [renameInputVal, setRenameInputVal] = useState(data.name);
    const [renameFormShow, setRenameFormShow] = useState(false);

    const actionRename = () => {
        setRenameFormShow(true);
    };

    const onRenameInputChange = (event) => {
        setRenameInputVal(event.target.value);
    };

    const onRenameFormSubmit = (event) => {
        event.preventDefault();

        const apiUrl = `${API_PATH}/${data.id}/`;
        const method = 'patch';
        const formData = {
            shop_id: data.shop_id,
            name: renameInputVal,
        };
        const callback = (response) => {
            setRenameFormShow(false);
        };
        apiFormSubmitRequest(
            apiUrl,
            method,
            formData,
            undefined,
            callback,
        );
    };

    const onRenameCancel = (event) => {
        event.preventDefault();

        setRenameInputVal(data.name);
        setRenameFormShow(false);
    };

    /**
     * Установить по умолчанию
     */
    const [makeDefaultPopupShown, setMakeDefaultPopupShown] = useState(false);

    const actionMakeDefault = () => {
        if (data.is_default) {
            return;
        }

        setMakeDefaultPopupShown(true);
    };

    const confirmMakeDefault = () => {
        const apiUrl = `${API_PATH}/${data.id}/`;
        const method = 'patch';
        const formData = {
            shop_id: data.shop_id,
            is_default: true,
        };
        const callback = (response) => {
            // TODO: грязный хак!
            // setMakeDefaultPopupShown(false);
            window.location.reload();
        };
        apiFormSubmitRequest(
            apiUrl,
            method,
            formData,
            undefined,
            callback,
        );
    };

    const cancelMakeDefault = () => {
        setMakeDefaultPopupShown(false);
    };

    /**
     * Создать копию
     */
    const actionCreateCopy = () => {
        const callback = (response) => {
            window.location.reload();
        };
        duplicateFlow(data.id, callback);
    };

    /**
     * Рендеринг
     */
    return <ListGroupItem
        className={cx('hover-bg-light pl-5', {isDefault: data.is_default})}
        onClick={() => actionClick(data.id)}
    >
        <div className="widget-content py-1 px-0 px-md-2">
            <div className='widget-content-wrapper'>
                <div className='widget-content-left' style={{width: 'calc(100% - 250px)'}}>
                    {
                        renameFormShow ?
                            <Form inline onSubmit={onRenameFormSubmit} onClick={(event) => event.stopPropagation()}>
                                <InputGroup row className='m-0'>
                                    <Input
                                        name="name"
                                        placeholder={t('flows.table_row.rename.placeholder', 'Flow name')}
                                        value={renameInputVal}
                                        onChange={onRenameInputChange}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button color="light" className='align-items-center pt-1 pb-0 px-2'
                                                onClick={onRenameCancel}>
                                            <i className="pe-7s-close fsize-3"/>
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Form>
                            : renameInputVal
                    }
                </div>
                <div className="widget-content-right" style={{width: 150}}>
                    {data.updated_at}
                </div>
                <div className="widget-content-right text-right" style={{width: 100}}>
                    <UncontrolledButtonDropdown onClick={(event) => event.stopPropagation()}>
                        <DropdownToggle
                            outline
                            className="pb-1 pt-2 br-a"
                            color="primary"
                            onClick={() => setIdSelected(data.id)}
                        >
                            <i className="pe-7s-more font-size-lg"/>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={actionRename}>
                                <i className="pe-7s-pen mr-2 fsize-15"/> {t('flows.table_row.menu.rename', 'Rename')}
                            </DropdownItem>

                            <DropdownItem className={cx({disabled: data.is_default})} onClick={actionMakeDefault}>
                                <i className="pe-7s-play mr-2 fsize-15" style={{marginTop: 2}}/> {t('flows.table_row.menu.make_default', 'Make default')}
                            </DropdownItem>

                            <DropdownItem onClick={actionCreateCopy}>
                                <i className="pe-7s-copy-file mr-2 fsize-15"/> {t('flows.table_row.menu.duplicate', 'Duplicate')}
                            </DropdownItem>

                            <DropdownItem className='text-danger' onClick={actionDelete}>
                                <i className="pe-7s-trash mr-2 fsize-15"/> {t('flows.table_row.menu.remove', 'Remove')}
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div>
        </div>

        <SweetAlert
            title={t('flows.alert.make_flow_default.title', 'Are you sure you want to make this flow default?')}
            confirmButtonColor="#d92550"
            show={makeDefaultPopupShown}
            text={t('flows.alert.make_flow_default.text', 'Only one flow can be a default one at a time. A previous default flow will be longer marked as default one.')}
            type="warning"
            showCancelButton
            cancelButtonText={t('flows.alert.make_flow_default.button.cancel', 'Cancel')}
            onConfirm={confirmMakeDefault}
            onCancel={cancelMakeDefault}
        />
    </ListGroupItem>;
};


const mapDispatchToTableRowProps = dispatch => ({
    duplicateFlow: (flowId, callback) => dispatch(duplicateFlow(flowId, callback)),
});

const EnhancedTableRow = connect(null, mapDispatchToTableRowProps)(TableRow);


const Flows = (
    {
        match,
        activeShop,
        isSendingInstallRequest,
        templates,

        getTemplates,
        installTemplate,
    }
) => {
    const {t, i18n} = useTranslation();
    const isChatApi = get(activeShop, 'source') === SHOP_SOURCE_CHAT_API;

    // State
    const [selectedTemplate, setSelectedTemplate] = useState(undefined);

    // --------------------------
    // Hooks
    // --------------------------
    // componentDidMount && componentDidUpdate
    useEffect(
        () => {
            getTemplates({});
        },
        []
    );

    return <Switch>
        <Route
            exact path={`${match.url}/create/`}
            render={
                (props) => <>
                    <AppHeader/>
                    <div className="app-main">
                        <AppSidebar/>
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <PageTitle
                                    heading={t('flows.page.create_flow.title', 'Create flow')}
                                    subheading=''
                                    icon="fa fas fa-share-alt-square icon-gradient bg-mean-fruit"
                                    headerButtonCreateUrl={`${match.url}create/`}
                                    enablePageTitleCreationButton={false}
                                />
                                <APIForm
                                    apiPath={API_PATH}
                                    modelId={undefined}
                                    method='post'
                                    deletionDisabled={true}
                                    actionRedirectUrl={`${match.url}/`}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        />
        <Route
            exact path={`${match.url}/:flowId(\\d+)/`}
            component={FlowBuilder}
        />
        <Route
            exact path={match.url}
            render={
                (props) => <>
                    <AppHeader/>
                    <div className="app-main">
                        <AppSidebar/>
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <PageTitle
                                    heading={t('flows.page.flows_list.title', 'Flows')}
                                    subheading=''
                                    icon="fa fas fa-share-alt-square icon-gradient bg-mean-fruit"
                                    headerButtonCreateUrl={`${match.url}create/`}
                                    enablePageTitleCreationButton={true}
                                />
                                {
                                    !isEmpty(templates) && <Container fluid>
                                        <Row>
                                            <Col lg={12} className='p-0'>
                                                <h4 className='mb-1'>Business use cases</h4>
                                                <small>Start with a template focused on your business and build your
                                                    own bot in minutes with our simple one-click solutions</small>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3 mb-5' style={{margin: '0 -30px'}}>
                                            {
                                                templates.map((template, idx) => <Col
                                                        md={6}
                                                        sm={12}
                                                        key={idx}
                                                        className='mb-3'
                                                    >
                                                        <Card
                                                            className="widget-content hover-bg-light hover-pointer"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSelectedTemplate(template);
                                                            }}
                                                        >
                                                            <div className="widget-content-wrapper">
                                                                <div className="widget-content-left mr-3">
                                                                    <div className="widget-content-left">
                                                                        <img width={40} className="rounded-circle"
                                                                             src={template.image_url || noImage}
                                                                             alt=""/>
                                                                    </div>
                                                                </div>
                                                                <div className="widget-content-left flex2">
                                                                    <div
                                                                        className="widget-heading font-size-lg font-weight-normal">
                                                                        {template.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                )}
                                        </Row>
                                    </Container>
                                }
                                {
                                    isChatApi && CHAT_API_VIDEOS_IDS_BY_LANG_CODE[i18n.languages[0]] && <Container fluid>
                                        <Row>
                                            <Col lg={12} className='p-0'>
                                                <h4 className='mb-1'>{t('flows.page.learning_videos.title', 'Learning videos')}</h4>
                                                <small></small>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3 mb-5' style={{margin: '0 -30px'}}>
                                            {
                                                CHAT_API_VIDEOS_IDS_BY_LANG_CODE[i18n.languages[0]].map((videoId, i) =>
                                                    <Col md={6} sm={12} className='mb-3' key={`${CHAT_API_VIDEOS_IDS_BY_LANG_CODE[i18n.languages[0]]}_${i}`}>
                                                        <YouTube
                                                            videoId={videoId}
                                                            containerClassName='relative'
                                                            opts={
                                                                {
                                                                    width: '100%',
                                                                    height: 300,
                                                                }
                                                            }
                                                        />
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Container>
                                }
                                <APITable2
                                    HeaderComponent={TableHeader}
                                    RowComponent={EnhancedTableRow}
                                    emptyMessage={t('flows.list.message.no_data', 'No flows')}
                                    deleteMessage={t('flows.list.message.confirm_flow_removal', 'Are you sure you want to remove the flow?')}
                                    apiPath={API_PATH}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal isOpen={selectedTemplate}
                           toggle={() => setSelectedTemplate(undefined)}
                           size='sm'
                        // centered={true}
                    >
                        <ModalHeader toggle={() => setSelectedTemplate(undefined)}
                                     cssModule={{'modal-title': 'w-100 text-center mb-0'}}>
                            {get(selectedTemplate, 'name')}
                        </ModalHeader>
                        <ModalBody>
                            <p>{get(selectedTemplate, 'description')}</p>
                        </ModalBody>
                        <ModalFooter className='justify-content-center flex-column'>
                            <Button
                                size="lg"
                                color="primary"
                                className="px-5"
                                disabled={isSendingInstallRequest}
                                onClick={() => installTemplate(
                                    selectedTemplate.id,
                                    (result) => {
                                        toast.success(t('flows.templates.toast_success', 'Template installed successfully!'));
                                        setTimeout(() => window.location.reload(), 500)
                                    }
                                )}
                            >
                                {t('flows.templates.popup.install_button', 'Install')}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </>
            }
        />
    </Switch>;
};


const mapStateToFlowsProps = state => ({
    activeShop: state.Shops.activeShop,
    isSendingInstallRequest: state.Templates.isSendingInstallRequest,
    templates: state.Templates.templates,
});

const mapDispatchToFlowsProps = dispatch => ({
    getTemplates: (filters, callback) => dispatch(getTemplates(filters, callback)),
    installTemplate: (templateId, callback) => dispatch(installTemplate(templateId, callback)),
});

export default connect(mapStateToFlowsProps, mapDispatchToFlowsProps)(Flows);
