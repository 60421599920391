import React, {Component, Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';

import AppHeader from '../Layout/AppHeader';
import AppSidebar from '../Layout/AppSidebar';
import APITable from './APITable';
import APIForm from './APIForm';
import PageTitle from "../Layout/AppMain/PageTitle";
import {concatURL} from "./utils";


export default class APIModel extends Component {
    render() {
        return (
            <Fragment>
                <AppHeader/>
                <div className={`app-main ${this.props.className || ''}`}>
                    <AppSidebar/>
                    <div className="app-main__outer">
                        <div className="app-main__inner">
                            <Switch>
                                <Route
                                    exact path={`${this.props.match.url}/create/`}
                                    render={
                                        (props) => <Fragment>
                                            <PageTitle
                                                heading={this.props.heading}
                                                subheading={this.props.subheading}
                                                icon={this.props.icon}
                                                enablePageTitleCreationButton={false}
                                            />
                                            <APIForm
                                                apiPath={this.props.apiPath}
                                                modelId={undefined}
                                                method='post'
                                                ArrayFieldTemplate={this.props.ArrayFieldTemplate}
                                                buttonSubmitDisabled={this.props.creationDisabled}
                                                deletionDisabled={true}
                                                actionRedirectUrl={
                                                    this.props.actionRedirectUrl !== undefined
                                                        ? this.props.actionRedirectUrl
                                                        : `${this.props.match.url}/`
                                                }
                                                afterSubmitCallback={this.props.afterSubmitCallback}
                                            />
                                        </Fragment>
                                    }
                                />
                                <Route
                                    exact path={`${this.props.match.url}/:id/`}
                                    render={
                                        (props) => <Fragment>
                                            <PageTitle
                                                heading={this.props.heading}
                                                subheading={this.props.subheading}
                                                icon={this.props.icon}
                                                headerButtonCreateUrl={concatURL(this.props.match.url, 'create/')}
                                                enablePageTitleCreationButton={!this.props.creationDisabled}
                                            />
                                            <APIForm
                                                apiPath={this.props.apiPath}
                                                modelId={props.match.params.id}
                                                method='patch'
                                                formSchema={this.props.formSchema}
                                                formUISchema={this.props.formUISchema}
                                                ArrayFieldTemplate={this.props.ArrayFieldTemplate}
                                                buttonSubmitDisabled={this.props.updateDisabled}
                                                deletionDisabled={this.props.deletionDisabled}
                                                actionRedirectUrl={`${this.props.match.url}/`}
                                            />
                                        </Fragment>
                                    }
                                />
                                <Route
                                    exact path={`${this.props.match.url}`}
                                    render={
                                        (props) => <APITable
                                            apiPath={this.props.apiPath}
                                            columnsConfig={this.props.tableColumns}
                                            filtersConfig={this.props.filtersConfig}
                                            tableEmptyMessage={this.props.tableEmptyMessage}
                                            heading={this.props.heading}
                                            subheading={this.props.subheading}
                                            tableOnly={this.props.tableOnly}
                                            hasFilters={this.props.hasFilters}
                                            icon={this.props.icon}
                                            headerButtonCreateUrl={concatURL(this.props.match.url, 'create/')}
                                            creationDisabled={this.props.creationDisabled}
                                        />
                                    }
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
