import React from "react";
import {connect} from "react-redux";
import {Button, Card} from "reactstrap";
import {useTranslation} from "react-i18next";

import avitoLogo from '../../../../assets/utils/images/messenger/avito.png';

const ConnectCardAvito = (
    {
        activeShop,
        user,
    }
) => {
    const {t, i18n} = useTranslation();

    const clientID = 'M0AoreyW_jWU6LeEh8sg';
    const oauthUrl = `https://avito.ru/oauth?response_type=code&client_id=${clientID}&scope=messenger:read,messenger:write,job:cv,job:negotiations,user:read,items:info,short_term_rent:read,stats:read&state=${activeShop.id}`;

    return <Card className="mb-3 profile-responsive">
        <div className="dropdown-menu-header text-dark">
            <div className="dropdown-menu-header-inner bg-white">
                <div className="menu-header-content btn-pane-right d-block text-center">
                    <h5 className="d-flex align-items-center justify-content-center mb-3">
                        <img src={avitoLogo} alt="Avito" style={{height: 30}} className='mr-2'/>
                        Avito
                    </h5>
                    <h6 className='opacity-6 my-4'>
                        {t('connect_cards.avito.caption', 'Connect your Avito account to HighTouch and automate your ad correspondence!')}
                    </h6>
                    <div className="menu-header-btn-pane">
                        <a href={oauthUrl}>
                            <Button color="main" route='/'>
                                {t('connect_bot.common.submit', 'Connect')}
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </Card>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    user: state.Auth.user,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCardAvito);
