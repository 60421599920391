import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Row,
} from 'reactstrap';
import {Link} from "react-router-dom";
import {useTranslation, Trans} from "react-i18next";
import get from 'lodash/get';

import {renderErrorsAsHTML} from "../../Components/utils";
import {touchPasswordResetForm} from "../../actions/Auth";
import {askPasswordReset} from "../../thunks/Auth";


const ForgotPassword = (
    {
        errors,
        isSendingRequest,
        isRequestSent,
        touchPasswordResetForm,
        askPasswordReset,
    }
) => {
    const {t, i18n} = useTranslation();

    const [email, setEmail] = useState('');

    // --------------------------
    // Hooks
    // --------------------------
    // componentDidMount && componentDidUpdate
    useEffect(
        () => {
            touchPasswordResetForm();
        },
        [email]
    );

    return <Fragment>
        <div className="h-min-100">
            <Row className="h-min-100 no-gutters">
                <Col md="12" className="h-min-100 d-flex bg-white justify-content-center align-items-center">
                    <Col lg="6" md="8" sm="12" className="mx-auto app-login-box">
                        <div className="app-logo"/>
                        {
                            !isRequestSent && <h4>
                                <Trans t={t} i18nKey='auth.forgot_password.header'>
                                    <div>Forgot your password?</div>
                                    <span>Enter the email address that the account was registered to:</span>
                                </Trans>
                            </h4>
                        }
                        {
                            isRequestSent && <>
                                <Card className='text-white bg-success'>
                                    <Trans t={t} i18nKey='auth.forgot_password.header_request_sent'>
                                        <CardHeader>
                                            Request was sent!
                                        </CardHeader>
                                        <CardBody>
                                            A password reset link was sent. Click the link in the email to create a new password.
                                        </CardBody>
                                    </Trans>
                                </Card>
                                <h6 className="mt-4 text-center">
                                    <Link to="/auth/login">
                                        <span className="text-primary">{t('auth.forgot_password.button_back', '← go back')}</span>
                                    </Link>
                                </h6>
                            </>
                        }
                        {
                            !isRequestSent && <Form
                                onSubmit={
                                    e => {
                                        e.preventDefault();
                                        askPasswordReset(email);
                                    }
                                }
                                autoComplete="on"
                            >
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Input type="email"
                                                   name="email"
                                                   id="exampleEmail"
                                                   placeholder={t('auth.forgot_password.placeholder_email', 'Email')}
                                                   required
                                                   value={email}
                                                   onChange={e => setEmail(e.target.value)}
                                                   invalid={get(errors, 'email') !== undefined}
                                                   onClick={touchPasswordResetForm}/>
                                        </FormGroup>
                                        <div className="text-danger">
                                            {renderErrorsAsHTML(get(errors, 'email'))}
                                            {renderErrorsAsHTML(get(errors, 'common'))}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mt-4 d-flex align-items-center bp-5">
                                    <h6 className="mb-0">
                                        <Link to="/auth/login">
                                            <span className="text-primary">
                                                {t('auth.forgot_password.button_back', '← go back')}
                                            </span>
                                        </Link>
                                    </h6>
                                    <div className="ml-auto">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="lg"
                                            className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                            disabled={isSendingRequest}
                                        >
                                            {t('auth.forgot_password.button_submit', 'Restore password')}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        }
                    </Col>
                </Col>
            </Row>
        </div>
    </Fragment>;
};

const mapStateToProps = state => ({
    errors: state.Auth.passwordReset.errors,
    isSendingRequest: state.Auth.passwordReset.isSendingRequest,
    isRequestSent: state.Auth.passwordReset.isRequestSent,
});

const mapDispatchToProps = dispatch => ({
    touchPasswordResetForm: () => dispatch(touchPasswordResetForm()),
    askPasswordReset: (email) => dispatch(askPasswordReset(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
