import React from 'react';
import {connect} from "react-redux";
import {Button, ButtonGroup, Card, CardBody} from "reactstrap";
import {toast} from "react-toastify";

import APIForm2 from "../../Components/APIForm2";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import PageTitle from "../../Layout/AppMain/PageTitle";
import {requestPermission, messaging} from "../../init-firebase-cloud-messaging";
import {revokeFirebaseToken} from "../../thunks/Notifications";
import {isMobileDevice} from "../../index";
import {renderErrorsAsHTML} from "../../Components/utils";
import {useTranslation} from "react-i18next";


const LiveChat = (
    {
        activeShop,
        firebaseTokenCancelTokenSource,
        firebaseToken,
        firebaseTokenErrors,
        revokeFirebaseToken,
    }
) => {
    const {t, i18n} = useTranslation();
    const isWebpushSupported = messaging && ('Notification' in window) && !(isMobileDevice());

    return <>
        <AppHeader/>
        <div className="app-main">
            <AppSidebar/>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <PageTitle
                        heading={t('settings.live_chat.page.title', 'Operator chat settings')}
                        subheading=''
                        icon="fa fas fa-cog icon-gradient bg-night-fade"
                        enablePageTitleCreationButton={false}
                    />
                    <Card
                        className="main-card mb-3"
                        onKeyDown={(event) => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                        }}
                    >
                        <CardBody>
                            <APIForm2
                                apiPath='shop/shops'
                                modelId={activeShop.id}
                                schemaPath='setting_live_chat_form_schema'
                            />
                        </CardBody>
                    </Card>

                    <Card
                        className="main-card mb-3"
                        onKeyDown={(event) => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                        }}
                    >
                        <CardBody>
                            <h5>{t('settings.live_chat.card.title', 'Browser notifications')}</h5>
                            <div className='mb-3 text-muted'>
                                <small>{t('settings.live_chat.card.hint', 'ℹ️ Notifications are triggered by messages in opened chats only.')}</small>
                            </div>
                            <div className='mb-2'>
                                {
                                    !isWebpushSupported && <span className="text-danger">
                                        {t('settings.live_chat.card.message.notifications_is_not_supported', 'This device is not supporting notifications!')}
                                    </span>
                                }
                                {
                                    isWebpushSupported && (
                                        firebaseToken ? <span className="text-success">
                                            {t('settings.live_chat.card.message.notifications_enabled', 'Notifications are enabled')}
                                        </span> : <span className="text-secondary">
                                            {t('settings.live_chat.card.message.notifications_disabled', 'Notifications are disabled')}
                                        </span>
                                    )
                                }
                            </div>

                            <ButtonGroup>
                                <Button
                                    color="primary"
                                    className=" btn-lg"
                                    type="submit"
                                    disabled={firebaseToken || !isWebpushSupported || (firebaseTokenCancelTokenSource !== undefined)}
                                    onClick={(event) => {
                                        requestPermission()
                                    }}
                                >
                                    {t('settings.live_chat.card.button.enable_notifications', 'Enable notifications')}
                                </Button>
                                <Button
                                    color="danger"
                                    className=" btn-lg"
                                    type="submit"
                                    disabled={!firebaseToken || !isWebpushSupported || (firebaseTokenCancelTokenSource !== undefined)}
                                    onClick={(event) => revokeFirebaseToken(
                                        firebaseToken,
                                        () => toast.success(t('settings.live_chat.card.toast.notifications_disabled', 'Notifications are disabled')),
                                    )}
                                >
                                    {t('settings.live_chat.card.button.disable_notifications', 'Disable notifications')}
                                </Button>
                            </ButtonGroup>

                            {renderErrorsAsHTML(firebaseTokenErrors)}

                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    </>;
};

const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    firebaseTokenCancelTokenSource: state.Notifications.firebaseTokenCancelTokenSource,
    firebaseToken: state.Notifications.firebaseToken,
    firebaseTokenErrors: state.Notifications.firebaseTokenErrors,
});

const mapDispatchToProps = dispatch => ({
    revokeFirebaseToken: (firebaseToken, callbaack) => dispatch(revokeFirebaseToken(firebaseToken, callbaack)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveChat);