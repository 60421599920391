export const START_BROADCAST_REQUEST_START = 'BROADCAST/START_BROADCAST_REQUEST_START';
export const START_BROADCAST_REQUEST_SUCCESS = 'BROADCAST/START_BROADCAST_REQUEST_SUCCESS';
export const START_BROADCAST_REQUEST_FAIL = 'BROADCAST/START_BROADCAST_REQUEST_FAIL';

export const STOP_BROADCAST_REQUEST_START = 'BROADCAST/STOP_BROADCAST_REQUEST_START';
export const STOP_BROADCAST_REQUEST_SUCCESS = 'BROADCAST/STOP_BROADCAST_REQUEST_SUCCESS';
export const STOP_BROADCAST_REQUEST_FAIL = 'BROADCAST/STOP_BROADCAST_REQUEST_FAIL';

export const RESUME_BROADCAST_REQUEST_START = 'BROADCAST/RESUME_BROADCAST_REQUEST_START';
export const RESUME_BROADCAST_REQUEST_SUCCESS = 'BROADCAST/RESUME_BROADCAST_REQUEST_SUCCESS';
export const RESUME_BROADCAST_REQUEST_FAIL = 'BROADCAST/RESUME_BROADCAST_REQUEST_FAIL';


export const startBroadcastRequestStart = (data) => ({
    type: START_BROADCAST_REQUEST_START,
    data,
});

export const startBroadcastRequestSuccess = (result) => ({
    type: START_BROADCAST_REQUEST_SUCCESS,
    result,
});

export const startBroadcastRequestFail = (data, errors) => ({
    type: START_BROADCAST_REQUEST_FAIL,
    data,
    errors,
});


export const stopBroadcastRequestStart = (data) => ({
    type: STOP_BROADCAST_REQUEST_START,
    data,
});

export const stopBroadcastRequestSuccess = (result) => ({
    type: STOP_BROADCAST_REQUEST_SUCCESS,
    result,
});

export const stopBroadcastRequestFail = (data, errors) => ({
    type: STOP_BROADCAST_REQUEST_FAIL,
    data,
    errors,
});


export const resumeBroadcastRequestStart = (data) => ({
    type: RESUME_BROADCAST_REQUEST_START,
    data,
});

export const resumeBroadcastRequestSuccess = (result) => ({
    type: RESUME_BROADCAST_REQUEST_SUCCESS,
    result,
});

export const resumeBroadcastRequestFail = (data, errors) => ({
    type: RESUME_BROADCAST_REQUEST_FAIL,
    data,
    errors,
});
