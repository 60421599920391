import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import cx from "classnames";

import {
    METIS_MENU_BILLING,
    METIS_MENU_CUSTOM_PREFIX
} from "../constants";
import {setUpgradeToPROModalOpen} from "../actions/Billing";

const _ExtendedLinkComponent = (
    {
        className,
        classNameActive,
        classNameHasActiveChild,
        active,
        hasActiveChild,
        to,
        externalLink,
        hasSubMenu,
        toggleSubMenu,
        activateMe,
        children,

        setUpgradeToPROModalOpen,
    }
) => {
    const customActivateMe = (event) => {
        /**
         * 1. Для внешних ссылок пункт меню не активируем
         * 2. Для ссылок-функций запускаем эти функции
         * 3. В остальных случаях оставляем стандартное поведение
         */
        if (to && to.startsWith(METIS_MENU_CUSTOM_PREFIX)) {
            switch (to) {
                case METIS_MENU_BILLING:
                    setUpgradeToPROModalOpen(true);
                    break;
            }

            event.preventDefault();
            return;
        } else if (externalLink) {
            return;
        }

        return activateMe && activateMe(event);
    };

    return <a
        className={cx(
            className,
            active && classNameActive,
            hasActiveChild && classNameHasActiveChild,
        )}
        href={to && to.startsWith(METIS_MENU_CUSTOM_PREFIX) ? '#' : to}
        onClick={hasSubMenu ? toggleSubMenu : customActivateMe}
        target={externalLink ? '_blank' : undefined}
    >
        {children}
    </a>;
};

const mapState1ToProps = state => ({});

const mapDispatch1ToProps = dispatch => ({
    setUpgradeToPROModalOpen: open => dispatch(setUpgradeToPROModalOpen(open)),
});

export default withRouter(connect(mapState1ToProps, mapDispatch1ToProps)(_ExtendedLinkComponent));
