import React from 'react';
import {useTranslation} from "react-i18next";
import {SelectFieldListItem, SelectFieldValueInput} from "../../Components/APIForm/Fields/MultiSelectField";
import {ListGroupItem} from "reactstrap";
import cx from 'classnames';
import find from 'lodash/find';
import {connect} from "react-redux";
import {DropdownList} from "react-widgets";

import APITable2 from "../../Components/APITable2";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import PageTitle from "../../Layout/AppMain/PageTitle";
import {apiFormSubmitRequest} from "../../thunks/ApiModel";


const API_PATH = 'shop/shop_administrators';

{/*<div>*/}
{/*    <div><strong>Администраторы</strong> - имеют полный доступ к системе</div>*/}
{/*    <div><strong>Редакторы</strong> - имеют полный доступ к системе, но не могут добавлять администраторов</div>*/}
{/*    <div><strong>Операторы</strong> - видят чаты</div>*/}
{/*    <div><strong>Наблюдатели</strong> - все видят, но ни на что не влияют</div>*/}
{/*</div>*/}

const TableHeader = () => {
    const {t, i18n} = useTranslation();
    
    return <ListGroupItem>
        <div className="widget-content py-1 px-0 px-md-2">
            <div className="widget-content-wrapper text-secondary">
                <div className="widget-content-left" style={{width: 100}}>
                    {t('settings.team.table_header.active', 'Is active')}
                </div>
                <div className="widget-content-left" style={{width: 'calc(100% - 600px)'}}>
                    {t('settings.team.table_header.email', 'Email')}
                </div>
                <div className="widget-content-left text-center" style={{width: 80}}>
                    {t('settings.team.table_header.online', 'Is online')}
                </div>
                <div className="widget-content-right text-center" style={{width: 140}}>
                    {t('settings.team.table_header.chat_operator', 'Chat operator')}
                </div>
                <div className="widget-content-right" style={{width: 200}}>
                    {t('settings.team.table_header.role', 'Role')}
                </div>
                <div className="widget-content-right" style={{width: 50}}>

                </div>
            </div>
        </div>
    </ListGroupItem>;
};


const TableRowRaw = (
    {
        data,
        setIdSelected,
        actionDelete,
        apiFormSubmitRequest,
        authUser,
    }
) => {
    const {t, i18n} = useTranslation();
    const ROLE_FIELD_ENUM = [
        {'id': 1, 'name': t('settings.team.table_row.role.viewer', 'Viewer')},
        {'id': 2, 'name': t('settings.team.table_row.role.editor', 'Editor')},
        {'id': 3, 'name': t('settings.team.table_row.role.administrator', 'Administrator')},
    ];
    /**
     * Toggle is_active
     */
    const actionToggleIsActive = () => {
        const apiUrl = `${API_PATH}/${data.id}/`;
        const method = 'patch';
        const formData = {
            shop_id: data.shop_id,
            is_active: !data.is_active,
        };
        const callback = (response) => {
            // TODO: грязный хак!
            // setMakeDefaultPopupShown(false);
            window.location.reload();
        };
        apiFormSubmitRequest(
            apiUrl,
            method,
            formData,
            undefined,
            callback,
        );
    };

    /**
     * Toggle is_operator
     */
    const actionToggleLiveChatOperator = () => {
        const apiUrl = `${API_PATH}/${data.id}/`;
        const method = 'patch';
        const formData = {
            shop_id: data.shop_id,
            role_live_chat_operator: !data.role_live_chat_operator,
        };
        const callback = (response) => {
            // TODO: грязный хак!
            window.location.reload();
        };
        apiFormSubmitRequest(
            apiUrl,
            method,
            formData,
            undefined,
            callback,
        );
    };

    /**
     * Change role_admin_panel
     */
    const actionChangeRoleAdminPanel = (role) => {
        const apiUrl = `${API_PATH}/${data.id}/`;
        const method = 'patch';
        const formData = {
            shop_id: data.shop_id,
            role_admin_panel: role.id,
        };
        const callback = (response) => {
            // TODO: грязный хак!
            window.location.reload();
        };
        apiFormSubmitRequest(
            apiUrl,
            method,
            formData,
            undefined,
            callback,
        );
    };

    /**
     * Рендеринг
     */
    return <ListGroupItem className='hover-bg-light'>
        <div className="widget-content py-1 px-0 px-md-2">
            <div className='widget-content-wrapper'>
                <div className="widget-content-left d-flex align-items-center" style={{width: 100}}>
                    <div
                        className="switch has-switch"
                        data-on-label="ON"
                        data-off-label="OFF"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (authUser.email === data.profile.user.email) {
                                return;
                            }

                            actionToggleIsActive();
                        }}
                    >
                        <div className={cx("switch-animate", {
                            'switch-on': data.is_active,
                            'switch-off': !data.is_active,
                            'disabled': authUser.email === data.profile.user.email,
                        })}>
                            <input type="checkbox"/>
                            <span className='switch-left bg-success'>ON</span>
                            <label>&nbsp;</label>
                            <span className="switch-right bg-success">OFF</span>
                        </div>
                    </div>
                </div>
                <div className="widget-content-left" style={{width: 'calc(100% - 600px)'}}>
                    <strong>{data.profile.user.email}</strong>
                </div>
                <div className="widget-content-left text-center" style={{width: 80}}>
                    {data.is_online ? '✅' : '◻️️'}
                </div>
                <div className="widget-content-right text-center" style={{width: 140}}>
                    <div
                        className="switch has-switch"
                        data-on-label="ON"
                        data-off-label="OFF"
                        onClick={(e) => {
                            e.stopPropagation();
                            actionToggleLiveChatOperator();
                        }}
                    >
                        <div className={cx("switch-animate", {
                            'switch-on': data.role_live_chat_operator,
                            'switch-off': !data.role_live_chat_operator
                        })}>
                            <input type="checkbox"/>
                            <span className="switch-left bg-success">ON</span>
                            <label>&nbsp;</label>
                            <span className="switch-right bg-success">OFF</span>
                        </div>
                    </div>
                </div>
                <div className="widget-content-right d-none d-sm-block" style={{width: 200}}>
                    <DropdownList
                        data={ROLE_FIELD_ENUM}
                        value={find(ROLE_FIELD_ENUM, {id: data.role_admin_panel})}
                        disabled={authUser.email === data.profile.user.email}
                        itemComponent={SelectFieldListItem}
                        valueComponent={SelectFieldValueInput}
                        onChange={actionChangeRoleAdminPanel}
                    />
                </div>
                <div className="widget-content-right text-right" style={{width: 50, paddingTop: 4}}>
                    <i className="pe-7s-trash mr-2 fsize-2 hover-text-danger hover-pointer" onClick={(e) => {
                        e.stopPropagation();

                        setIdSelected(data.id);
                        actionDelete();
                    }}/>
                </div>
            </div>
        </div>
    </ListGroupItem>;
};

const mapStateToProps = state => ({
    authUser: state.Auth.user,
});

const mapDispatchToProps = dispatch => ({
    apiFormSubmitRequest: (apiPath, method, formData, redirectUrl, callback) => dispatch(apiFormSubmitRequest(apiPath, method, formData, redirectUrl, callback)),
});

const TableRow = connect(mapStateToProps, mapDispatchToProps)(TableRowRaw);


const Team = (
    {
        match,
    }
) => {
    const {t, i18n} = useTranslation();
    return <>
        <AppHeader/>
        <div className="app-main">
            <AppSidebar/>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <PageTitle
                        heading={t('settings.team.page.title', 'Team members')}
                        subheading=''
                        icon="fa fas fa-cog icon-gradient bg-night-fade"
                        headerButtonCreateUrl={`${match.url}create/`}
                        enablePageTitleCreationButton={false}
                    />
                    <APITable2
                        HeaderComponent={TableHeader}
                        RowComponent={TableRow}
                        emptyMessage={t('settings.team.list.message.no_data', 'No team members')}
                        deleteMessage={t('settings.team.list.confirmation.remove', 'Are you sure you want to remove the team member?')}
                        apiPath={API_PATH}
                    />
                </div>
            </div>
        </div>
    </>;
};

export default Team;
