import {ofType} from 'redux-observable';
import {from, EMPTY, of} from 'rxjs';
import i18next from 'i18next';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators'

import {
    LOG_IN_REQUEST_START,
    logInRequestFail,
    logInRequestSuccess,
    LOGOUT_REQUEST_START,
    logoutRequestFail,
    logoutRequestSuccess,
    QUICK_LOG_IN_REQUEST_START,
    quickLogInRequestFail,
    quickLogInRequestSuccess,
} from '../actions/Auth'
import {SHOPS_SELECT_SHOP} from "../actions/Shops";
import {eventsSetUserId} from "../Components/utils";
import {
    apiAxiosInstance,
    apiAuthAxiosInstance,
    apiQuickAuthAxiosInstance,
} from "../helpers/api";


export const logInRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(LOG_IN_REQUEST_START),
        mergeMap(
            action => from(apiAuthAxiosInstance.post('login/',
                {
                    'username': action.username,
                    'password': action.password,
                }
             )).pipe(
                map(response => {
                    if (response.data.status === 'ok') {
                        eventsSetUserId(response.data.result.user.id);
                        return logInRequestSuccess(response.data.result.token, response.data.result.user)
                    } else {
                        return logInRequestFail(response.data.errors)
                    }
                }),
                catchError(error => {
                    return of(logInRequestFail({
                        'common': i18next.t('errors.common.request_sent', 'Error sending request')
                    }));
                })
            )
        )
    );

export const quickLogInRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(QUICK_LOG_IN_REQUEST_START),
        mergeMap(
            action => from(apiQuickAuthAxiosInstance.post('/login/quick',
                {
                    'username': action.username,
                }
            )).pipe(
                map(response => {
                    if (response.data.status === 'ok') {
                        // eventsSetUserId(response.data.result.user.id);
                        return quickLogInRequestSuccess(response.data.result.secret_code)
                    } else {
                        return quickLogInRequestFail(response.data.errors)
                    }
                }),
                catchError(error => {
                    return of(quickLogInRequestFail({
                        'common': i18next.t('errors.common.request_sent', 'Error sending request')
                    }));
                })
            )
        )
    );


export const logOutEpic = (action$, state$) =>
    action$.pipe(
        ofType(LOGOUT_REQUEST_START),
        switchMap(
            action => from(apiAxiosInstance.post('auth/logout/'))
            .pipe(
                map(response => {
                    if (response.status === 204) {
                        eventsSetUserId(null);
                        return logoutRequestSuccess()
                    } else {
                        return logoutRequestFail(response.data.errors)
                    }
                }),
                catchError(error => {
                    return of(logoutRequestFail({
                        'common': i18next.t('errors.common.request_sent', 'Error sending request'),
                    }));
                }),
            )
        )
    );


export const setGlobalUserData = (action$, state$) =>
    action$.pipe(
        ofType(SHOPS_SELECT_SHOP),
        switchMap(
            () => {
                window.s2bUserID = state$.value.Auth.user.id;
                window.s2bUserEmail = state$.value.Auth.user.email;

                // Set Yandex metrika data
                if (window.ym && window.yandexMetrikaID) {
                    window.ym(window.yandexMetrikaID, 'userParams', {
                        UserID: state$.value.Auth.user.id,
                        UserEmail: state$.value.Auth.user.email,
                    });
                }

                return EMPTY;
            }
        )
    );
