import React, {useState} from 'react';
import {connect} from 'react-redux';
import Hamburger from 'react-hamburgers';
import cx from 'classnames';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons';

import {setEnableMobileMenu, setEnableMobileMenuSmall,} from '../actions/ThemeOptions';


const AppMobileMenu = (
    {
        enableMobileMenu,
        enableMobileMenuSmall,
        setEnableMobileMenu,
        setEnableMobileMenuSmall,
    }
) => {
    let [activeSecondaryMenuMobile, setActiveSecondaryMenuMobile] = useState(false);

    return <>
        <div className="app-header__mobile-menu">
            <div onClick={() => setEnableMobileMenu(!enableMobileMenu)}>
                <Hamburger
                    active={enableMobileMenu}
                    type="elastic"
                    onClick={() => setEnableMobileMenu(!enableMobileMenu)}
                />
            </div>
        </div>
        <div className="app-header__menu">
            <span onClick={() => setEnableMobileMenuSmall(!enableMobileMenuSmall)}>
                <Button size="sm"
                        className={cx("btn-icon btn-icon-only pl-3 pr-3", {active: activeSecondaryMenuMobile})}
                        color="primary"
                        onClick={() => setActiveSecondaryMenuMobile(!activeSecondaryMenuMobile)}>
                    <div className="btn-icon-wrapper"><FontAwesomeIcon icon={faEllipsisV}/></div>
                </Button>
            </span>
        </div>
    </>;
}


const mapStateToProps = state => ({
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMobileMenu);