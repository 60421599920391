import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    Card,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Input,
    Row,
    UncontrolledDropdown
} from 'reactstrap';
import {push} from 'connected-react-router'
import DataTable from 'react-data-table-component';
import Loader from "react-loaders";
import forIn from 'lodash/forIn';
import isEmpty from 'lodash/isEmpty';
import clone from 'lodash/clone';
import get from 'lodash/get';
import fileDownload from 'js-file-download';

import PageTitle from "../Layout/AppMain/PageTitle";
import {filtersToRequestParams, renderErrorsAsHTML} from './utils';
import FiltersDropdown from "./FiltersDropdown";
import {apiTableConfigRequest, apiTableDataRequest, apiTableExportRequest} from "../thunks/ApiModel";
import {apiTableSetConfig, apiTableSetData, apiTableSetFilters, apiTableSetSearch,} from "../actions/APIModel";
import FiltersValueList from "./FiltersValueList";


const APITable = (
    {
        heading,
        subheading,
        icon,
        headerButtonCreateUrl,
        creationDisabled,
        hasFilters,
        hasHeader,
        tableOnly,
        apiPath,
        activeShop,
        config,
        data,
        filters,
        search,
        errors,
        configErrors,
        totalRows,
        pathname,
        push,
        columnsConfig,
        dataRequestCancelTokenSource,
        configRequestCancelTokenSource,
        tableEmptyMessage,
        apiTableConfigRequest,
        apiTableExportRequest,
        apiTableDataRequest,
        apiTableSetConfig,
        apiTableSetFilters,
        apiTableSetData,
        apiTableSetSearch,
    }
) => {
    const {t, i18n} = useTranslation();

    const [searchInputVal, setSearchInputVal] = useState(undefined);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    const redirectById = (id) => {
        let target_pathname = pathname;
        let lastChar = pathname.substr(-1);
        if (lastChar !== '/') {
            target_pathname += '/';
        }
        push(`${target_pathname}${id}/`)
    };

    const handlePageChange = page => {
        const offset = (page - 1) * perPage;
        apiTableDataRequest(
            apiPath,
            {
                shop_id: activeShop.id,
                limit: perPage,
                offset: offset,
                search: search,
                ...filtersToRequestParams(filters, get(config, 'filters')),
            },
            false,
        );
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        const offset = (page - 1) * newPerPage;
        apiTableDataRequest(
            apiPath,
            {
                shop_id: activeShop.id,
                limit: newPerPage,
                offset: offset,
                search: search,
                ...filtersToRequestParams(filters, get(config, 'filters')),
            },
            false,
        );
        setPerPage(newPerPage);
    };

    const onSearchSubmit = (event) => {
        event.preventDefault();
        apiTableSetSearch(searchInputVal || undefined);
    };

    // Component did mount
    useEffect(() => {
        apiTableDataRequest(
            apiPath,
            {
                shop_id: activeShop.id,
                limit: perPage,
                search: search,
                ...filtersToRequestParams(filters, get(config, 'filters')),
            },
            false,
        );
    }, [filters, search]);

    // Component did mount
    useEffect(() => {
        // Очищаем старые данные и перезагружаем таблицу
        apiTableDataRequest(
            apiPath,
            {
                shop_id: activeShop.id,
                limit: perPage,
            },
            true,
        );
        hasFilters && apiTableConfigRequest(apiPath, activeShop.id);

        return () => {
            apiTableSetFilters(undefined);
            apiTableSetConfig(undefined);
            apiTableSetData(undefined, 0);
            apiTableSetSearch(undefined);
        }
    }, [activeShop]);

    const customStyles = {
        header: {
            style: {
                display: 'none',
            }
        },
        headCells: {
            style: {
                color: 'black',
                fontSize: '.9rem',
                fontWeight: 'normal',
                paddingTop: '.5rem',
            },
        },
        rows: {
            style: {
                borderBottomColor: 'rgba(0, 0, 0, 0.125)',
            },
            highlightOnHoverStyle: {
                backgroundColor: '#f7f7f9',
                borderTop: '1px solid rgba(0, 0, 0, 0.125)',
                borderBottomColor: 'rgba(0, 0, 0, 0.125)',
            },
        },
        cells: {
            style: {
                color: 'rgb(73, 80, 87)',
                fontSize: '.9rem',
                padding: '1.2rem',
            },
        },
        pagination: {
            style: {
                fontSize: '.9rem',
                color: (totalRows > perPage) ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.18)',
            },
        }
    };

    const paginationOptions = {
        rowsPerPageText: t('components.api_table.pagination.rows_per_page', 'Results per page'),
        rangeSeparatorText: t('components.api_table.pagination.separator', 'of'),
    };

    return <Fragment>
        {
            hasHeader !== false && <PageTitle
                heading={heading}
                subheading={subheading}
                icon={icon}
                headerButtonCreateUrl={headerButtonCreateUrl}
                enablePageTitleCreationButton={!creationDisabled}
            />
        }
        <Row>
            {
                config && <>
                    <Col md="12" className="m-0 d-flex flex-row align-items-center">
                        {
                            config.search && <Form
                                className='w-100'
                                style={{maxWidth: '300px'}}
                                onSubmit={onSearchSubmit}
                            >
                                <Input
                                    placeholder={get(config, 'search.placeholder', 'Search...')}
                                    value={searchInputVal}
                                    onChange={(event) => setSearchInputVal(event.target.value)}
                                />
                            </Form>
                        }
                        {
                            config.filters && <FiltersDropdown
                                config={config}
                                filters={filters}
                                setFilters={
                                    newData => {
                                        setPage(1);
                                        apiTableSetFilters(newData);
                                        apiTableSetSearch(searchInputVal);
                                    }
                                }
                                showTitle={false}
                            />
                        }
                        {
                            config.export && <UncontrolledDropdown className='ml-auto'>
                                <DropdownToggle color='null py-0' style={{fontSize: '1.9rem', paddingRight: 0}}>
                                    <div className='d-flex align-items-center'>
                                        <i className="pe-7s-menu"/>
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-hover-link" right>
                                    <DropdownItem
                                        toggle={false}
                                        disabled={isEmpty(data)}
                                        onClick={() => apiTableExportRequest(
                                            apiPath,
                                            {
                                                shop_id: activeShop.id,
                                                search: search,
                                                ...filtersToRequestParams(filters, get(config, 'filters')),
                                            },
                                            (result) => {
                                                fileDownload(result.data, 'export.xlsx');
                                            }
                                        )}
                                    >
                                        {t('components.api_table.export_to_excel.title', 'Export to Excel')}{' '}&nbsp;
                                        {
                                            totalRows && <span className="text-secondary">
                                                ({t('components.api_table.export_to_excel.rows_total_label', 'records:')} <strong>{totalRows}</strong>)
                                            </span>
                                        }
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        }
                    </Col>
                    <Col md="12" className="mb-3">
                        {
                            config.filters && <FiltersValueList
                                config={config.filters}
                                data={filters}
                                onFilterUnset={
                                    (key, filter) => {
                                        const newData = clone(filters);
                                        delete newData[key]
                                        apiTableSetFilters(newData);
                                    }
                                }
                            />
                        }
                    </Col>
                </>
            }
            <Col md="12">
                <Card className="main-card mb-3">
                    {
                        (!isEmpty(errors) || !isEmpty(configErrors)) && <Alert color='danger'>
                            {
                                !isEmpty(errors) && <>
                                    <h4>{forIn(errors.common, (k, v) => renderErrorsAsHTML(v))}</h4>
                                    <h4>{forIn(errors.detail, (k, v) => renderErrorsAsHTML(v))}</h4>
                                </>
                            }
                            {
                                !isEmpty(configErrors) && <>
                                    <h4>{forIn(configErrors.common, (k, v) => renderErrorsAsHTML(v))}</h4>
                                    <h4>{forIn(configErrors.detail, (k, v) => renderErrorsAsHTML(v))}</h4>
                                </>
                            }
                            <Button
                                className="mb-2 mr-2 btn btn-light"
                                onClick={
                                    () => {
                                        const offset = (page - 1) * perPage;
                                        apiTableDataRequest(
                                            apiPath,
                                            {
                                                shop_id: activeShop.id,
                                                limit: perPage,
                                                offset: offset,
                                            },
                                            false,
                                        );
                                        hasFilters && apiTableConfigRequest(apiPath, activeShop.id);
                                    }
                                }
                            >
                                {t('components.api_table.button.try_again', 'Try again')}
                            </Button>
                        </Alert>
                    }

                    <DataTable
                        title="Users"
                        columns={columnsConfig}
                        data={data}
                        onRowClicked={(row) => !tableOnly && redirectById(row.id)}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        paginationComponentOptions={paginationOptions}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        highlightOnHover
                        pointerOnHover={!tableOnly}
                        customStyles={customStyles}
                        progressPending={(dataRequestCancelTokenSource || configRequestCancelTokenSource) && (isEmpty(data) || isEmpty(config)) && isEmpty(errors)} // Когда данных нет, показываем прелоадер. Иначе - накладывваем белую плашку
                        progressComponent={
                            <div className="d-flex justify-content-center align-items-center text-center py-5">
                                <Loader type="line-scale-party" active/>
                            </div>
                        }
                        noDataComponent={
                            <div className="d-flex justify-content-center align-items-center text-center py-5">
                                {tableEmptyMessage}
                            </div>
                        }
                    />

                    {
                        dataRequestCancelTokenSource && (!isEmpty(data) || !isEmpty(errors)) && <div
                            className="position-absolute"
                            style={{
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                background: 'rgba(255, 255, 255, .4)',
                            }}
                        />
                    }
                </Card>
            </Col>
        </Row>
    </Fragment>;
}


const mapStateToProps = state => ({
    data: state.APITable.data,
    filters: state.APITable.filters,
    search: state.APITable.search,
    config: state.APITable.config,
    totalRows: state.APITable.totalRows,
    errors: state.APITable.errors,
    configErrors: state.APITable.configErrors,
    dataRequestCancelTokenSource: state.APITable.dataRequestCancelTokenSource,
    configRequestCancelTokenSource: state.APITable.configRequestCancelTokenSource,
    activeShop: state.Shops.activeShop,
    pathname: state.router.location.pathname,
});

const mapDispatchToProps = dispatch => ({
    push: (path) => dispatch(push(path)),
    apiTableDataRequest: (apiPath, queryParams, clearOldData) => dispatch(apiTableDataRequest(apiPath, queryParams, clearOldData)),
    apiTableConfigRequest: (apiPath, shopId, callback) => dispatch(apiTableConfigRequest(apiPath, shopId, callback)),
    apiTableExportRequest: (apiPath, queryParams, callback) => dispatch(apiTableExportRequest(apiPath, queryParams, callback)),
    apiTableSetFilters: (filters) => dispatch(apiTableSetFilters(filters)),
    apiTableSetConfig: (config) => dispatch(apiTableSetConfig(config)),
    apiTableSetData: (data) => dispatch(apiTableSetData(data)),
    apiTableSetSearch: (search) => dispatch(apiTableSetSearch(search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(APITable);
