import React, {Fragment} from 'react'
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {push} from 'connected-react-router'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import pick from 'lodash/pick'
import forIn from 'lodash/forIn'
import get from 'lodash/get'
import clone from "lodash/clone"

import {
    apiFormDeleteSweetalertHide,
    apiFormSetFormData,
    apiFormSetFormSchema,
    apiFormSetLiveValidate,
} from '../../actions/APIModel'
import {renderErrorsAsHTML} from '../utils'
import FormView from './FormView';
import SelectField from "./Fields/SelectField";
import ImageWidget from "./Fields/ImageWidget";
import MultiSelectField from "./Fields/MultiSelectField";
import MultilangTextInputField from "./Fields/MultilangTextInputField";
import CheckboxField from "./Fields/CheckboxField";
import MultilangTextareaField from "./Fields/MultilangTextareaField";
import MultilangMultiSelectField from "./Fields/MultilangMultiSelectField";
import UploadContentField from "./Fields/UploadContentField";
import FileWidget from "./Fields/FileWidget";
import VideoWidget from "./Fields/VideoWidget";
import {
    apiFormDataRequest,
    apiFormDeleteRequest,
    apiFormSchemaRequest,
    apiFormSubmitRequest,
} from "../../thunks/ApiModel";
import { STEP_TYPE_COMBINED } from '../../constants';


class APIForm extends React.Component {
    constructor() {
        super();

        this.loadData = this.loadData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    loadData() {
        if (this.props.isSendingDataRequest || this.props.isSendingSchemaRequest) {
            return;
        }

        // ShopId здесь нужен, чтобы отобразить правильные объекты в выпадающих списках
        this.props.apiFormSchemaRequest(`${this.props.apiPath}/${this.props.modelId}/form_schema/?shop_id=${this.props.activeShop.id}`);

        if (this.props.modelId === undefined) {
            const defaultFormData = {
                shop_id: this.props.activeShop.id,
            };
            this.props.apiFormSetFormData(defaultFormData);
        } else {
            this.props.apiFormDataRequest(`${this.props.apiPath}/${this.props.modelId}/`);
        }
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        // TODO: подумать, правда ли тут должен быть сконфигурирован этот редирект
        if (this.props.activeShop.id !== prevProps.activeShop.id) {
            this.props.push(`/`);
        }

        if (get(this.props, 'data.shop_id') && this.props.activeShop.id !== this.props.data.shop_id) {
            this.props.push(`/`);
        }

        // Когда смотрим страницу одной модели и нажимаем кнопку Создать
        if (this.props.modelId !== prevProps.modelId) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        this.props.apiFormSetFormData(undefined);
        this.props.apiFormSetFormSchema(undefined, undefined);
        this.props.apiFormSetLiveValidate(false);
    }

    updateMenuButtons = (formData) => {
        const mbLen = formData.menu_buttons?.length;
        if (mbLen > 0) {
            // buttons exists
            const menu_buttons = formData.state.menu_buttons.map(
                (item, index) => {
                    return index < mbLen
                        ? {
                            ...formData.menu_buttons[index],
                            ...item
                        }
                        : item;
                }
            );
            formData.menu_buttons = menu_buttons;
        } else {
            formData.menu_buttons = formData.state.menu_buttons;
        }
    }

    updateMedia = (formData) => {
        if (!formData?.state?.upload_content) {
            return;
        }
        formData.image_url = formData.state.upload_content?.imagePath || null;
        formData.file_url = formData.state.upload_content?.filePath || null;
        formData.custom_field_media = formData.state.upload_content?.variable || null;
    }

    onChange = ({ formData }) => {
        // TODO: make it simpler
        //menu_buttons hack for step.id == 22. This step holds menu buttons inside 'state' prop of the object,
        // but backend awaits it in the root of the object
        const mbFormData = clone(formData);
        if (mbFormData.type?.id == STEP_TYPE_COMBINED) {
            this.updateMenuButtons(mbFormData);
            this.updateMedia(mbFormData);
        }
        this.props.apiFormSetFormData(mbFormData);
    };

    onDelete = () => {
        this.props.apiFormDeleteRequest(
            `${this.props.apiPath}/${this.props.modelId}/`,
            this.props.actionRedirectUrl,
        );

        this.props.apiFormAfterDeleteCallback && this.props.apiFormAfterDeleteCallback();

        this.props.apiFormDeleteSweetalertHide();
    };

    onSubmit = ({idSchema, formData}) => {
        const that = this;
        if (this.props.buttonSubmitDisabled || this.props.isSendingActionRequest) {
            return;
        }

        let formKeys = keys(idSchema);
        formKeys.push('shop_id');
        // console.log('Step onSubmit', formData, formKeys)

        // TODO: make it simpler
        // menu_buttons hack for step.id == 22. This step holds menu buttons inside 'state' prop of the object,
        // but backend awaits it in the root of the object
        if (formData.type?.id == STEP_TYPE_COMBINED) {
            formKeys.push('menu_buttons');
            formKeys.push('file_url');
            formKeys.push('image_url');
            formKeys.push('custom_field_media');
        }

        let formDataFiltered = pick(this.props.data, formKeys);

        let apiUrl = `${this.props.apiPath}/`;
        if (this.props.modelId) {
            apiUrl += `${this.props.modelId}/`;
        }

        this.props.apiFormSubmitRequest(
            apiUrl,
            this.props.method,
            formDataFiltered,
            this.props.actionRedirectUrl,
            (response) => {
                that.props.afterSubmitCallback && that.props.afterSubmitCallback(response);
            }
        );
    };

    getFormUISchema = (uiSchema) => {
        // 1. Check uiSchema is object, null or undefined
        if (typeof uiSchema !== 'object' && uiSchema !== undefined) {
            throw 'Wrong UI Schema'
        }

        const result = {};

        for (let [key, value] of Object.entries(uiSchema || {})) {
            // Recursively exec myself
            if (typeof value === 'object' && value !== null) {
                result[key] = this.getFormUISchema(value);
            } else if (key === "ui:field") {
                switch (value) {
                    case 'SelectField':
                        result[key] = SelectField;
                        break;
                    case 'CheckboxField':
                        result[key] = CheckboxField;
                        break;
                    case 'MultiSelectField':
                        result[key] = MultiSelectField;
                        break;
                    case 'MultilangTextInputField':
                        result[key] = MultilangTextInputField;
                        break;
                    case 'MultilangTextareaField':
                        result[key] = MultilangTextareaField;
                        break;
                    case 'MultilangMultiSelectField':
                        result[key] = MultilangMultiSelectField;
                        break;
                    case 'UploadContentField':
                        result[key] = UploadContentField;
                        break;
                    default:
                        throw `Wrong ui:field "${value}"`
                }
            } else if (key === "ui:widget") {
                switch (value) {
                    case 'ImageWidget':
                        result[key] = ImageWidget;
                        break;
                    case 'FileWidget':
                        result[key] = FileWidget;
                        break;
                    case 'VideoWidget':
                        result[key] = VideoWidget;
                        break;
                    default:
                        result[key] = value;
                }
            } else if (key === "ui:description") {
                result[key] = <div dangerouslySetInnerHTML={{__html: value}}/>;
            } else {
                result[key] = value;
            }
        }

        return result;
    };

    render = () => {
        let formView = undefined;
        if (this.props.isSendingDataRequest || this.props.isSendingSchemaRequest) {
            formView = <div></div>;
        } else if (this.props.data === undefined && !isEmpty(this.props.errors)) {
            formView = <div className='p-4'>
                <h4>{forIn(this.props.errors.common, (k, v) => renderErrorsAsHTML(v))}</h4>
                <h4>{forIn(this.props.errors.detail, (k, v) => renderErrorsAsHTML(v))}</h4>
                <button className="mb-2 mr-2 btn btn-light" onClick={this.loadData}>{this.props.t('components.api_form.button.retry', 'Try again')}</button>
            </div>
        } else if (this.props.data === undefined) {
            formView = <div className="p-4">
                <h4>{this.props.emptyMessage ? this.props.emptyMessage : this.props.t('components.api_form.message.no_data', 'No data')}</h4>
                <button className="mb-2 mr-2 btn btn-light" onClick={this.loadData}>{this.props.t('components.api_form.button.retry', 'Try again')}</button>
            </div>
        } else if (this.props.formSchema === undefined) {
            formView = <div className="p-4">
                <h4>💢 {this.props.t('components.api_form.message.something_went_wrong', 'Something went wrong')}</h4>
                <button className="mb-2 mr-2 btn btn-light" onClick={this.loadData}>{this.props.t('components.api_form.button.retry', 'Try again')}</button>
            </div>
        } else {
            formView = <FormView
                formSchema={this.props.formSchema}
                formUISchema={this.getFormUISchema(this.props.formUISchema)}
                formData={this.props.data}
                onSubmit={this.onSubmit}
                onChange={this.onChange}
                onDelete={this.onDelete}
                buttonSubmitDisabled={this.props.buttonSubmitDisabled}
                deletionDisabled={this.props.deletionDisabled}
                actionRedirectUrl={this.props.actionRedirectUrl}
                isSendingActionRequest={this.props.isSendingActionRequest}
                liveValidate={this.props.liveValidate}
                ArrayFieldTemplate={this.props.ArrayFieldTemplate}
            />
        }

        return <Fragment>
            {formView}
        </Fragment>;
    }
}


const mapStateToProps = state => ({
    data: state.APIForm.data,
    errors: state.APIForm.errors,
    formSchema: state.APIForm.formSchema,
    formUISchema: state.APIForm.formUISchema,
    isSendingDataRequest: state.APIForm.isSendingDataRequest,
    isSendingSchemaRequest: state.APIForm.isSendingSchemaRequest,
    isSendingActionRequest: state.APIForm.isSendingActionRequest,
    liveValidate: state.APIForm.liveValidate,
    activeShop: state.Shops.activeShop,
});

const mapDispatchToProps = dispatch => ({
    push: (path) => dispatch(push(path)),
    apiFormSchemaRequest: (apiPath) => dispatch(apiFormSchemaRequest(apiPath)),
    apiFormDataRequest: (apiPath) => dispatch(apiFormDataRequest(apiPath)),
    apiFormSubmitRequest: (apiPath, method, formData, redirectUrl, callback) => dispatch(apiFormSubmitRequest(apiPath, method, formData, redirectUrl, callback)),
    apiFormDeleteRequest: (apiPath, redirectUrl, callback) => dispatch(apiFormDeleteRequest(apiPath, redirectUrl, callback)),
    apiFormDeleteSweetalertHide: () => dispatch(apiFormDeleteSweetalertHide()),

    apiFormSetFormData: (formData) => dispatch(apiFormSetFormData(formData)),
    apiFormSetFormSchema: (formSchema, formUISchema) => dispatch(apiFormSetFormSchema(formSchema, formUISchema)),
    apiFormSetLiveValidate: (liveValidate) => dispatch(apiFormSetLiveValidate(liveValidate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(APIForm));
