import {toast} from "react-toastify";
import i18next from 'i18next';

import {store} from "../configureStore";
import {
    resumeBroadcastRequestFail,
    resumeBroadcastRequestStart,
    resumeBroadcastRequestSuccess,
    startBroadcastRequestFail,
    startBroadcastRequestStart,
    startBroadcastRequestSuccess,
    stopBroadcastRequestFail,
    stopBroadcastRequestStart,
    stopBroadcastRequestSuccess,
} from '../actions/Broadcast'
import {renderErrorsAsString} from "../Components/utils";
import {serviceBroadcastAxiosInstance} from "../helpers/api";


export const startBroadcast = (broadcastId, callback) => {
    return function (dispatch) {
        let data = {
            shop_id: store.getState().Shops.activeShop.id,
            broadcast_id: broadcastId,
        };
        dispatch(startBroadcastRequestStart(data));

        serviceBroadcastAxiosInstance.post('start_broadcast', data).then(
            response => {
                if (response.data.status === 'ok') {
                    dispatch(startBroadcastRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    toast.error(renderErrorsAsString(response.data.errors));
                    dispatch(startBroadcastRequestFail(data, response.data.errors));
                }
            },
            error => {
                toast.error(i18next.t('errors.common.request_sent', 'Error sending request'));
                dispatch(startBroadcastRequestFail(data, {
                    'common': i18next.t('errors.common.request_sent', 'Error sending request'),
                }));
            },
        );
    };
};


export const stopBroadcast = (broadcastId, callback) => {
    return function (dispatch) {
        let data = {
            shop_id: store.getState().Shops.activeShop.id,
            broadcast_id: broadcastId,
        };
        dispatch(stopBroadcastRequestStart(data));

        serviceBroadcastAxiosInstance.post('stop_broadcast', data).then(
            response => {
                if (response.data.status === 'ok') {
                    dispatch(stopBroadcastRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    toast.error(renderErrorsAsString(response.data.errors));
                    dispatch(stopBroadcastRequestFail(data, response.data.errors));
                }
            },
            error => {
                toast.error(i18next.t('errors.common.request_sent', 'Error sending request'));
                dispatch(stopBroadcastRequestFail(data, {
                    'common': i18next.t('errors.common.request_sent', 'Error sending request'),
                }));
            },
        );
    };
};


export const resumeBroadcast = (broadcastId, callback) => {
    return function (dispatch) {
        let data = {
            shop_id: store.getState().Shops.activeShop.id,
            broadcast_id: broadcastId,
        };
        dispatch(resumeBroadcastRequestStart(data));

        serviceBroadcastAxiosInstance.post('resume_broadcast', data).then(
            response => {
                if (response.data.status === 'ok') {
                    dispatch(resumeBroadcastRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    toast.error(renderErrorsAsString(response.data.errors));
                    dispatch(resumeBroadcastRequestFail(data, response.data.errors));
                }
            },
            error => {
                toast.error(i18next.t('errors.common.request_sent', 'Error sending request'));
                dispatch(resumeBroadcastRequestFail(data, {
                    'common': i18next.t('errors.common.request_sent', 'Error sending request'),
                }));
            },
        );
    };
};
