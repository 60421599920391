export const URLS = {
  AUTH: {
    OLD_SIGNUP: '/auth/old-signup',
    SIGNUP: '/auth/signup',
    QUCK_SIGNUP: '/auth/quick-signup',
    FORGOT_PASSWORD: '/auth/forgot-password'
  },
  INTEGRATIONS: {
    WABA: {
      CREATE_API_KEY: '/waba/create-api-key'
    }
  },
  ROOT: '/'
};