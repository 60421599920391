import {
    LOAD_CHARTS_DATA_REQUEST_START,
    LOAD_CHARTS_DATA_REQUEST_SUCCESS,
    LOAD_CHARTS_DATA_REQUEST_FAIL,
} from '../actions/Statistic'


const defaultState = {
    chartsData: {},
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case LOAD_CHARTS_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                chartsData: action.result,
            };

        case LOAD_CHARTS_DATA_REQUEST_FAIL:
            return {
                ...state,
                chartsData: action.result,
            };
    }
    return state;
}
