import React from "react";
import {connect} from "react-redux";
import {Button, Card} from "reactstrap";
import qs from 'qs';
import {useTranslation} from "react-i18next";

import vkLogo from '../../../../assets/utils/images/messenger/vk.png';

const ConnectCardVK = (
    {
        activeShop,
        user,
    }
) => {
    const {t, i18n} = useTranslation();

    // Attention! This redirect URL is used in servicevk python code! DO NOT CHANGE!
    const nextTo = qs.stringify({next_to: `${window.location.protocol}//${window.location.hostname}/panel/#/${activeShop.id}/connect/vk`});
    const redirectUri = qs.stringify({redirect_uri: `${window.location.protocol}//${window.location.hostname}/service/servicevk/oauth/login_user?user_id=${user.id}&${nextTo}`});
    const oauthUrl = `https://oauth.vk.com/authorize?client_id=${process.env.REACT_APP_VK_APP_ID}&scope=groups,offline&response_type=code&${redirectUri}`;

    return <Card className="mb-3 profile-responsive">
        <div className="dropdown-menu-header text-dark">
            <div className="dropdown-menu-header-inner bg-white">
                <div className="menu-header-content btn-pane-right d-block text-center">
                    <h5 className="d-flex align-items-center justify-content-center mb-3">
                        <img src={vkLogo} alt="VK" style={{height: 30}} className='mr-2'/>
                        {t('connect_cards.vk.title', 'Vkontakte')}
                    </h5>
                    <h6 className='opacity-6 my-4'>
                        {t('connect_cards.vk.caption', 'Connect one Vkontakte account to HighTouch and manage its community messages in two clicks')}
                    </h6>
                    <div className="menu-header-btn-pane">
                        <a href={oauthUrl}>
                            <Button color="main" route='/'>
                                {t('connect_bot.common.submit', 'Connect')}
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </Card>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    user: state.Auth.user,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCardVK);
