import React, {useState} from "react";
import {connect} from 'react-redux';
import {Trans, useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {Button, Col, Form } from 'reactstrap';

import {logInRequestStart, registerCloseNotificationPopup, touchLoginForm} from "../../../actions/Auth";
import {TG_AUTH} from "../../../constants";

// import StylesTypography from '../../StylesTypographyPage/StylesTypography'


function Login(
    {
        errors,
        validationEmailNotification,
        loginRequestStart,
        touchLoginForm,
        registerCloseNotificationPopup,
    }
) {
    const {t, i18n} = useTranslation();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const sendLogInRequest = (e) => {
        e.preventDefault();
        loginRequestStart(username, password);
    };

    const link = `${TG_AUTH}?lang_code=${i18n.languages[0]}`;
    const handleSignIn = (e) => {
        e.preventDefault();
        window.location.href = link;
    }

    return <>
        <div className="h-100 bg-dark">
            <div className="d-flex h-100 justify-content-center align-items-center">
                <Col md="8" className="mx-auto app-login-box">
                    {/*<div className="mx-auto mb-3 text-center">*/}
                    {/*    <h4 className="mt-2">*/}
                    {/*        {t('auth.login.title', 'Nice to meet you!')}*/}
                    {/*    </h4>*/}
                    {/*</div>*/}

                    <div className="login-form modal-dialog w-100">
                        <div className="modal-content">
                            <div className="modal-body py-4 py-sm-5">
                                <div className="login-form__logo bg-transparent w-100 justify-content-center">
                                    <div className="logo-src"/>
                                </div>
                                <Form onSubmit={sendLogInRequest} autoComplete="on">
                                    <div className={'login-form__disclaimer my-4'}>
                                        Click the button below to open Telegram app on the desktop. When Telegram chat is opened, click 'Start'.
                                    </div>
                                    {/*<Link to={'/auth/login'}*/}
                                    {/*      size={'block'}*/}
                                    {/*      className={'btn btn-info btn-block d-flex align-items-center justify-content-center font-weight-bold login-form__button'}*/}
                                    {/*>*/}
                                    {/*    <i className={'fa fab fa-telegram-plane fa-lg pr-2'}></i>*/}
                                    {/*    Continue with Telegram*/}
                                    {/*</Link>*/}
                                    <Button
                                        onClick={handleSignIn}
                                        color={"info"}
                                        size={'block'}
                                        className={'d-flex align-items-center justify-content-center font-weight-bold text-white login-form_button login-form_button__tg'}
                                    >
                                        <i className={'fa fab fa-telegram-plane fa-lg pr-2'}></i>
                                        Continue with Telegram
                                    </Button>

                                    <div className="text-danger mr-3">{get(errors, 'common')}</div>

                                </Form>

                            </div>



                        </div>
                    </div>
                </Col>
            </div>
        </div>

    </>;
}


const mapStateToProps = state => ({
    errors: state.Auth.login.errors,
    validationEmailNotification: state.Auth.register.validationEmailNotification,
});

const mapDispatchToProps = dispatch => ({
    loginRequestStart: (username, password) => dispatch(logInRequestStart(username, password)),
    touchLoginForm: () => dispatch(touchLoginForm()),
    registerCloseNotificationPopup: () => dispatch(registerCloseNotificationPopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

