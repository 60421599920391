import {
    ACTIVATE_BOT_REQUEST_START,
    ACTIVATE_BOT_REQUEST_SUCCESS,
    ACTIVATE_BOT_REQUEST_FAIL,

    SHUTDOWN_BOT_REQUEST_START,
    SHUTDOWN_BOT_REQUEST_SUCCESS,
    SHUTDOWN_BOT_REQUEST_FAIL,

    GROUPS_REQUEST_FAIL,
    GROUPS_REQUEST_START,
    GROUPS_REQUEST_SUCCESS,

    AVITO_BOT_CREATE_REQUEST_START,
    AVITO_BOT_CREATE_REQUEST_SUCCESS,
    AVITO_BOT_CREATE_REQUEST_FAIL,

    BOT_CREATE_REQUEST_START,
    BOT_CREATE_REQUEST_SUCCESS,
    BOT_CREATE_REQUEST_FAIL,

    BOT_UPDATE_REQUEST_START,
    BOT_UPDATE_REQUEST_SUCCESS,
    BOT_UPDATE_REQUEST_FAIL,

    CLEAR_ERRORS,
} from "../actions/Connect";


const defaultState = {
    isSendingRequest: false,
    groups: undefined,
    errors: undefined,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case CLEAR_ERRORS:
            return {
                ...state,
                errors: undefined,
            };

        case GROUPS_REQUEST_START:
            return {
                ...state,
                isSendingRequest: true,
            };

        case GROUPS_REQUEST_SUCCESS:
            return {
                ...state,
                isSendingRequest: false,
                groups: action.items,
            };

        case GROUPS_REQUEST_FAIL:
            return {
                ...state,
                isSendingRequest: false,
                groups: undefined,
            };

        case AVITO_BOT_CREATE_REQUEST_START:
            return {
                ...state,
                isSendingRequest: true,
            };

        case AVITO_BOT_CREATE_REQUEST_SUCCESS:
            return {
                ...state,
                isSendingRequest: false,
            };

        case AVITO_BOT_CREATE_REQUEST_FAIL:
            return {
                ...state,
                isSendingRequest: false,
                errors: action.errors,
            };

        case BOT_CREATE_REQUEST_START:
        case BOT_UPDATE_REQUEST_START:
            return {
                ...state,
                isSendingRequest: true,
                errors: undefined,
            };

        case BOT_CREATE_REQUEST_SUCCESS:
        case BOT_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                isSendingRequest: false,
            };

        case BOT_CREATE_REQUEST_FAIL:
        case BOT_UPDATE_REQUEST_FAIL:
            return {
                ...state,
                isSendingRequest: false,
                errors: action.errors
            };

    }
    return state;
}
