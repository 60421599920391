import React, {memo, useCallback, useMemo, useRef, useState} from "react";
import {
    Button,
    Col,
    FormGroup,
    Label,
    Row
} from "reactstrap";

import cn from 'classnames'

import Dropzone from 'react-dropzone'
import {useTranslation} from "react-i18next";

import imageUploading from '../../../assets/utils/images/uploading.gif';
import {uploadToCdnAxiosInstance} from "../../../helpers/api";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Select from "react-select";
import findIndex from "lodash/findIndex";
import isEqual from "lodash/isEqual";
import {getFileIconClsByURL} from "./FileWidget";
import {colourStyles} from "./SelectField";


const UploadContentField = (props) => {

    const [isUploading, setIsUploading] = useState(false);
    const [uploadFailed, setUploadFailed] = useState(false);


    const onDropImage = useCallback((files) => {

        setIsUploading(true);

        const file = files[0];
        // Check the file type.
        if (!file.type.match('image.*')) {
            props.onChange({
                ...props.formData,
                imagePath: null,
                rSelected: props.formData.rSelected || null,
            })
            setIsUploading(false);
            setUploadFailed(true);
            return;
        }

        let formData = new FormData();
        formData.append('file', file);

        uploadToCdnAxiosInstance.post(`${file.name}/`, formData).then(
            (request) => {
                setIsUploading(false);
                setUploadFailed(false);
                props.onChange({
                    ...props.formData,
                    imagePath: request.data.result.cdn_filepath,
                    rSelected: props.formData.rSelected || null,
                })
            },
            error => {
                console.log(error);
                setIsUploading(false);
                setUploadFailed(true);
                props.onChange({
                    ...props.formData,
                    imagePath: null,
                    rSelected: props.formData.rSelected || null,
                })
            },
        );
    }, [props.formData])

    const onDropFile = useCallback((files) => {
        setIsUploading(true)

        const file = files[0];

        let formData = new FormData();
        formData.append('file', file);

        uploadToCdnAxiosInstance.post(`${file.name}/`, formData).then(
            (request) => {

                setIsUploading(false);
                setUploadFailed(false);

                props.onChange({
                    ...props.formData,
                    filePath: request.data.result.cdn_filepath,
                    rSelected: props.formData.rSelected || null,
                })
            },
            error => {
                console.log(error);
                setIsUploading(false);
                setUploadFailed(true);
                props.onChange({
                    ...props.formData,
                    filePath: null,
                    rSelected: props.formData.rSelected || null,
                })
            },
        );
    }, [props.formData])

    const onImageCancel = useCallback(() => {
        props.onChange({
            ...props.formData,
            imagePath: null,
            rSelected: props.formData.rSelected || null,
        })
    }, [props.formData])

    const onFileCancel = useCallback(() => {
        props.onChange({
            ...props.formData,
            filePath: null,
            rSelected: props.formData.rSelected || null,
        })
    }, [props.formData])

    const onRadioBtnClick = useCallback((rSelected) => {
        props.onChange({
            ...props.formData,
            rSelected: props.formData.rSelected === rSelected ? null : rSelected
        })
    }, [props.formData])

    const selectOnChange = useCallback((option, action) => {
        if (action.action === 'select-option') {
            props.onChange({
                ...props.formData,
                variable: option.value === '' ? null : option.value,
                rSelected: props.formData.rSelected || null,
            })
        } else if (action.action === 'deselect-option') {
            props.onChange({
                ...props.formData,
                variable: null,
                rSelected: props.formData.rSelected || null,
            })
        } else if (action.action === 'remove-value') {
            props.onChange({
                ...props.formData,
                variable: null,
                rSelected: props.formData.rSelected || null,
            })
        }
    }, [props.formData])


    const image = useMemo(() => !(isUploading || uploadFailed)
        && !!props.formData.imagePath
        && <img key={props.formData.imagePath}
                style={{height: '100%'}}
                src={props.formData.imagePath}
                alt=""
        />, [isUploading, uploadFailed, props.formData])

    const fileSrc = useMemo(()=>!(isUploading || uploadFailed)
        && props.formData.filePath,[isUploading, uploadFailed, props.formData.filePath]);

    const fileCls = useMemo(() => getFileIconClsByURL(props.formData.filePath), [props.formData]);

    let file = useMemo(()=>fileSrc
        && <a href={fileSrc}
              target='_blank'
              className='py-2 d-flex flex-column'
              style={{height: '100%'}}
              onClick={e => e.stopPropagation()}
        >
            <div
                className={`${fileCls} bg-size-cover bg-center `}
                style={{height: 30, width: 26, margin: 'auto'}}
            />
            {fileSrc}
        </a>,[fileCls,fileSrc])

    const options = useMemo(() => props.uiSchema['ui:options'] && Object.entries(props.uiSchema['ui:options']).map(
        ([key, el]) => ({
            label: (typeof el === 'string')
                ? el
                : (!isEmpty(el)
                    ? el.name
                    : '-----'),
            value: el || '',
            isDisabled: get(el, 'isDisabled'),
        })
    ),[props.uiSchema['ui:options']])

    const defaultOption = useMemo(() => {
        const defaultValue = props.formData.variable || null;
        let defaultOption = null;
        if (
            (!isEmpty(defaultValue) || defaultValue === null)
            && typeof props.uiSchema['ui:options'] === 'object'
        ) {
            let i = -1;
            if (defaultValue === null) {
                i = findIndex(options, (el) => el.value === '');
            } else {
                // Пробуем сравнить по ID (если есть)
                i = defaultValue.id
                    ? findIndex(options, (el) => isEqual(get(el, 'value.id'), defaultValue.id))
                    : findIndex(options, (el) => isEqual(el.value, defaultValue));
            }

            if (i !== -1) {
                defaultOption = options[i];
            }
        }
        return defaultOption
    },[props.uiSchema['ui:options'], props.formData])


    return (
        <FormGroup className={cn(get(props.uiSchema, 'ui:className'), 'combined-step-form__upload-content-field')}
                   onClick={(e) => {
                       e.preventDefault()
                   }}
        >
            <Label className='mt-3'>
                {props.schema.title}
                {(props.schema.title && props.required) ? ' *' : ''}
            </Label>
            <Row>

                <Col md={12}>
                    <div className="grid-menu grid-menu-3col">
                        <Row className="no-gutters">
                            <Col md="4">
                                <Button
                                    className="btn-icon-vertical btn-square btn-transition upload-type-button"
                                    outline color="link"
                                    onClick={() => onRadioBtnClick('Image')}
                                    active={!!props.formData.rSelected
                                    && props.formData.rSelected === 'Image'}
                                >
                                    <i className="lnr-picture btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                    Image
                                </Button>
                            </Col>
                            <Col md="4">
                                <Button
                                    className="btn-icon-vertical btn-square btn-transition upload-type-button"
                                    outline color="link"
                                    onClick={() => onRadioBtnClick('File')}
                                    active={!!props.formData.rSelected
                                    && props.formData.rSelected === 'File'}
                                >
                                    <i className="lnr-file-add btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                    File
                                </Button>
                            </Col>
                            <Col md="4">
                                <Button
                                    className="btn-icon-vertical btn-square btn-transition upload-type-button"
                                    outline color="link"
                                    onClick={() => onRadioBtnClick('Variable')}
                                    active={!!props.formData.rSelected
                                    && props.formData.rSelected === 'Variable'}
                                >
                                    <i className="lnr-list btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                    From variable
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col md="12">
                    {!!props.formData.rSelected && {
                        'Image': <div className="mt-2 dropzone-wrapper dropzone-wrapper-sm">
                            {!!props.formData.imagePath &&
                            <Button onClick={onImageCancel}
                                    size={'sm'}
                                    color={'danger'}
                                    outline
                                    className={'p-0 border dropzone-clear-button'}>
                                <i className={'pe-7s-trash'}></i>
                            </Button>
                            }
                            <Dropzone
                                onDrop={onDropImage}
                                onFileDialogCancel={onImageCancel}
                            >
                                {({getRootProps, getInputProps}) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} required={props.required}/>
                                        <div className="dropzone-content">
                                            {
                                                isUploading && <Spinner/>
                                            }
                                            {
                                                !isUploading && (!!props.formData.imagePath
                                                    ? image
                                                    : <div className="font-icon-wrapper">
                                                        <i className="pe-7s-photo"> </i>
                                                        <p>Drop your image here, or click to select image to
                                                            upload.</p>
                                                    </div>)
                                            }
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>,


                        'File': <div className="mt-2 dropzone-wrapper dropzone-wrapper-sm">
                            {!!props.formData.filePath &&
                            <Button onClick={onFileCancel}
                                    size={'sm'}
                                    color={'danger'}
                                    outline
                                    className={'p-0 border dropzone-clear-button'}>
                                <i className={'pe-7s-trash'}></i>
                            </Button>
                            }
                            <Dropzone
                                onDrop={onDropFile}
                                onFileDialogCancel={onFileCancel}
                            >
                                {({getRootProps, getInputProps}) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} required={props.required}/>
                                        <div className="dropzone-content">
                                            {
                                                isUploading && <Spinner/>
                                            }
                                            {
                                                !isUploading && (!!props.formData.filePath
                                                    ? file
                                                    : <div className="font-icon-wrapper">
                                                        <i className="pe-7s-file"> </i>
                                                        <p>Drop your file here, or click to select file to
                                                            upload.</p>
                                                    </div>)
                                            }
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>,


                        'Variable': <Select
                            className={'mt-2'}
                            defaultValue={defaultOption}
                            options={options}
                            isClearable={false}
                            isSearchable={true}
                            name="color"
                            onChange={selectOnChange}
                            styles={colourStyles}
                        />,


                    }[props.formData.rSelected]
                    }
                </Col>

            </Row>
        </FormGroup>
    )

}


const Spinner = memo(() => <img src={imageUploading} style={{height: '100%'}}/>)



export default UploadContentField;
