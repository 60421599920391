import {
    GET_TEMPLATES_REQUEST_FAIL,
    GET_TEMPLATES_REQUEST_START,
    GET_TEMPLATES_REQUEST_SUCCESS,

    INSTALL_TEMPLATE_REQUEST_FAIL,
    INSTALL_TEMPLATE_REQUEST_START,
    INSTALL_TEMPLATE_REQUEST_SUCCESS,
} from '../actions/Templates';


const defaultState = {
    isSendingTemplatesRequest: false,
    isSendingInstallRequest: false,

    templatesError: undefined,
    templates: undefined,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case GET_TEMPLATES_REQUEST_START:
            return {
                ...state,
                isSendingTemplatesRequest: true,
                templatesError: undefined,
            };
        case GET_TEMPLATES_REQUEST_SUCCESS:
            return {
                ...state,
                templates: action.result,
                templatesError: undefined,
                isSendingTemplatesRequest: false,
            };
        case GET_TEMPLATES_REQUEST_FAIL:
            return {
                ...state,
                templatesError: action.error.message,
                isSendingTemplatesRequest: false,
            };

        case INSTALL_TEMPLATE_REQUEST_START:
            return {
                ...state,
                isSendingInstallRequest: true,
            };
        case INSTALL_TEMPLATE_REQUEST_SUCCESS:
            return {
                ...state,
                isSendingInstallRequest: false,
            };
        case INSTALL_TEMPLATE_REQUEST_FAIL:
            return {
                ...state,
                isSendingInstallRequest: false,
            };
    }
    return state;
}
