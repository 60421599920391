import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import {useTranslation} from "react-i18next";

import robokassaLogo from '../../../../assets/utils/images/plugins/robokassa.png';

const PluginCardRobokassa = (
    {
        activeShop,
    }
) => {
    const {t, i18n} = useTranslation();

    return <div className='card p-3'>
        <h5 className="d-flex align-items-center justify-content-center my-0" style={{height: 80}}>
            <img src={robokassaLogo} alt="Robokassa" style={{width: 150}} className='mr-2 py-3'/>
        </h5>
        <h6 className='opacity-6 my-4'>
            {t('settings.integrations.robokassa.caption', 'Receive payments for catalog goods orders via Robokassa')}
        </h6>
        <div className="menu-header-btn-pane">
            <Link to={`/${activeShop.id}/settings/payment_gates/create/`}>
                <Button color="primary">
                    {t('settings.integrations.common.button_setup', 'Connect')}
                </Button>
            </Link>
        </div>
    </div>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PluginCardRobokassa);
