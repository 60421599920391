import {
    SET_UPGRADE_TO_PRO_MODAL_OPEN,

    BILLING_CONFIG_REQUEST_START,
    BILLING_CONFIG_REQUEST_SUCCESS,
    BILLING_CONFIG_REQUEST_FAIL,

    CHARGEBEE_CREATE_PORTAL_SESSION_REQUEST_START,
    CHARGEBEE_CREATE_PORTAL_SESSION_REQUEST_SUCCESS,
    CHARGEBEE_CREATE_PORTAL_SESSION_REQUEST_FAIL,

    CHARGEBEE_GENERATE_HOSTED_PAGE_REQUEST_START,
    CHARGEBEE_GENERATE_HOSTED_PAGE_REQUEST_SUCCESS,
    CHARGEBEE_GENERATE_HOSTED_PAGE_REQUEST_FAIL,

    CONTACT_SALES_REQUEST_START,
    CONTACT_SALES_REQUEST_SUCCESS,
    CONTACT_SALES_REQUEST_FAIL,
} from '../actions/Billing';
import {SHOPS_SELECT_SHOP} from '../actions/Shops'


const defaultState = {
    upgradeToPROModalOpen: false,

    isSendingCustomerPortalSessionRequest: false,
    isSendingHostedPageRequest: false,
    isSendingContactSalesRequest: false,

    billingConfig: undefined,
    billingConfigErrors: undefined,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case SET_UPGRADE_TO_PRO_MODAL_OPEN:
            return {
                ...state,
                upgradeToPROModalOpen: action.open,
            };

        case BILLING_CONFIG_REQUEST_START:
            return {
                ...state,
                billingConfigErrors: undefined,
            };
        case BILLING_CONFIG_REQUEST_SUCCESS:
            return {
                ...state,
                billingConfig: action.result,
            };
        case BILLING_CONFIG_REQUEST_FAIL:
            return {
                ...state,
                billingConfigErrors: action.errors,
            };

        case SHOPS_SELECT_SHOP:
            return defaultState;
    }
    return state;
}
