import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

import {store} from "../configureStore";
import {apiFormSubmitRequest} from "./ApiModel";
import {addEmptyStep} from "../actions/Steps";
import {STEP_TYPE_ACTION, STEP_TYPE_CONDITION, STEP_TYPE_RANDOMIZER, STEP_TYPE_STORE_ORDER} from "../constants";


export const duplicateStepRequest = (stepId) => {
    const prevStep = find(store.getState().Steps.steps, {id: stepId});
    const newStepData = cloneDeep(prevStep);

    delete newStepData.id;
    delete newStepData.is_default;
    newStepData.next_step = null;
    newStepData.html_position_x += 400;
    newStepData.menu_buttons.forEach(el => {
        delete el.id;
        el.next_step = null;
    });
    if (newStepData.type.id === STEP_TYPE_CONDITION) {
        newStepData.state.condition_config.fallback_step_id = null;
        newStepData.state.condition_config.condition_groups.forEach(g => {
            g.next_step_id = null;
        });
    }
    if (newStepData.type.id === STEP_TYPE_RANDOMIZER) {
        newStepData.state.randomizer_config.split_groups.forEach(g => {
            g.next_step_id = null;
        });
    }
    if (newStepData.type.id === STEP_TYPE_STORE_ORDER) {
        if (!isEmpty(newStepData.state)) {
            newStepData.state.store_order_config.payment_fail_next_step_id = null;
            newStepData.state.store_order_config.payment_success_next_step_id = null;
        }
    }
    if (newStepData.type.id === STEP_TYPE_ACTION) {
        newStepData.actions.forEach(action => {
            delete action.id;
        });
    }

    // Add Step to Store
    return apiFormSubmitRequest(
        'step/steps/',
        'post',
        newStepData,
        undefined,
        (response) => store.dispatch(addEmptyStep(response)),
    );
};
