import React from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Button} from "reactstrap";
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";


const PageTitle = (
    {
        heading,
        icon,
        subheading,
        enablePageTitleCreationButton,
        headerButtonCreateUrl,

        // Map state to props
        enablePageTitleIcon,
        enablePageTitleSubheading,
    }
) => {
    const {t, i18n} = useTranslation();

    let buttons = [];
    if (enablePageTitleCreationButton && headerButtonCreateUrl) {
        buttons.push(<Link to={headerButtonCreateUrl} key={1}>
            <Button className="btn-shadow mr-3" color="primary">
                <FontAwesomeIcon icon={faPlus}/>
                &nbsp;&nbsp;{t('layout.page_title.button_create', 'Create')}
            </Button>
        </Link>);
    }

    return <div className="app-page-title">
        <div className="page-title-wrapper">
            <div className="page-title-heading">
                <div className={cx("page-title-icon", {'d-none': !enablePageTitleIcon})}>
                    <i className={icon}/>
                </div>
                <div>
                    {heading}
                    <div className={cx("page-title-subheading", {'d-none': !enablePageTitleSubheading})}>
                        {subheading}
                    </div>
                </div>
            </div>
            <div className="page-title-actions">
                {buttons}
            </div>
        </div>
    </div>
}

const mapStateToProps = state => ({
    enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
    enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);