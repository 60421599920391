import React from "react";
import {connect} from "react-redux";
import get from "lodash/get";

import {
  SHOP_SOURCE_CHAT_API,
  SHOP_SOURCE_HIGHTOUCH,
  SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU,
  SHOP_SOURCE_RETAIL_CRM_WABA_RU,
} from "../../../constants";
import AppHeader from "../../../Layout/AppHeader";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import AppSidebar from "../../../Layout/AppSidebar";
import Statistic from "./Statistic";
import Landing from "./Landing";
import {useTranslation} from "react-i18next";


const AnalyticsDashboard = (
    {
        activeShop,
    }
) => {
    const {t, i18n} = useTranslation();
    const isHighTouch = get(activeShop, 'source') === SHOP_SOURCE_HIGHTOUCH;
    const isRetailCRM = (
        get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_WABA_RU
        || get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU
    );
    const isChatApi = get(activeShop, 'source') === SHOP_SOURCE_CHAT_API;
    
    return <>
        <AppHeader/>
        <div className="app-main">
            <AppSidebar/>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    {
                        isHighTouch && <PageTitle
                            heading={activeShop.trade_name}
                            icon="fa fas fa-rocket icon-gradient bg-mean-fruit"
                        />
                    }
                    
                    {
                        !isRetailCRM && !isChatApi && <Landing/>
                    }
                    
                    {
                        !isRetailCRM && <Statistic/>
                    }

                </div>
            </div>
        </div>
    </>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    bots: state.Bots.bots,
    billingConfig: state.Billing.billingConfig,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsDashboard);
