export const BOTS_REQUEST_START = 'CHANNELS/BOTS_REQUEST_START';
export const BOTS_REQUEST_SUCCESS = 'CHANNELS/BOTS_REQUEST_SUCCESS';
export const BOTS_REQUEST_FAIL = 'CHANNELS/BOTS_REQUEST_FAIL';

export const BOT_REQUEST_START = 'CHANNELS/BOT_REQUEST_START';
export const BOT_REQUEST_SUCCESS = 'CHANNELS/BOT_REQUEST_SUCCESS';
export const BOT_REQUEST_FAIL = 'CHANNELS/BOT_REQUEST_FAIL';

export const BOT_STATUS_REQUEST_SUCCESS = 'CHANNELS/BOT_STATUS_REQUEST_SUCCESS';
export const BOT_STATUS_REQUEST_FAIL = 'CHANNELS/BOT_STATUS_REQUEST_FAIL';

export const BOT_STATISTIC_REQUEST_SUCCESS = 'CHANNELS/BOT_STATISTIC_REQUEST_SUCCESS';
export const BOT_STATISTIC_REQUEST_FAIL = 'CHANNELS/BOT_STATISTIC_REQUEST_FAIL';

export const QR_CODE_REQUEST_SUCCESS = 'DASHBOARD/QR_CODE_REQUEST_SUCCESS';
export const QR_CODE_REQUEST_FAIL = 'DASHBOARD/QR_CODE_REQUEST_FAIL';

export const RESTART_REQUEST_START = 'DASHBOARD/RESTART_REQUEST_START';
export const RESTART_REQUEST_SUCCESS = 'DASHBOARD/RESTART_REQUEST_SUCCESS';
export const RESTART_REQUEST_FAIL = 'DASHBOARD/RESTART_REQUEST_FAIL';

export const STOP_REQUEST_START = 'DASHBOARD/STOP_REQUEST_START';
export const STOP_REQUEST_SUCCESS = 'DASHBOARD/STOP_REQUEST_SUCCESS';
export const STOP_REQUEST_FAIL = 'DASHBOARD/STOP_REQUEST_FAIL';

export const DELETE_REQUEST_START = 'DASHBOARD/DELETE_REQUEST_START';
export const DELETE_REQUEST_SUCCESS = 'DASHBOARD/DELETE_REQUEST_SUCCESS';
export const DELETE_REQUEST_FAIL = 'DASHBOARD/DELETE_REQUEST_FAIL';

export const WHATSAPP_LOGOUT_REQUEST_START = 'DASHBOARD/WHATSAPP_LOGOUT_REQUEST_START';
export const WHATSAPP_LOGOUT_REQUEST_SUCCESS = 'DASHBOARD/WHATSAPP_LOGOUT_REQUEST_SUCCESS';
export const WHATSAPP_LOGOUT_REQUEST_FAIL = 'DASHBOARD/WHATSAPP_LOGOUT_REQUEST_FAIL';

export const SYNC_BOT_USERS_REQUEST_START = 'DASHBOARD/SYNC_BOT_USERS_REQUEST_START';
export const SYNC_BOT_USERS_REQUEST_SUCCESS = 'DASHBOARD/SYNC_BOT_USERS_REQUEST_SUCCESS';
export const SYNC_BOT_USERS_REQUEST_FAIL = 'DASHBOARD/SYNC_BOT_USERS_REQUEST_FAIL';

export const BOTS_CLEAR_DATA = 'CHANNELS/BOTS_CLEAR_DATA';


export const botsRequestStart = () => ({
    type: BOTS_REQUEST_START,
});

export const botsRequestSuccess = (bots) => ({
    type: BOTS_REQUEST_SUCCESS,
    bots,
});

export const botsRequestFail = (errors) => ({
    type: BOTS_REQUEST_FAIL,
    errors,
});

export const botRequestStart = () => ({
    type: BOT_REQUEST_START,
});

export const botRequestSuccess = (bot) => ({
    type: BOT_REQUEST_SUCCESS,
    bot,
});

export const botRequestFail = (errors) => ({
    type: BOT_REQUEST_FAIL,
    errors,
});

export const botStatusRequestSuccess = (result) => ({
    type: BOT_STATUS_REQUEST_SUCCESS,
    result
});

export const botStatusRequestFail = (botId, errors) => ({
    type: BOT_STATUS_REQUEST_FAIL,
    botId,
    errors,
});

export const botStatisticRequestSuccess = (result) => ({
    type: BOT_STATISTIC_REQUEST_SUCCESS,
    result,
});

export const botStatisticRequestFail = (errors) => ({
    type: BOT_STATISTIC_REQUEST_FAIL,
    errors,
});

export const whatsappQRCodeRequestSuccess = (result) => ({
    type: QR_CODE_REQUEST_SUCCESS,
    result,
});

export const whatsappQRCodeRequestFail = (errors) => ({
    type: QR_CODE_REQUEST_FAIL,
    errors,
});


export const restartRequestStart = (bot_id, bot_type) => ({
    type: RESTART_REQUEST_START,
    bot_id,
    bot_type,
});

export const restartRequestSuccess = (result) => ({
    type: RESTART_REQUEST_SUCCESS,
    result,
});

export const restartRequestFail = (errors) => ({
    type: RESTART_REQUEST_FAIL,
    errors,
});


export const stopRequestStart = (bot_id, bot_type) => ({
    type: STOP_REQUEST_START,
    bot_id,
    bot_type,
});

export const stopRequestSuccess = (result) => ({
    type: STOP_REQUEST_SUCCESS,
    result,
});

export const stopRequestFail = (errors) => ({
    type: STOP_REQUEST_FAIL,
    errors,
});


export const deleteRequestStart = (bot_id, bot_type) => ({
    type: DELETE_REQUEST_START,
    bot_id,
    bot_type,
});

export const deleteRequestSuccess = (result) => ({
    type: DELETE_REQUEST_SUCCESS,
    result,
});

export const deleteRequestFail = (errors) => ({
    type: DELETE_REQUEST_FAIL,
    errors,
});


export const whatsappLogoutRequestStart = (bot_id) => ({
    type: WHATSAPP_LOGOUT_REQUEST_START,
    bot_id,
});

export const whatsappLogoutRequestSuccess = (result) => ({
    type: WHATSAPP_LOGOUT_REQUEST_SUCCESS,
    result,
});

export const whatsappLogoutRequestFail = (errors) => ({
    type: WHATSAPP_LOGOUT_REQUEST_FAIL,
    errors,
});

export const syncBotUsersRequestStart = (bot_id) => ({
    type: SYNC_BOT_USERS_REQUEST_START,
    bot_id,
});

export const syncBotUsersRequestSuccess = (result) => ({
    type: SYNC_BOT_USERS_REQUEST_SUCCESS,
    result,
});

export const syncBotUsersRequestFail = (errors) => ({
    type: SYNC_BOT_USERS_REQUEST_FAIL,
    errors,
});

export const botsClearData = (errors) => ({
    type: BOTS_CLEAR_DATA,
    errors,
});
