import React from "react";
import {FormGroup, Label} from "reactstrap";
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import Select from 'react-select'


export const colourStyles = {
    control: styles => ({...styles, backgroundColor: 'white'}),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        const isGroupDivider = get(data.value, 'isGroupDivider');

        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? '#353ed1'
                    : isFocused
                        ? '#eef1f3'
                        : null,
            borderTop: isGroupDivider ? '1px solid #e1e5ea' : styles.borderTop,
            fontSize: isGroupDivider ? '.7rem' : styles.fontSize,
            marginTop: isGroupDivider ? '.5rem' : styles.marginTop,
        };
    },
};


const SelectField = (
    {
        schema,
        formData,
        onChange,
        required,
        uiSchema,
    }
) => {
    const options = get(schema, 'enum', []).map(
        el => ({
            label: (typeof el === 'string')
                ? el
                : (!isEmpty(el)
                    ? el.name
                    : '-----'),
            value: el,
            isDisabled: get(el, 'isDisabled'),
        })
    );

    const isDisabled = get(uiSchema, 'ui:readonly');

    const defaultValue = isEmpty(formData) ? get(schema, 'default', undefined) : formData;
    let defaultOption = undefined;
    if (
        (!isEmpty(defaultValue) || defaultValue === null)
        && !isEmpty(schema.enum)
    ) {
        let i = -1;
        if (defaultValue === null) {
            i = findIndex(options, (el) => el.value === null);
        } else {
            // Пробуем сравнить по ID (если есть)
            i = defaultValue.id
                ? findIndex(options, (el) => isEqual(get(el, 'value.id'), defaultValue.id))
                : findIndex(options, (el) => isEqual(el.value, defaultValue));
        }

        if (i !== -1) {
            defaultOption = options[i];
        }

        // TODO: это костыль, но работает)) Спасает от проблемы пустого значения в данных формы вместо дефолта
        if (!isEqual(defaultValue, formData)) {
            setTimeout(
                () => onChange(defaultValue),
                200
            );
        }
    }

    const description = schema.description || uiSchema['ui:description'];

    return <FormGroup className={get(uiSchema, 'ui:className')}>
        <Label className='mt-3'>
            {schema.title}
            {(schema.title && required) ? ' *' : ''}
        </Label>
        {
            !isEmpty(description) && <small className="form-text text-muted mb-3">
                {description}
            </small>
        }

        <Select
            defaultValue={defaultOption}
            options={options}
            isDisabled={isDisabled}
            isClearable={false}
            isSearchable={true}
            name="color"
            onChange={(option, action) => {
                if (action.action === 'select-option') {
                    onChange(option.value);
                } else if (action.action === 'deselect-option') {
                    onChange(undefined);
                } else if (action.action === 'remove-value') {
                    onChange(undefined);
                }
            }}
            styles={colourStyles}
        />
    </FormGroup>
};

export default SelectField;
