import React from 'react';
import {connect} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// Auth

import {getQueryParams} from "../../../Components/utils";
import Preloader from "../../../Components/Preloader";
import {getMe} from "../../../thunks/Auth";
import {startInitiallyLoadingShops, shopsClearData} from "../../../actions/Shops";


const AuthTokenAuthorizationWrapper = (
    {
        children,
        activeShop,
        authToken,
        isAuthenticated,
        shopsAreInitiallyLoaded,
        startInitiallyLoadingShops,
        getMe,
    }
) => {
    const queryAuthToken = getQueryParams().authToken;
    if (!isEmpty(queryAuthToken) && (!isAuthenticated || authToken !== queryAuthToken)) {
        getMe(queryAuthToken);
        return (<Preloader/>)
    }
    if (!isAuthenticated) {
        if (shopsAreInitiallyLoaded || activeShop) {
            shopsClearData();
            return (<Preloader/>)
        }
    }
    if (isAuthenticated) {
        if (!shopsAreInitiallyLoaded || !activeShop) {
            startInitiallyLoadingShops();
            return (<Preloader/>)
        }
    }
    return children;
};

const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    authToken: state.Auth.authToken,
    isAuthenticated: state.Auth.isAuthenticated,
    shopsAreInitiallyLoaded: state.Shops.shopsAreInitiallyLoaded,
});

const mapDispatchToProps = dispatch => ({
    shopsClearData: () => dispatch(shopsClearData()),
    startInitiallyLoadingShops: () => dispatch(startInitiallyLoadingShops()),
    getMe: (authToken) => dispatch(getMe(authToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthTokenAuthorizationWrapper);
