import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
// import {Button, Col} from "reactstrap";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    // Link,
    Redirect,
    withRouter
} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import get from 'lodash/get';

import {renderErrorsAsHTML} from "../../../../Components/utils";
import {clearErrors} from "../../../../actions/Connect";
import {
  // CHARGEBEE_ADDON_WHATSAPP_WEB_INSTANCE,
  // CONTACT_SALES_REQUEST_TYPE_WA_TRIAL,
  CHANNEL_REGISTER_BOT_LINK,
  CHANNEL_REGISTER_WHATSAPP,
} from "../../../../constants";
import {chargebeeGenerateHostedPage, contactSalesRequest} from "../../../../thunks/Billing";
import { getRegisterChannelLink } from "../../../../helpers/links";


const WhatsApp = (
    {
        history,
        user,
        activeShop,
        billingConfig,
        errors,
        isSendingHostedPageRequest,
        isSendingContactSalesRequest,

        clearErrors,
        chargebeeGenerateHostedPage,
        contactSalesRequest,

        handlerClose
    }
) => {
    const {t, i18n} = useTranslation();

    const [redirectTo, setRedirectTo] = useState(undefined);

    // ComponentDidMount
    useEffect(() => {
        clearErrors();
    }, []);

    if (redirectTo) {
        return <Redirect to={redirectTo}/>
    }

    const tariff = get(billingConfig, 'tariff');
    const activeLang = i18n.languages[0];

    return <>
        <ModalHeader toggle={handlerClose}>
            {t('connect_card.wa.title', 'Connect WhatsApp')}
        </ModalHeader>

        <ModalBody>

        <Trans t={t} i18nKey='connect_card.wa.instruction_text'>
            <ol>
                <li>Для работы WhatsApp-канала вам потребуется смартфон с отдельным аккаунтом WhatsApp.</li>
                <li>Смартфон станет сервером, через который будет работать WhatsApp-бот</li>
                <li>Перед подключением аккаунта WhatsApp пожалуйста, удалите все чаты в аккаунте</li>
                <li>В случае отключения смартфона от сети интернет бот не будет получать сообщения и не будет отвечать на них</li>
            </ol>
        </Trans>
        <div className="text-center">
            {renderErrorsAsHTML(errors)}
        </div>
        </ModalBody>

        <ModalFooter className={'align-items-stretch'}>
            <Button color="additional" onClick={handlerClose}>
                {t('bot_card.common.button_cancel', 'Cancel')}
            </Button>
            <a
                className="btn btn-main"
                title={t('connect_card.wa.wa_trial_request_link_title', 'Request WhatsApp trial')}
                href={getRegisterChannelLink(CHANNEL_REGISTER_WHATSAPP)}
                target="_blank"
                onClick={() => {history.push(`/${activeShop.id}/channels/`)}}
            >
                <Trans t={t} i18nKey='connect_card.wa.wa_trial_request_button'>
                    Request free trial instance
                    <br/>
                    <small>(to start free 3-days trial)</small>
                </Trans>
            </a>
        </ModalFooter>
    </>
};


const mapStateToProps = state => ({
    user: state.Auth.user,
    activeShop: state.Shops.activeShop,
    errors: state.Connect.errors,
    billingConfig: state.Billing.billingConfig,
    isSendingHostedPageRequest: state.Billing.isSendingHostedPageRequest,
    isSendingContactSalesRequest: state.Billing.isSendingContactSalesRequest,
});

const mapDispatchToProps = dispatch => ({
    clearErrors: () => dispatch(clearErrors()),
    chargebeeGenerateHostedPage: (addon, callback) => dispatch(chargebeeGenerateHostedPage(addon, callback)),
    contactSalesRequest: (request_type, callback) => dispatch(contactSalesRequest(request_type, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WhatsApp));
