import React, {useState} from "react";
import {connect} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import {
    Card,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    ListGroup,
    ListGroupItem,
    UncontrolledDropdown
} from "reactstrap";
import get from 'lodash/get';

import waLogo from '../../../../assets/utils/images/messenger/wa.png';
import chatbotEmpty from '../../../../assets/utils/images/chatbot-empty.svg';
import {ADAPTER_STATUS_WAITING, BOT_STATUS_RUNNING,} from "../../../../constants";
import {extractDigits} from "../../../../Components/utils";
import {whatsappLogoutRequestStart} from "../../../../actions/Dashboard";
import {deleteBot, restartBot, stopBot, syncBotUsers} from "../../../../thunks/Dashboard";
import {getStatus, getTopLine} from "./utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStop} from "@fortawesome/free-solid-svg-icons";


const BotCardWhatsApp = (
    {
        bot,
        whatsappLogoutRequestStart,
        syncBotUsers,
        restartBot,
        stopBot,
        deleteBot,
    }
) => {
    const {t, i18n} = useTranslation();

    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [showSyncBotUsersAlert, setShowSyncBotUsersAlert] = useState(false);

    return <>
        <Card className="mb-3 profile-responsive bot-card">
            {getTopLine(bot)}

            <div className="position-relative">
                <UncontrolledDropdown>
                    <DropdownToggle color="light" className='bot-card__menu-toggle text-dark'>
                        <i className="pe-7s-menu"/>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem
                            onClick={
                                () => restartBot(
                                    bot.id,
                                    () => {
                                        window.location.reload()
                                    }
                                )
                            }
                        >
                            <i className="pe-7s-plug fsize-2 text-primary"/>&nbsp;
                            {t('bot_card.common.restart', "Restart")}
                        </DropdownItem>
                        <DropdownItem
                            onClick={() => whatsappLogoutRequestStart(bot.id)}
                        >
                            <i className="lnr-unlink fsize-1 text-primary"/>&nbsp;
                            {t('bot_card.whatsapp.disconnect_phone', "Disconnect phone")}
                        </DropdownItem>
                        {/*<DropdownItem*/}
                        {/*    onClick={() => setShowSyncBotUsersAlert(true)}*/}
                        {/*    disabled={bot.adapter_status !== ADAPTER_STATUS_RUNNING}*/}
                        {/*>*/}
                        {/*    <i className="lnr-sync fsize-1 text-primary"/>&nbsp;*/}
                        {/*    Синхронизировать пользователей*/}
                        {/*</DropdownItem>*/}
                        {/*<DropdownItem*/}
                        {/*    onClick={() => setShowDeleteAlert(true)}*/}
                        {/*>*/}
                        {/*    <i className="pe-7s-trash fsize-2 text-primary"/>&nbsp;*/}
                        {/*    Remove */}
                        {/*</DropdownItem>*/}
                        <DropdownItem
                            onClick={
                                () => stopBot(
                                    bot.id,
                                    () => {
                                        window.location.reload()
                                    }
                                )
                            }
                            disabled={bot.status !== BOT_STATUS_RUNNING}
                        >
                            <FontAwesomeIcon
                                icon={faStop}
                                className='text-primary'
                            />&nbsp;&nbsp;
                            {t('connect_bot.common.stop', 'Stop')}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>

                <div className="bot-card__status">
                    {getStatus(bot)}
                </div>

                {
                    bot.adapter_status === ADAPTER_STATUS_WAITING &&
                    <div className='bot-card__qr-code'>
                        <div className="whatsapp-image-wrapper overflow-hidden pt-4 text-center">
                            {bot.qr_code ? <img alt="Scan me!" src={bot.qr_code}/> : undefined}
                        </div>
                        <div className='px-3 py-4'>
                            <ol>
                                <Trans i18nKey='connect_bot.whatsapp.instruction'>
                                    <li className='mb-3'>
                                        Откройте WhatsApp на телефоне
                                    </li>
                                    <li className='mb-3'>
                                        Нажмите кнопку меню &nbsp;
                                        <svg height="24px" viewBox="0 0 24 24" width="24px">
                                            <rect fill="#f2f2f2" height="24" rx="3" width="24"></rect>
                                            <path
                                                d="m12 15.5c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5-1.5-.675-1.5-1.5.675-1.5 1.5-1.5zm0-2c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5zm0-5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5z"
                                                fill="#818b90"></path>
                                        </svg>
                                        &nbsp; или настройки &nbsp;
                                        <svg width="24" height="24" viewBox="0 0 24 24">
                                            <rect fill="#F2F2F2" width="24" height="24" rx="3"></rect>
                                            <path
                                                d="M9.34 8.694a4.164 4.164 0 0 0-1.606 3.289c0 1.338.63 2.528 1.61 3.292l-1.46 2.527a7.065 7.065 0 0 1-3.052-5.82c0-2.41 1.206-4.54 3.048-5.816l1.46 2.528zm6.713 2.859c-.217-2.079-1.992-3.739-4.148-3.739-.578 0-1.128.116-1.628.329L8.819 5.617a7.042 7.042 0 0 1 3.086-.704c3.76 0 6.834 2.958 7.059 6.64h-2.91zm-1.065.43a3.083 3.083 0 1 1-6.166 0 3.083 3.083 0 0 1 6.166 0zm-6.164 6.364l1.458-2.523a4.153 4.153 0 0 0 1.623.322 4.154 4.154 0 0 0 4.12-3.524h2.922a7.062 7.062 0 0 1-7.042 6.426c-1.105 0-2.15-.25-3.081-.7zm11.197-7.21a7.88 7.88 0 0 0-.404-1.824l.286-.12a.527.527 0 0 0-.403-.973l-.29.12a8.176 8.176 0 0 0-1.197-1.77l.231-.23a.526.526 0 1 0-.744-.744l-.234.234a8.17 8.17 0 0 0-1.775-1.18l.13-.31a.526.526 0 1 0-.972-.403l-.12.313a8.463 8.463 0 0 0-1.995-.405v-.35A.533.533 0 0 0 12 2.97a.533.533 0 0 0-.535.526v.338a8.02 8.02 0 0 0-2.173.416l-.13-.313a.526.526 0 0 0-.972.402l.129.311a8.171 8.171 0 0 0-1.775 1.18l-.235-.235a.526.526 0 0 0-.743.744l.23.231A8.175 8.175 0 0 0 4.6 8.34l-.29-.12a.526.526 0 0 0-.403.971l.285.122a7.882 7.882 0 0 0-.404 1.824h-.322a.533.533 0 0 0-.526.534c0 .29.235.535.526.535h.28c.02.831.166 1.624.418 2.378l-.257.1a.523.523 0 1 0 .402.968l.252-.105a8.17 8.17 0 0 0 1.191 1.797l-.187.187a.526.526 0 1 0 .744.743l.184-.183a8.173 8.173 0 0 0 1.792 1.208l-.096.231a.526.526 0 1 0 .972.403l.096-.23c.698.24 1.436.387 2.208.428v.243c0 .29.244.526.535.526.29 0 .534-.235.534-.526v-.253a8.012 8.012 0 0 0 2.03-.417l.09.229a.523.523 0 1 0 .967-.403l-.096-.231a8.172 8.172 0 0 0 1.792-1.208l.184.183a.526.526 0 1 0 .743-.744l-.187-.186a8.174 8.174 0 0 0 1.191-1.798l.252.104a.526.526 0 1 0 .403-.971l-.257-.095a8.074 8.074 0 0 0 .417-2.378h.281c.29 0 .526-.244.526-.535a.533.533 0 0 0-.526-.534h-.323z"
                                                fill="#818B90"></path>
                                        </svg>
                                        &nbsp; и выберите&nbsp;"WhatsApp&nbsp;Web"
                                    </li>
                                    <li className='mb-3'>
                                        Наведите Ваш телефон на этот экран, чтобы отсканировать код
                                    </li>
                                </Trans>
                            </ol>
                        </div>
                    </div>
                }

                <div className="dropdown-menu-header text-dark mt-0">
                    <div className="dropdown-menu-header-inner bg-white">
                        <div className="menu-header-content btn-pane-right d-block text-center">
                            <h5 className="d-flex align-items-center justify-content-center mb-3">
                                <img src={waLogo} alt="WhatsApp" style={{height: 30}} className='mr-2'/>
                                {bot.name}
                            </h5>

                            <div className="avatar-icon-wrapper mr-3 avatar-icon-xl btn-hover-shine">
                                <div className="avatar-icon rounded">
                                    <img src={bot.avatar_url || chatbotEmpty}/>
                                </div>
                            </div>

                            <h6>{bot.wa_phone_number}</h6>

                            <div>
                                <small>{t('bot_card.common.link_to_chat', "Link to chat")}:</small>
                                <br/>
                                <a
                                    href={`https://wa.me/${extractDigits(bot.wa_phone_number)}`}
                                    target='_blank'
                                >
                                    https://wa.me/{extractDigits(bot.wa_phone_number)}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <ListGroup flush>
                    <ListGroupItem>
                        <div className="widget-content px-0 pb-0 pt-1">
                            <div className="text-center">
                                <a
                                    href={`https://wa.me/${extractDigits(bot.wa_phone_number)}`}
                                    target='_blank'
                                    className='btn btn-main btn-lg'
                                >
                                      {t('bot_card.common.test_channel', 'Test channel')}
                                </a>
                            </div>
                        </div>
                    </ListGroupItem>
                </ListGroup>
                {/*{false && <ListGroup flush>*/}
                {/*    <ListGroupItem>*/}
                {/*        <div className="widget-content px-0 pb-0 pt-1">*/}
                {/*            <div className="text-center">*/}
                {/*            <span className="widget-heading opacity-4 mb-0">*/}
                {/*                {t('bot_card.common.users', 'Subscribers')}*/}
                {/*            </span>*/}
                {/*                <h6 className="mt-2 mb-3">*/}
                {/*            <span className="pr-2">*/}
                {/*                <b className="text-primary">{get(bot, 'statistic.count_bot_users_day')}</b> {t('bot_card.common.users_new_today', 'new for totay')},*/}
                {/*            </span>*/}
                {/*                    <span>*/}
                {/*                <b className="text-info">{get(bot, 'statistic.count_bot_users_all')}</b> {t('bot_card.common.users_total', 'total')}*/}
                {/*            </span>*/}
                {/*                </h6>*/}
                {/*            </div>*/}
                {/*            <div className="text-center widget-heading mb-0">*/}
                {/*        <span className="opacity-4">*/}
                {/*            {t('bot_card.common.leads_total', 'Leads')}:&nbsp;*/}
                {/*        </span>*/}
                {/*                {get(bot, 'statistic.leads_count_all')}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </ListGroupItem>*/}
                {/*</ListGroup>}*/}
            </div>
        </Card>

        {/*<SweetAlert*/}
        {/*    title="Синхронизировать пользователей?"*/}
        {/*    confirmButtonColor="#d92550"*/}
        {/*    show={showSyncBotUsersAlert}*/}
        {/*    text="Система загрузит список ВСЕХ пользователей из WhatsApp - аккаунта и напишет тем из них, чаты с которыми отсутствуют в системе."*/}
        {/*    type="warning"*/}
        {/*    showCancelButton*/}
        {/*    cancelButtonText={t('bot_card.common.button_cancel', 'Cancel')}*/}
        {/*    onConfirm={() => {*/}
        {/*        syncBotUsers(bot.id);*/}
        {/*        setShowSyncBotUsersAlert(false);*/}
        {/*    }}*/}
        {/*    onCancel={() => setShowSyncBotUsersAlert(false)}*/}
        {/*/>*/}

        {/*<SweetAlert*/}
        {/*    title="Remove number?"*/}
        {/*    confirmButtonColor="#d92550"*/}
        {/*    show={showDeleteAlert}*/}
        {/*    text={t('bot_card.common.alert_messages_will_be_removed', '⚠️ Messages history will be permanently removed!')}*/}
        {/*    type="warning"*/}
        {/*    showCancelButton*/}
        {/*    cancelButtonText={t('bot_card.common.button_cancel', 'Cancel')}*/}
        {/*    onConfirm={() => {*/}
        {/*        deleteBot(*/}
        {/*            bot.id,*/}
        {/*            () => {*/}
        {/*                window.location.reload()*/}
        {/*            }*/}
        {/*        );*/}
        {/*        setShowDeleteAlert(false);*/}
        {/*    }}*/}
        {/*    onCancel={() => setShowDeleteAlert(false)}*/}
        {/*/>*/}
    </>;
};


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    restartBot: (bot_id, callback) => dispatch(restartBot(bot_id, callback)),
    stopBot: (bot_id, callback) => dispatch(stopBot(bot_id, callback)),
    deleteBot: (bot_id, callback) => dispatch(deleteBot(bot_id, callback)),
    whatsappLogoutRequestStart: (bot_id) => dispatch(whatsappLogoutRequestStart(bot_id)),
    syncBotUsers: (bot_id) => dispatch(syncBotUsers(bot_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BotCardWhatsApp);
