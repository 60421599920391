import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {Button, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {useTranslation} from "react-i18next";
import get from 'lodash/get';
import {toast} from "react-toastify";
import i18next from "i18next";

import waLogo from "../../assets/utils/images/messenger/wa.png";
import {retailCRMSetURL, touchRegisterForm} from "../../actions/Auth";
import {getQueryParams, renderErrorsAsHTML} from '../../Components/utils'
import {retailCRMRegister, sendAuthHSM} from "../../thunks/Auth";
import {RETAIL_CRM_MESSENGER_TO_MODULE} from "../../constants";


const RetailCRM = (
    {
        match,
        retailCRMURL,
        errors,
        retailCRMSetURL,
        sendAuthHSM,
        touchRegisterForm,
        retailCRMRegister,
    }
) => {
    const {t, i18n} = useTranslation();

    // Params
    const {messenger} = useParams();
    const module_name = RETAIL_CRM_MESSENGER_TO_MODULE[messenger];
    
    // State
    const [accountURL, setAccountURL] = useState(retailCRMURL);
    const [apiKey, setApiKey] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [code, setCode] = useState(undefined);

    // --------------------------
    // Hooks
    // --------------------------
    // componentDidMount && componentDidUpdate
    useEffect(
        () => {
            touchRegisterForm();
            retailCRMSetURL(getQueryParams().account);
        },
        [phoneNumber]
    );

    // componentWillUnmount
    useEffect(() => {
        return () => setIsCodeSent(false);
    }, []);

    const sendVerifyCodeHSMRequest = (e) => {
        e.preventDefault();

        if (!isCodeSent) {
            sendAuthHSM(
                phoneNumber,
                i18n.languages[0],
                () => {
                    setIsCodeSent(true);
                },
            );
        } else {
            retailCRMRegister(
                module_name,
                phoneNumber,
                accountURL,
                apiKey,
                code,
                (result) => {
                    toast.success(i18next.t('auth.retailcrm.register.toast_success', 'Account created'));
                    setTimeout(
                        () => {
                            window.location.href = result.redirect_url;
                        },
                        1000
                    );
                }
            );
        }
    };

    // --------------------------
    // --------------------------
    // --------------------------

    return <div className="h-100">
        <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
                <div className="mx-auto mb-3 text-center">
                    <h4 className="mt-2">
                        {(messenger === 'whatsapp' && t('auth.retailcrm.register.title_whatsapp', 'Apply for WhatsApp Business API'))
                        || (messenger === 'instagram' && t('auth.retailcrm.register.title_instagram', 'Apply for Instagram'))
                        || t('auth.retailcrm.register.title_wrong_messenger', 'Wrong request')}
                    </h4>
                </div>
                {module_name && (
                    <div className="modal-dialog w-100">
                        <div className="modal-content">
                            <div className="modal-body py-5 px-4">
                                <Form onSubmit={sendVerifyCodeHSMRequest} autoComplete="on">
                                    <Row form>
                                        <Col md={12} style={{paddingLeft: 40}}>
                                            <FormGroup>
                                                <Label for="retailcrm_url">
                                                    <span className="text-danger">*</span>
                                                    {' '}{t('auth.retailcrm.register.account_url.label', 'RetailCRM account URL:')}
                                                </Label>
                                                <div className="d-flex align-items-center">
                                                    <Input id="retailcrm_url"
                                                           name="retailcrm_url"
                                                           required
                                                           placeholder='my_account.retailcrm.ru'
                                                           value={accountURL}
                                                           onChange={e => {
                                                               setAccountURL(e.target.value);
                                                           }}
                                                    />
                                                </div>
                                                <div>
                                                    {renderErrorsAsHTML(get(errors, 'retailcrm_url'))}
                                                </div>
                                            </FormGroup>
                                            <FormGroup className='mt-4'>
                                                <Label for="retailcrm_api_key">
                                                    <span className="text-danger">*</span>
                                                    {' '}{t('auth.retailcrm.register.api_key.label', 'RetailCRM apiKey:')}
                                                    <div className='opacity-6 mt-1'>
                                                        <small>
                                                            {!isCodeSent && t('auth.retailcrm.register.api_key.caption', 'API KEY should have following rights:')}
                                                            <ul className='my-0 pl-3'>
                                                                <li>{'/api/integration-modules/{code}'}</li>
                                                                <li>{'/api/integration-modules/{code}/edit'}</li>
                                                            </ul>
                                                        </small>
                                                    </div>
                                                </Label>
                                                <div className="d-flex align-items-center">
                                                    <Input id="retailcrm_api_key"
                                                           name="retailcrm_api_key"
                                                           placeholder={t('auth.retailcrm.register.api_key.placeholder', "API KEY")}
                                                           required
                                                           value={apiKey}
                                                           onChange={e => {
                                                               setApiKey(e.target.value);
                                                           }}
                                                           invalid={get(errors, 'retailcrm_api_key') !== undefined}
                                                           onClick={touchRegisterForm}/>
                                                </div>
                                                <div>
                                                    {renderErrorsAsHTML(get(errors, 'retailcrm_api_key'))}
                                                </div>
                                            </FormGroup>
                                            <FormGroup style={{marginLeft: -40}} className='mt-4'>
                                                <Label for="phone" style={{marginLeft: 40}}>
                                                    <span className="text-danger">*</span>
                                                    {' '}{t('auth.register.phone.label_phone', 'WhatsApp number:')}
                                                    <div className='opacity-6 mt-1'>
                                                        <small>
                                                            {!isCodeSent && t('auth.retailcrm.register.phone.caption', 'Type your WhatsApp number to Get in touch with our accounting team')}
                                                        </small>
                                                    </div>
                                                </Label>
                                                <div className="d-flex align-items-center">
                                                    <img src={waLogo} alt="WhatsApp" style={{height: 30}} className='mr-2'/>
                                                    <Input id="phone"
                                                           name="phone"
                                                           placeholder={t('auth.register.phone.placeholder_phone', "number with country code...")}
                                                           required
                                                           value={phoneNumber}
                                                           onChange={e => {
                                                               setPhoneNumber(e.target.value);
                                                           }}
                                                           invalid={get(errors, 'phone') !== undefined}
                                                           onClick={touchRegisterForm}/>
                                                </div>
                                                <div style={{marginLeft: 40}}>
                                                    {renderErrorsAsHTML(get(errors, 'phone'))}
                                                </div>
                                            </FormGroup>
                                            {
                                                isCodeSent && <>
                                                    <FormGroup className='mt-4'>
                                                        <Label for="code">
                                                            <span className="text-danger">*</span>
                                                            {' '}{t('auth.register.code.label', 'Verification code:')}
                                                        </Label>
                                                        <div className="d-flex align-items-center">
                                                            <Input id="code"
                                                                   name="code"
                                                                   type='number'
                                                                   placeholder={t('auth.register.code.placeholder', 'xxxxx')}
                                                                   required
                                                                   value={code}
                                                                   onChange={e => {
                                                                       setCode(e.target.value);
                                                                   }}
                                                                   invalid={get(errors, 'code') !== undefined}
                                                                   onClick={touchRegisterForm}/>
                                                        </div>
                                                        <div>
                                                            {renderErrorsAsHTML(get(errors, 'code'))}
                                                        </div>
                                                        <div className='mb-3'>
                                                            {t('auth.register.code.caption', '5-digits verification code has been sent to your WhatsApp number')}
                                                        </div>
                                                    </FormGroup>
                                                </>
                                            }
                                            <div className='text-danger mb-3'>
                                                {renderErrorsAsHTML(get(errors, 'common'))}
                                                {renderErrorsAsHTML(get(errors, 'detail'))}
                                            </div>
                                            {
                                                !isCodeSent && <Button color="primary"
                                                                       className="btn-wide btn-pill btn-shadow btn-hover-shine mt-4"
                                                                       size="lg">
                                                    {t('auth.retailcrm.register.phone.button_submit', 'Send code')}
                                                </Button>
                                            }
                                            {
                                                isCodeSent && <Button color="primary"
                                                                      className="btn-wide btn-pill btn-shadow btn-hover-shine mt-4"
                                                                      size="lg">
                                                    {(messenger === 'whatsapp' && t('auth.retailcrm.register.full.button_submit_whatsapp', 'Apply for WABA'))
                                                      || (messenger === 'instagram' && t('auth.retailcrm.register.full.button_submit_instagram', 'Apply for Instagram'))}
                                                </Button>
                                            }
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                )}
            </Col>
        </div>
    </div>;
};


const mapStateToProps = state => ({
    retailCRMURL: state.Auth.retailCRMURL,
    errors: state.Auth.register.errors,
});

const mapDispatchToProps = dispatch => ({
    retailCRMSetURL: (url) => dispatch(retailCRMSetURL(url)),
    sendAuthHSM: (phoneNumber, lang_code, callback) => dispatch(sendAuthHSM(phoneNumber, lang_code, callback)),
    retailCRMRegister: (module_name, phone, retailcrm_url, retailcrm_api_key, code, callback) => dispatch(retailCRMRegister(module_name, phone, retailcrm_url, retailcrm_api_key, code, callback)),
    touchRegisterForm: () => dispatch(touchRegisterForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RetailCRM);
