export const API_TABLE_CONFIG_REQUEST_START = 'API_FORM/API_TABLE_CONFIG_REQUEST_START';
export const API_TABLE_CONFIG_REQUEST_SUCCESS = 'API_FORM/API_TABLE_CONFIG_REQUEST_SUCCESS';
export const API_TABLE_CONFIG_REQUEST_FAIL = 'API_FORM/API_TABLE_CONFIG_REQUEST_FAIL';

export const API_TABLE_DATA_REQUEST_START = 'API_TABLE/API_TABLE_DATA_REQUEST_START';
export const API_TABLE_DATA_REQUEST_SUCCESS = 'API_TABLE/API_TABLE_DATA_REQUEST_SUCCESS';
export const API_TABLE_DATA_REQUEST_FAIL = 'API_TABLE/API_TABLE_DATA_REQUEST_FAIL';

export const API_TABLE_EXPORT_REQUEST_START = 'API_TABLE/API_TABLE_EXPORT_REQUEST_START';
export const API_TABLE_EXPORT_REQUEST_SUCCESS = 'API_TABLE/API_TABLE_EXPORT_REQUEST_SUCCESS';
export const API_TABLE_EXPORT_REQUEST_FAIL = 'API_TABLE/API_TABLE_EXPORT_REQUEST_FAIL';

export const API_TABLE_SET_FILTERS = 'API_FORM/API_TABLE_SET_FILTERS';
export const API_TABLE_SET_CONFIG = 'API_FORM/API_TABLE_SET_CONFIG';
export const API_TABLE_SET_DATA = 'API_FORM/API_TABLE_SET_DATA';
export const API_TABLE_SET_SEARCH = 'API_FORM/API_TABLE_SET_SEARCH';

export const API_FORM_SCHEMA_REQUEST_START = 'API_FORM/API_FORM_SCHEMA_REQUEST_START';
export const API_FORM_SCHEMA_REQUEST_SUCCESS = 'API_FORM/API_FORM_SCHEMA_REQUEST_SUCCESS';
export const API_FORM_SCHEMA_REQUEST_FAIL = 'API_FORM/API_FORM_SCHEMA_REQUEST_FAIL';

export const API_FORM_DATA_REQUEST_START = 'API_FORM/API_FORM_DATA_REQUEST_START';
export const API_FORM_DATA_REQUEST_SUCCESS = 'API_FORM/API_FORM_DATA_REQUEST_SUCCESS';
export const API_FORM_DATA_REQUEST_FAIL = 'API_FORM/API_FORM_DATA_REQUEST_FAIL';

export const API_FORM_SUBMIT_REQUEST_START = 'API_FORM/API_FORM_SUBMIT_REQUEST_START';
export const API_FORM_SUBMIT_REQUEST_SUCCESS = 'API_FORM/API_FORM_SUBMIT_REQUEST_SUCCESS';
export const API_FORM_SUBMIT_REQUEST_FAIL = 'API_FORM/API_FORM_SUBMIT_REQUEST_FAIL';

export const API_FORM_DELETE_SWEETALERT_SHOW = 'API_FORM/API_FORM_DELETE_SWEETALERT_SHOW';
export const API_FORM_DELETE_SWEETALERT_HIDE = 'API_FORM/API_FORM_DELETE_SWEETALERT_HIDE';
export const API_FORM_DELETE_REQUEST_START = 'API_FORM/API_FORM_DELETE_REQUEST_START';
export const API_FORM_DELETE_REQUEST_SUCCESS = 'API_FORM/API_FORM_DELETE_REQUEST_SUCCESS';
export const API_FORM_DELETE_REQUEST_FAIL = 'API_FORM/API_FORM_DELETE_REQUEST_FAIL';

export const API_FORM_SET_LIVE_VALIDATE = 'API_FORM/API_FORM_SET_LIVE_VALIDATE';
export const API_FORM_SET_FORM_SCHEMA = 'API_FORM/API_FORM_SET_FORM_SCHEMA';
export const API_FORM_SET_FORM_DATA = 'API_FORM/API_FORM_SET_FORM_DATA';


export const apiTableConfigRequestStart = (apiPath, cancelTokenSource) => ({
    type: API_TABLE_CONFIG_REQUEST_START,
    apiPath,
    cancelTokenSource,
});

export const apiTableConfigRequestSuccess = (result) => ({
    type: API_TABLE_CONFIG_REQUEST_SUCCESS,
    result,
});

export const apiTableConfigRequestFail = (errors) => ({
    type: API_TABLE_CONFIG_REQUEST_FAIL,
    errors,
});


export const apiTableDataRequestStart = (apiPath, clearOldData, cancelTokenSource) => ({
    type: API_TABLE_DATA_REQUEST_START,
    apiPath,
    clearOldData,
    cancelTokenSource,
});

export const apiTableDataRequestSuccess = (results, count) => ({
    type: API_TABLE_DATA_REQUEST_SUCCESS,
    results,
    count,
});

export const apiTableDataRequestFail = (errors) => ({
    type: API_TABLE_DATA_REQUEST_FAIL,
    errors,
});


export const apiTableExportRequestStart = (apiPath, clearOldData, cancelTokenSource) => ({
    type: API_TABLE_EXPORT_REQUEST_START,
    apiPath,
    clearOldData,
    cancelTokenSource,
});

export const apiTableExportRequestSuccess = (results, count) => ({
    type: API_TABLE_EXPORT_REQUEST_SUCCESS,
    results,
    count,
});

export const apiTableExportRequestFail = (errors) => ({
    type: API_TABLE_EXPORT_REQUEST_FAIL,
    errors,
});


export const apiTableSetConfig = (config) => ({
    type: API_TABLE_SET_CONFIG,
    config,
});

export const apiTableSetFilters = (filters) => ({
    type: API_TABLE_SET_FILTERS,
    filters,
});

export const apiTableSetData = (data, totalRows) => ({
    type: API_TABLE_SET_DATA,
    data,
    totalRows,
});

export const apiTableSetSearch = (search) => ({
    type: API_TABLE_SET_SEARCH,
    search,
});


export const apiFormSchemaRequestStart = (apiPath) => ({
    type: API_FORM_SCHEMA_REQUEST_START,
    apiPath,
});

export const apiFormSchemaRequestSuccess = (result) => ({
    type: API_FORM_SCHEMA_REQUEST_SUCCESS,
    result,
});

export const apiFormSchemaRequestFail = (errors) => ({
    type: API_FORM_SCHEMA_REQUEST_FAIL,
    errors,
});

export const apiFormDataRequestStart = (apiPath) => ({
    type: API_FORM_DATA_REQUEST_START,
    apiPath,
});

export const apiFormDataRequestSuccess = (result) => ({
    type: API_FORM_DATA_REQUEST_SUCCESS,
    result,
});

export const apiFormDataRequestFail = (errors) => ({
    type: API_FORM_DATA_REQUEST_FAIL,
    errors,
});


export const apiFormSubmitRequestStart = (apiPath, method, formData, redirectUrl, callback) => ({
    type: API_FORM_SUBMIT_REQUEST_START,
    apiPath,
    method,
    formData,
    redirectUrl,
    callback,
});

export const apiFormSubmitRequestSuccess = (result) => ({
    type: API_FORM_SUBMIT_REQUEST_SUCCESS,
    result,
});

export const apiFormSubmitRequestFail = (errors) => ({
    type: API_FORM_SUBMIT_REQUEST_FAIL,
    errors,
});

export const apiFormSetLiveValidate = (liveValidate) => ({
    type: API_FORM_SET_LIVE_VALIDATE,
    liveValidate,
});

export const apiFormSetFormSchema = (formSchema, formUISchema) => ({
    type: API_FORM_SET_FORM_SCHEMA,
    formSchema,
    formUISchema,
});

export const apiFormSetFormData = (formData) => ({
    type: API_FORM_SET_FORM_DATA,
    formData,
});

export const apiFormDeleteSweetalertShow = (idToRemove) => ({
    type: API_FORM_DELETE_SWEETALERT_SHOW,
    idToRemove: idToRemove,
});

export const apiFormDeleteSweetalertHide = () => ({
    type: API_FORM_DELETE_SWEETALERT_HIDE,
});

export const apiFormDeleteRequestStart = (apiPath, redirectUrl, callback) => ({
    type: API_FORM_DELETE_REQUEST_START,
    apiPath,
    redirectUrl,
    callback,
});

export const apiFormDeleteRequestSuccess = () => ({
    type: API_FORM_DELETE_REQUEST_SUCCESS,
});

export const apiFormDeleteRequestFail = (errors) => ({
    type: API_FORM_DELETE_REQUEST_FAIL,
    errors,
});

