import React from "react";
import {connect} from "react-redux";
import {Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import get from "lodash/get";


// const CONNECTION_MECHANISM_API_KEY = 1
const CONNECTION_MECHANISM_OAUTH2_AUTH_CODE_ONLY = 2
const CONNECTION_MECHANISM_OAUTH2_AUTH_CODE_REFRESH_TOKEN = 3
// const CONNECTION_MECHANISM_OAUTH2_RESOURSE_OWNER_CREDENTIALS = 4
// const CONNECTION_MECHANISM_OAUTH2_CLIENT_CREDENTIALS = 5
// const CONNECTION_MECHANISM_SESSION_AUTH = 6
// const CONNECTION_MECHANISM_BASIC_AUTH = 7
// const CONNECTION_MECHANISM_DIGEST_AUTH = 8


const PluginCard = (
    {
        activeShop,
        plugin,
    }
) => {
    const {t, i18n} = useTranslation();

    const pluginName = get(plugin, 'name');
    const pluginImage = get(plugin, 'image_url');
    const pluginDescription = get(plugin, 'description');
    const connection_mechanism = get(plugin, 'connection_type.mechanism');

    return <div className='card p-3'>
        <h5 className="d-flex align-items-center justify-content-center my-0" style={{height: 80}}>
            <img src={pluginImage} alt={pluginName} style={{width: 140}} className='mr-2 py-3'/>
        </h5>
        <h6 className='opacity-6 my-4'>
            {pluginDescription}
        </h6>
        <div className="menu-header-btn-pane">
            {
                (
                    connection_mechanism === CONNECTION_MECHANISM_OAUTH2_AUTH_CODE_REFRESH_TOKEN
                    || connection_mechanism === CONNECTION_MECHANISM_OAUTH2_AUTH_CODE_ONLY
                ) &&
                <a href={get(plugin, 'connection_type.authorize_url')} target='_blank'>
                    <Button color="primary">
                        {t('settings.integrations.common.button_setup', 'Connect')}
                    </Button>
                </a>
            }
            {
                connection_mechanism === undefined && <div className="menu-header-btn-pane">
                    <Button color="primary" disabled>
                        {t('settings.integrations.common.button_setup_trough_support', 'Connect by support request')}
                    </Button>
                </div>
            }
        </div>
    </div>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PluginCard);
