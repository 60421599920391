import React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import {withTranslation} from "react-i18next";


class CombinedNodeWidget_ extends AbstractNodeWidget {
    constructor(props) {
        super(props);

        this.type = 'combined-node';
    }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <i className="pe-7s-chat"> </i>{options.step.name || this.props.t('pages.main.flow_builder.node.combined.name', 'Text message')}
        </>;
    }
}

export const CombinedNodeWidget = withTranslation()(CombinedNodeWidget_);

export class CombinedNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'combined-node',
        });
    }
}


export class CombinedNodeFactory extends AbstractReactFactory {
    constructor() {
        super('combined-node');
    }

    generateModel(event) {
        return new CombinedNodeModel();
    }

    generateReactWidget(event) {
        return <CombinedNodeWidget engine={this.engine} node={event.model}/>;
    }
}
