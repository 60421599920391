import {ofType} from 'redux-observable'
import {catchError, filter, map, mergeMap, switchMap} from 'rxjs/operators'
import {concat, of, from} from "rxjs";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import i18next from 'i18next';

import {store} from "../configureStore";
import {
    LOG_IN_REQUEST_SUCCESS,
    LOGOUT_REQUEST_SUCCESS,
    logoutRequestSuccess,
} from '../actions/Auth'
import {
    loadActiveShopAdministratorsRequestFail,
    loadActiveShopAdministratorsRequestSuccess,
    START_INITIALLY_LOADING_SHOPS,
    SHOPS_REQUEST_START,
    SHOPS_REQUEST_SUCCESS,
    SHOP_BY_ID_REQUEST_START,
    SHOP_BY_ID_REQUEST_SUCCESS,
    SHOPS_SELECT_SHOP,
    shopsRequestFail,
    shopsClearData,
    shopsRequestStart,
    shopsRequestSuccess,
    shopsSelectShop,
    updateCurrentShopAdministrator,
    shopByIdRequestStart,
    shopByIdRequestSuccess,
    shopByIdRequestFail,
} from "../actions/Shops";
import {eventsLogEvent} from "../Components/utils";
import {EVENT_ADMIN_OPEN_APP} from "../constants";
import {apiAxiosInstance} from "../helpers/api";


export const startInitiallyLoadingShopsEpic = (action$, state$) =>
    action$.pipe(
        ofType(START_INITIALLY_LOADING_SHOPS, LOG_IN_REQUEST_SUCCESS),
        filter(() => !state$.value.Shops.shopsAreInitiallyLoaded),
        map(
            action => {
                const path = store.getState().router.location.pathname;
                const pathShop = path.match(/^\/(\d+)\//);
                const pathShopId = pathShop ? parseInt(pathShop[1]) : undefined;

                if (pathShopId) {
                    return shopByIdRequestStart(pathShopId);
                } else {
                    return shopsRequestStart();
                }
            }
        )
    );

export const shopByIdRequestStartEpic = (action$, state$) =>
    action$.pipe(
        ofType(SHOP_BY_ID_REQUEST_START),
        switchMap(action => from(apiAxiosInstance.get(`shop/shops/${action.id}/`))
            .pipe(
                map(response => {
                    if (response.data.status === 'ok') {
                        return shopByIdRequestSuccess(response.data.result);
                    } else {
                        return shopByIdRequestFail(response.data.errors);
                    }
                }),
                catchError(error => {
                    return of(shopByIdRequestFail({
                        'common': i18next.t('errors.common.request_sent', 'Error sending request'),
                    }));
                }),
            )
        ),
    );

export const shopsRequestStartEpic = (action$, state$) =>
    action$.pipe(
        ofType(SHOPS_REQUEST_START),
        switchMap(action => from(apiAxiosInstance.get(`shop/shops/?limit=${100}&search=${action.search || ""}`))
            .pipe(
                map(response => {
                    if (response.data.status === 'ok') {
                        if (!action.search && isEmpty(response.data.result.results)) {
                            return logoutRequestSuccess();
                        }
                        return shopsRequestSuccess(response.data.result.results);
                    } else {
                        return shopsRequestFail(response.data.errors);
                    }
                }),
                catchError(error => {
                    return of(shopsRequestFail({
                        'common': i18next.t('errors.common.request_sent', 'Error sending request'),
                    }));
                }),
            )
        ),
    );

export const shopByIdRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(SHOP_BY_ID_REQUEST_SUCCESS),
        filter(() => state$.value.Shops.activeShop === undefined),
        map(action => {
            return shopsSelectShop(action.shop);
        })
    );

export const shopsRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(SHOPS_REQUEST_SUCCESS),
        filter(() => state$.value.Shops.activeShop === undefined),
        map(action => {
            const path = store.getState().router.location.pathname;
            const pathShop = path.match(/^\/(\d+)\//);
            const pathShopId = pathShop ? parseInt(pathShop[1]) : undefined;
            const selectedShop = find(action.shops, {id: pathShopId}) || action.shops[0];

            return shopsSelectShop(selectedShop);
        })
    );

export const shopsClearDataEpic = (action$, state$) =>
    action$.pipe(
        ofType(LOGOUT_REQUEST_SUCCESS),
        map(shopsClearData)
    );


export const loadShopAdministratorEpic = (action$, state$) =>
    action$.pipe(
        ofType(SHOPS_SELECT_SHOP),
        switchMap(
            action => from(apiAxiosInstance.get(`shop/shop_administrators/?shop_id=${action.shop.id}&is_active=true`))
                .pipe(
                    mergeMap(response => {
                        const currentShopAdministrator = find(
                            response.data.result.results,
                            ['profile.user.id', state$.value.Auth.user.id]
                        );
    
                        eventsLogEvent(EVENT_ADMIN_OPEN_APP, {});
    
                        return concat(
                            of(loadActiveShopAdministratorsRequestSuccess(response.data.result.results)),
                            of(updateCurrentShopAdministrator(currentShopAdministrator)),
                        );
                    }),
                catchError(error => {
                    return of(loadActiveShopAdministratorsRequestFail({
                        'common': i18next.t('errors.common.request_sent', 'Error sending request')
                    }));
                }),
            )
        ),
    );
