import React, {useState} from "react";
import {Button, Card, CardFooter, Col, FormGroup, FormText, Input, Row} from "reactstrap";
import {useTranslation} from "react-i18next";

import imageUploading from '../../../assets/utils/images/uploading.gif';
import {uploadToCdnAxiosInstance} from "../../../helpers/api";
import {TIMEOUT_FILE_UPLOAD_TO_CDN} from "../../../constants";


const VideoWidget = (props) => {
    const {t, i18n} = useTranslation();

    let [isUploading, setIsUploading] = useState(false);
    let [uploadFailed, setUploadFailed] = useState(false);

    let button = props.value ? <Col xs={4} md={8} className='text-right text-sm-left'>
        <Button
            onClick={() => {
                props.onChange(null)
            }}
        >
            {t('connect_bot.common.remove', 'Remove')}
        </Button>
    </Col> : '';

    const videoSrc = (isUploading || uploadFailed) ? undefined : props.value;
    const videoCls = (isUploading || uploadFailed) ? undefined : 'icon-doc-generic';

    return <FormGroup className='mb-4'>
        <Card>
            {
                isUploading && <img src={imageUploading} style={{width: 70}}/>
            }
            {
                videoSrc && !isUploading && <a
                    href={videoSrc}
                    target='_blank'
                    className='d-flex align-items-center justify-content-between py-2 card-body'>
                    {
                        !isUploading && <div
                            className={`${videoCls} bg-size-cover bg-center m-1 mr-3`}
                            style={{height: 30, width: 26}}
                        />
                    }
                    {
                        !isUploading && videoSrc
                    }
                </a>
            }
            <CardFooter className='py-2 pt-3 flex-column'>
                <Row style={{width: 'calc(100% + 30px)'}}>
                    <Col xs={button ? 8 : 12} md={button ? 4 : 12}>
                        <Input type="file"
                               accept="video/*"
                               required={props.required}
                               onChange={(event) => {
                                   setIsUploading(true);

                                   const video = event.target.files[0];
                                   let formData = new FormData();
                                   formData.append('file', video);

                                   uploadToCdnAxiosInstance.post(`${video.name}/`, formData, {timeout: TIMEOUT_FILE_UPLOAD_TO_CDN * 10}).then(
                                       (request) => {
                                           props.onChange(request.data.result.cdn_filepath);
                                           setIsUploading(false);
                                           setUploadFailed(false);
                                       },
                                       error => {
                                           props.onChange(null);
                                           setIsUploading(false);
                                           setUploadFailed(true);
                                       },
                                   );
                               }}
                        />
                    </Col>
                    {button}
                </Row>
                <Row className='mt-2'>
                    <Col xs={12}>
                        <FormText color="muted">
                            {t('components.api_form.video_widget.hint.separate_message_and_formats', 'Video will be sent as a separate message. If text or image is specified, it will be sent first as a separate message. Supported video formats: mp4, 3gp.')}
                        </FormText>
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    </FormGroup>
};

export default VideoWidget;
