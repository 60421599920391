import {
    ASK_PASSWORD_RESET_REQUEST_START,
    ASK_PASSWORD_RESET_REQUEST_SUCCESS,
    ASK_PASSWORD_RESET_REQUEST_FAIL,

    SET_NEW_PASSWORD_REQUEST_START,
    SET_NEW_PASSWORD_REQUEST_SUCCESS,
    SET_NEW_PASSWORD_REQUEST_FAIL,

    LOG_IN_REQUEST_START,
    LOG_IN_REQUEST_SUCCESS,
    LOG_IN_REQUEST_FAIL,

    QUICK_LOG_IN_REQUEST_START,
    QUICK_LOG_IN_REQUEST_SUCCESS,
    QUICK_LOG_IN_REQUEST_FAIL,

    TOUCH_LOGIN_FORM,
    TOUCH_PASSWORD_RESET_FORM,
    TOUCH_REGISTER_FORM,
    RESET_REGISTER_FORM,
    
    SET_REGISTER_REFERRAL_CODE,
    SET_PASSWORD_RESET_FORM_ERRORS,
    SET_REGISTER_FORM_ERRORS,
    REGISTER_CLOSE_NOTIFICATION_POPUP,

    LOGOUT_REQUEST_SUCCESS,

    SEND_AUTH_HSM_REQUEST_START,
    SEND_AUTH_HSM_REQUEST_SUCCESS,
    SEND_AUTH_HSM_REQUEST_FAIL,

    SEND_CODE_REQUEST_START,
    SEND_CODE_REQUEST_SUCCESS,
    SEND_CODE_REQUEST_FAIL,

    VERIFY_CODE_REQUEST_START,
    VERIFY_CODE_REQUEST_SUCCESS,
    VERIFY_CODE_REQUEST_FAIL,

    REGISTER_REQUEST_START,
    REGISTER_REQUEST_SUCCESS,
    REGISTER_REQUEST_FAIL,

    GET_ME_REQUEST_START,
    GET_ME_REQUEST_SUCCESS,
    GET_ME_REQUEST_FAIL,

    RETAILCRM_SET_URL,

    RETAILCRM_REGISTER_REQUEST_START,
    RETAILCRM_REGISTER_REQUEST_SUCCESS,
    RETAILCRM_REGISTER_REQUEST_FAIL,
} from '../actions/Auth'
import {SHOPS_REQUEST_FAIL} from "../actions/Shops";


export default function reducer(state = {
    authToken: undefined,
    isAuthenticated: false,
    user: undefined,
    login: {
        quickLoginStatus: undefined,
        errors: {},
    },
    passwordReset: {
        isSendingRequest: false,
        isRequestSent: false,
        errors: {},
    },
    register: {
        validationEmailNotification: false,
        phone_number: undefined,
        code: undefined,
        referral_code: undefined,
        errors: {},
    },
    retailCRMURL: undefined,
}, action) {
    switch (action.type) {
        case TOUCH_LOGIN_FORM:
            return {
                ...state,
                login: {
                    ...state.login,
                    quickLoginStatus: undefined,
                    errors: {},
                }
            };

        case LOG_IN_REQUEST_START:
            return {
                ...state,
                login: {
                    ...state.login,
                    errors: {},
                }
            };

        case LOG_IN_REQUEST_SUCCESS:
            return {
                ...state,
                authToken: action.token,
                isAuthenticated: true,
                user: action.user,
                login: {
                    ...state.login,
                    quickLoginStatus: undefined,
                }

            };

        case LOG_IN_REQUEST_FAIL:
            return {
                ...state,
                authToken: undefined,
                isAuthenticated: false,
                user: undefined,
                login: {
                    ...state.login,
                    errors: action.errors
                }
            };

        case QUICK_LOG_IN_REQUEST_START:
            return {
                ...state,
                login: {
                    ...state.login,
                    errors: {},
                }
            };

        case QUICK_LOG_IN_REQUEST_SUCCESS:
            return {
                ...state,
                login: {
                    ...state.login,
                    quickLoginStatus: action.authCode
                }
            };

        case QUICK_LOG_IN_REQUEST_FAIL:
            return {
                ...state,
                authToken: undefined,
                isAuthenticated: false,
                user: undefined,
                login: {
                    ...state.login,
                    quickLoginStatus: undefined,
                    errors: action.errors
                }
            };

        case TOUCH_PASSWORD_RESET_FORM:
            return {
                ...state,
                passwordReset: {
                    ...state.passwordReset,
                    errors: {},
                    isSendingRequest: false,
                    isRequestSent: false,
                }
            };

        case SET_PASSWORD_RESET_FORM_ERRORS:
            return {
                ...state,
                passwordReset: {
                    ...state.passwordReset,
                    errors: action.errors,
                }
            };

        case ASK_PASSWORD_RESET_REQUEST_START:
        case SET_NEW_PASSWORD_REQUEST_START:
            return {
                ...state,
                passwordReset: {
                    ...state.passwordReset,
                    errors: {},
                    isSendingRequest: true,
                    isRequestSent: false,
                }
            };

        case ASK_PASSWORD_RESET_REQUEST_SUCCESS:
        case SET_NEW_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                passwordReset: {
                    ...state.passwordReset,
                    errors: {},
                    isSendingRequest: false,
                    isRequestSent: true,
                }
            };

        case ASK_PASSWORD_RESET_REQUEST_FAIL:
        case SET_NEW_PASSWORD_REQUEST_FAIL:
            return {
                ...state,
                passwordReset: {
                    ...state.passwordReset,
                    errors: action.errors,
                    isSendingRequest: false,
                    isRequestSent: false,
                }
            };

        case LOGOUT_REQUEST_SUCCESS:
        case SHOPS_REQUEST_FAIL:
            return {
                ...state,
                authToken: undefined,
                user: undefined,
                isAuthenticated: false,
            };

        case TOUCH_REGISTER_FORM:
            return {
                ...state,
                register: {
                    ...state.register,
                    errors: {},
                }
            };

        case RESET_REGISTER_FORM:
            return {
                ...state,
                register: {
                    ...state.register,
                    validationEmailNotification: false,
                    phone_number: undefined,
                    code: undefined,
                    errors: {},
                }
            };

        case SET_REGISTER_REFERRAL_CODE:
            return {
                ...state,
                register: {
                    ...state.register,
                    referral_code: action.referral_code,
                }
            };
            
        case SEND_CODE_REQUEST_START:
            return {
                ...state,
                register: {
                    ...state.register,
                    phone_number: action.phone_number,
                    errors: {},
                }
            };

        case SEND_CODE_REQUEST_SUCCESS:
            return {
                ...state,
                register: {
                    ...state.register,
                    code: undefined,
                }
            };

        case SEND_AUTH_HSM_REQUEST_START:
            return {
                ...state,
                register: {
                    ...state.register,
                    phone_number: action.phone_number,
                    errors: {},
                }
            };

        case SEND_AUTH_HSM_REQUEST_SUCCESS:
            return {
                ...state,
                register: {
                    ...state.register,
                    code: undefined,
                }
            };

        case VERIFY_CODE_REQUEST_START:
            return {
                ...state,
                register: {
                    ...state.register,
                    phone_number: action.phone_number,
                    errors: {},
                }
            };

        case VERIFY_CODE_REQUEST_SUCCESS:
            return {
                ...state,
                register: {
                    ...state.register,
                    code: action.code,
                    errors: {},
                }
            };

        case SET_REGISTER_FORM_ERRORS:
        case SEND_CODE_REQUEST_FAIL:
        case VERIFY_CODE_REQUEST_FAIL:
        case SEND_AUTH_HSM_REQUEST_FAIL:
            return {
                ...state,
                register: {
                    ...state.register,
                    errors: action.errors,
                }
            };

        case REGISTER_REQUEST_START:
            return {
                ...state,
                register: {
                    ...state.register,
                    errors: {},
                }
            };

        case REGISTER_REQUEST_SUCCESS:
            return {
                ...state,
                register: {
                    ...state.register,
                    validationEmailNotification: action.showEmailNotification,
                    phone_number: undefined,
                    code: undefined,
                    referral_code: undefined,
                },
            };

        case REGISTER_REQUEST_FAIL:
        case RETAILCRM_REGISTER_REQUEST_FAIL:
            return {
                ...state,
                register: {
                    ...state.register,
                    errors: action.errors
                }
            };

        case RETAILCRM_REGISTER_REQUEST_START:
            return {
                ...state,
                register: {
                    ...state.register,
                    errors: {},
                }
            };

        case RETAILCRM_REGISTER_REQUEST_SUCCESS:
            return {
                ...state,
                register: {
                    ...state.register,
                    validationEmailNotification: false,
                    phone_number: undefined,
                    code: undefined,
                },
            };

        case REGISTER_CLOSE_NOTIFICATION_POPUP:
            return {
                ...state,
                register: {
                    ...state.register,
                    validationEmailNotification: false,
                }
            }

        case GET_ME_REQUEST_START:
            return {
                authToken: action.authToken,
                ...state,
            };

        case GET_ME_REQUEST_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                authToken: action.authToken,
                user: action.user,
                login: {
                    ...state.login,
                    quickLoginStatus: undefined,
                }
            };

        case GET_ME_REQUEST_FAIL:
            return {
                ...state,
                authToken: undefined,
                isAuthenticated: false,
                user: undefined,
                login: {
                    ...state.login,
                    errors: action.errors,
                }
            };

        case RETAILCRM_SET_URL:
            return {
                ...state,
                retailCRMURL: action.url,
            };
    }
    return state;
}
