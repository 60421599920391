import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Moment from "react-moment";
import 'moment/locale/ru';
import PerfectScrollbar from "react-perfect-scrollbar";
import {Button, Form, Input, Nav, NavItem,} from "reactstrap";
import {withTranslation} from "react-i18next";
import isEmpty from "lodash/isEmpty";
import assign from "lodash/assign";
import get from "lodash/get";
import isArray from "lodash/isArray";
import clone from "lodash/clone";
import isEqual from "lodash/isEqual";
import maxBy from "lodash/maxBy";

import {
    clearChatFiltersKey,
    mergeChats,
    setActiveChat,
    setAllOldChatsLoaded,
    setChatFilters,
    setChats,
    setChatsVisibleLimit,
    updateChatFilters,
} from "../../../actions/Chats";
import {LOGO_BY_TYPE} from '../../../Components/utils';
import {loadChats} from "../../../thunks/Chats";
import {isMobileDevice} from "../../../index";
import ChatFiltersDropdown from "./ChatFiltersDropdown";
import ChatFiltersList from "./ChatFiltersList";

const CHAT_TYPE_PERSONAL = 1;
const CHAT_TYPE_GROUP = 2;
const CHAT_TYPE_CHANNEL = 3;


const emptyGroupAva = <svg className='img-fluid' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212" width="212"
                           height="212">
    <path fill="#CCC"
          d="M105.946.25C164.318.25 211.64 47.596 211.64 106s-47.322 105.75-105.695 105.75C47.571 211.75.25 164.404.25 106S47.571.25 105.946.25z"/>
    <path fill="#FFF"
          d="M61.543 100.988c8.073 0 14.246-6.174 14.246-14.246s-6.173-14.246-14.246-14.246-14.246 6.173-14.246 14.246 6.174 14.246 14.246 14.246zm8.159 17.541a48.192 48.192 0 0 1 8.545-6.062c-4.174-2.217-9.641-3.859-16.704-3.859-21.844 0-28.492 15.67-28.492 15.67v8.073h26.181l.105-.248c.303-.713 3.164-7.151 10.365-13.574zm80.755-9.921c-6.854 0-12.21 1.543-16.336 3.661a48.223 48.223 0 0 1 8.903 6.26c7.201 6.422 10.061 12.861 10.364 13.574l.105.248h25.456v-8.073c-.001 0-6.649-15.67-28.492-15.67zm0-7.62c8.073 0 14.246-6.174 14.246-14.246s-6.173-14.246-14.246-14.246-14.246 6.173-14.246 14.246 6.173 14.246 14.246 14.246zm-44.093 3.21a23.21 23.21 0 0 0 4.464-.428c.717-.14 1.419-.315 2.106-.521 1.03-.309 2.023-.69 2.976-1.138a21.099 21.099 0 0 0 3.574-2.133 20.872 20.872 0 0 0 5.515-6.091 21.283 21.283 0 0 0 2.121-4.823 22.16 22.16 0 0 0 .706-3.193c.16-1.097.242-2.224.242-3.377s-.083-2.281-.242-3.377a22.778 22.778 0 0 0-.706-3.193 21.283 21.283 0 0 0-3.272-6.55 20.848 20.848 0 0 0-4.364-4.364 21.099 21.099 0 0 0-3.574-2.133 21.488 21.488 0 0 0-2.976-1.138 22.33 22.33 0 0 0-2.106-.521 23.202 23.202 0 0 0-4.464-.428c-12.299 0-21.705 9.405-21.705 21.704 0 12.299 9.406 21.704 21.705 21.704zM145.629 131a36.739 36.739 0 0 0-1.2-1.718 39.804 39.804 0 0 0-3.367-3.967 41.481 41.481 0 0 0-3.442-3.179 42.078 42.078 0 0 0-5.931-4.083 43.725 43.725 0 0 0-3.476-1.776c-.036-.016-.069-.034-.104-.05-5.692-2.581-12.849-4.376-21.746-4.376-8.898 0-16.055 1.795-21.746 4.376-.196.089-.379.185-.572.276a43.316 43.316 0 0 0-3.62 1.917 42.32 42.32 0 0 0-5.318 3.716 41.501 41.501 0 0 0-3.443 3.179 40.632 40.632 0 0 0-3.366 3.967c-.452.61-.851 1.186-1.2 1.718-.324.493-.6.943-.841 1.351l-.061.101a27.96 27.96 0 0 0-.622 1.119c-.325.621-.475.975-.475.975v11.692h82.53v-11.692s-.36-.842-1.158-2.195a35.417 35.417 0 0 0-.842-1.351z"/>
</svg>;
const emptyAva = <svg className='img-fluid' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212" width="212"
                      height="212">
    <path fill="#CCC"
          d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"/>
    <g fill="#FFF">
        <path
            d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z"/>
    </g>
</svg>;

const trunc = (input_str, n) => (input_str.length > n) ? input_str.substr(0, n - 1) + '...' : input_str;

const getChatAvatar = (chat) => chat.avatar_url
    ? <img alt='avatar' src={chat.avatar_url} className='br-50'/>
    : (chat.type === CHAT_TYPE_PERSONAL ? emptyAva : emptyGroupAva);

const getChatUrl = (chat) => {
    return `/${chat.shop_id}/chats/${chat.id}/`;
};

class Index extends React.Component {
    constructor() {
        super();

        this.state = {
            searchInputValue: '',
            isMobile: isMobileDevice(),
        };

        this.redirectToChat = this.redirectToChat.bind(this);
        this.clearData = this.clearData.bind(this);
        this.loadChatsAPI = this.loadChatsAPI.bind(this);
        this.socketFailFallback = this.socketFailFallback.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onChatsScroll = this.onChatsScroll.bind(this);
    }

    componentDidMount() {
        const that = this;
        // On connect
        this.props.socket.on('connect', () => {
            // TODO: subscribe to single shop
            that.props.socket.emit('get_chats_list');
        });
        this.props.socket.on('reconnect', () => {
            // TODO: subscribe to single shop
            that.props.socket.emit('get_chats_list');
        });

        // Chats events
        this.props.socket.on('chats_list', (data) => {
            /**
             * Отображаем первоначальный список чатов
             */
            that.props.setChats(data);

            // Activate first chat
            if (!isEmpty(data) && !this.state.isMobile) {
                that.redirectToChat(data[0]);
            }
        });
        this.props.socket.on('chats_list_update', (data) => {
            /**
             * Ставим свежие чаты на самый верх
             */
            if (isEmpty(that.props.chatFilters)) {
                that.props.mergeChats(data);
            }
        });

        // Errors
        this.props.socket.on('connect_error', () => {
            that.socketFailFallback();
        });
        this.props.socket.on('connect_timeout', () => {
            that.socketFailFallback();
        });
        // this.props.socket.on('reconnect_error', () => {
        //     that.socketFailFallback();
        // });
        this.props.socket.on('reconnect_failed', () => {
            that.socketFailFallback();
        });

        // Do not redirect if activeChat is set in URL
        const replaceOld = !this.props.match.params.activeChatId;
        this.loadChatsAPI({}, replaceOld);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * Если обновился магазин, грузим все по-новой
         */
        if (this.props.activeShop.id !== prevProps.activeShop.id) {
            this.clearData();
            this.loadChatsAPI({}, true);
            return;
        }

        /**
         * Если обновились фильтры, грузим чаты по-новой
         */
        if (!isEqual(this.props.chatFilters, prevProps.chatFilters)) {
            this.loadChatsAPI(
                {},
                true,
                (response) => {
                    /**
                     * КОСТЫЛЬ!
                     *   При обновлении фильтров активный чат сбросится.
                     *   Если URL не изменится, то надо заново реактивировать первый чат.
                     */
                    if (isEmpty(response.results)) {
                        return
                    }
                    const firstChat = response.results[0];
                    const firstCHatUrl = getChatUrl(firstChat);

                    if (firstCHatUrl === this.props.match.url) {
                        this.props.setActiveChat(firstChat);
                    }
                }
            );
        }
    }

    componentWillUnmount() {
        this.clearData();
    }

    redirectToChat(chat) {
        const chatUrl = getChatUrl(chat);
        if (chatUrl !== this.props.match.url) {
            this.props.history.push(chatUrl);
        }
    };

    clearData() {
        this.props.setChatFilters({});
        this.props.setChats(undefined);
    };

    loadChatsAPI(extendFilters = {}, replaceOld = true, callback) {
        /**
         * Подгрузить список чатов из API
         */
        const that = this;
        const filtersAll = assign(
            clone(this.props.chatFilters),
            {
                limit: 20,
            },
            extendFilters,
        );
        if (filtersAll.assigned_to_id === null) {
            delete filtersAll.assigned_to_id;
            // Это ГРЯЗНЫЙ ХАК, т.к. DRF не умеет сам по себе фильтровать Null - значения.
            //   Цифра "2" - обязательная
            filtersAll.unassigned = 2;
        }

        this.props.loadChats(
            filtersAll,
            (response) => {
                if (replaceOld) {
                    this.props.setChats(response.results);

                    // Activate first chat
                    if (!isEmpty(response.results) && !that.state.isMobile) {
                        that.redirectToChat(response.results[0]);
                    }
                } else {
                    this.props.mergeChats(response.results);
                }

                callback && callback(response);
            }
        );
    };

    socketFailFallback() {
        /**
         *  Не подгружать обновления если введен поиск
         */
        if (isEmpty(this.props.chats)) {
            const replaceOld = !this.props.match.params.activeChatId;
            this.loadChatsAPI({}, replaceOld);
        } else {
            const filters = {
                'updated_at__gt': maxBy(this.props.chats, 'updated_at').updated_at,
                'limit': 100,
            };
            this.loadChatsAPI(filters, false);
        }
    };

    onSearchSubmit(event) {
        event.preventDefault();

        if (this.state.searchInputValue === this.props.chatFilters.search) {
            // Уже сделали поиск по этому запросу
            return;
        }
        if (this.props.chatsRequestSending) {
            // Уже отослали запрос
            return;
        }

        if (this.props.wsConnected && !this.state.searchInputValue) {
            this.state.socket.emit('get_chats_list');
            this.props.clearChatFiltersKey('search');
        } else {
            this.props.updateChatFilters({
                search: this.state.searchInputValue,
            });
        }
    };

    onChatsScroll(element) {
        if (element.scrollTop + element.clientHeight + 60 < element.scrollHeight) {
            // Еще не доскроллили до конца
            return;
        }
        if (this.props.chatsRequestSending) {
            // Уже отослали запрос
            return;
        }
        if (this.props.allOldChatsLoaded) {
            // Все старые чаты уже загружены
            return;
        }

        if (this.props.chats.length - this.props.chatsVisibleLimit < 2) {
            const filters = this.props.chatFilters;
            if (!isEmpty(this.props.chats)) {
                filters['last_message_time__lt'] = this.props.chats[this.props.chats.length - 1].last_message_time;
            }

            this.loadChatsAPI(
                filters,
                false,
                (response) => {
                    if (response.next === null) {
                        this.props.setAllOldChatsLoaded(true);
                    }
                }
            );
        }

        this.props.setChatsVisibleLimit(this.props.chatsVisibleLimit + 20);
    };

    render() {
        const now = new Date();
        const chatsHTML = !isEmpty(this.props.chats) && this.props.chats
            .slice(0, this.props.chatsVisibleLimit)
            .map(chat => {
                const activeChatId = parseInt(this.props.match.params.activeChatId);

                const isActive = chat.id === activeChatId;
                const chatLink = `/${chat.shop_id}/chats/${chat.id}/`;

                const chatDate = new Date(chat.last_message_time * 1000);

                const button = <Button
                    className="dropdown-item b-radius-0"
                    color={isActive ? 'primary' : ''}
                >
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                                <div className="avatar-icon-wrapper">
                                    {/*<div className="badge badge-bottom badge-success badge-dot badge-dot-lg"/>*/}
                                    <div className="avatar-icon" style={{background: '#dedede'}}>
                                        {getChatAvatar(chat)}
                                    </div>
                                </div>
                            </div>
                            <div className="widget-content-left">
                                <div className="widget-heading pr-5">
                                    {chat.name}
                                </div>
                                <div className="widget-subheading pr-2">
                                    {trunc(get(chat, 'last_message_text', '') || '', 30)}
                                </div>
                                <div className="widget-time text-secondary">
                                    {
                                        (now.getDate() == chatDate.getDate() && (now - chatDate) < 24 * 60 * 60 * 1000)
                                            ? <Moment locale='ru' format="HH:mm">{chat.last_message_time * 1000}</Moment>
                                            : <Moment locale='ru' format="DD/MM/YY">{chat.last_message_time * 1000}</Moment>
                                    }
                                </div>
                                <div className="widget-messenger">
                                    <img className='img-fluid'
                                         src={LOGO_BY_TYPE[chat.bot.bot_type]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Button>;

                return <NavItem key={chat.id} className='w-100 border-bottom'>
                    {
                        isActive && button
                    }
                    {
                        !isActive && <Link to={chatLink} className='text-decoration-none'>
                            {button}
                        </Link>
                    }
                </NavItem>;
            });

        return <>
            {/*{*/}
            {/*    this.props.wsConnected || <CardHeader className='py-2'>*/}
            {/*        Connecting <Ionicon rotate={true} fontSize="30px" icon="ios-refresh"/>*/}
            {/*    </CardHeader>*/}
            {/*}*/}
            <Form
                className="app-inner-layout__sidebar-header chats-search-pane"
                onSubmit={this.onSearchSubmit}
            >
                <Nav horizontal='true' className='justify-content-between'>
                    <NavItem className="pt-4 pl-3 pb-3">
                        <Input
                            placeholder={this.props.t('pages.chats.chats.placeholder_search', "Search...")}
                            value={this.state.searchInputValue}
                            onChange={(event) => this.setState({searchInputValue: event.target.value})}
                        />
                        {
                            this.props.chatFilters.search && <i
                                className="fa fas fa-times"
                                onClick={
                                    (event) => {
                                        const that = this;
                                        this.setState(
                                            {searchInputValue: ''},
                                            () => that.onSearchSubmit(event)
                                        )
                                    }
                                }
                            />
                        }
                    </NavItem>
                    <NavItem className="pt-3 pb-2 d-flex align-items-center justify-content-center">
                        <ChatFiltersDropdown/>
                    </NavItem>
                </Nav>

                <ChatFiltersList/>
            </Form>

            <PerfectScrollbar onScrollY={this.onChatsScroll}>
                <Nav vertical>
                    {
                        isArray(this.props.chats) && !chatsHTML
                            ? <div className="p-3 text-center">
                                {this.props.t('pages.chats.chats.no_chats', '🎃 No chats')}
                            </div>
                            : chatsHTML
                    }
                </Nav>
            </PerfectScrollbar>
        </>;
    }
}


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    wsConnected: state.Chats.wsConnected,
    chats: state.Chats.chats,
    chatsRequestSending: state.Chats.chatsRequestSending,
    chatsVisibleLimit: state.Chats.chatsVisibleLimit,
    allOldChatsLoaded: state.Chats.allOldChatsLoaded,
    chatFilters: state.Chats.chatFilters,
});

const mapDispatchToProps = dispatch => ({
    loadChats: (filters, callback) => dispatch(loadChats(filters, callback)),
    setChats: (chats) => dispatch(setChats(chats)),
    setActiveChat: (chat) => dispatch(setActiveChat(chat)),
    mergeChats: (chats) => dispatch(mergeChats(chats)),
    setChatsVisibleLimit: (limit) => dispatch(setChatsVisibleLimit(limit)),
    setAllOldChatsLoaded: (loaded) => dispatch(setAllOldChatsLoaded(loaded)),

    setChatFilters: (filters) => dispatch(setChatFilters(filters)),
    updateChatFilters: (filtersToUpdate) => dispatch(updateChatFilters(filtersToUpdate)),
    clearChatFiltersKey: (key) => dispatch(clearChatFiltersKey(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(withTranslation()(Index))
);
