import React from 'react';
import {connect} from 'react-redux';
import Hamburger from 'react-hamburgers';
import get from 'lodash/get';

import AppMobileMenu from './AppMobileMenu';
import {setEnableClosedSidebar} from '../actions/ThemeOptions';
import ExtendedLink from "../Components/ExtendedLink";


const HeaderLogo = (
    {
        activeShop,
        enableClosedSidebar,
        setEnableClosedSidebar,
    }
) => {
    const activeShopId = get(activeShop, 'id');
    return <>
        <div className="app-header__logo">
            <ExtendedLink to={`#/${activeShopId}/dashboard/`} className="logo-src"/>
            <div className="header__pane ml-auto">
                <div onClick={() => setEnableClosedSidebar(!enableClosedSidebar)}>
                    <Hamburger
                        active={enableClosedSidebar}
                        type="elastic"
                    />
                </div>
            </div>
        </div>
        <AppMobileMenu/>
    </>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
});

const mapDispatchToProps = dispatch => ({
    setEnableClosedSidebar: enable => dispatch(setEnableClosedSidebar(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);