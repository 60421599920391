import isEmpty from 'lodash/isEmpty'
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {Button, ButtonGroup, Col, Row} from 'reactstrap'

class DefaultArrayItem extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            animationClass: ''
        }
        this.handleMoveDown = this.handleMoveDown.bind(this)
        this.handleMoveUp = this.handleMoveUp.bind(this)
    }

    handleMoveUp(){
        this.props.onReorderClick(this.props.index, this.props.index - 1)()
        if(this.props.triggerAnimationClass){
            this.props.triggerAnimationClass(this.props.index-1)
        }
    }

    handleMoveDown(){
        this.props.onReorderClick(this.props.index, this.props.index + 1)()
        if(this.props.triggerAnimationClass){
            this.props.triggerAnimationClass(this.props.index + 1)
        }
    }

    render() {
        const {props} = this
        const {animationClass} = this.state
        return (
            <Col key={props.index} className={`${props.className} ${animationClass}`} xs={12}>
                <Row>
                    <Col xs={props.hasToolbar ? 9 : 12} className='array-element-fields'>
                        {props.children}
                    </Col>

                    {props.hasToolbar && (
                        <Col xs={3} className="d-flex align-items-center array-element-buttons">
                            <ButtonGroup className='w-100'>
                                {(props.hasMoveUp || props.hasMoveDown) && (
                                    <Button
                                        color='light'
                                        disabled={props.disabled || props.readonly || !props.hasMoveUp}
                                        onClick={this.handleMoveUp}
                                    >
                                        <i className='lnr-arrow-up'></i>
                                    </Button>
                                )}

                                {(props.hasMoveUp || props.hasMoveDown) && (
                                    <Button
                                        color='light'
                                        disabled={
                                            props.disabled || props.readonly || !props.hasMoveDown
                                        }
                                        onClick={this.handleMoveDown}
                                    >
                                        <i className='lnr-arrow-down'></i>
                                    </Button>
                                )}

                                {props.hasRemove && (
                                    <Button
                                        color="danger"
                                        disabled={props.disabled || props.readonly}
                                        onClick={props.onDropIndexClick(props.index)}
                                    >
                                        <i className='lnr-trash'></i>
                                    </Button>
                                )}
                            </ButtonGroup>
                        </Col>
                    )}
                </Row>
            </Col>
        );
    }
}

const DefaultNormalArrayFieldTemplate = (props) => {
    const {t, i18n} = useTranslation();

    const [animatedItemIndex, setAnimatedItemIndex] = useState(null);

    const triggerAnimationClass = useCallback((index) => {
        setAnimatedItemIndex(index);
        setTimeout(()=>setAnimatedItemIndex(null),500);
    }, [])
  
    return (
        <fieldset className={`${props.className} ${props.uiSchema.className}`} id={props.idSchema.$id}>
            <Row key={`array-item-list-${props.idSchema.$id}`}>
                {props.items && props.items.map(p =>
                    <DefaultArrayItem
                        {...p}
                        triggerAnimationClass={triggerAnimationClass}
                        className={`${p.className} ${p.index === animatedItemIndex? 'moved-highlighted':''}`}
                    />)}
            </Row>

            {props.canAdd && <Button
                    onClick={props.onAddClick}
                    disabled={props.disabled || props.readonly}
                    className={'btn-light ' + (isEmpty(props.items) ? 'mt-1' : '')}
                    size='lg'
                >
                    {t('components.api_form.button.add', 'Add')}
                </Button>
            }
        </fieldset>
    );
};

export default DefaultNormalArrayFieldTemplate;
