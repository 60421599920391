import React from "react";
import {toast} from "react-toastify";
// import axios from "axios";
import i18next from 'i18next';

import {
    deleteRequestFail,
    deleteRequestStart,
    deleteRequestSuccess,
    restartRequestFail,
    restartRequestStart,
    restartRequestSuccess,
    botRequestFail,
    botRequestStart,
    botRequestSuccess,
    stopRequestFail,
    stopRequestStart,
    stopRequestSuccess,
    syncBotUsersRequestFail,
    syncBotUsersRequestStart,
    syncBotUsersRequestSuccess,
} from '../actions/Dashboard';
import {TIMEOUT_API} from "../constants";
import {store} from "../configureStore";
import {renderErrorsAsString} from "../Components/utils";
import {apiAxiosInstance} from "../helpers/api";

export const syncBotUsers = (botId) => {
    return function (dispatch) {
        const data = {
            bot_id: botId,
            shop_id: store.getState().Shops.activeShop.id,
        };

        dispatch(syncBotUsersRequestStart(data));

        apiAxiosInstance.post('bot/sync_users/', data, {timeout: TIMEOUT_API * 10}).then(
            (response) => {
                if (response.data.status === 'ok') {
                    const res = response.data.result;
                    toast.success(
                        <div>
                            {i18next.t('thunks.api_model.sync_bot_users.text_1', 'Total subscribers & groups')}: {res.count_total}<br />
                            {i18next.t('thunks.api_model.sync_bot_users.text_2', 'Restored')}: {res.count_restored_chats}<br />
                            {res.count_skipped_groups ? <div>{i18next.t('thunks.api_model.sync_bot_users.text_3', 'Groups skipped')}: {res.count_skipped_groups}</div> : ''}
                            {res.count_failed_chats ? <div>{i18next.t('thunks.api_model.sync_bot_users.text_4', 'Errors')}: {res.count_failed_chats}</div> : ''}
                        </div>
                    );
                    dispatch(syncBotUsersRequestSuccess(response.data.result));
                } else {
                    toast.error(renderErrorsAsString(response.data.errors));
                    dispatch(syncBotUsersRequestFail(response.data.errors));
                }
            },
            error => {
                toast.error(i18next.t('thunks.api_model.sync_bot_users.error_sync', 'Synchronisation error!'));
                dispatch(syncBotUsersRequestFail(error));
            },
        );
    };
};


export const stopBot = (botId, successCallback) => {
    return function (dispatch) {
        const data = {
            bot_id: botId,
            shop_id: store.getState().Shops.activeShop.id,
        };

        dispatch(stopRequestStart(data));

        apiAxiosInstance.get(`bot/bot/${botId}/stop/`).then(
            (response) => {
                if (response.data.status === 'ok') {
                    toast.success(i18next.t('thunks.api_model.stop_bot.text_1', 'Bot has stopped'));
                    dispatch(stopRequestSuccess(response.data));
                    successCallback && successCallback(botId);
                } else {
                    toast.error(i18next.t('thunks.common.error_sending_request', 'Error sending request'));
                    dispatch(stopRequestFail(response.data));
                }
            },
            error => {
                toast.error(i18next.t('errors.common.request_sent', 'Error sending request'));
                dispatch(stopRequestFail(error));
            },
        );
    };
};


export const getBot = (botId, successCallback) => {
    return function (dispatch) {
        const data = {
            bot_id: botId,
            shop_id: store.getState().Shops.activeShop.id,
        };

        dispatch(botRequestStart(data));

        apiAxiosInstance.get(`bot/bots/${botId}/`).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(botRequestSuccess(response.data.result));
                    successCallback && successCallback(botId);
                } else {
                    dispatch(botRequestFail(response.data.errors));
                }
            },
            error => {
                dispatch(botRequestFail(error));
            },
        );
    };
};


export const restartBot = (botId, successCallback) => {
    return function (dispatch) {
        const data = {
            bot_id: botId,
            shop_id: store.getState().Shops.activeShop.id,
        };

        dispatch(restartRequestStart(data));

        apiAxiosInstance.get(`bot/bot/${botId}/restart/`, {timeout: TIMEOUT_API * 2}).then(
            (response) => {
                if (response.data.status === 'ok') {
                    toast.success(i18next.t('thunks.dashboard.toast_bot_stopped', 'Bot has stopped'));
                    dispatch(restartRequestSuccess(response.data));
                    successCallback && successCallback(botId);
                } else {
                    toast.error(renderErrorsAsString(response.data.errors));
                    dispatch(restartRequestFail(response.data));
                }
            },
            error => {
                toast.error(i18next.t('errors.common.error_restarting', 'Error restarting'));
                dispatch(restartRequestFail(error));
            },
        );
    };
};


export const deleteBot = (botId, successCallback) => {
    return function (dispatch) {
        const data = {
            bot_id: botId,
            shop_id: store.getState().Shops.activeShop.id,
        };

        dispatch(deleteRequestStart(data));

        apiAxiosInstance.delete(`bot/bots/${botId}/?shop_id=${data.shop_id}`).then(
            (response) => {
                if (response.status === 204) {
                    toast.success(i18next.t('thunks.dashboard.toast_bot_removed', 'Bot was removed'));
                    dispatch(deleteRequestSuccess(response.data));
                    successCallback && successCallback(botId);
                } else {
                    toast.error(i18next.t('errors.common.error_deleting', 'Cannot remove bot'));
                    dispatch(deleteRequestFail(response.data));
                }
            },
            error => {
                toast.error(i18next.t('errors.common.error_deleting', 'Cannot remove bot'));
                dispatch(deleteRequestFail(error));
            },
        );
    };
};
