import {LOCATION_CHANGE} from 'connected-react-router'

import {
    API_TABLE_CONFIG_REQUEST_START,
    API_TABLE_CONFIG_REQUEST_SUCCESS,
    API_TABLE_CONFIG_REQUEST_FAIL,

    API_TABLE_DATA_REQUEST_START,
    API_TABLE_DATA_REQUEST_SUCCESS,
    API_TABLE_DATA_REQUEST_FAIL,

    API_TABLE_SET_CONFIG,
    API_TABLE_SET_FILTERS,
    API_TABLE_SET_DATA,
    API_TABLE_SET_SEARCH,
} from '../actions/APIModel'


const defaultState = {
    configRequestCancelTokenSource: undefined,
    dataRequestCancelTokenSource: undefined,
    data: undefined,
    config: undefined,
    filters: undefined,
    errors: undefined,
    configErrors: undefined,
    totalRows: 0,
};


export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case API_TABLE_CONFIG_REQUEST_START:
            state.configRequestCancelTokenSource && state.configRequestCancelTokenSource.cancel();

            return {
                ...state,
                configRequestCancelTokenSource: action.cancelTokenSource,
                configErrors: undefined,
                config: undefined,
            };

        case API_TABLE_CONFIG_REQUEST_SUCCESS:
            return {
                ...state,
                configRequestCancelTokenSource: undefined,
                configErrors: undefined,
                config: action.result,
            };

        case API_TABLE_CONFIG_REQUEST_FAIL:
            console.log('Not implemented Exception: "API_TABLE_DATA_REQUEST_FAIL"');
            return {
                ...state,
                configRequestCancelTokenSource: undefined,
                configErrors: action.errors,
                config: undefined,
            };


        case API_TABLE_DATA_REQUEST_START:
            state.dataRequestCancelTokenSource && state.dataRequestCancelTokenSource.cancel();

            return {
                ...state,
                dataRequestCancelTokenSource: action.cancelTokenSource,
                data: action.clearOldData ? undefined : state.data,
                totalRows: action.clearOldData ? 0 : state.totalRows,
            };

        case API_TABLE_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                dataRequestCancelTokenSource: undefined,
                errors: undefined,
                data: action.results,
                totalRows: action.count,
            };

        case API_TABLE_DATA_REQUEST_FAIL:
            console.log('Not implemented Exception: "API_TABLE_DATA_REQUEST_FAIL"');
            return {
                ...state,
                dataRequestCancelTokenSource: undefined,
                errors: action.errors,
                data: undefined,
                totalRows: 0,
            };


        case API_TABLE_SET_CONFIG:
            return {
                ...state,
                config: action.config,
            };
        case API_TABLE_SET_FILTERS:
            return {
                ...state,
                filters: action.filters,
            };
        case API_TABLE_SET_DATA:
            return {
                ...state,
                data: action.data,
                totalRows: action.totalRows,
            };
        case API_TABLE_SET_SEARCH:
            return {
                ...state,
                search: action.search,
            };


        case LOCATION_CHANGE:
            state.configRequestCancelTokenSource && state.configRequestCancelTokenSource.cancel();
            state.dataRequestCancelTokenSource && state.dataRequestCancelTokenSource.cancel();

            return {
                ...state,
                data: undefined,
                config: undefined,
                filters: undefined,
                search: undefined,
                totalRows: 0,
            }
    }
    return state;
}
