import React, {useState} from "react";
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import {Trans, useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,} from 'reactstrap';

import {logInRequestStart, registerCloseNotificationPopup, touchLoginForm} from "../../actions/Auth";
import {renderErrorsAsHTML} from '../../Components/utils'
import { URLS } from "../../router";


function Login(
    {
        errors,
        validationEmailNotification,
        loginRequestStart,
        touchLoginForm,
        registerCloseNotificationPopup,
    }
) {
    const {t, i18n} = useTranslation();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const sendLogInRequest = (e) => {
        e.preventDefault();
        loginRequestStart(username, password);
    };

    return <>
        <div className="h-100">
            <div className="d-flex h-100 justify-content-center align-items-center">
                <Col md="8" className="mx-auto app-login-box">
                    <div className="mx-auto mb-3 text-center">
                        <h4 className="mt-2">
                            {t('auth.login.title', 'Nice to meet you!')}
                        </h4>
                    </div>

                    <div className="modal-dialog w-100">
                        <div className="modal-content">
                            <div className="modal-body px-4 py-5">
                                <Form onSubmit={sendLogInRequest} autoComplete="on">
                                    <FormGroup>
                                        <Label for="username">{t('auth.login.label_username', "Email or phone")}</Label>
                                        <Input type="text" name="username" id="username"
                                               placeholder={t('auth.login.placeholder_username', "Email or phone...")}
                                               required
                                               value={username}
                                               onChange={e => setUsername(e.target.value)}
                                               invalid={get(errors, 'email') !== undefined}
                                               onClick={touchLoginForm}/>
                                    </FormGroup>
                                    {renderErrorsAsHTML(get(errors, 'username'))}
                                    {renderErrorsAsHTML(get(errors, 'email'))}
                                    <FormGroup>
                                        <Label
                                            for="examplePassword">{t('auth.login.label_password', "Password")}</Label>
                                        <Input type="password" name="password" id="examplePassword"
                                               placeholder={t('auth.login.placeholder_password', "My password...")}
                                               required
                                               value={password}
                                               onChange={e => setPassword(e.target.value)}
                                               invalid={get(errors, 'password') !== undefined}
                                               onClick={touchLoginForm}/>
                                    </FormGroup>
                                    {renderErrorsAsHTML(get(errors, 'password'))}

                                    <div className="text-danger mr-3">{get(errors, 'common')}</div>

                                    <Button type="submit" color="primary" size="lg"
                                            className="btn-wide mt-4 btn-pill btn-shadow btn-hover-shine">
                                        {t('auth.login.button_submit', 'Log in')}
                                    </Button>
                                </Form>
                            </div>
                            <div className="modal-footer d-block text-center">
                            <h6 className="mb-0">
                                <Trans t={t} i18nKey='auth.login.login_content'>
                                    Don't have an account yet?{' '}
                                    <Link to={ URLS.AUTH.SIGNUP }>
                                        <span className="text-primary">Register</span>
                                    </Link>
                                    {' '}&nbsp; | &nbsp;{' '}
                                    <Link to={ URLS.AUTH.FORGOT_PASSWORD }>
                                        <span className="text-primary">Password restore</span>
                                    </Link>
                                </Trans>
                            </h6>
                        </div>
                        </div>
                    </div>
                </Col>
            </div>
        </div>

        <Modal isOpen={validationEmailNotification} toggle={registerCloseNotificationPopup}>
            <ModalHeader>
                <i className="lnr-checkmark-circle icon-gradient bg-happy-itmeo fsize-2"> </i>
                {t('auth.login.account_created.title', 'Account created!')}
            </ModalHeader>
            <ModalBody>
                {t('auth.login.account_created.text1', 'Registration confirmation instructions were sent to you via email')}
                <br/>
                <br/>
                <i className="pe-7s-mail-open-file icon-gradient bg-plum-plate fsize-2"> </i>
                {' '} {t('auth.login.account_created.text2', 'Please, click the link in the email.')}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={registerCloseNotificationPopup}>
                    {t('auth.login.account_created.button', 'Ok, thanks!')}
                </Button>{' '}
            </ModalFooter>
        </Modal>
    </>;
}


const mapStateToProps = state => ({
    errors: state.Auth.login.errors,
    validationEmailNotification: state.Auth.register.validationEmailNotification,
});

const mapDispatchToProps = dispatch => ({
    loginRequestStart: (username, password) => dispatch(logInRequestStart(username, password)),
    touchLoginForm: () => dispatch(touchLoginForm()),
    registerCloseNotificationPopup: () => dispatch(registerCloseNotificationPopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
