import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import cx from "classnames";
import Moment from "react-moment";
import {Button, UncontrolledTooltip} from "reactstrap";
import 'moment/locale/ru';
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import assign from "lodash/assign";
import isEqual from "lodash/isEqual";

import {
    mergeMessages,
    receiveMessage,
    setActiveChat,
    setAllOldMessagesLoaded,
    setMessages,
    setMessagesVisibleLimit,
} from "../../../actions/Chats";
import {getFileIconClsByURL} from "../../../Components/APIForm/Fields/FileWidget";
import {loadChatById, loadMessages} from "../../../thunks/Chats";
import s2bIcon from "../../../assets/utils/images/logo_square.jpeg";
import {
    CHAT_TYPE_CHANNEL,
    CHAT_TYPE_GROUP,
    MESSAGE_CONTENT_TYPE_INLINE,
    MESSAGE_CONTENT_TYPE_SYSTEM_ERROR,
    MESSAGE_CONTENT_TYPE_SYSTEM_INFO,
    MESSAGE_DIRECTION_SENT,
    MESSAGE_DIRECTION_SYSTEM,
    MESSAGE_STATUS_DELETED,
    MESSAGE_STATUS_DELIVERED,
    MESSAGE_STATUS_FAILED,
    MESSAGE_STATUS_NEW,
    MESSAGE_STATUS_READ,
    MESSAGE_STATUS_SENT,
} from "../../../constants";


const emptyAva = <svg className='img-fluid' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212" width="212"
                      height="212">
    <path fill="#CCC"
          d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"/>
    <g fill="#FFF">
        <path
            d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z"/>
    </g>
</svg>;

const iconMessageNew = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="16" height="15"><path fill="currentColor" d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z"></path></svg>;
const iconMessageSent = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="16" height="15"><path fill="currentColor" d="M10.91 3.316l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>;
const iconMessageFailed = <i className='fa fas fa-exclamation-triangle' style={{color: 'rgb(255 6 0)'}}/>;
const iconMessageDelivered = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="16" height="15" className='opacity-6'><path fill="currentColor" d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>;
const iconMessageRead = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="16" height="15" style={{color: 'rgb(79, 195, 247)'}}><path fill="currentColor" d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>;
const iconMessageDeleted = <i className='fa fas fa-trash-alt text-black-50 opacity-8'/>;


class MessageList extends Component {
    constructor() {
        super();

        this.state = {
            scheduleMessagesScrollTo: undefined,
        };

        this.loadInitialData = this.loadInitialData.bind(this);
        this.clearData = this.clearData.bind(this);
        this.loadLastMessagesAPI = this.loadLastMessagesAPI.bind(this);
        this.onMessagesScroll = this.onMessagesScroll.bind(this);
        this.reloadActiveChatAPI = this.reloadActiveChatAPI.bind(this);
        this.loadMessagesAPI = this.loadMessagesAPI.bind(this);
    }

    componentDidMount() {
        const that = this;

        // Конкретный чат
        this.props.socket.on('messages_list', (data) => {
            const messagesDiv = document.querySelector('.app-inner-layout__content .scrollbar-container');
            messagesDiv && that.setState(
                {
                    scheduleMessagesScrollTo: messagesDiv.scrollHeight
                },
                () => that.props.setMessages(data)
            );
        });

        this.props.socket.on('receive_message', (data) => {
            that.props.receiveMessage(data);
        });

        // Errors
        this.props.socket.on('connect_error', () => {
            that.loadLastMessagesAPI();
        });
        this.props.socket.on('connect_timeout', () => {
            that.loadLastMessagesAPI();
        });
        // this.props.socket.on('reconnect_error', () => {
        //     that.loadLastMessagesAPI();
        // });
        this.props.socket.on('reconnect_failed', () => {
            that.loadLastMessagesAPI();
        });

        this.loadInitialData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * Если обновился чат или магазин, грузим все по-новой
         */
        if (this.props.match.url !== prevProps.match.url) {
            if (this.props.wsConnected && prevProps.activeChat) {
                this.props.socket.emit('unsubscribe_chat', prevProps.activeChat.id);
            }

            this.loadInitialData();
        } else if (this.state.scheduleMessagesScrollTo && !isEqual(this.props.messages, prevProps.messages)) {
            /**
             * Скроллим сообщения куда должны
             * Отрицательный скролл отсчитывается снизу блока.
             */
            const messagesDiv = document.querySelector('.app-inner-layout__content .scrollbar-container');
            if (messagesDiv) {
                if (this.state.scheduleMessagesScrollTo >= 0) {
                    messagesDiv.scrollTop = this.state.scheduleMessagesScrollTo;
                } else {
                    messagesDiv.scrollTop = messagesDiv.scrollHeight + this.state.scheduleMessagesScrollTo;
                }
            }

            this.setState({
                scheduleMessagesScrollTo: undefined
            });
        }
    }

    componentWillUnmount() {
        this.clearData();
    }

    loadInitialData() {
        const that = this;
        this.reloadActiveChatAPI(
            (newActiveChat) => {
                if (that.props.wsConnected) {
                    that.props.socket.emit('subscribe_chat', newActiveChat.id);
                } else {
                    that.loadMessagesAPI(
                        newActiveChat.id,
                        {},
                        true,
                        undefined,
                        that.clearData,
                    );
                }
            }
        );
    }

    clearData() {
        this.props.setMessages([]);
    };

    loadLastMessagesAPI() {
        if (!this.props.activeChat) {
            return;
        }

        const filters = {};
        if (!isEmpty(this.props.messages)) {
            filters['created_at__gt'] = this.props.messages[0].created_at;
            filters['limit'] = 100;
        }

        this.loadMessagesAPI(this.props.activeChat.id, filters, false);
    }

    onMessagesScroll(element) {
        const that = this;

        if (!this.props.activeChat) {
            return;
        }

        if (element.scrollTop > 60) {
            // Еще не доскроллили до верху
            return;
        }
        if (this.props.messagesRequestSending) {
            // Уже отослали запрос
            return;
        }
        if (this.props.allOldMessagesLoaded) {
            // Все старые сообщения уже загружены
            return;
        }

        if (this.props.messages.length - this.props.messagesVisibleLimit < 2) {
            const filters = {};
            if (!isEmpty(this.props.messages)) {
                filters['created_at__lt'] = this.props.messages[this.props.messages.length - 1].created_at;
            }

            this.loadMessagesAPI(
                this.props.activeChat.id,
                filters,
                false,
                (response) => {
                    if (response.next === null) {
                        that.props.setAllOldMessagesLoaded(true);
                    }
                }
            );
        }

        this.props.setMessagesVisibleLimit(this.props.messagesVisibleLimit + 20);
    }

    reloadActiveChatAPI(successCallback) {
        const that = this;
        const activeChatId = parseInt(this.props.match.params.activeChatId);

        this.props.setActiveChat(undefined);

        /**
         * Подгрузить из API чат исходя из урла
         */
        this.props.loadChatById(
            activeChatId,
            (response) => {
                that.props.setActiveChat(response);
                successCallback && successCallback(response);
            },
        );
    }

    loadMessagesAPI(
        chatId,
        filters = {},
        replaceOld = true,
        successCallback = undefined,
        errorCallback = undefined,
    ) {
        const that = this;
        const filtersAll = assign(
            {
                limit: 20,
                chat: chatId,
            },
            filters
        );

        this.props.loadMessages(
            filtersAll,
            (response) => {
                if (replaceOld) {
                    that.setState(
                        {
                            scheduleMessagesScrollTo: 999999
                        },
                        () => {
                            that.props.setMessages(response.results);
                        }
                    );
                } else {
                    const messagesDiv = document.querySelector('.app-inner-layout__content .scrollbar-container');
                    that.setState({
                            scheduleMessagesScrollTo: messagesDiv
                                ? (messagesDiv.scrollTop - messagesDiv.scrollHeight)
                                : that.state.scheduleMessagesScrollTo
                        },
                        () => {
                            if (!isEmpty(response.results)) {
                                that.props.mergeMessages(response.results);
                            }
                        });
                }

                successCallback && successCallback(response);
            },
            errorCallback
        )
    }

    render() {
        const isGroup = this.props.activeChat && this.props.activeChat.type === CHAT_TYPE_GROUP;
        const isChannel = this.props.activeChat && this.props.activeChat.type === CHAT_TYPE_CHANNEL;
        const now = new Date();

        const messagesHTML = this.props.messages && this.props.messages
            .slice(0, this.state.messagesVisibleLimit)
            .reverse()
            .map(
                (message) => {
                    const directionMine = message.direction === MESSAGE_DIRECTION_SENT;
                    const directionSystem = message.direction === MESSAGE_DIRECTION_SYSTEM;
                    const isContentTypeSystemInfo = message.content_type === MESSAGE_CONTENT_TYPE_SYSTEM_INFO;
                    const isContentTypeSystemError = message.content_type === MESSAGE_CONTENT_TYPE_SYSTEM_ERROR;
                    const isContentTypeInline = message.content_type === MESSAGE_CONTENT_TYPE_INLINE;
                    const messageDate = new Date(message.created_at * 1000);
                    const messageDateHTML = (
                        now.getDate() == messageDate.getDate()
                        && (now - messageDate) < 24 * 60 * 60 * 1000
                    )
                        ? <Moment locale='ru' format="HH:mm">{message.created_at * 1000}</Moment>
                        : <>
                            <Moment
                                locale='ru'
                                format={now.getFullYear() == messageDate.getFullYear() ? "DD MMM" : "DD MMM YYYY"}
                                style={{fontSize: '.6rem'}}
                            >
                                {message.created_at * 1000}
                            </Moment>
                            &nbsp;&nbsp;
                            <Moment locale='ru' format="HH:mm">{message.created_at * 1000}</Moment>
                        </>;

                    if (directionSystem) {
                        return <div className="message" key={message.id}>
                            <div className="chat-box-wrapper justify-content-center">
                                <div className='message-content'>
                                    <div className={cx("chat-box flex-column br-a", {
                                        'bg-warning-light': isContentTypeSystemInfo,
                                        'bg-danger-light': isContentTypeSystemError,
                                    })}>
                                        <div>{message.text}</div>
                                        <div className='d-flex align-items-center' style={{justifyContent:'flex-end'}}>
                                            <small className="opacity-6">{messageDateHTML}</small>
                                            {
                                                message.step && <>
                                                    <i className="fa fas fa-question-circle fa-sm text-black-50 opacity-8 pull-right ml-2"
                                                       style={{marginTop: '1px'}}
                                                       id={'message-' + message.id}
                                                    />
                                                    <UncontrolledTooltip placement="right" target={'message-' + message.id}>
                                                        {get(message, 'step.flow.name')}: {get(message, 'step.name')}
                                                    </UncontrolledTooltip>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    let messageIcon;
                    switch (message.status) {
                        case MESSAGE_STATUS_NEW:
                            messageIcon = iconMessageNew;
                            break;
                        case MESSAGE_STATUS_SENT:
                            messageIcon = iconMessageSent;
                            break;
                        case MESSAGE_STATUS_FAILED:
                            messageIcon = iconMessageFailed;
                            break;
                        case MESSAGE_STATUS_DELIVERED:
                            messageIcon = iconMessageDelivered;
                            break;
                        case MESSAGE_STATUS_READ:
                            messageIcon = iconMessageRead;
                            break;
                        case MESSAGE_STATUS_DELETED:
                            messageIcon = iconMessageDeleted;
                            break;
                        default:
                            messageIcon = undefined;
                    }

                    const avatar = directionMine
                        ? s2bIcon
                        : (
                            isChannel
                                ? get(this.props.activeChat, 'avatar_url')
                                : get(message, 'bot_user.avatar_url')
                        );
                    const avatarBlock = avatar
                        ? <img alt='avatar' src={avatar} className='w-auto'/>
                        : emptyAva;
                    let avatarHTML = <div className="avatar-icon avatar-icon-lg border-0">
                        {avatarBlock}
                    </div>;

                    return <div className={cx("message", {'justify-content-end': directionMine})} key={message.id}>
                        <div className={directionMine
                            ? 'chat-box-wrapper chat-box-wrapper-right'
                            : 'chat-box-wrapper'}
                        >
                            <div className="avatar-icon-wrapper">
                                {avatarHTML}
                            </div>
                            <div className='message-content'>
                                {
                                    !isContentTypeInline && <div className="chat-box flex-column">
                                        {
                                            !directionMine && isGroup &&
                                            <strong className={cx("opacity-9", {'text-right': directionMine})}>
                                                {get(message, 'bot_user.name')}
                                            </strong>
                                        }
                                        {
                                            message.image_url && <div className='message-image mt-2'>
                                                <img alt={message.text} src={message.image_url} className='mb-3'/>
                                            </div>
                                        }
                                        {
                                            message.file_url && <>
                                                <a href={message.file_url} target='_blank' className='message-file'>
                                                    <div
                                                        className={`${getFileIconClsByURL(message.file_url)} bg-size-cover bg-center m-1 mr-2`}
                                                        style={{height: 30, width: 40}}
                                                    />
                                                    <span>{message.file_url}</span>
                                                </a>
                                                <span>{message.text}</span>
                                            </>
                                        }
                                        {
                                            message.video_url && <>
                                                <a href={message.video_url} target='_blank' className='message-file'>
                                                    <span className="fa fas fa-video fa-lg text-black-50 opacity-8 m-2 text-decoration-none text-dark" />
                                                    <span>{message.video_url}</span>
                                                </a>
                                            </>
                                        }
                                        {
                                            !message.file_url && <div className='message-text'>
                                                {message.text}
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    isContentTypeInline && <Button disabled color='light'>{message.text}</Button>
                                }
                                <div className="d-flex align-items-center" style={{justifyContent:'flex-end'}}>
                                    <small className="opacity-6">{messageDateHTML}</small>
                                    {
                                        directionMine && messageIcon && <span className="ml-2 opacity-8 message-status">{messageIcon}</span>
                                    }
                                    {
                                        message.step && <>
                                            <i className="fa fas fa-question-circle fa-sm text-black-50 opacity-8 pull-right ml-2"
                                               id={'message-' + message.id}
                                            />
                                            <UncontrolledTooltip placement="top" target={'message-' + message.id}>
                                                {get(message, 'step.flow.name')}: {get(message, 'step.name')}
                                            </UncontrolledTooltip>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>;
                }
            );

        return <PerfectScrollbar onScrollY={this.onMessagesScroll}>
            <div className="table-responsive">
                <div className="chat-wrapper">
                    {messagesHTML}
                </div>
            </div>
        </PerfectScrollbar>;
    }
}

const mapStateToProps = state => ({
    wsConnected: state.Chats.wsConnected,
    activeChat: state.Chats.activeChat,
    messages: state.Chats.messages,
    messagesRequestSending: state.Chats.messagesRequestSending,
    messagesVisibleLimit: state.Chats.messagesVisibleLimit,
    allOldMessagesLoaded: state.Chats.allOldMessagesLoaded,
});

const mapDispatchToProps = dispatch => ({
    setActiveChat: (chat) => dispatch(setActiveChat(chat)),
    loadChatById: (chatId, callback) => dispatch(loadChatById(chatId, callback)),
    loadMessages: (filters, successCallback, errorCallback) => dispatch(loadMessages(filters, successCallback, errorCallback)),
    setMessages: (messages) => dispatch(setMessages(messages)),
    receiveMessage: (message) => dispatch(receiveMessage(message)),
    mergeMessages: (messages) => dispatch(mergeMessages(messages)),
    setAllOldMessagesLoaded: (loaded) => dispatch(setAllOldMessagesLoaded(loaded)),
    setMessagesVisibleLimit: (limit) => dispatch(setMessagesVisibleLimit(limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(MessageList)
);
