import React from "react";
import {connect} from "react-redux";
import { Route, Switch, Redirect, withRouter} from "react-router-dom";
import {Col, Container, Row, Modal} from "reactstrap";
import {useTranslation} from "react-i18next";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import {
    BOT_TYPE_AVITO,
    BOT_TYPE_EXPRESS,
    BOT_TYPE_FB,
    BOT_TYPE_IG,
    BOT_TYPE_OK,
    BOT_TYPE_RETAILCRM,
    BOT_TYPE_TG,
    BOT_TYPE_VIBER,
    BOT_TYPE_VK,
    BOT_TYPE_WA,
    BOT_TYPE_WABA,
    SHOP_SOURCE_HIGHTOUCH,
    SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU,
    SHOP_SOURCE_RETAIL_CRM_WABA_RU,
} from "../../../constants";
import AppHeader from "../../../Layout/AppHeader";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import AppSidebar from "../../../Layout/AppSidebar";
import ConnectCardTelegram from "./ConnectCards/Telegram";
import ConnectCardWhatsApp from "./ConnectCards/WhatsApp";
import ConnectCardWABA from "./ConnectCards/WABA";
import ConnectCardInstagram from "./ConnectCards/Instagram";
import ConnectCardVK from "./ConnectCards/VK";
import ConnectCardAvito from "./ConnectCards/Avito";
import ConnectCardOK from "./ConnectCards/OK";
import ConnectCardFacebook from "./ConnectCards/Facebook";
import ConnectCardViber from "./ConnectCards/Viber";
import ConnectCardExpress from "./ConnectCards/Express";
import BotCardTelegram from "./BotCards/Telegram";
import BotCardWhatsApp from "./BotCards/WhatsApp";
import BotCardFB from "./BotCards/Facebook";
import BotCardVK from "./BotCards/VK";
import BotCardAvito from "./BotCards/Avito";
import BotCardWABA from "./BotCards/WABA";
import BotCardViber from "./BotCards/Viber";
import BotCardOK from "./BotCards/OK";
import BotCardExpress from "./BotCards/Express";
import BotCardRetailCRM from "./BotCards/RetailCRM";
import BotCardInstagram from "./BotCards/Instagram";
import WABA from "./ConnectBot/WABA";
import WhatsApp from "./ConnectBot/WhatsApp";
import Telegram from "./ConnectBot/TGBot";


const Channels = (
    {
        match,
        history,
        activeShop,
        bots,
        user
    }
) => {
    const {t, i18n} = useTranslation();
    
    const isHighTouch = get(activeShop, 'source') === SHOP_SOURCE_HIGHTOUCH;
    /*
    const isRetailCRM = (
        get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_WABA_RU
        || get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU
    );
    */
    
    const handlerClose = () => {
        history.push(`${match.url}/`);
    };

    return <>
        <AppHeader/>
        <div className="app-main">
            <AppSidebar/>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    {
                        isHighTouch && <PageTitle
                            heading={t('pages.channels.title_main', 'Channels')}
                            icon="fa fas fa-bullseye icon-gradient bg-malibu-beach"
                        />
                    }

                    {
                        !isEmpty(bots) && <Container>
                            <h4 className='my-4'>
                                {t('pages.channels.title_existing_channels', 'Active channels')}
                            </h4>
                            <Row>
                                {bots.map(
                                    (bot) => {
                                        let card;
                                        switch (bot.bot_type) {
                                            case BOT_TYPE_TG:
                                                card = <BotCardTelegram bot={bot} key={bot.id}/>;
                                                break;
                                            case BOT_TYPE_WA:
                                                card = <BotCardWhatsApp bot={bot} key={bot.id}/>;
                                                break;
                                            case BOT_TYPE_FB:
                                                card = <BotCardFB bot={bot} key={bot.id}/>;
                                                break;
                                            case BOT_TYPE_VK:
                                                card = <BotCardVK bot={bot} key={bot.id}/>;
                                                break;
                                            case BOT_TYPE_AVITO:
                                                card = <BotCardAvito bot={bot} key={bot.id}/>;
                                                break;
                                            case BOT_TYPE_WABA:
                                                card = <BotCardWABA bot={bot} key={bot.id}/>;
                                                break;
                                            case BOT_TYPE_VIBER:
                                                card = <BotCardViber bot={bot} key={bot.id}/>;
                                                break;
                                            case BOT_TYPE_OK:
                                                card = <BotCardOK bot={bot} key={bot.id}/>;
                                                break;
                                            case BOT_TYPE_EXPRESS:
                                                card = <BotCardExpress bot={bot} key={bot.id}/>;
                                                break;
                                            case BOT_TYPE_IG:
                                                card = <BotCardInstagram bot={bot} key={bot.id}/>;
                                                break;
                                            case BOT_TYPE_RETAILCRM:
                                                card = <BotCardRetailCRM bot={bot} key={bot.id}/>;
                                                break;
                                            default:
                                                throw `Wrong BotType: ${bot.bot_type}`;
                                        }

                                        return <Col xs="12" sm="6" xl="4" key={bot.id}>
                                            {card}
                                        </Col>
                                    }
                                )}
                            </Row>
                        </Container>
                    }

                    {
                        isHighTouch && <Container>
                            <h4 className='my-4'>
                                {t('pages.channels.title_new_channels', 'Add new channel')}
                            </h4>
                            <Row>
                                <Col xs="12" sm="6" xl="4">
                                    <ConnectCardWABA/>
                                </Col>
                                {/*<Col xs="12" sm="6" xl="4">*/}
                                {/*    <ConnectCardWhatsApp/>*/}
                                {/*</Col>*/}
                                <Col xs="12" sm="6" xl="4">
                                    <ConnectCardTelegram/>
                                </Col>
                                <Col xs="12" sm="6" xl="4">
                                    <ConnectCardInstagram/>
                                </Col>
                                <Col xs="12" sm="6" xl="4">
                                    <ConnectCardFacebook/>
                                </Col>
                                {
                                    user.is_superuser && <>
                                        
                                        <Col xs="12" sm="6" xl="4">
                                            <ConnectCardVK/>
                                        </Col>
                                        <Col xs="12" sm="6" xl="4">
                                            <ConnectCardViber/>
                                        </Col>
                                        <Col xs="12" sm="6" xl="4">
                                            <ConnectCardAvito/>
                                        </Col>
                                        <Col xs="12" sm="6" xl="4">
                                            <ConnectCardOK/>
                                        </Col>
                                        <Col xs="12" sm="6" xl="4">
                                            <ConnectCardExpress/>
                                        </Col>
                                    </>
                                }
                            </Row>
                        </Container>
                    }
                </div>
            </div>
        </div>
        <Route
            path={`${match.url}/connect`}
            render={() =>
                <Modal isOpen={true}
                       toggle={handlerClose}
                >
                    <Switch>
                        <Route path={`${match.url}/connect/waba`}
                               render={() => <WABA handlerClose={handlerClose}/>}
                        />
                        <Route path={`${match.url}/connect/whatsapp`}
                               render={() => <WhatsApp handlerClose={handlerClose}/>}
                        />
                        <Route path={`${match.url}/connect/telegram`}
                               render={() => <Telegram handlerClose={handlerClose}/>}
                        />
                        <Redirect to={`${match.url}/`}/>
                    </Switch>
                </Modal>
            }
          />
    </>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    bots: state.Bots.bots,
    billingConfig: state.Billing.billingConfig,
    user: state.Auth.user
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Channels));
