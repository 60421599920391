import React from "react";
import axios from "axios";
import get from "lodash/get";
import {toast} from "react-toastify";
import {Button} from "reactstrap";
import i18next from "i18next";

import {store} from "../configureStore";
import {
    createAvitoBotRequestFail,
    createAvitoBotRequestStart,
    createAvitoBotRequestSuccess,

    createBotRequestFail,
    createBotRequestStart,
    createBotRequestSuccess,

    groupsRequestFail,
    groupsRequestStart,
    groupsRequestSuccess,
} from '../actions/Connect'
import {
    BOT_TYPE_VIBER,
    BOT_TYPE_WA,
    BOT_TYPE_WABA,
    BOT_TYPE_TG,
    BOT_TYPE_OK,
    BOT_TYPE_EXPRESS,
} from "../constants";
import {chargebeeGenerateHostedPage} from "./Billing";
import {renderErrorsAsHTML} from "../Components/utils";
import {
    apiAxiosInstance,
    serviceAvitoAxiosInstance,
    serviceCoreAxiosInstance
} from "../helpers/api";


const createBot = (requestData, callback) => {
    return function (dispatch) {
        dispatch(createBotRequestStart());

        apiAxiosInstance.post('/bot/bots/', requestData).then(
            response => {
                if (response.data.status === 'ok') {
                    dispatch(createBotRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    if (get(response.data, 'errors.upgrade_to_pro')) {
                        toast.error(<>
                            <div>{response.data.errors.upgrade_to_pro}</div>
                            <Button
                                color="light"
                                className="my-2"
                                onClick={() => {
                                    store.dispatch(chargebeeGenerateHostedPage(
                                        undefined,
                                        (hosted_page) => {
                                        const chargebeeInstance = window.Chargebee.getInstance();
                                        chargebeeInstance.openCheckout({
                                                hostedPage: () => new Promise((resolve, reject) => {
                                                    resolve(hosted_page);
                                                }),
                                                success: function (hostedPageId) {
                                                    // window.location.reload();
                                                }
                                            }
                                        );
                                    }));
                                    toast.dismiss();
                                }}
                            >
                                {i18next.t('layout.vertical_nav.button_become_pro', 'Upgrade to PRO')}
                            </Button>
                        </>);
                    } else {
                        toast.error(<>{renderErrorsAsHTML(response.data.errors, false, '')}</>);
                    }
                    dispatch(createBotRequestFail(response.data.errors));
                }
            },
            error => dispatch(createBotRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    };
};

export const createAvitoBot = (shop_id, avito_code, callback) => {
    return function (dispatch) {
        const requestData = {
            shop_id,
            avito_code,
        };
        dispatch(createAvitoBotRequestStart(requestData));

        serviceAvitoAxiosInstance.post('/create_bot', requestData).then(
            response => {
                if (response.data.status === 'ok') {
                    dispatch(createAvitoBotRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(createAvitoBotRequestFail(response.data.errors));
                }
            },
            error => dispatch(createAvitoBotRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    };
};

export const createWABot = (shop_id, name, callback) => createBot(
    {
        shop_id,
        bot_type: BOT_TYPE_WA,
        name,
    },
    callback,
);

export const createWABABot = (shop_id, name, d360token, callback) => createBot(
    {
        shop_id,
        bot_type: BOT_TYPE_WABA,
        name,
        waba_dialog360_api_key: d360token,
    },
    callback,
);

export const createTGBot = (shop_id, name, tg_bot_token, callback) => createBot(
    {
        shop_id,
        bot_type: BOT_TYPE_TG,
        name,
        tg_bot_token,
    },
    callback,
);

export const createOKBot = (shop_id, name, ok_group_token, callback) => createBot(
    {
        shop_id,
        bot_type: BOT_TYPE_OK,
        name,
        ok_group_token,
    },
    callback,
);

export const createExpressBot = (
    shop_id,
    name,
    express_id,
    express_app_id,
    express_secret_key,
    callback,
) => createBot(
    {
        shop_id,
        bot_type: BOT_TYPE_EXPRESS,
        name,
        express_id,
        express_app_id,
        express_secret_key,
    },
    callback,
);

export const createViberBot = (shop_id, name, viber_bot_uri, viber_bot_token, callback) => createBot(
    {
        shop_id,
        name,
        viber_bot_uri,
        viber_bot_token,
        bot_type: BOT_TYPE_VIBER,
    },
    callback,
);


export const loadGroups = (base_url, callback) => {
    return function (dispatch) {
        dispatch(groupsRequestStart());

        const params = {
            user_id: store.getState().Auth.user.id,
        };
        serviceCoreAxiosInstance.get('get_groups', {params: params, baseURL: base_url}).then(
            response => {
                if (response.data.status === 'ok') {
                    dispatch(groupsRequestSuccess(response.data.result.count, response.data.result.items));
                    callback && callback(response.data.result.items);
                } else {
                    dispatch(groupsRequestFail(response.data.errors));
                }
            },
            error => dispatch(groupsRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    };
};
