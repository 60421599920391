import {createHashHistory} from 'history'
import {routerMiddleware} from 'connected-react-router'
import {applyMiddleware, compose, createStore} from 'redux';
import {createLogger} from 'redux-logger'
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {createEpicMiddleware} from 'redux-observable';
import thunk from 'redux-thunk';

import createRootReducer from './reducers';
import rootEpic from './epics'


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const loggerMiddleware = createLogger({
    predicate: ({args}) => process.env.REACT_APP_ENABLE_LOGGER,
});
const epicMiddleware = createEpicMiddleware();

export const history = createHashHistory();
const rootReducer = createRootReducer(history);

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2,  // see "Merge Process" section for details.
    whitelist: ['Auth', 'Notifications'],  // Things, we want to persist
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    {},
    composeEnhancers(
        applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            thunk,
            epicMiddleware,
            loggerMiddleware, // neat middleware that logs actions
        )
    )
);

epicMiddleware.run(rootEpic);

export const persistor = persistStore(store);
