import React from "react";
import {CustomInput} from "reactstrap";
import get from 'lodash/get'


const CheckboxField = (props) => {
    const myOnChange = (event) => {
        props.onChange(event.target.checked);
    };

    if (props.formData !== true && props.formData !== false) {
        props.onChange(false);
    }

    const description = props.schema.description || props.uiSchema['ui:description'];
    return <>
        <CustomInput
            type="checkbox"
            id={get(props.idSchema, '$id')}
            checked={props.formData}
            readOnly={get(props.uiSchema, 'ui:readonly')}
            onChange={myOnChange}
            label={
                <>
                    {get(props.schema, 'title')}
                    {(props.schema.title && props.required) ? ' *' : ''}
                </>
            }
        />
        <small className="form-text text-muted mb-3 mt-2">
            {description}
        </small>
    </>;
};

export default CheckboxField;
