export const GET_TEMPLATES_REQUEST_START = 'TEMPLATES/GET_TEMPLATES_REQUEST_START';
export const GET_TEMPLATES_REQUEST_SUCCESS = 'TEMPLATES/GET_TEMPLATES_REQUEST_SUCCESS';
export const GET_TEMPLATES_REQUEST_FAIL = 'TEMPLATES/GET_TEMPLATES_REQUEST_FAIL';

export const INSTALL_TEMPLATE_REQUEST_START = 'TEMPLATES/INSTALL_TEMPLATE_REQUEST_START';
export const INSTALL_TEMPLATE_REQUEST_SUCCESS = 'TEMPLATES/INSTALL_TEMPLATE_REQUEST_SUCCESS';
export const INSTALL_TEMPLATE_REQUEST_FAIL = 'TEMPLATES/INSTALL_TEMPLATE_REQUEST_FAIL';


export const getTemplatesRequestStart = (data) => ({
    type: GET_TEMPLATES_REQUEST_START,
    data,
});

export const getTemplatesRequestSuccess = (result) => ({
    type: GET_TEMPLATES_REQUEST_SUCCESS,
    result,
});

export const getTemplatesRequestFail = (error) => ({
    type: GET_TEMPLATES_REQUEST_FAIL,
    error,
});

export const installTemplateRequestStart = (templateId, data) => ({
    type: INSTALL_TEMPLATE_REQUEST_START,
    templateId,
    data,
});

export const installTemplateRequestSuccess = (result) => ({
    type: INSTALL_TEMPLATE_REQUEST_SUCCESS,
    result,
});

export const installTemplateRequestFail = (error) => ({
    type: INSTALL_TEMPLATE_REQUEST_FAIL,
    error,
});
