import {
    START_BROADCAST_REQUEST_START,
    START_BROADCAST_REQUEST_SUCCESS,
    START_BROADCAST_REQUEST_FAIL,
    STOP_BROADCAST_REQUEST_START,
    STOP_BROADCAST_REQUEST_SUCCESS,
    STOP_BROADCAST_REQUEST_FAIL,
    RESUME_BROADCAST_REQUEST_START,
    RESUME_BROADCAST_REQUEST_SUCCESS,
    RESUME_BROADCAST_REQUEST_FAIL,
} from '../actions/Broadcast';


const defaultState = {
    isSendingRequest: false,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case START_BROADCAST_REQUEST_START:
        case STOP_BROADCAST_REQUEST_START:
        case RESUME_BROADCAST_REQUEST_START:
            return {
                ...state,
                isSendingRequest: true,
            };
        case START_BROADCAST_REQUEST_SUCCESS:
        case STOP_BROADCAST_REQUEST_SUCCESS:
        case RESUME_BROADCAST_REQUEST_SUCCESS:
        case START_BROADCAST_REQUEST_FAIL:
        case STOP_BROADCAST_REQUEST_FAIL:
        case RESUME_BROADCAST_REQUEST_FAIL:
            return {
                ...state,
                isSendingRequest: false,
            };
    }

    return state;
}
