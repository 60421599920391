import get from 'lodash/get';
import merge from 'lodash/merge';
import clone from 'lodash/clone';
import keyBy from 'lodash/keyBy';
import unionBy from 'lodash/unionBy';
import mergeWith from 'lodash/mergeWith';
import values from 'lodash/values';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import assign from "lodash/assign";
import isEqual from "lodash/isEqual";

import {
    LOAD_CHAT_BY_ID_REQUEST_FAIL,
    LOAD_CHAT_BY_ID_REQUEST_START,
    LOAD_CHAT_BY_ID_REQUEST_SUCCESS,
    LOAD_CHATS_REQUEST_FAIL,
    LOAD_CHATS_REQUEST_START,
    LOAD_CHATS_REQUEST_SUCCESS,
    LOAD_MESSAGES_REQUEST_FAIL,
    LOAD_MESSAGES_REQUEST_START,
    LOAD_MESSAGES_REQUEST_SUCCESS,
    MERGE_CHATS,
    MERGE_MESSAGES,
    RECEIVE_MESSAGE,
    SET_ACTIVE_CHAT,
    UPDATE_ACTIVE_CHAT_DATA,
    SET_ACTIVE_CHAT_SCHEMA,
    SET_ALL_OLD_CHATS_LOADED,
    SET_ALL_OLD_MESSAGES_LOADED,
    SET_CHATS,
    SET_CHAT_FILTERS,
    UPDATE_CHAT_FILTERS,
    CLEAR_CHAT_FILTERS_KEY,
    SET_CHATS_VISIBLE_LIMIT,
    SET_MESSAGES,
    SET_MESSAGES_VISIBLE_LIMIT,
    SET_WS_CONNECTED,
} from "../actions/Chats";


const filterChats = (chats, chatFilters) => {
    return filter(
        chats,
        (chat) => !(
            chatFilters.is_thread_open !== undefined && chatFilters.is_thread_open !== chat.is_thread_open
            || chatFilters.assigned_to_id !== undefined && chatFilters.assigned_to_id !== get(chat.assigned_to, 'id')
        )
    )
};

const defaultState = {
    wsConnected: false,
    chats: undefined,
    activeChat: undefined,
    activeChatSchema: undefined,
    chatsRequestSending: false,
    chatsVisibleLimit: 20,
    allOldChatsLoaded: false,
    chatFilters: {},

    messages: [],
    messagesRequestSending: false,
    messagesVisibleLimit: 20,
    allOldMessagesLoaded: false,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case SET_WS_CONNECTED:
            return {
                ...state,
                wsConnected: action.is_connected,
            };
        case SET_CHATS:
            return {
                ...state,
                chats: cloneDeep(action.chats),
                activeChat: undefined,
                allOldChatsLoaded: false,
                chatsVisibleLimit: 20,

                messages: [],
                allOldMessagesLoaded: false,
                messagesVisibleLimit: 20,
            };
        case SET_CHATS_VISIBLE_LIMIT:
            return {
                ...state,
                chatsVisibleLimit: action.limit,
            };
        case MERGE_CHATS:
            const actionChatsById = keyBy(action.chats, 'id');
            const stateChatsById = keyBy(state.chats, 'id');
            const resultChatsById = mergeWith(
                stateChatsById,
                actionChatsById,
                (left, right) => {
                    if (!left) {
                        return right;
                    } else if (!right) {
                        return left;
                    }
                    return left.updated_at > right.updated_at ? left : right;
                }
            );

            const newChats = values(resultChatsById);
            newChats.sort((left, right) => right.last_message_time - left.last_message_time);

            return {
                ...state,
                chats: cloneDeep(newChats),
            };
        case SET_CHAT_FILTERS:
            return {
                ...state,
                chatFilters: cloneDeep(action.filters),
            };
        case UPDATE_CHAT_FILTERS:
            const updatedChatFilters = assign(clone(state.chatFilters), action.filtersToUpdate);

            if (!isEqual(updatedChatFilters, state.chatFilters)) {
                return {
                    ...state,
                    chatFilters: updatedChatFilters,
                }
            }
            return state;
        case CLEAR_CHAT_FILTERS_KEY:
            const clearedChatFilters = cloneDeep(state.chatFilters);
            delete clearedChatFilters[action.key];

            if (!isEqual(clearedChatFilters, state.chatFilters)) {
                return {
                    ...state,
                    chatFilters: clearedChatFilters,
                }
            }
            return state;
        case LOAD_CHATS_REQUEST_START:
            return {
                ...state,
                chatsRequestSending: true,
            };
        case LOAD_CHATS_REQUEST_SUCCESS:
            return {
                ...state,
                chatsRequestSending: false,
            };
        case LOAD_CHATS_REQUEST_FAIL:
            return {
                ...state,
                chatsRequestSending: false,
            };
        case LOAD_CHAT_BY_ID_REQUEST_START:
            return {
                ...state,
                chatByIdRequestSending: true,
            };
        case LOAD_CHAT_BY_ID_REQUEST_SUCCESS:
            return {
                ...state,
                chatByIdRequestSending: false,
            };
        case LOAD_CHAT_BY_ID_REQUEST_FAIL:
            return {
                ...state,
                chatByIdRequestSending: false,
            };
        case SET_ACTIVE_CHAT:
            let newActiveChat = action.chat;
            if (newActiveChat && get(newActiveChat, 'id') === get(state, 'activeChat.id') && !newActiveChat.store) {
                newActiveChat = merge(state.activeChat, newActiveChat)
            }
            return {
                ...state,
                activeChat: cloneDeep(newActiveChat),
                messages: [],
            };
        case UPDATE_ACTIVE_CHAT_DATA:
            const updatedActiveChat = merge(cloneDeep(state.activeChat), action.data);

            let updatedChats = cloneDeep(state.chats);
            const idx = findIndex(updatedChats, ['id', updatedActiveChat.id]);
            if (idx !== -1) {
                updatedChats[idx] = updatedActiveChat;
            }

            updatedChats = filterChats(updatedChats, state.chatFilters);

            return {
                ...state,
                chats: updatedChats,
                activeChat: updatedActiveChat,
            };

        case SET_ACTIVE_CHAT_SCHEMA:
            return {
                ...state,
                activeChatSchema: cloneDeep(action.schema),
            };

        case SET_MESSAGES:
            return {
                ...state,
                messages: cloneDeep(action.messages),
                allOldMessagesLoaded: false,
                messagesVisibleLimit: 20,
            };
        case SET_MESSAGES_VISIBLE_LIMIT:
            return {
                ...state,
                messagesVisibleLimit: action.limit,
            };
        case RECEIVE_MESSAGE:
            // Не делаем Deep - клонирование для быстроты работы
            const messages = clone(state.messages);
            messages.unshift(action.message);

            return {
                ...state,
                messages,
            };
        case MERGE_MESSAGES:
            const newMessages = unionBy(state.messages, action.messages, (msg) => msg.id);
            newMessages.sort((left, right) => right.created_at - left.created_at);

            return {
                ...state,
                messages: newMessages,
            };
        case LOAD_MESSAGES_REQUEST_START:
            return {
                ...state,
                messagesRequestSending: true,
            };
        case LOAD_MESSAGES_REQUEST_SUCCESS:
            return {
                ...state,
                messagesRequestSending: false,
            };
        case LOAD_MESSAGES_REQUEST_FAIL:
            return {
                ...state,
                messagesRequestSending: false,
            };
        case SET_ALL_OLD_MESSAGES_LOADED:
            return {
                ...state,
                allOldMessagesLoaded: action.loaded,
            };
        case SET_ALL_OLD_CHATS_LOADED:
            return {
                ...state,
                allOldChatsLoaded: action.loaded,
            };
    }
    return state;
}
