import React from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import get from "lodash/get";
import Hamburger from "react-hamburgers";

import HeaderLogo from '../AppLogo';
import MegaMenu from './Components/MegaMenu';
// import UserBox from './Components/UserBox';
import {
    SHOP_SOURCE_CHAT_API,
    SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU,
    SHOP_SOURCE_RETAIL_CRM_WABA_RU
} from "../../constants";
// import HeaderDots from "./Components/HeaderDots";
import {setEnableMobileMenu} from "../../actions/ThemeOptions";
import AppMobileMenu from "../AppMobileMenu";
import AccountMenu from "./Components/AccountMenu";


const Header = (
    {
        user,
        activeShop,
        enableHeaderShadow,
        headerBackgroundColor,
        enableMobileMenuSmall,
        enableMobileMenu,
        setEnableMobileMenu
    }
) => {
    const isChatApi = get(activeShop, 'source') === SHOP_SOURCE_CHAT_API;
    const isRetailCRM = (get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_WABA_RU
      || get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU);

    if ((isChatApi || isRetailCRM) && !get(user, 'is_superuser')) {
        return <>
            <div className={cx(
                'hamburger__container-wrapper',
                {'hamburger__container-wrapper--open': enableMobileMenu},
            )}>
                <div onClick={() => setEnableMobileMenu(!enableMobileMenu)} className="hamburger__container">
                    <Hamburger
                        active={enableMobileMenu}
                        type="elastic"
                    />
                </div>
            </div>
            <AppMobileMenu/>
        </>
    }

    return (
        <div className={cx("app-header", headerBackgroundColor, {'header-shadow': enableHeaderShadow})}>
            <HeaderLogo/>

            <div className={cx(
                "app-header__content",
                {'header-mobile-open': enableMobileMenuSmall},
            )}>
                <div className="app-header-left">
                    {/*<SearchBox/>*/}
                    <MegaMenu/>
                </div>
                <div className="app-header-right">
                    {/*<HeaderDots/>*/}
                    {/*<UserBox/>*/}
                    <AccountMenu/>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.Auth.user,
    activeShop: state.Shops.activeShop,
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = dispatch => ({
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);