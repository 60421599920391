import axios, {CancelToken} from "axios";
import i18next from 'i18next';

import {store} from "../configureStore";
import {
    addFirebaseTokenRequestFail,
    addFirebaseTokenRequestStart,
    addFirebaseTokenRequestSuccess,
    revokeFirebaseTokenRequestStart,
    revokeFirebaseTokenRequestSuccess,
    revokeFirebaseTokenRequestFail,
} from '../actions/Notifications';
import {apiAxiosInstance} from "../helpers/api";


export const addFirebaseToken = (firebaseToken, callback) => {
    return function (dispatch) {
        const activeShopId = store.getState().Shops.activeShop.id;
        const cancelTokenSource = CancelToken.source();
        const data = {
            firebase_token: firebaseToken,
        }
        dispatch(addFirebaseTokenRequestStart(data, cancelTokenSource));

        apiAxiosInstance.post(
            `/shop/shops/${activeShopId}/add_firebase_token/`,
            data
        ).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(addFirebaseTokenRequestSuccess(data, response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(addFirebaseTokenRequestFail(response.data.errors));
                }
            },
            error => {
                if (axios.isCancel(error)) {
                    console.log('Request is cancelleed');
                } else {
                    dispatch(addFirebaseTokenRequestFail({
                        common: i18next.t('errors.common.request_sent', 'Error sending request'),
                    }))
                }
            },
        );
    }
};


export const revokeFirebaseToken = (firebaseToken, callback) => {
    return function (dispatch) {
        const activeShopId = store.getState().Shops.activeShop.id;
        const cancelTokenSource = CancelToken.source();
        const data = {
            firebase_token: firebaseToken,
        }
        dispatch(revokeFirebaseTokenRequestStart(data, cancelTokenSource));

        apiAxiosInstance.post(
            `/shop/shops/${activeShopId}/revoke_firebase_token/`,
            data
        ).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(revokeFirebaseTokenRequestSuccess(data, response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(revokeFirebaseTokenRequestFail(response.data.errors));
                }
            },
            error => {
                if (axios.isCancel(error)) {
                    console.log('Request is cancelleed');
                } else {
                    dispatch(revokeFirebaseTokenRequestFail({
                        common: i18next.t('errors.common.request_sent', 'Error sending request'),
                    }))
                }
            },
        );
    }
};
