import React from 'react';
import {connect} from "react-redux";
import {Card, CardBody} from "reactstrap";

import APIForm2 from "../../Components/APIForm2";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import PageTitle from "../../Layout/AppMain/PageTitle";
import {useTranslation} from "react-i18next";


const Common = (
    {
        activeShop,
    }
) => {
    const {t, i18n} = useTranslation();
    
    return <>
        <AppHeader/>
        <div className="app-main">
            <AppSidebar/>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <PageTitle
                        heading={t('settings.common.page.title', 'General settings')}
                        subheading=''
                        icon="fa fas fa-cog icon-gradient bg-night-fade"
                        enablePageTitleCreationButton={false}
                    />
                    <Card
                        className="main-card mb-3"
                        onKeyDown={(event) => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                        }}
                    >
                        <CardBody>
                            <APIForm2
                                apiPath='shop/shops'
                                modelId={activeShop.id}
                                schemaPath='setting_common_form_schema'
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    </>;
};

const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Common);