import React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import {DefaultPortModel, PortWidget} from '@projectstorm/react-diagrams';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import {
    CONDITION_ARG_BOT,
    CONDITION_ARG_FORMULA,
    CONDITION_ARG_TAG,
    CONDITION_CURRENT_DATE,
    CONDITION_CURRENT_DATETIME,
    CONDITION_CUSTOM_FIELD,
    CONDITION_SYSTEM_FIELD,
    CUTOM_FIELD_OPERATION_SET_DATETIME_NOW,
    CUTOM_FIELD_OPERATION_SET_EXACT,
    CUTOM_FIELD_OPERATION_SET_FROM_OTHER_FIELD,
    CUTOM_FIELD_TYPE_DATE,
    CUTOM_FIELD_TYPE_DATETIME,
} from '../../../../constants';
import {withTranslation} from "react-i18next";


class ConditionNodeWidget_ extends AbstractNodeWidget {
    constructor(props) {
        super(props);

        this.type = 'condition-node';
    }

    // getHeaderClassName() {
    //     return 'bg-tempting-azure bg-opacity-3';
    // }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <i className="pe-7s-way"> </i> {options.step.name || this.props.t('pages.main.flow_builder.node.condition.name', 'Condition')}
        </>;
    }

    getBody() {
        const that = this;
        const ports = this.props.node.getPorts();
        const options = this.props.node.options;
        const condition_groups = get(options.step, 'state.condition_config.condition_groups', []);

        const conditionGroupsHtml = [];
        condition_groups.map((group, idx) => {
            const port = get(ports, `condition-${that.id}-${idx}`);

            const conditions = [];
            group.conditions.map(cond => {
                let innerHtml = <></>;
                if (get(cond, 'left_arg.type') === CONDITION_ARG_TAG) {
                    innerHtml = <>
                        <strong>{this.props.t('pages.main.flow_builder.node.condition.text.tags', 'Tags')}</strong>{' '}
                        {get(cond, 'operation.name', '').toLowerCase()}{' '}
                        <strong>{get(cond, 'right_arg.name')}</strong>
                    </>;
                } else if (get(cond, 'left_arg.type') === CONDITION_ARG_FORMULA) {
                    innerHtml = <>{this.props.t('pages.main.flow_builder.node.condition.text.formula', 'Formula')}: <strong>{get(cond, 'text_input')}</strong></>;
                } else if (get(cond, 'left_arg.type') === CONDITION_ARG_BOT) {
                    innerHtml = <>
                        {this.props.t('pages.main.flow_builder.node.condition.text.channel', 'Channel')}:{' '}
                        <strong>{get(cond, 'right_arg.name')}</strong>
                    </>;
                } else if ([
                    CONDITION_SYSTEM_FIELD,
                    CONDITION_CUSTOM_FIELD,
                    CONDITION_CURRENT_DATE,
                    CONDITION_CURRENT_DATETIME,
                ].indexOf(get(cond, 'left_arg.type')) !== -1) {
                    const rArgOperation = get(cond, 'right_arg_group.right_arg_operation');
                    const rArgDateExact = get(cond, 'right_arg_group.date_exact');
                    const rArgDatetimeExact = get(cond, 'right_arg_group.datetime_exact');
                    const rArgOtherField = get(cond, 'right_arg_group.other_field');
                    const rArgHasOffset = get(cond, 'right_arg_group.has_offset');
                    const rArgOffsetConfig = get(cond, 'right_arg_group.offset_config');

                    let right_arg_txt = get(cond, 'right_arg.name') || get(cond, 'text_input');

                    switch (get(cond, 'left_arg.type')) {
                        case CUTOM_FIELD_TYPE_DATE:
                        case CONDITION_CURRENT_DATE:
                            if (isEmpty(rArgOperation)) {
                                return undefined;
                            }

                            if (rArgOperation.id === CUTOM_FIELD_OPERATION_SET_EXACT) {
                                right_arg_txt = rArgDateExact;
                            } else if (rArgOperation.id === CUTOM_FIELD_OPERATION_SET_DATETIME_NOW) {
                                right_arg_txt = this.props.t('pages.main.flow_builder.node.condition.text.current_date', 'current date') + (rArgHasOffset ? ` ${rArgOffsetConfig.sign} ${rArgOffsetConfig.amount} ${rArgOffsetConfig.interval.id}` : '');
                            } else if (rArgOperation.id === CUTOM_FIELD_OPERATION_SET_FROM_OTHER_FIELD) {
                                right_arg_txt = rArgOtherField && (rArgOtherField.name + (rArgHasOffset ? ` ${rArgOffsetConfig.sign} ${rArgOffsetConfig.amount} ${rArgOffsetConfig.interval.id}` : ''));
                            }
                            break;
                        case CUTOM_FIELD_TYPE_DATETIME:
                        case CONDITION_CURRENT_DATETIME:
                            if (isEmpty(rArgOperation)) {
                                return undefined;
                            }

                            if (rArgOperation.id === CUTOM_FIELD_OPERATION_SET_EXACT) {
                                right_arg_txt = rArgDatetimeExact;
                            } else if (rArgOperation.id === CUTOM_FIELD_OPERATION_SET_DATETIME_NOW) {
                                right_arg_txt = this.props.t('pages.main.flow_builder.node.condition.text.current_date_and_time', 'current date and time') + (rArgHasOffset ? ` ${rArgOffsetConfig.sign} ${rArgOffsetConfig.amount} ${rArgOffsetConfig.interval.id}` : '');
                            } else if (rArgOperation.id === CUTOM_FIELD_OPERATION_SET_FROM_OTHER_FIELD) {
                                right_arg_txt = rArgOtherField && (rArgOtherField.name + (rArgHasOffset ? ` ${rArgOffsetConfig.sign} ${rArgOffsetConfig.amount} ${rArgOffsetConfig.interval.id}` : ''));
                            }
                            break;
                    }

                    innerHtml = <>
                        <strong>{get(cond, 'left_arg.name')}</strong>
                        {' '}{get(cond, 'operation.name', '').toLowerCase()}{' '}
                        <strong>{right_arg_txt}</strong>
                    </>;
                }
                conditions.push(
                    <div className='py-1' key={conditions.length}>
                        {innerHtml}
                    </div>
                );
            });

            conditionGroupsHtml.push(
                <div className='condition-group border-bottom py-3' key={conditionGroupsHtml.length}>
                    {conditions}
                    {
                        port && <PortWidget engine={this.props.engine} port={port} className="out-port">
                            <div className="circle-port"/>
                        </PortWidget>
                    }
                </div>
            );
        });

        const fallbackPort = get(ports, `condition-${that.id}-fallback`);
        conditionGroupsHtml.push(
            <div className='condition-group py-3' key={conditionGroupsHtml.length}>
                {this.props.t('pages.main.flow_builder.node.condition.text.no_condition_is_met', 'No condition is met')}

                {
                    fallbackPort && <PortWidget engine={this.props.engine} port={fallbackPort} className="out-port">
                        <div className="circle-port"/>
                    </PortWidget>
                }
            </div>
        );

        return conditionGroupsHtml;
    }

    getErrors() {
        return '';
    }
}

export const ConditionNodeWidget = withTranslation()(ConditionNodeWidget_);

export class ConditionNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'condition-node',
            disableNextStepPort: true,
            disableCascadePort: true,
        });

        const that = this;
        const condition_groups = get(this.options.step, 'state.condition_config.condition_groups', []);
        condition_groups.map((group, idx) => {
            this.addPort(
                new DefaultPortModel({
                    in: false,
                    name: `condition-${that.id}-${idx}`,
                    label: '',
                    conditionsGroupCfg: {
                        idx: idx,
                        isFallback: false,
                    },
                })
            );
        });

        this.addPort(
            new DefaultPortModel({
                in: false,
                name: `condition-${that.id}-fallback`,
                label: '',
                conditionsGroupCfg: {
                    idx: condition_groups.length,
                    isFallback: true,
                },
            })
        );
    }
}


export class ConditionNodeFactory extends AbstractReactFactory {
    constructor() {
        super('condition-node');
    }

    generateModel(event) {
        return new ConditionNodeModel();
    }

    generateReactWidget(event) {
        return <ConditionNodeWidget engine={this.engine} node={event.model}/>;
    }
}
