import React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import {withTranslation} from "react-i18next";


class TextNodeWidget_ extends AbstractNodeWidget {
    constructor(props) {
        super(props);

        this.type = 'text-node';
    }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <i className="pe-7s-comment"> </i> {options.step.name || this.props.t('pages.main.flow_builder.node.text.name', 'Text message')}
        </>;
    }
}

export const TextNodeWidget = withTranslation()(TextNodeWidget_);

export class TextNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'text-node',
        });
    }
}


export class TextNodeFactory extends AbstractReactFactory {
    constructor() {
        super('text-node');
    }

    generateModel(event) {
        return new TextNodeModel();
    }

    generateReactWidget(event) {
        return <TextNodeWidget engine={this.engine} node={event.model}/>;
    }
}
