export const TOUCH_LOGIN_FORM = 'AUTH/TOUCH_LOGIN_FORM';
export const LOG_IN_REQUEST_START = 'AUTH/LOG_IN_REQUEST_START';
export const LOG_IN_REQUEST_SUCCESS = 'AUTH/LOG_IN_REQUEST_SUCCESS';
export const LOG_IN_REQUEST_FAIL = 'AUTH/LOG_IN_REQUEST_FAIL';

export const QUICK_LOG_IN_REQUEST_START = 'AUTH/QUICK_LOG_IN_REQUEST_START';
export const QUICK_LOG_IN_REQUEST_SUCCESS = 'AUTH/QUICK_LOG_IN_REQUEST_SUCCESS';
export const QUICK_LOG_IN_REQUEST_FAIL = 'AUTH/QUICK_LOG_IN_REQUEST_FAIL';

export const SEND_AUTH_HSM_REQUEST_START = 'SEND_AUTH_HSM_REQUEST_START';
export const SEND_AUTH_HSM_REQUEST_SUCCESS = 'SEND_AUTH_HSM_REQUEST_SUCCESS';
export const SEND_AUTH_HSM_REQUEST_FAIL = 'SEND_AUTH_HSM_REQUEST_FAIL';

export const SEND_CODE_REQUEST_START = 'AUTH/SEND_CODE_REQUEST_START';
export const SEND_CODE_REQUEST_SUCCESS = 'AUTH/SEND_CODE_REQUEST_SUCCESS';
export const SEND_CODE_REQUEST_FAIL = 'AUTH/SEND_CODE_REQUEST_FAIL';

export const TOUCH_PASSWORD_RESET_FORM = 'AUTH/TOUCH_PASSWORD_RESET_FORM';
export const SET_PASSWORD_RESET_FORM_ERRORS = 'AUTH/SET_PASSWORD_RESET_FORM_ERRORS';
export const ASK_PASSWORD_RESET_REQUEST_START = 'AUTH/ASK_PASSWORD_RESET_REQUEST_START';
export const ASK_PASSWORD_RESET_REQUEST_SUCCESS = 'AUTH/ASK_PASSWORD_RESET_REQUEST_SUCCESS';
export const ASK_PASSWORD_RESET_REQUEST_FAIL = 'AUTH/ASK_PASSWORD_RESET_REQUEST_FAIL';

export const SET_NEW_PASSWORD_REQUEST_START = 'AUTH/SET_NEW_PASSWORD_REQUEST_START';
export const SET_NEW_PASSWORD_REQUEST_SUCCESS = 'AUTH/SET_NEW_PASSWORD_REQUEST_SUCCESS';
export const SET_NEW_PASSWORD_REQUEST_FAIL = 'AUTH/SET_NEW_PASSWORD_REQUEST_FAIL';

export const VERIFY_CODE_REQUEST_START = 'AUTH/VERIFY_CODE_REQUEST_START';
export const VERIFY_CODE_REQUEST_SUCCESS = 'AUTH/VERIFY_CODE_REQUEST_SUCCESS';
export const VERIFY_CODE_REQUEST_FAIL = 'AUTH/VERIFY_CODE_REQUEST_FAIL';

export const SET_REGISTER_REFERRAL_CODE = 'AUTH/SET_REGISTER_REFERRAL_CODE';
export const SET_REGISTER_FORM_ERRORS = 'AUTH/SET_REGISTER_FORM_ERRORS';
export const TOUCH_REGISTER_FORM = 'AUTH/TOUCH_REGISTER_FORM';
export const RESET_REGISTER_FORM = 'AUTH/RESET_REGISTER_FORM';
export const REGISTER_REQUEST_START = 'AUTH/REGISTER_REQUEST_START';
export const REGISTER_REQUEST_SUCCESS = 'AUTH/REGISTER_REQUEST_SUCCESS';
export const REGISTER_REQUEST_FAIL = 'AUTH/REGISTER_REQUEST_FAIL';
export const REGISTER_CLOSE_NOTIFICATION_POPUP = 'AUTH/REGISTER_CLOSE_NOTIFICATION_POPUP';

export const LOGOUT_REQUEST_START = 'AUTH/LOGOUT_REQUEST_START';
export const LOGOUT_REQUEST_SUCCESS = 'AUTH/LOGOUT_REQUEST_SUCCESS';
export const LOGOUT_REQUEST_FAIL = 'AUTH/LOGOUT_REQUEST_FAIL';

export const GET_ME_REQUEST_START = 'AUTH/GET_ME_REQUEST_START';
export const GET_ME_REQUEST_SUCCESS = 'AUTH/GET_ME_REQUEST_SUCCESS';
export const GET_ME_REQUEST_FAIL = 'AUTH/GET_ME_REQUEST_FAIL';

export const RETAILCRM_SET_URL = 'RETAILCRM/SET_URL_AND_API_KEY';

export const RETAILCRM_REGISTER_REQUEST_START = 'RETAILCRM/REGISTER_REQUEST_START';
export const RETAILCRM_REGISTER_REQUEST_SUCCESS = 'RETAILCRM/REGISTER_REQUEST_SUCCESS';
export const RETAILCRM_REGISTER_REQUEST_FAIL = 'RETAILCRM/REGISTER_REQUEST_FAIL';


// --------------------------------------
// Login
// --------------------------------------

export const touchLoginForm = () => ({
    type: TOUCH_LOGIN_FORM,
});

export const logInRequestStart = (username, password) => ({
    type: LOG_IN_REQUEST_START,
    username,
    password,
});

export const logInRequestSuccess = (token, user) => ({
    type: LOG_IN_REQUEST_SUCCESS,
    token,
    user,
});

export const logInRequestFail = (errors) => ({
    type: LOG_IN_REQUEST_FAIL,
    errors,
});


// --------------------------------------

// --------------------------------------
// Quick Login
// --------------------------------------


export const quickLogInRequestStart = (username) => ({
    type: QUICK_LOG_IN_REQUEST_START,
    username,
});

export const quickLogInRequestSuccess = (authCode) => ({
    type: QUICK_LOG_IN_REQUEST_SUCCESS,
    authCode,
});

export const quickLogInRequestFail = (errors) => ({
    type: QUICK_LOG_IN_REQUEST_FAIL,
    errors,
});


// --------------------------------------

export const touchPasswordResetForm = () => ({
    type: TOUCH_PASSWORD_RESET_FORM,
});

export const setPasswordResetFormErrors = (errors) => ({
    type: SET_PASSWORD_RESET_FORM_ERRORS,
    errors,
});

export const askPasswordResetRequestStart = (email) => ({
    type: ASK_PASSWORD_RESET_REQUEST_START,
    email,
});

export const askPasswordResetRequestSuccess = (email, result) => ({
    type: ASK_PASSWORD_RESET_REQUEST_SUCCESS,
    email,
    result,
});

export const askPasswordResetRequestFail = (errors) => ({
    type: ASK_PASSWORD_RESET_REQUEST_FAIL,
    errors,
});


// --------------------------------------

export const setNewPasswordRequestStart = (password, token) => ({
    type: SET_NEW_PASSWORD_REQUEST_START,
    password,
    token,
});

export const setNewPasswordRequestSuccess = (password, token, result) => ({
    type: SET_NEW_PASSWORD_REQUEST_SUCCESS,
    password,
    token,
    result,
});

export const setNewPasswordRequestFail = (errors) => ({
    type: SET_NEW_PASSWORD_REQUEST_FAIL,
    errors,
});


// --------------------------------------
// Registration
// --------------------------------------

export const sendAuthHSMRequestStart = (phone_number) => ({
    type: SEND_AUTH_HSM_REQUEST_START,
    phone_number,
});

export const sendAuthHSMRequestSuccess = (result) => ({
    type: SEND_AUTH_HSM_REQUEST_SUCCESS,
    result,
});

export const sendAuthHSMRequestFail = errors => ({
    type: SEND_AUTH_HSM_REQUEST_FAIL,
    errors,
});

export const sendCodeRequestStart = (phone_number) => ({
    type: SEND_CODE_REQUEST_START,
    phone_number,
});

export const sendCodeRequestSuccess = (result) => ({
    type: SEND_CODE_REQUEST_SUCCESS,
    result,
});

export const sendCodeRequestFail = errors => ({
    type: SEND_CODE_REQUEST_FAIL,
    errors,
});

// --------------------------------------

export const verifyCodeRequestStart = (phone_number, code) => ({
    type: VERIFY_CODE_REQUEST_START,
    phone_number,
    code,
});

export const verifyCodeRequestSuccess = (result, phone_number, code) => ({
    type: VERIFY_CODE_REQUEST_SUCCESS,
    result,
    phone_number,
    code,
});

export const verifyCodeRequestFail = errors => ({
    type: VERIFY_CODE_REQUEST_FAIL,
    errors,
});

// --------------------------------------

export const setRegisterReferralCode = (referral_code) => ({
    type: SET_REGISTER_REFERRAL_CODE,
    referral_code,
});

export const setRegisterFormErrors = (errors) => ({
    type: SET_REGISTER_FORM_ERRORS,
    errors,
});

export const touchRegisterForm = () => ({
    type: TOUCH_REGISTER_FORM,
});

export const resetRegisterForm = () => ({
    type: RESET_REGISTER_FORM,
});

export const registerRequestStart = (email, phone_number, code, password, passwordrep, check, referral_code) => ({
    type: REGISTER_REQUEST_START,
    email,
    phone_number,
    code,
    password,
    passwordrep,
    check,
    referral_code
});

export const registerRequestSuccess = showEmailNotification => ({
    type: REGISTER_REQUEST_SUCCESS,
    showEmailNotification,
});

export const registerRequestFail = errors => ({
    type: REGISTER_REQUEST_FAIL,
    errors,
});

export const registerCloseNotificationPopup = () => ({
    type: REGISTER_CLOSE_NOTIFICATION_POPUP,
});

// --------------------------------------

export const retailCRMSetURL = (url) => ({
    type: RETAILCRM_SET_URL,
    url,
});

export const retailCRMRegisterRequestStart = (module_name, phone, retailcrm_url, retailcrm_api_key, code) => ({
    type: RETAILCRM_REGISTER_REQUEST_START,
    module_name,
    phone,
    retailcrm_url,
    retailcrm_api_key,
    code,
});

export const retailCRMRegisterRequestSuccess = email => ({
    type: RETAILCRM_REGISTER_REQUEST_SUCCESS,
    email,
});

export const retailCRMRegisterRequestFail = errors => ({
    type: RETAILCRM_REGISTER_REQUEST_FAIL,
    errors,
});



// --------------------------------------
// Logout
// --------------------------------------

export const logoutRequestStart = () => ({
    type: LOGOUT_REQUEST_START,
});

export const logoutRequestSuccess = () => ({
    type: LOGOUT_REQUEST_SUCCESS,
});

export const logoutRequestFail = errors => ({
    type: LOGOUT_REQUEST_FAIL,
    errors,
});


// --------------------------------------
// Get ME
// --------------------------------------

export const getMeRequestStart = (authToken) => ({
    type: GET_ME_REQUEST_START,
    authToken,
});

export const getMeRequestSuccess = (authToken, user) => ({
    type: GET_ME_REQUEST_SUCCESS,
    authToken,
    user,
});

export const getMeRequestFail = errors => ({
    type: GET_ME_REQUEST_FAIL,
    errors,
});
