import {
    SHOPS_REQUEST_START,
    SHOPS_REQUEST_SUCCESS,
    SHOPS_REQUEST_FAIL,
    SHOP_BY_ID_REQUEST_START,
    SHOP_BY_ID_REQUEST_SUCCESS,
    SHOP_BY_ID_REQUEST_FAIL,
    SHOPS_CLEAR_DATA,
    SHOPS_SELECT_SHOP,
    LOAD_ACTIVE_SHOP_ADMINISTRATORS_REQUEST_SUCCESS,
    LOAD_ACTIVE_SHOP_ADMINISTRATORS_REQUEST_FAIL,
    UPDATE_CURRENT_SHOP_ADMINISTRATOR,
} from '../actions/Shops'


const defaultState = {
    activeShop: undefined,
    activeShopAdministrators: undefined,
    currentShopAdministrator: undefined,
    shops: undefined,
    isSendingRequest: false,
    shopsAreInitiallyLoaded: false,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case SHOPS_REQUEST_START:
            return {
                ...state,
                isSendingRequest: action.setRequestSending,
            }

        case SHOP_BY_ID_REQUEST_START:
            return {
                ...state,
                isSendingRequest: true,
            }

        case SHOPS_REQUEST_FAIL:
        case SHOP_BY_ID_REQUEST_FAIL:
            return {
                ...state,
                isSendingRequest: false,
            }

        case SHOPS_REQUEST_SUCCESS:
            return {
                ...state,
                shops: action.shops,
                shopsAreInitiallyLoaded: true,
                isSendingRequest: false,
            };

        case SHOP_BY_ID_REQUEST_SUCCESS:
            return {
                ...state,
                shops: [action.shop],
                shopsAreInitiallyLoaded: true,
                isSendingRequest: false,
            };

        case SHOPS_CLEAR_DATA:
            return defaultState;

        case SHOPS_SELECT_SHOP:
            return {
                ...state,
                activeShop: action.shop,
            };

        case LOAD_ACTIVE_SHOP_ADMINISTRATORS_REQUEST_SUCCESS:
            return {
                ...state,
                activeShopAdministrators: action.shopAdministrators,
            };

        case LOAD_ACTIVE_SHOP_ADMINISTRATORS_REQUEST_FAIL:
            return {
                ...state,
                activeShopAdministrators: undefined,
            };

        case UPDATE_CURRENT_SHOP_ADMINISTRATOR:
            return {
                ...state,
                currentShopAdministrator: action.currentShopAdministrator,
            };
    }
    return state;
}
