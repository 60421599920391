import axios from "axios";
import {merge} from "lodash";
import {getI18n} from "react-i18next";
import {get} from "lodash";

import {store} from "../configureStore";
import {
    TIMEOUT_API,
    TIMEOUT_SERVICE_CORE,
    TIMEOUT_SERVICE_AUTH,
    TIMEOUT_SERVICE_BROADCAST,
    TIMEOUT_SERVICE_AVITO,
    TIMEOUT_FILE_UPLOAD_TO_CDN
} from "../constants";

axios.defaults.crossDomain = (process.env.NODE_ENV === 'development');

const addAuthorizationToRequestConfig = (config) => {
    // TODO: Decide whether to check store for readiness and presence of the token
    const configAddon = {
      headers: {
        'Authorization': `Bearer ${store.getState().Auth.authToken}`
      }
    };
    return merge(config, configAddon)
};

const addAcceptLanguageHeaderToRequestConfig = (config) => {
    
    const i18n = getI18n();
    // Detector has to be sync
    const lang = get(i18n, 'languages.0') || i18n.services.languageDetector.detect()[0];
    const configAddon = {
      headers: {
        'Accept-Language': lang
      }
    };
    return merge(config, configAddon)
};


const apiAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: TIMEOUT_API,
    headers: {
      'Content-Type': 'application/json'
    }
});
apiAxiosInstance.interceptors.request.use(addAuthorizationToRequestConfig, error => Promise.reject(error));
apiAxiosInstance.interceptors.request.use(addAcceptLanguageHeaderToRequestConfig, error => Promise.reject(error));


const serviceCoreAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_CORE_URL,
    timeout: TIMEOUT_SERVICE_CORE,
    headers: {
      'Content-Type': 'application/json'
    }
});
serviceCoreAxiosInstance.interceptors.request.use(addAuthorizationToRequestConfig, error => Promise.reject(error));
serviceCoreAxiosInstance.interceptors.request.use(addAcceptLanguageHeaderToRequestConfig, error => Promise.reject(error));


const serviceAuthAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_AUTH_URL,
    timeout: TIMEOUT_SERVICE_AUTH,
    headers: {
      'Content-Type': 'application/json'
    }
});
serviceAuthAxiosInstance.interceptors.request.use(addAcceptLanguageHeaderToRequestConfig, error => Promise.reject(error));

// TODO: Remove the instance after fully migrating to service_auth
const apiAuthAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}auth/`,
    timeout: TIMEOUT_SERVICE_AUTH,
    headers: {
      'Content-Type': 'application/json'
    }
});
apiAuthAxiosInstance.interceptors.request.use(addAcceptLanguageHeaderToRequestConfig, error => Promise.reject(error));

//TODO remove mock instance after move quick auth to the correct route
const apiQuickAuthAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}v2/auth/`,
    timeout: TIMEOUT_SERVICE_AUTH,
    headers: {
        'Content-Type': 'application/json'
    }
});
apiAuthAxiosInstance.interceptors.request.use(addAcceptLanguageHeaderToRequestConfig, error => Promise.reject(error));


const serviceBroadcastAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_BROADCAST_URL,
    timeout: TIMEOUT_SERVICE_BROADCAST,
    headers: {
      'Content-Type': 'application/json'
    }
});
serviceBroadcastAxiosInstance.interceptors.request.use(addAuthorizationToRequestConfig, error => Promise.reject(error));
serviceBroadcastAxiosInstance.interceptors.request.use(addAcceptLanguageHeaderToRequestConfig, error => Promise.reject(error));


const serviceAvitoAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_AVITO_URL,
    timeout: TIMEOUT_SERVICE_AVITO,
    headers: {
      'Content-Type': 'application/json'
    }
});
serviceAvitoAxiosInstance.interceptors.request.use(addAuthorizationToRequestConfig, error => Promise.reject(error));
serviceAvitoAxiosInstance.interceptors.request.use(addAcceptLanguageHeaderToRequestConfig, error => Promise.reject(error));


const uploadToCdnAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/cdn/upload/`,
    timeout: TIMEOUT_FILE_UPLOAD_TO_CDN,
});
uploadToCdnAxiosInstance.interceptors.request.use(addAuthorizationToRequestConfig, error => Promise.reject(error));
uploadToCdnAxiosInstance.interceptors.request.use(addAcceptLanguageHeaderToRequestConfig, error => Promise.reject(error));

export {
    apiAxiosInstance,
    apiQuickAuthAxiosInstance,
    serviceCoreAxiosInstance,
    serviceAuthAxiosInstance,
    apiAuthAxiosInstance,
    serviceBroadcastAxiosInstance,
    serviceAvitoAxiosInstance,
    uploadToCdnAxiosInstance
}