import React, {Fragment} from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import {DefaultPortModel, PortWidget} from '@projectstorm/react-diagrams';
import get from "lodash/get";

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import {store} from "../../../../configureStore";
import {withTranslation} from "react-i18next";


class StoreOrderNodeWidget_ extends AbstractNodeWidget {
    constructor(props) {
        super(props);

        this.type = 'store-order-node';
    }

    // getHeaderClassName() {
    //     return 'bg-sunny-morning bg-opacity-3';
    // }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <i className="pe-7s-check"> </i> {options.step.name || this.props.t('pages.main.flow_builder.node.store_order.name', 'Process order')}
        </>;
    }


    getBody() {
        const options = this.props.node.options;
        const ports = this.props.node.getPorts();
        const text = get(options.step.text, store.getState().Shops.activeShop.lang_by_default);
        const imageUrl = options.step.image_url;
        const is_paid = get(options.step, 'state.store_order_config.is_paid', false);

        let callbacksHTML = '';
        if (is_paid) {
            const paymentSuccessPort = get(ports, `store-order-${this.id}-payment-success`);
            const paymentFailPort = get(ports, `store-order-${this.id}-payment-fail`);

            callbacksHTML = <>
                <div className='fallback-group border-top py-3 position-relative'>
                    <strong className='text-success'>✓ &nbsp; {this.props.t('pages.main.flow_builder.node.store_order.text.payment_successful', 'Successful payment')}</strong>
                    <PortWidget engine={this.props.engine} port={paymentSuccessPort} className="out-port">
                        <div className="circle-port"/>
                    </PortWidget>
                </div>
                <div className='fallback-group border-top py-3 position-relative'>
                    <strong className='text-secondary'>✕ &nbsp; {this.props.t('pages.main.flow_builder.node.store_order.text.payment_failed', 'Payment failed')}</strong>
                    <PortWidget engine={this.props.engine} port={paymentFailPort} className="out-port">
                        <div className="circle-port"/>
                    </PortWidget>
                </div>
            </>;
        }

        return <>
            {
                imageUrl && <div className="text-center">
                    <img
                        className="img-fluid mb-3"
                        draggable={false}
                        src={imageUrl}
                    />
                </div>
            }
            {
                text && <div className='pb-3'>
                    {
                        text.split('\n').map((item, key) => {
                            return <Fragment key={key}>{item}<br/></Fragment>
                        })
                    }
                </div>
            }

            {callbacksHTML}
        </>;
    }

    getErrors() {
        const options = this.props.node.options;
        const text = get(options.step.text, store.getState().Shops.activeShop.lang_by_default);
        const imageUrl = options.step.image_url;
        const is_paid = get(options.step, 'state.store_order_config.is_paid', false);

        const errors = [];
        if (!text && !imageUrl) {
            errors.push(<div className='pb-3'>{this.props.t('pages.main.flow_builder.node.store_order.error.add_text_or_image', 'Please provide text or image')}</div>);
        }

        return errors.map((error, idx) => <div className='py-2' key={idx}>
                <i className='text-danger opacity-9'>{error}</i>
            </div>
        );
    }
}

export const StoreOrderNodeWidget = withTranslation()(StoreOrderNodeWidget_);

export class StoreOrderNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'store-order-node'
        });

        const is_paid = get(this.options.step, 'state.store_order_config.is_paid', false);
        if (is_paid) {
            this.addPort(
                new DefaultPortModel({
                    in: false,
                    name: `store-order-${this.id}-payment-success`,
                    label: '',
                    storeOrderGroupCfg: {
                        type: 'success',
                    },
                })
            );
            this.addPort(
                new DefaultPortModel({
                    in: false,
                    name: `store-order-${this.id}-payment-fail`,
                    label: '',
                    storeOrderGroupCfg: {
                        type: 'fail',
                    },
                })
            );
        }
    }
}


export class StoreOrderNodeFactory extends AbstractReactFactory {
    constructor() {
        super('store-order-node');
    }

    generateModel(event) {
        return new StoreOrderNodeModel();
    }

    generateReactWidget(event) {
        return <StoreOrderNodeWidget engine={this.engine} node={event.model}/>;
    }
}
