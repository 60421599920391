import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Button, Card} from "reactstrap";
import {useTranslation} from "react-i18next";

import okLogo from '../../../../assets/utils/images/messenger/ok.png';


const ConnectCardOK = (
    {
        activeShop,
    }
) => {
    const {t, i18n} = useTranslation();

    return <Card className="mb-3 profile-responsive">
        <div className="dropdown-menu-header text-dark">
            <div className="dropdown-menu-header-inner bg-white">
                <div className="menu-header-content btn-pane-right d-block text-center">
                    <h5 className="d-flex align-items-center justify-content-center mb-3">
                        <img src={okLogo} alt="OK" style={{height: 30}} className='mr-2'/>
                        {t('connect_cards.ok.title', 'Odnoklassniki')}
                    </h5>
                    <h6 className='opacity-6 my-4'>
                        {t('connect_cards.ok.caption', "Communicate with the group's subscribers in Odnoklassniki")}
                    </h6>
                    <div className="menu-header-btn-pane">
                        <Link to={`/${activeShop.id}/connect/ok`}>
                            <Button color="main">
                                {t('connect_bot.common.submit', 'Connect')}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </Card>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCardOK);
