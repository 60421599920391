import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import io from "socket.io-client";
import 'moment/locale/ru';
import {Card,} from "reactstrap";
import {Route, Switch} from 'react-router-dom';

import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import {setWsConnected} from "../../actions/Chats";
import {isMobileDevice} from '../../index';
import Chats from "./Chats/index";
import Messages from "./Messages/index";
import ChatDetail from "./Detail/ChatDetail";


const ChatsWrapper = (
    {
        match,
        wsConnected,
        setWsConnected,
    }
) => {
    // State
    const [socket, setSocket] = useState(undefined);

    // --------------------------
    // Hooks
    // --------------------------
    // componentDidMount && componentWillUnmount
    useEffect(() => {
        let newSocket = io(
            process.env.REACT_APP_MESSENGER_SOCKET_URL,
            {
                // path: '/ws',
                transports: ['websocket'],
            }
        );

        newSocket.on('connect', () => {
            setWsConnected(true);
        });
        newSocket.on('reconnect', () => {
            setWsConnected(true);
        });
        newSocket.on('disconnect', () => {
            setWsConnected(false);
        });

        // Errors
        newSocket.on('connect_error', () => {
            setWsConnected(false);
        });
        newSocket.on('connect_timeout', () => {
            setWsConnected(false);
        });
        newSocket.on('reconnect_error', () => {
            setWsConnected(false);
        });
        newSocket.on('reconnect_failed', () => {
            setWsConnected(false);
        });

        setSocket(newSocket);

        // componentWillUnmount
        return () => {
            newSocket.removeAllListeners();
            newSocket.close();
        };
    }, []);

    const isMobile = isMobileDevice();

    return <Fragment>
        <AppHeader/>
        <div className="app-main">
            <AppSidebar/>
            <div className="app-main__outer position-fixed w-100">
                <div className="app-main__inner p-0">
                    <div className='app-inner-layout chat-layout'>
                        {
                            socket && <Switch>
                                <Route
                                    exact path={`${match.url}/`}
                                    render={
                                        (props) => <Fragment>
                                            {
                                                isMobile &&
                                                <div className="app-inner-layout__wrapper flex-column">
                                                    <Chats socket={socket}/>
                                                </div>
                                            }
                                            {
                                                !isMobile && <div className="app-inner-layout__wrapper">
                                                    <Card className="app-inner-layout__sidebar">
                                                        <Chats socket={socket}/>
                                                    </Card>
                                                    <Card className="app-inner-layout__content"/>
                                                    <Card className='app-inner-layout__right-col'/>
                                                </div>
                                            }
                                        </Fragment>
                                    }
                                />
                                <Route
                                    exact path={`${match.url}/:activeChatId/`}
                                    render={
                                        ({match}) => {
                                            return <Fragment>
                                                {
                                                    isMobile &&
                                                    <div className="app-inner-layout__wrapper flex-column">
                                                        <Messages
                                                            socket={socket}
                                                            match={match}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    !isMobile &&
                                                    <div className="app-inner-layout__wrapper">
                                                        <Card className="app-inner-layout__sidebar">
                                                            <Chats
                                                                socket={socket}
                                                                match={match}
                                                            />
                                                        </Card>
                                                        <Card className="app-inner-layout__content">
                                                            <Messages
                                                                socket={socket}
                                                                match={match}
                                                            />
                                                        </Card>
                                                        <Card className='app-inner-layout__right-col'>
                                                            <ChatDetail/>
                                                        </Card>
                                                    </div>
                                                }
                                            </Fragment>
                                        }
                                    }
                                />
                            </Switch>
                        }
                    </div>
                </div>
            </div>
        </div>
    </Fragment>;
};


const mapStateToProps = state => ({
    wsConnected: state.Chats.wsConnected,
});

const mapDispatchToProps = dispatch => ({
    setWsConnected: (is_connected) => dispatch(setWsConnected(is_connected)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatsWrapper);
