import React, {Component, Fragment} from 'react';
import {DefaultPortModel, NodeModel, PortWidget} from '@projectstorm/react-diagrams';
import get from 'lodash/get';
import find from 'lodash/find';
import {Button, ButtonGroup} from "reactstrap";

import {store} from "../../../../configureStore";
import {
    INLINE_BUTTON_TYPE_LINK,
    INLINE_BUTTON_TYPE_NEXT_STEP,
    INLINE_BUTTON_TYPE_PHONE,
    MENU_BUTTON_NEXT_STEP,
    MENU_BUTTON_PHONE_NUMBER
} from "../../../../constants";
import {apiFormDeleteSweetalertShow} from "../../../../actions/APIModel";
import {duplicateStepRequest} from "../../../../thunks/FlowBuilder";
import {stepClicked} from "../../../../actions/Steps";
import {useTranslation} from "react-i18next";


export class AbstractNodeWidget extends Component {
    constructor(props) {
        super(props);
        
        this.mouseDownTime = undefined;

        this.getAdditionalFooter = this.getAdditionalFooter.bind(this);
        this.getStepType = this.getStepType.bind(this);
        this.getHeader = this.getHeader.bind(this);
        this.getHeaderClassName = this.getHeaderClassName.bind(this);
        
    }

    getStepType() {
        const stepTypeId = this.props.node.options.step.type.id;
        const stepTypes = store.getState().Steps.stepTypes;
        return find(stepTypes, {id: stepTypeId});
    }
    
    getNextStepLabel() {
        return this.props.t('pages.main.flow_builder.node.abstract.next_step', 'Next step')
    }
    
    getCascadeStepLabel() {
        return this.props.t('pages.main.flow_builder.node.abstract.cascade_step', 'Message is not sent')
    }

    getAdditionalFooter() {
        return '';
    }

    getHeader() {
        throw 'Not Implemented';
    }

    getHeaderClassName() {
        const bgClass = get(this.getStepType(), 'html_bg_class');
        return bgClass ? `${bgClass} bg-opacity-50 bg-opacity-3` : `bg-gray bg-opacity-50`;
    }

    getBody() {
        const options = this.props.node.options;
        const text = get(options.step.text, store.getState().Shops.activeShop.lang_by_default);
        const imageUrl = options.step.image_url;
        const fileUrl = options.step.file_url;
        const videoUrl = options.step.video_url;

        return <>
            {
                imageUrl && <div className="text-center">
                    <img
                        className="img-fluid mb-3"
                        draggable={false}
                        src={imageUrl}
                    />
                </div>
            }
            {
                text && text.split('\n').map((item, key) => {
                    return <Fragment key={key}>{item}<br/></Fragment>
                })
            }
            {
                fileUrl && <div className="pb-1 pt-4">
                    <a href={fileUrl} target='_blank'>
                        {fileUrl}
                    </a>
                </div>
            }
            {
                videoUrl && <div className="pb-1 pt-4">
                    <a href={videoUrl} target='_blank'>
                        {videoUrl}
                    </a>
                </div>
            }
        </>;
    }

    getErrors() {
        const options = this.props.node.options;
        const text = get(options.step.text, store.getState().Shops.activeShop.lang_by_default);
        const imageUrl = options.step.image_url;
        const fileUrl = options.step.file_url;
        const videoUrl = options.step.video_url;

        if (!text && !imageUrl && !fileUrl && !videoUrl) {
            return <i className='text-danger opacity-9'>
                {this.props.t('pages.main.flow_builder.node.abstract.error.all_is_blank', 'Add text, image, video, or file')}
            </i>;
        }
    }

    render() {
        const that = this;
        const options = this.props.node.options;
        const ports = this.props.node.getPorts();
        
        const menuItemsHtml = [];
        options.step.menu_buttons.map((menuItem) => {
                let port = undefined;
                if (menuItem.type.id === MENU_BUTTON_NEXT_STEP) {
                    port = get(ports, `menu-item-${that.id}-${menuItem.id}`);
                }

                let className = '';
                if (menuItem.type.id === MENU_BUTTON_PHONE_NUMBER) {
                    className = 'bg-happy-fisher bg-opacity-3';
                } else if (
                    !get(menuItem.name, store.getState().Shops.activeShop.lang_by_default)
                    // || menuItem.type.id === MENU_BUTTON_NEXT_STEP && !menuItem.next_step
                ) {
                    className = 'error';
                }

                const name = get(menuItem.name, store.getState().Shops.activeShop.lang_by_default);
                menuItemsHtml.push(
                    <div className='menu-item' key={menuItemsHtml.length}>
                        <Button className={`pull-right ${className}`} outline={true}>
                            {
                                name || <span className='invisible'>no text</span>
                            }
                            {' '}
                            {
                                port && <PortWidget engine={this.props.engine} port={port} className="out-port">
                                    <div className="circle-port"/>
                                </PortWidget>
                            }
                        </Button>
                    </div>
                );
            }
        );

        const inlineButtonsHtml = [];
        options.step.inline_buttons.map((inlineButton) => {
                let port = undefined;
                if (inlineButton.type.id === INLINE_BUTTON_TYPE_NEXT_STEP) {
                    port = get(ports, `inline-button-${that.id}-${inlineButton.id}`);
                }

                let className = '';
                if (inlineButton.type.id === INLINE_BUTTON_TYPE_PHONE) {
                    className = 'bg-happy-fisher bg-opacity-3';
                } else if (inlineButton.type.id === INLINE_BUTTON_TYPE_LINK) {
                    className = 'bg-happy-fisher bg-opacity-3';
                } else if (
                    !get(inlineButton.name, store.getState().Shops.activeShop.lang_by_default)
                    || inlineButton.type.id === MENU_BUTTON_NEXT_STEP && !inlineButton.next_step
                ) {
                    className = 'error';
                }

                const name = get(inlineButton.name, store.getState().Shops.activeShop.lang_by_default);
                inlineButtonsHtml.push(
                    <div className='menu-item' key={inlineButtonsHtml.length}>
                        <Button className={`pull-right ${className}`} outline={true}>
                            {
                                name || <span className='invisible'>no text</span>
                            }
                            {' '}
                            {
                                port && <PortWidget engine={this.props.engine} port={port} className="out-port">
                                    <div className="circle-port"/>
                                </PortWidget>
                            }
                        </Button>
                    </div>
                );
            }
        );

        const nextStepOutPort = this.props.node.getPort('next-step-out');
        const cascadeStepOutPort = this.props.node.getPort('cascade-step-out');
        const defaultInPort = this.props.node.getPort('default-in');
        return (
            <div
                className={`hightouch-node ${this.type} card ${options.selected ? 'selected' : ''}`}
                onMouseDown={e => {
                    this.mouseDownTime = Date.now()
                }}
                onMouseUp={e => {
                    if (Date.now() - this.mouseDownTime < 150) {
                        store.dispatch(stepClicked(options.id));
                    }
                }}
            >
                <div className="actions-block justify-content-center">
                    <ButtonGroup size='lg' onMouseDown={e => e.stopPropagation()}>
                        <Button
                            outline
                            className='border-right-0 bg-white'
                            onClick={
                                e => {
                                    store.dispatch(duplicateStepRequest(options.id));
                                    e.stopPropagation();
                                }
                            }
                        >
                            <i className='pe-7s-copy-file'/>
                        </Button>
                        <Button
                            outline
                            className='border-left bg-white text-danger'
                            onClick={
                                e => {
                                    store.dispatch(apiFormDeleteSweetalertShow(options.id));
                                    e.stopPropagation();
                                }
                            }
                        >
                            <i className='pe-7s-trash'/>
                        </Button>
                    </ButtonGroup>
                </div>

                <div className="wellcome-message bg-success text-center"
                     style={{display: options.step.is_default ? 'block' : 'none'}}>
                    {this.props.t('pages.main.flow_builder.node.abstract.default_step', 'Default step')}
                </div>

                <div className={`card-header node-header ${this.getHeaderClassName()}`}>
                    {this.getHeader()}

                    {
                        defaultInPort && <PortWidget
                            engine={this.props.engine} port={defaultInPort}
                            className="in-port multi-port"
                        >
                            <div className="circle-port"/>
                        </PortWidget>
                    }
                </div>
                <div className="card-body">
                    {this.getBody()}
                    {this.getErrors()}
                </div>
                <div className="card-footer flex-column">
                    {menuItemsHtml}
                    {inlineButtonsHtml}
                    {
                        (cascadeStepOutPort || nextStepOutPort) && <div className="py-3 w-100">
                            {
                                nextStepOutPort && <div className='text-right w-100 px-4 py-2 position-relative'>
                                    <span className="opacity-7">{this.getNextStepLabel()}</span>
                                    <PortWidget
                                        engine={this.props.engine} port={nextStepOutPort}
                                        className="out-port"
                                    >
                                        <div className="circle-port"/>
                                    </PortWidget>
                                </div>
                            }
                            {
                                cascadeStepOutPort && <div className='text-right w-100 px-4 py-2 position-relative'>
                                    <span className="opacity-7">{this.getCascadeStepLabel()}</span>
                                    <PortWidget
                                        engine={this.props.engine} port={cascadeStepOutPort}
                                        className="out-port"
                                    >
                                        <div className="circle-port"/>
                                    </PortWidget>
                                </div>
                            }
                        </div>
                    }
                    {this.getAdditionalFooter()}
                </div>
            </div>
        );
    }
}

export class AbstractNodeModel extends NodeModel {
    constructor(options) {
        super({
            ...options,
        });

        const that = this;

        // setup an in and out port
        if (options.disableInPort !== true) {
            this.addPort(
                new DefaultPortModel({
                    in: true,
                    name: 'default-in'
                })
            );
        }
        if (options.disableNextStepPort !== true) {
            this.addPort(
                new DefaultPortModel({
                    in: false,
                    name: 'next-step-out'
                })
            );
        }
        if (
            options.alwaysEnableCascadePort
            || (options.disableCascadePort !== true && get(store.getState().Shops, 'activeShop.enable_cascade'))
        ) {
            this.addPort(
                new DefaultPortModel({
                    in: false,
                    name: 'cascade-step-out'
                })
            );
        }

        this.options.step.menu_buttons.map((menuItem) => {
            if (menuItem.type.id !== MENU_BUTTON_NEXT_STEP) {
                return;
            }

            this.addPort(
                new DefaultPortModel({
                    in: false,
                    name: `menu-item-${that.id}-${menuItem.id}`,
                    label: get(menuItem.name, store.getState().Shops.activeShop.lang_by_default),
                    menuItem: menuItem
                })
            );
        });

        this.options.step.inline_buttons.map((inlineButton) => {
            if (inlineButton.type.id !== INLINE_BUTTON_TYPE_NEXT_STEP) {
                return;
            }

            this.addPort(
                new DefaultPortModel({
                    in: false,
                    name: `inline-button-${that.id}-${inlineButton.id}`,
                    label: get(inlineButton.name, store.getState().Shops.activeShop.lang_by_default),
                    inlineButton: inlineButton
                })
            );
        });
    }

    serialize() {
        return {
            ...super.serialize(),
            step: this.options.step,
        };
    }

    deserialize(ob, engine) {
        super.deserialize(ob, engine);
        this.options.step = ob.step;
    }
}
