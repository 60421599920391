import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    // Button,
    Col,
    // Input,
    // InputGroup,
    // InputGroupAddon
} from "reactstrap";
import {Link, Redirect} from "react-router-dom";

import {createTGBot} from "../../../thunks/Connect";
import {renderErrorsAsHTML} from "../../../Components/utils";
import {clearErrors} from "../../../actions/Connect";
import APIForm from "../../../Components/APIForm";


const InstallPlugin = (
    {
        activeShop,
        createTGBot,
        isSendingRequest,
        errors,
        clearErrors,
        match,
    }
) => {
    const [redirectTo, setRedirectTo] = useState(undefined);

    // ComponentDidMount
    useEffect(() => {
        clearErrors();
    }, []);

    const dashboardUrl = `/${activeShop.id}/dashboard`;

    const onButtonClick = (event) => {
        // pass
    };

    if (redirectTo) {
        return <Redirect to={redirectTo}/>
    }

    return <div className="d-flex justify-content-center align-items-center">
        <Col md="8" className="mx-auto app-login-box">
            <div className="modal-dialog w-100 mx-auto">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="app-header__logo w-100 justify-content-center">
                            <div className="logo-src"/>
                        </div>

                        <div className="h5 modal-title text-center">
                            <h4 className="mt-4 mb-4">
                                <div>
                                    Подключение Telegram-бота
                                </div>

                                <span className='font-size-md'>
                                    Введите токен бота в Telegram:
                                </span>
                            </h4>
                        </div>

                        <div className='d-flex justify-content-center my-3'>
                            <APIForm
                                apiPath={`integrations/${match.params.slug}`}
                                modelId={undefined}
                                method='post'
                                deletionDisabled={true}
                                actionRedirectUrl={`/${activeShop.id}/settings/plugins/`}
                            />
                        </div>

                        <div className="text-center">
                            {renderErrorsAsHTML(errors)}
                        </div>

                        <div className='modal-body font-size-md mt-4'>
                            <h5 className='text-center mb-3'>
                                <a href="https://docs.hightouch.ai/kak-nachat/podklyuchenie-botov/telegram.-sozdanie-bota" target='_blank'>
                                    📕 Подробная инструкция
                                </a>
                            </h5>

                            <ol>
                                <li className='mb-3'>
                                    Откройте приложение Telegram и через поиск перейдите в аккаунт{' '}
                                    <a href="https://t.me/BotFather" target='_blank'>@BotFather</a>,{' '}
                                    напишите команду <code>/newbot</code>, и у вас появится бот вашей компании.{' '}
                                    <br/>
                                    Если бот у вас уже есть, то напишите команду <code>/mybots</code> и выберите{' '}
                                    существующий.
                                </li>
                                <li className='mb-3'>
                                    Задайте имя для бота. Оно должно быть на английском языке и соответствовать названию
                                    вашей компании.
                                </li>
                                <li className='mb-3'>
                                    Задайте юзернейм бота, это может быть сочетание имени вашего бота
                                    и слова <strong>bot</strong>.
                                    Возможны
                                    вариации: <strong>NameBot</strong> или <strong>Name_bot</strong>.
                                </li>
                                <li className='mb-3'>
                                    Ваш бот готов. С помощью команды <code>/token</code> получите ключ доступа к боту
                                    (токен).
                                    <br/>
                                    Обычно он выглядит
                                    так: <strong>3245214234:AAsdgfgre87qJddYccR-ynswefcWz21324 </strong>
                                    Скопируйте его и вставьте в поле ввода выше.
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-center">
                        <Link to={dashboardUrl} className="btn-lg btn btn-link">
                            Back to bots list
                        </Link>
                    </div>
                </div>
            </div>
        </Col>
    </div>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    errors: state.Connect.errors,
    isSendingRequest: state.Connect.isSendingRequest,
});

const mapDispatchToProps = dispatch => ({
    createTGBot: (shop_id, name, tg_bot_token, callback) => dispatch(createTGBot(shop_id, name, tg_bot_token, callback)),
    clearErrors: () => dispatch(clearErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstallPlugin);
