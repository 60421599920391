import React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import get from "lodash/get";

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import {withTranslation} from "react-i18next";


class SmartDelayNodeWidget_ extends AbstractNodeWidget {
    constructor() {
        super();

        this.type = 'smart-delay-node';
    }

    // getHeaderClassName() {
    //     return 'bg-strong-bliss bg-opacity-3';
    // }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <i className="pe-7s-hourglass"> </i> {options.step.name || this.props.t('pages.main.flow_builder.node.smart_delay.name', 'Delay')}
        </>;
    }

    getBody() {
        const options = this.props.node.options;
        const duration = get(options.step, 'state.delay_config.duration');
        const interval = get(options.step, 'state.delay_config.interval');
        const has_time_window = get(options.step, 'state.delay_config.has_time_window');
        const time_from = get(options.step, 'state.delay_config.time_window_config.time_from');
        const time_to = get(options.step, 'state.delay_config.time_window_config.time_to');
        const days = get(options.step, 'state.delay_config.time_window_config.days');

        if (!duration || !interval) {
            return '';
        }

        return <div>
            <i className='mr-2 fsize-2 position-relative text-danger font-weight-bold opacity-9 pe-7s-clock' style={{bottom: -3}}/>
            {interval.name}: <strong>{duration}</strong>
            {
                has_time_window && <div className='mt-2'>
                    <i className='mr-2 fsize-2 position-relative text-danger font-weight-bold opacity-9 pe-7s-date' style={{bottom: -3}}/>
                    {this.props.t('pages.main.flow_builder.node.smart_delay.time_window', 'Time window')}: <strong>{time_from}</strong> - <strong>{time_to}</strong>

                    <ul className='mt-2'>
                        {days.map((el, idx) => <li key={idx}>{el.name}</li>)}
                    </ul>
                </div>
            }
        </div>;
    }

    getErrors() {
        const options = this.props.node.options;
        const duration = get(options.step, 'state.delay_config.duration');

        if (duration === undefined) {
            return <i className='text-danger opacity-9'>
                {this.props.t('pages.main.flow_builder.node.smart_delay.errors.delay_is_not_specified', 'Please specify delay duration')}
            </i>;
        }
    }
}

export const SmartDelayNodeWidget = withTranslation()(SmartDelayNodeWidget_);

export class SmartDelayNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'smart-delay-node',
            disableCascadePort: true,
        });
    }
}


export class SmartDelayNodeFactory extends AbstractReactFactory {
    constructor() {
        super('smart-delay-node');
    }

    generateModel(event) {
        return new SmartDelayNodeModel();
    }

    generateReactWidget(event) {
        return <SmartDelayNodeWidget engine={this.engine} node={event.model}/>;
    }
}
