import React from 'react';
import ReactDOM from 'react-dom';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import * as Sentry from '@sentry/browser';

import {history, persistor, store} from './configureStore';
import Main from './Pages/Main';
import Preloader from './Components/Preloader';
import './i18n';
import './assets/base.scss';
import * as serviceWorker from './serviceWorker';
import {eventsInit} from "./Components/utils";

if (process.env.NODE_ENV === 'production') {
    Sentry.init({dsn: "https://8417358d17454c7398d0b6a18d7d4fb1@sentry.io/1412867"});
}

export const isMobileDevice = () => (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

const rootElement = document.getElementById('root');
const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <PersistGate loading={<Preloader/>} persistor={persistor}>
                    <Component/>
                </PersistGate>
            </ConnectedRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./Pages/Main', () => {
        const NextApp = require('./Pages/Main').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();

/**
 * VH trick
 */
const recalcVH = () => {
    /**
     * We execute the same script as before
     */
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

window.addEventListener('resize', () => {
    recalcVH();
});

recalcVH();

window.Chargebee && window.Chargebee.init({
    site: process.env.REACT_APP_CHARGEBEE_SITE,
});

eventsInit();
