import React from 'react';
import {connect} from "react-redux";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, UncontrolledTooltip} from 'reactstrap';

import APIModel from "../../Components/APIModel";
import DefaultNormalArrayFieldTemplate from "../../Components/APIForm/Fields/DefaultNormalArrayFieldTemplate";
import {concatURL, LOGO_BY_TYPE} from '../../Components/utils';
import {resumeBroadcast, startBroadcast, stopBroadcast} from "../../thunks/Broadcast";
import {store} from "../../configureStore";
import {useTranslation} from "react-i18next";


const BROADCAST_STATUS_NEW = 1;
const BROADCAST_STATUS_PENDING = 2;
const BROADCAST_STATUS_RUNNING = 3;
const BROADCAST_STATUS_STOPPED = 5;
const BROADCAST_STATUS_COMPLETED = 6;

const BROADCAST_STATUS_TO_ICON = {}
BROADCAST_STATUS_TO_ICON[BROADCAST_STATUS_NEW] = '⏳';
BROADCAST_STATUS_TO_ICON[BROADCAST_STATUS_PENDING] = '⏳';
BROADCAST_STATUS_TO_ICON[BROADCAST_STATUS_RUNNING] = <span className='text-primary'>▶️</span>;
BROADCAST_STATUS_TO_ICON[BROADCAST_STATUS_STOPPED] = '⏹';
BROADCAST_STATUS_TO_ICON[BROADCAST_STATUS_COMPLETED] = '✅';


const Broadcasts = (
    {
        match,
        startBroadcast,
        stopBroadcast,
        resumeBroadcast,
        isSendingRequest,
    }
) => {
    const {t, i18n} = useTranslation();

    const BROADCAST_COLUMNS = [
        {
            name: t('broadcasts.columns.channel', 'Channel'),
            cell: row => <div>
                <img
                    src={LOGO_BY_TYPE[row.target.bot.bot_type]}
                    style={{width: '1.3rem'}}
                    className='mr-2'
                />
                {row.target.bot.name}
            </div>
        },
        {
            name: t('broadcasts.columns.name', 'Name'),
            selector: "name",
        },
        {
            name: t('broadcasts.columns.created_at', 'Created at'),
            selector: "created_at",
        },
        {
            name: t('broadcasts.columns.messages_total', 'Receivers'),
            selector: "messages_total",
        },
        {
            name: t('broadcasts.columns.messages_sent', 'Sent to'),
            cell: row => <>
                <div id={`broadcast-${row.id}`}>
                    {row.messages_sent}
                    &nbsp;&nbsp;
                    {BROADCAST_STATUS_TO_ICON[row.status.id]}
                </div>
                <UncontrolledTooltip placement="top" target={`broadcast-${row.id}`}>
                    {row.status.name}
                </UncontrolledTooltip>
            </>
        },
        {
            name: "",
            width: '85px',
            cell: row => {
                const canBeStarted = [
                    BROADCAST_STATUS_NEW,
                ].indexOf(row.status.id) !== -1;
                const canBeStopped = [
                    BROADCAST_STATUS_NEW,
                    BROADCAST_STATUS_PENDING,
                    BROADCAST_STATUS_RUNNING,
                ].indexOf(row.status.id) !== -1;
                const canBeResumed = [
                    BROADCAST_STATUS_STOPPED,
                ].indexOf(row.status.id) !== -1;

                return <div>
                    {
                        (canBeStarted || canBeStopped || canBeResumed) && <UncontrolledButtonDropdown
                            onClick={(event) => event.stopPropagation()}
                        >
                            <DropdownToggle
                                outline
                                className="pb-1 pt-2 br-a"
                                color="primary"
                                onClick={() => {
                                }}
                            >
                                <i className="pe-7s-more font-size-lg"/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                {
                                    canBeStarted && <DropdownItem
                                        onClick={() => store.dispatch(startBroadcast(
                                            row.id,
                                            () => {
                                                // TODO: Грязный хак
                                                window.location.reload();
                                            }
                                        ))}
                                    >
                                        <span className='text-success'>▶️</span>&nbsp;️ {t('broadcasts.rows.button_start', 'Start')}
                                    </DropdownItem>
                                }
                                {
                                    canBeResumed && <DropdownItem
                                        onClick={() => store.dispatch(resumeBroadcast(
                                            row.id,
                                            () => {
                                                // TODO: Грязный хак
                                                window.location.reload();
                                            }
                                        ))}
                                    >
                                        <span className='text-success'>▶️</span>&nbsp; {t('broadcasts.rows.button_resume', 'Resume')}
                                    </DropdownItem>
                                }
                                {
                                    canBeStopped && <DropdownItem
                                        onClick={() => store.dispatch(stopBroadcast(
                                            row.id,
                                            () => {
                                                // TODO: Грязный хак
                                                window.location.reload();
                                            }
                                        ))}
                                    >
                                        <span className='text-primary'>⏹</span>&nbsp; {t('broadcasts.rows.button_stop', 'Stop')}
                                    </DropdownItem>
                                }
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    }
                </div>
            }
        },
    ];

    return <APIModel
        heading={t('broadcasts.api_model.heading', 'Broadcasts')}
        subheading=''
        icon="fa fas fa-bullhorn icon-gradient bg-sunny-morning"
        apiPath='broadcast/broadcasts'
        tableColumns={BROADCAST_COLUMNS}
        tableEmptyMessage={t('broadcasts.api_model.message_empty', "No broadcasts yet...")}
        match={match}
        hasFilters={true}
        creationDisabled={isSendingRequest}
        updateDisabled={true}
        deletionDisabled={true}
        ArrayFieldTemplate={DefaultNormalArrayFieldTemplate}
        actionRedirectUrl={`${match.url}/`}
        afterSubmitCallback={(result => {
            console.log('submitted ', result);
            // do not start by default
            /*
            startBroadcast(
                result.id,
                () => {
                    window.location.href = concatURL(window.location.href, '..');
                }
            );
            */
        })}
    />;
};

const mapStateToProps = state => ({
    isSendingRequest: state.Broadcast.isSendingRequest,
});

const mapDispatchToProps = dispatch => ({
    startBroadcast: (broadcastId, callback) => dispatch(startBroadcast(broadcastId, callback)),
    stopBroadcast: (broadcastId, callback) => dispatch(stopBroadcast(broadcastId, callback)),
    resumeBroadcast: (broadcastId, callback) => dispatch(resumeBroadcast(broadcastId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Broadcasts);
