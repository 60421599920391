import find from 'lodash/find'
import i18next from 'i18next';
import {ofType} from 'redux-observable'
import {of, timer, from} from 'rxjs';
import {catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMap, takeUntil} from 'rxjs/operators'
import {LOCATION_CHANGE} from 'connected-react-router'

import {LOGOUT_REQUEST_SUCCESS} from '../actions/Auth'
import {SHOPS_SELECT_SHOP} from '../actions/Shops'
import {
    BOT_STATUS_REQUEST_SUCCESS,
    BOTS_REQUEST_START,
    BOTS_REQUEST_SUCCESS,
    botsClearData,
    botsRequestFail,
    botsRequestStart,
    botsRequestSuccess,
    botStatisticRequestFail,
    botStatisticRequestSuccess,
    botStatusRequestFail,
    botStatusRequestSuccess,
    WHATSAPP_LOGOUT_REQUEST_START,
    WHATSAPP_LOGOUT_REQUEST_SUCCESS,
    whatsappLogoutRequestFail,
    whatsappLogoutRequestSuccess,
    whatsappQRCodeRequestFail,
    whatsappQRCodeRequestSuccess,
} from "../actions/Dashboard";
import {
    ADAPTER_STATUS_WAITING,
    BOT_TYPE_WA,
} from '../constants';
import {
    apiAxiosInstance,
    serviceCoreAxiosInstance
} from "../helpers/api";


const STATUS_UPDATE_INTERVAL = 5 * 60 * 1000; // 5 min


export const botsLoadEpic = (action$, state$) =>
    action$.pipe(
        ofType(LOCATION_CHANGE, SHOPS_SELECT_SHOP),
        filter(() => state$.value.Shops.shopsAreInitiallyLoaded
                && state$.value.router.location.pathname.match(/^\/\d+\/channels\/?/)),
        map(action => botsRequestStart())
    );

export const botsRequestStartEpic = (action$, state$) =>
    action$.pipe(
        ofType(BOTS_REQUEST_START),
        switchMap(
            () => from(apiAxiosInstance.get(`bot/bots/?shop_id=${state$.value.Shops.activeShop.id}`))
        ),
        map(response => {
            if (response.data.status === 'ok') {
                return botsRequestSuccess(response.data.result.results);
            } else {
                return botsRequestFail(response.data.errors);
            }
        }),
        catchError(error => {
            return of(botsRequestFail({
                'common': i18next.t('errors.common.request_sent', 'Error sending request'),
            }));
        })
    );

export const botUpdateStatisticEpic = (action$, state$) =>
    action$.pipe(
        ofType(BOTS_REQUEST_SUCCESS),
        map((action) => action.bots),
        concatMap(x => x),
        mergeMap(
            (bot) => from(apiAxiosInstance.post('analytics/bot/', {bot_id: bot.id}))
        ),
        map(response => {
            if (response.data.status === 'ok') {
                return botStatisticRequestSuccess(response.data.result)
            } else {
                return botStatisticRequestFail(response.data.errors);
            }
        }),
        catchError(error => {
            return of(botStatisticRequestFail({
                'common': i18next.t('errors.common.request_sent', 'Error sending request'),
            }));
        }),
    );

export const botPollStatusEpic = (action$, state$) =>
    action$.pipe(
        ofType(BOTS_REQUEST_SUCCESS),
        map((action) => action.bots),
        concatMap(x => x),
        mergeMap((bot) =>
            timer(0, STATUS_UPDATE_INTERVAL)
                .pipe(
                    takeUntil(action$.ofType(LOCATION_CHANGE, SHOPS_SELECT_SHOP)),
                    exhaustMap(() => from(apiAxiosInstance.get(`bot/bot/${bot.id}/status/`))),
                    map(response => {
                        if (response.data.status === 'ok') {
                            return botStatusRequestSuccess(response.data.result);
                        } else {
                            return botStatusRequestFail(bot.id, response.data.errors);
                        }
                    }),
                    catchError(error => {
                        return of(botStatusRequestFail(bot.id, {
                            'common': i18next.t('errors.common.request_sent', 'Error sending request'),
                        }));
                    }),
                )
        ),
    );

export const whatsappGetQRCodeEpic = (action$, state$) =>
    action$.pipe(
        ofType(BOT_STATUS_REQUEST_SUCCESS, WHATSAPP_LOGOUT_REQUEST_SUCCESS),
        filter((action) => {
            let bot_id = action.bot_id || action.result.bot_id;
            let bot = find(state$.value.Bots.bots, {id: bot_id});

            return bot && bot.bot_type === BOT_TYPE_WA && bot.adapter_status === ADAPTER_STATUS_WAITING;
        }),
        concatMap(
            (action) => from(serviceCoreAxiosInstance.get(`whatsapp/get_qr/${action.result.bot_id}`))
        ),
        map(response => {
            if (response.data.status === 'ok') {
                return whatsappQRCodeRequestSuccess(response.data.result)
            } else {
                return whatsappQRCodeRequestFail(response.data.result)
            }

        }),
        catchError(error => {
            return of(whatsappQRCodeRequestFail({
                'common': i18next.t('errors.common.request_sent', 'Error sending request'),
            }));
        }),
    );

export const whatsappLogoutRequestStartEpic = (action$, state$) =>
    action$.pipe(
        ofType(WHATSAPP_LOGOUT_REQUEST_START),
        switchMap(
            (action) => from(serviceCoreAxiosInstance.get(`whatsapp/logout/${action.bot_id}`))
        ),
        map(response => {
            if (response.data.status === 'ok') {
                return whatsappLogoutRequestSuccess(response.data.result)
            } else {
                return whatsappLogoutRequestFail(response.data.result)
            }
        }),
        catchError(error => {
            return of(whatsappLogoutRequestFail({
                'common': i18next.t('errors.common.request_sent', 'Error sending request'),
            }));
        }),
    );

export const botsClearDataEpic = (action$, state$) =>
    action$.pipe(
        ofType(LOGOUT_REQUEST_SUCCESS),
        map(botsClearData)
    );
