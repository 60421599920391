export const GET_STEP_TYPES_REQUEST_START = 'STEPS/GET_STEP_TYPES_REQUEST_START';
export const GET_STEP_TYPES_REQUEST_SUCCESS = 'STEPS/GET_STEP_TYPES_REQUEST_SUCCESS';
export const GET_STEP_TYPES_REQUEST_FAIL = 'STEPS/GET_STEP_TYPES_REQUEST_FAIL';

export const GET_STEPS_REQUEST_START = 'STEPS/GET_STEPS_REQUEST_START';
export const GET_STEPS_REQUEST_SUCCESS = 'STEPS/GET_STEPS_REQUEST_SUCCESS';
export const GET_STEPS_REQUEST_FAIL = 'STEPS/GET_STEPS_REQUEST_FAIL';

export const GET_FLOW_REQUEST_START = 'STEPS/GET_FLOW_REQUEST_START';
export const GET_FLOW_REQUEST_SUCCESS = 'STEPS/GET_FLOW_REQUEST_SUCCESS';
export const GET_FLOW_REQUEST_FAIL = 'STEPS/GET_FLOW_REQUEST_FAIL';

export const DUPLICATE_FLOW_REQUEST_START = 'STEPS/DUPLICATE_FLOW_REQUEST_START';
export const DUPLICATE_FLOW_REQUEST_SUCCESS = 'STEPS/DUPLICATE_FLOW_REQUEST_SUCCESS';
export const DUPLICATE_FLOW_REQUEST_FAIL = 'STEPS/DUPLICATE_FLOW_REQUEST_FAIL';

export const UPDATE_ACTIVE_FLOW = 'STEPS/UPDATE_ACTIVE_FLOW';
export const CLEAR_ACTIVE_FLOW = 'STEPS/CLEAR_ACTIVE_FLOW';

export const UPDATE_ACTIVE_FLOW_REQUEST_START = 'STEPS/UPDATE_ACTIVE_FLOW_REQUEST_START';
export const UPDATE_ACTIVE_FLOW_REQUEST_SUCCESS = 'STEPS/UPDATE_ACTIVE_FLOW_REQUEST_SUCCESS';
export const UPDATE_ACTIVE_FLOW_REQUEST_FAIL = 'STEPS/UPDATE_ACTIVE_FLOW_REQUEST_FAIL';

export const ADD_EMPTY_STEP = 'STEPS/ADD_EMPTY_STEP';
export const STEP_MOVED = 'STEPS/STEP_MOVED';
export const STEP_CLICKED = 'STEPS/STEP_CLICKED';
export const STEP_REMOVED = 'STEPS/STEP_REMOVED';

export const UPDATE_STEP_REQUEST_START = 'STEPS/UPDATE_STEP_REQUEST_START';
export const UPDATE_STEP_REQUEST_SUCCESS = 'STEPS/UPDATE_STEP_REQUEST_SUCCESS';
export const UPDATE_STEP_REQUEST_FAIL = 'STEPS/UPDATE_STEP_REQUEST_FAIL';

export const MENU_BUTTON_HAS_UPDATED = 'STEPS/MENU_BUTTON_HAS_UPDATED';
export const INLINE_BUTTON_HAS_UPDATED = 'STEPS/INLINE_BUTTON_HAS_UPDATED';

export const FLOW_BUILDER_SET_RIGHT_MENU_OPEN = 'STEPS/FLOW_BUILDER_CLOSE_RIGHT_MENU';


export const getStepTypesRequestStart = (data) => ({
    type: GET_STEP_TYPES_REQUEST_START,
    data,
});

export const getStepTypesRequestSuccess = (result) => ({
    type: GET_STEP_TYPES_REQUEST_SUCCESS,
    result,
});

export const getStepTypesRequestFail = (error) => ({
    type: GET_STEP_TYPES_REQUEST_FAIL,
    error,
});

export const getFlowRequestStart = (flowId) => ({
    type: GET_FLOW_REQUEST_START,
    flowId,
});

export const getFlowRequestSuccess = (result) => ({
    type: GET_FLOW_REQUEST_SUCCESS,
    result,
});

export const getFlowRequestFail = (error) => ({
    type: GET_FLOW_REQUEST_FAIL,
    error,
});

export const duplicateFlowRequestStart = (flowId) => ({
    type: DUPLICATE_FLOW_REQUEST_START,
    flowId,
});

export const duplicateFlowRequestSuccess = (result) => ({
    type: DUPLICATE_FLOW_REQUEST_SUCCESS,
    result,
});

export const duplicateFlowRequestFail = (error) => ({
    type: DUPLICATE_FLOW_REQUEST_FAIL,
    error,
});

export const getStepsRequestStart = (data) => ({
    type: GET_STEPS_REQUEST_START,
    data,
});

export const getStepsRequestSuccess = (result) => ({
    type: GET_STEPS_REQUEST_SUCCESS,
    result,
});

export const getStepsRequestFail = (error) => ({
    type: GET_STEPS_REQUEST_FAIL,
    error,
});

export const addEmptyStep = (step) => ({
    type: ADD_EMPTY_STEP,
    step: step,
});

export const stepMoved = (stepId, x, y) => ({
    type: STEP_MOVED,
    stepId,
    x,
    y,
});

export const stepClicked = (stepId) => ({
    type: STEP_CLICKED,
    stepId,
});

export const stepRemoved = (stepId) => ({
    type: STEP_REMOVED,
    stepId,
});

export const updateStepRequestStart = (data) => ({
    type: UPDATE_STEP_REQUEST_START,
    data,
});

export const updateStepRequestSuccess = (result) => ({
    type: UPDATE_STEP_REQUEST_SUCCESS,
    result,
});

export const updateStepRequestFail = (error) => ({
    type: UPDATE_STEP_REQUEST_FAIL,
    error,
});

export const stepMenuButtonHasUpdated = (stepId, result) => ({
    type: MENU_BUTTON_HAS_UPDATED,
    stepId,
    result,
});

export const stepInlineButtonHasUpdated = (stepId, result) => ({
    type: INLINE_BUTTON_HAS_UPDATED,
    stepId,
    result,
});

export const updateActiveFlow = (data) => ({
    type: UPDATE_ACTIVE_FLOW,
    data,
});

export const clearActiveFlow = () => ({
    type: CLEAR_ACTIVE_FLOW,
});

export const updateActiveFlowRequestStart = (data) => ({
    type: UPDATE_ACTIVE_FLOW_REQUEST_START,
    data,
});

export const updateActiveFlowRequestSuccess = (response) => ({
    type: UPDATE_ACTIVE_FLOW_REQUEST_SUCCESS,
    response,
});

export const updateActiveFlowRequestFail = (errors) => ({
    type: UPDATE_ACTIVE_FLOW_REQUEST_FAIL,
    errors,
});

export const flowBuilderSetRightMenuOpen = (open) => ({
    type: FLOW_BUILDER_SET_RIGHT_MENU_OPEN,
    open,
});
