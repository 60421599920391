import React from 'react';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import APIModel from "../../Components/APIModel";
import {useTranslation} from "react-i18next";


const NotificationChannels = (
    {
        match,
    }
) => {
    const {t, i18n} = useTranslation();
    
    const NOTIFICATION_CHANNEL_COLUMNS = [
        {
            name: t('settings.notification_channels.column.type', 'Type'),
            cell: row => <div>
                {row.type.name}
            </div>
        },
        {
            name: t('settings.notification_channels.column.active', 'Active'),
            cell: row => <div>
                {row.is_active ? '✅' : '⏹'}
            </div>
        },
        {
            name: t('settings.notification_channels.column.email', "Email"),
            selector: "email",
        },
        {
            name: t('settings.notification_channels.column.telegram_user', "Telegram user"),
            selector: "tg_user_name",
        },
        {
            name: t('settings.notification_channels.column.tags', 'Tags'),
            cell: row => <div>
                {
                    get(row, 'tags', []).map(
                        (tag, idx) => <div className='my-1' key={idx}>
                            <code className='text-secondary'>
                                {tag.name}
                            </code>
                        </div>
                    )
                }
                {
                    isEmpty(row.tags) && '-'
                }
            </div>
        },
    ];
    
    return <APIModel
        heading={t('settings.notification_channels.page.title', 'Notifications channels')}
        subheading=''
        icon="fa fas fa-cog icon-gradient bg-night-fade"
        apiPath='notifications/channels'
        tableColumns={NOTIFICATION_CHANNEL_COLUMNS}
        tableEmptyMessage={t('settings.notification_channels.list.no_data', 'No notifications channels are configured')}
        match={match}
        hasFilters={true}
    />;
};

export default NotificationChannels;
