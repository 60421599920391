import React from 'react';
import {useTranslation} from "react-i18next";
import {LOGO_BY_TYPE} from "../../Components/utils";
import APIModel from "../../Components/APIModel";
import DefaultNormalArrayFieldTemplate from "../../Components/APIForm/Fields/DefaultNormalArrayFieldTemplate";


const WebsiteIconLinks = (
    {
        match,
    }
) => {
    const {t, i18n} = useTranslation();

    const WEBSITE_ICON_LINK_COLUMNS = [
        {
            name: t('growth.columns.name', 'Name'),
            selector: "name",
        },
        {
            name: t('audience.bot_users.columns.channel', 'Channel'),
            cell: row => <div>
                <img
                    src={LOGO_BY_TYPE[row.bot.bot_type]}
                    style={{width: '1.3rem'}}
                    className='mr-2'
                />
                {row.bot.name}
            </div>
        },
        {
            name: t('growth.columns.prefilled_text', 'Pre-filled message'),
            selector: "start_text",
        },
        {
            name: t('growth.columns.clicks', 'Clicks'),
            selector: "clicks",
        },
    ];

    return <APIModel
        heading={t('growth.website_icon_links.api_model.heading', 'Website Icon Links')}
        subheading=''
        icon="pe-7s-disk icon-gradient bg-happy-fisher"
        apiPath='growth/website_icon_links'
        tableColumns={WEBSITE_ICON_LINK_COLUMNS}
        tableEmptyMessage={t('growth.website_icon_links.api_model.message_empty', "No widgets yet")}
        ArrayFieldTemplate={DefaultNormalArrayFieldTemplate}
        match={match}
        hasFilters={false}
    />;
};

export default WebsiteIconLinks;
