import {
    LOAD_PLUGIN_INSTALLATIONS_REQUEST_FAIL,
    LOAD_PLUGIN_INSTALLATIONS_REQUEST_START,
    LOAD_PLUGIN_INSTALLATIONS_REQUEST_SUCCESS,
    LOAD_PLUGINS_REQUEST_FAIL,
    LOAD_PLUGINS_REQUEST_START,
    LOAD_PLUGINS_REQUEST_SUCCESS,
} from '../actions/Plugins'


const defaultState = {
    plugins: undefined,
    pluginInstallations: undefined,
    isSendingPluginsRequest: false,
    isSendingPluginInstallationsRequest: false,
    loadPluginsErrors: undefined,
    loadPluginInstallationsErrors: undefined,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case LOAD_PLUGINS_REQUEST_START:
            return {
                ...state,
                isSendingPluginsRequest: true,
                loadPluginsErrors: undefined,
            };
        case LOAD_PLUGINS_REQUEST_SUCCESS:
            return {
                ...state,
                plugins: action.result,
                isSendingPluginsRequest: false,
            };
        case LOAD_PLUGINS_REQUEST_FAIL:
            return {
                ...state,
                loadPluginsErrors: action.error.message,
                isSendingPluginsRequest: false,
            };
        case LOAD_PLUGIN_INSTALLATIONS_REQUEST_START:
            return {
                ...state,
                isSendingPluginInstallationsRequest: true,
                loadPluginInstallationsErrors: undefined,
            };
        case LOAD_PLUGIN_INSTALLATIONS_REQUEST_SUCCESS:
            return {
                ...state,
                pluginInstallations: action.result,
                isSendingPluginInstallationsRequest: false,
            };
        case LOAD_PLUGIN_INSTALLATIONS_REQUEST_FAIL:
            return {
                ...state,
                loadPluginInstallationsErrors: action.error.message,
                isSendingPluginInstallationsRequest: false,
            };

    }
    return state;
}
