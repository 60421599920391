import React from 'react';

import APIModel from "../../Components/APIModel";
import {useTranslation, Trans} from "react-i18next";


const PaymentGates = (
    {
        match,
    }
) => {
    const {t, i18n} = useTranslation();
    
    const PAYMENT_GATES_COLUMNS = [
        {
            name: t('settings.payment_gates.column.name', 'Name'),
            selector: "name",
        },
        {
            name: t('settings.payment_gates.column.type', 'Type'),
            cell: row => <div>
                {row.type.name}
            </div>
        },
    ];
    
    return <APIModel
        heading={t('settings.payment_gates.page.title', 'Payment gateways')}
        subheading={<Trans i18nKey='settings.payment_gates.page.subtitle' t={t}><div className='text-dark'>⚠️ You can learn more reading <a href={t('settings.payment_gates.page.url_help', 'https://docs.hightouch.ai/nastroiki/podklyuchenie-platezhnykh-sistem')} target='_blank'>payments gateways connection instructions</a></div></Trans>}
        icon="fa fas fa-cog icon-gradient bg-night-fade"
        apiPath='integrations/payment_gates'
        tableColumns={PAYMENT_GATES_COLUMNS}
        tableEmptyMessage={t('settings.payment_gates.list.message.no_data', 'No payment gateways are connected')}
        match={match}
    />;
};

export default PaymentGates;
