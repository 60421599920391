import {getI18n} from "react-i18next";
import {get} from "lodash";

import {store} from "../configureStore";
import {
    SHOP_SOURCE_CHAT_API,
    SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU,
    SHOP_SOURCE_RETAIL_CRM_WABA_RU,
    // SUPPORT_BOT_LINK_BASE,
    SUPPORT_BOT_LINK_BASE_WA,
    SUPPORT_BOT_LINK_BASE_TG,
    SUPPORT_SOURCE_CHAT_API,
    SUPPORT_SOURCE_HIGHTOUCH,
    SUPPORT_SOURCE_RETAIL_CRM,
    LANDING_BANNER_DEFAULT_BOT_LINK_BASE, CHANNEL_REGISTER_BOT_LINK_BASE,
} from "../constants";

export const getTrackingParams = () => {
    const i18n = getI18n();
    // Detector has to be sync
    const lang = get(i18n, 'languages.0') || i18n.services.languageDetector.detect()[0];
    
    const shop = get(store.getState(), 'Shops.activeShop');
    const isChatApi = get(shop, 'source') === SHOP_SOURCE_CHAT_API;
    const isRetailCRM = (
        get(shop, 'source') === SHOP_SOURCE_RETAIL_CRM_WABA_RU
        || get(shop, 'source') === SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU
    );
    const source = (isChatApi && SUPPORT_SOURCE_CHAT_API) || (isRetailCRM && SUPPORT_SOURCE_RETAIL_CRM) || SUPPORT_SOURCE_HIGHTOUCH;
    
    const user = get(store.getState(), 'Auth.user');
    return {
        user,
        shop,
        source,
        lang
    }
};

export const getSupportLink = () => {
    const i18n = getI18n();
    const {
        lang
    } = getTrackingParams();

    const supportLang = ['es','en','ru'].includes(lang) ? lang : 'en'
    return `${SUPPORT_BOT_LINK_BASE_WA}?utm_referrer=app_menu&support_source=app&lang_code=${supportLang}`

    // return `${SUPPORT_BOT_LINK_BASE}?support_source=${source}&lang_code=${lang}&support_shop_id=${shop.id}&support_user_id=${user.id}`;
};

export const getTgSupportLink = () => {
    const {
        lang
    } = getTrackingParams();

    const supportLang = ['es','en','ru'].includes(lang) ? lang : 'en'
    return `${SUPPORT_BOT_LINK_BASE_TG}?utm_referrer=app_menu&support_source=app&lang_code=${supportLang}`

    // return `${SUPPORT_BOT_LINK_BASE}?support_source=${source}&lang_code=${lang}&support_shop_id=${shop.id}&support_user_id=${user.id}`;
};

export const getRegisterChannelLink = (channel) => {
    const i18n = getI18n();
    const {
        user,
        shop,
        source,
        lang
    } = getTrackingParams();

    return `${i18n.t('layout.common.register_channel_link_base', CHANNEL_REGISTER_BOT_LINK_BASE)}?support_channel_registration=${channel}&support_source=${source}&lang_code=${lang}&support_shop_id=${shop.id}&support_user_id=${user.id}`;
};

export const getBannerLink = (bannerIndex) => {
    const i18n = getI18n();
    const {
        user,
        shop,
        source,
        lang
    } = getTrackingParams();

    let bannerLinkBase = '';
    // I18n key must be static
    switch (bannerIndex) {
        case 1:
            bannerLinkBase = i18n.t(
                'pages.main.landing.banners.banner1.link',
                LANDING_BANNER_DEFAULT_BOT_LINK_BASE
            );
            break;
        case 2:
            bannerLinkBase = i18n.t(
                'pages.main.landing.banners.banner2.link',
                LANDING_BANNER_DEFAULT_BOT_LINK_BASE
            );
            break;
        case 3:
            bannerLinkBase = i18n.t(
                'pages.main.landing.banners.banner3.link',
                LANDING_BANNER_DEFAULT_BOT_LINK_BASE
            );
            break;
        default:
            return '';

    }
    return `${bannerLinkBase}${bannerLinkBase.indexOf('?') !== -1 ? '&' : '?'}support_source=${source}&lang_code=${lang}&support_shop_id=${shop.id}&support_user_id=${user.id}`;
};