import * as firebase from "firebase/app";
import "firebase/messaging";
import {toast} from "react-toastify";

import {addFirebaseToken, revokeFirebaseToken} from "./thunks/Notifications";
import {store} from "./configureStore";


let messaging = undefined;
if (firebase.messaging.isSupported()) {
    const initializedFirebaseApp = firebase.initializeApp({
        apiKey: "AIzaSyCsbcDH1reemtNok0eYuniK4yI7oGOWQ6U",
        authDomain: "shop2bot-267013.firebaseapp.com",
        databaseURL: "https://shop2bot-267013.firebaseio.com",
        projectId: "shop2bot-267013",
        storageBucket: "shop2bot-267013.appspot.com",
        messagingSenderId: "22631970731",
        appId: "1:22631970731:web:ac124e440d970fd4dc84ee"
    });
    messaging = initializedFirebaseApp.messaging();

    messaging.usePublicVapidKey(
        // Project Settings => Cloud Messaging => Web Push certificates
        "BDKOuJPQadptoESXvraZdq5yZuwIWvTaeFpKDx5mXMbpKmcFFWTunVOrl-KUq3JpYWF8mEyBCGsCsYSYFjZfQp8"
    );

    messaging.onTokenRefresh(() => {
        const oldToken = store.getState().Notifications.firebaseToken;

        messaging.getToken().then((refreshedToken) => {
            // Indicate that the new Instance ID token has not yet been sent to the
            // app server.
            // console.log('Token refreshed.');
            oldToken && store.dispatch(revokeFirebaseToken(oldToken));
            // Send Instance ID token to app server.
            store.dispatch(addFirebaseToken(refreshedToken));
        }).catch((err) => {
            // console.log('Unable to retrieve refreshed token ', err);
            oldToken && store.dispatch(revokeFirebaseToken(oldToken));
        });
    });
}

const requestPermission = () => {
    if (!('Notification' in window) || messaging === undefined) {
        toast.error('Browser notifications are not supported!');
        return;
    }

    const oldToken = store.getState().Notifications.firebaseToken;

    messaging.requestPermission()
        .then(async function () {
            const token = await messaging.getToken();
            store.dispatch(addFirebaseToken(token, () => toast.success('Notifications enabled')));
        })
        .catch(function (err) {
            debugger;
            toast.error("Permission not granted!");
            oldToken && store.dispatch(revokeFirebaseToken(oldToken));
        });

    navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
}

export {messaging, requestPermission};
