import React from "react";
import {connect} from "react-redux";
import cx from "classnames";
import 'moment/locale/ru';
import {useTranslation} from "react-i18next";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import find from "lodash/find";

import {clearChatFiltersKey} from "../../../actions/Chats";
import {getDisplayName} from '../Messages/TopPane';


const ChatFiltersList = (
    {
        chatFilters,
        currentShopAdministrator,
        activeShopAdministrators,

        // Redux Actions
        clearChatFiltersKey,
    }
) => {
    const {t, i18n} = useTranslation();

    return <div
        className={cx('px-3 text-secondary chat-filters-list', {'pb-3': !isEmpty(chatFilters)})}
        style={{fontSize: '.7rem'}}
    >
        {
            chatFilters.is_thread_open !== undefined && <div className='d-flex align-items-center'>
                <i
                    className="pe-7s-close"
                    onClick={() => clearChatFiltersKey('is_thread_open')}
                />
                {t('chats.filter_list.thread_title', "Live chat")}:&nbsp; <strong>
                {
                    chatFilters.is_thread_open
                        ? t('chats.filter_list.is_thread_open_open', "open")
                        : t('chats.filter_list.is_thread_open_closed', "closed")
                }
            </strong>
            </div>
        }
        {
            chatFilters.assigned_to_id !== undefined && <div className='d-flex align-items-center'>
                <i
                    className="pe-7s-close"
                    onClick={() => clearChatFiltersKey('assigned_to_id')}
                />
                {t('chats.filter_list.assigned_to_title', "Assigned to")}:&nbsp; <strong>
                {
                    chatFilters.assigned_to_id === null
                        ? t('chats.filter_list.assigned_to_nobody', 'not assigned')
                        : (
                            chatFilters.assigned_to_id === get(currentShopAdministrator, 'id')
                                ? t('chats.filter_list.assigned_to_me', 'me')
                                : getDisplayName(find(activeShopAdministrators, {id: chatFilters.assigned_to_id}))
                        )
                }
            </strong>
            </div>
        }
    </div>;
};


const mapStateToProps = state => ({
    activeChat: state.Chats.activeChat,
    chatFilters: state.Chats.chatFilters,
    currentShopAdministrator: state.Shops.currentShopAdministrator,
    activeShopAdministrators: state.Shops.activeShopAdministrators,
});

const mapDispatchToProps = dispatch => ({
    clearChatFiltersKey: (key) => dispatch(clearChatFiltersKey(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatFiltersList);
