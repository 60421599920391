import React, {Component} from "react";
import {FormGroup, Label} from "reactstrap";
import {Multiselect} from "react-widgets";
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';


export const SelectFieldListItem = ({item}) => {
    if (typeof item === 'string') {
        return <span>{item}</span>
    } else {
        return <span>{!isEmpty(item) ? item.name : '-----'}</span>;
    }
};


export const SelectFieldValueInput = ({item}) => {
    if (typeof item === 'string') {
        return <span>{item}</span>
    } else {
        return <span>{!isEmpty(item) ? item.name : '-----'}</span>;
    }
};


export default class MultiSelectField extends Component {
    itemComponent=SelectFieldListItem;
    tagComponent=SelectFieldValueInput;

    render() {
        let value = this.props.formData;
        if (isEmpty(this.props.formData) && this.props.value) {
            value = this.props.value;
            this.props.onChange(value);
        }

        const description = get(this.props.schema, 'description') || get(this.props.uiSchema, 'ui:description');

        return <FormGroup>
            <Label className='mt-3'>
                {this.props.schema.title}
                {(this.props.schema.title && this.props.required) ? ' *' : ''}
            </Label>
            {
                !isEmpty(description) && <small className="form-text text-muted mb-3">
                    {description}
                </small>
            }
            <Multiselect
                data={this.props.schema.items.enum}
                value={value}
                itemComponent={this.itemComponent}
                tagComponent={this.tagComponent}
                onChange={(...args) => {
                    this.props.onChange(args[0]);
                }}
            />
        </FormGroup>;
    }
}
