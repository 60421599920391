import React, {useState} from "react";
import {Button, Card, CardBody, CardFooter, Col, FormGroup, FormText, Input, Row} from "reactstrap";
import {useTranslation} from "react-i18next";

import imageUploading from '../../../assets/utils/images/uploading.gif';
import imageUploadFailed from '../../../assets/utils/images/uploadFailed.png';
import {uploadToCdnAxiosInstance} from "../../../helpers/api";


const ImageWidget = (props) => {
    const {t, i18n} = useTranslation();

    let [isUploading, setIsUploading] = useState(false);
    let [uploadFailed, setUploadFailed] = useState(false);

    let button = props.value ? <Col xs={4} md={8} className='text-right text-sm-left'>
        <Button onClick={() => {
            props.onChange(null)
        }}>
            {t('connect_bot.common.remove', 'Remove')}
        </Button>
    </Col> : '';

    const imgStyles = (imageUploading || imageUploadFailed)
        ? {maxHeight: '150px', width: 'auto', maxWidth: '100%'}
        : {maxWidth: '500px', maxHeight: '500px'};
    const imgSrc = isUploading ? imageUploading : (uploadFailed ? undefined : props.value);

    return <FormGroup className='mb-4'>
        <Card>
            {
                imgSrc && <CardBody className='p-0'>
                    <img src={imgSrc} style={imgStyles}/>
                </CardBody>
            }

            <CardFooter className='py-2 pt-3 flex-column'>
                <Row style={{width: 'calc(100% + 30px)'}}>
                    <Col xs={button ? 8 : 12} md={button ? 4 : 12}>
                        <Input type="file"
                               required={props.required}
                               accept="image/png, image/jpeg, image/gif"
                               onChange={(event) => {
                                   setIsUploading(true);

                                   const file = event.target.files[0];
                                   // Check the file type.
                                   if (!file.type.match('image.*')) {
                                       props.onChange(null);
                                       setIsUploading(false);
                                       setUploadFailed(true);
                                       return;
                                   }
                                   let formData = new FormData();
                                   formData.append('file', file);

                                   uploadToCdnAxiosInstance.post(`${file.name}/`, formData).then(
                                       (request) => {
                                           props.onChange(request.data.result.cdn_filepath);
                                           setIsUploading(false);
                                           setUploadFailed(false);
                                       },
                                       error => {
                                           props.onChange(null);
                                           setIsUploading(false);
                                           setUploadFailed(true);
                                       },
                                   );
                               }}
                        />
                    </Col>
                    {button}
                </Row>
                <Row className='mt-2'>
                    <Col xs={12}>
                        <FormText color="muted">
                            {t('components.api_form.image_widget.hint.text_is_caption_for_image', 'If image is provided, text will be sent as the image caption')}
                        </FormText>
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    </FormGroup>
};

export default ImageWidget;
