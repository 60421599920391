import {ofType} from 'redux-observable'
import {switchMap} from 'rxjs/operators'
import {of} from "rxjs";

import {SHOPS_SELECT_SHOP,} from "../actions/Shops";
import {loadBillingConfig} from "../thunks/Billing";


export const loadBillingConfigEpic = (action$, state$) =>
    action$.pipe(
        ofType(SHOPS_SELECT_SHOP),
        switchMap(
            () => of(loadBillingConfig())
        )
    );
