import React from "react";
import {connect} from "react-redux";
import cx from "classnames";
import 'moment/locale/ru';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,} from "reactstrap";
import {useTranslation} from "react-i18next";
import get from "lodash/get";
import filter from "lodash/filter";

import {updateChatFilters} from "../../../actions/Chats";
import {getDisplayName} from '../Messages/TopPane';


const ChatFiltersDropdown = (
    {
        chatFilters,
        currentShopAdministrator,
        activeShopAdministrators,

        // Redux Actions
        updateChatFilters,
    }
) => {
    const {t, i18n} = useTranslation();

    let operatorDropdownItems = filter(
        activeShopAdministrators,
        (shopAdministrator) => {
            if (!shopAdministrator) {
                return false;
            }

            // Не выводим себя
            if (shopAdministrator.id === get(currentShopAdministrator, 'id')) {
                return false;
            }

            // Выводим только операторов чата
            if (!shopAdministrator.role_live_chat_operator) {
                return false;
            }

            return true;
        },
    ).map(
        shopAdministrator => {
            const isActive = get(chatFilters, 'assigned_to_id') === shopAdministrator.id;

            return <DropdownItem
                key={shopAdministrator.id}
                className={cx({'active': isActive})}
                toggle={false}
                onClick={(event) => {
                    event.stopPropagation();
                    updateChatFilters({assigned_to_id: shopAdministrator.id})
                }}
            >
                {getDisplayName(shopAdministrator)}
            </DropdownItem>;
        }
    );

    return <UncontrolledDropdown className='chat-filters-dropdown'>
        <DropdownToggle color='null'>
            <i className="fa fas fa-filter"/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-hover-link">
            <DropdownItem header>{t('chats.filter_dropdown.is_thread_open_title', "Live chat")}</DropdownItem>
            <DropdownItem
                toggle={false}
                className={cx({'active': get(chatFilters, 'is_thread_open') === true})}
                onClick={() => {updateChatFilters({is_thread_open: true});}}
            >
                {t('chats.filter_dropdown.is_thread_open_open', "Open")}
            </DropdownItem>
            <DropdownItem
                toggle={false}
                className={cx({'active': get(chatFilters, 'is_thread_open') === false})}
                onClick={() => {updateChatFilters({is_thread_open: false});}}
            >
                {t('chats.filter_dropdown.is_thread_open_closed', "Closed")}
            </DropdownItem>

            <DropdownItem divider/>
            <DropdownItem header>{t('chats.filter_dropdown.assigned_to_title', "Assigned to")}</DropdownItem>
            <DropdownItem
                toggle={false}
                className={cx({'active': get(chatFilters, 'assigned_to_id') === null})}
                onClick={() => {updateChatFilters({assigned_to_id: null});}}
            >
                {t('chats.filter_dropdown.assigned_to_nobody', "Not assigned")}
            </DropdownItem>
            {
                currentShopAdministrator && <DropdownItem
                    toggle={false}
                    className={cx({'active': get(chatFilters, 'assigned_to_id') === get(currentShopAdministrator, 'id')})}
                    onClick={() => updateChatFilters({assigned_to_id: currentShopAdministrator.id})}
                >
                    {t('chats.filter_dropdown.assigned_to_me', "To me")}
                </DropdownItem>
            }

            {operatorDropdownItems}
        </DropdownMenu>
    </UncontrolledDropdown>;
};


const mapStateToProps = state => ({
    activeChat: state.Chats.activeChat,
    chatFilters: state.Chats.chatFilters,
    currentShopAdministrator: state.Shops.currentShopAdministrator,
    activeShopAdministrators: state.Shops.activeShopAdministrators,
});

const mapDispatchToProps = dispatch => ({
    updateChatFilters: (filtersToUpdate) => dispatch(updateChatFilters(filtersToUpdate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatFiltersDropdown);
