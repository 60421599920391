import React, {useState} from "react";
import {Button, Card, CardFooter, Col, FormGroup, FormText, Input, Row} from "reactstrap";
import {useTranslation} from "react-i18next";

import imageUploading from '../../../assets/utils/images/uploading.gif';
import {uploadToCdnAxiosInstance} from "../../../helpers/api";


export const getFileIconClsByURL = (fileUrl) => {
    if (!fileUrl) {
        return undefined;
    }

    let fileCls = 'icon-doc-generic';
    const splt = fileUrl.split('.');
    const ext = splt[splt.length - 1].toLowerCase();

    switch (ext) {
        case 'xls':
        case 'xlsx':
            fileCls = 'icon-doc-xls';
            break;
        case 'pdf':
            fileCls = 'icon-doc-pdf';
            break;
        case 'ppt':
        case 'pptx':
            fileCls = 'icon-doc-ppt';
            break;
        case 'txt':
            fileCls = 'icon-doc-txt';
            break;
        case 'doc':
        case 'docx':
            fileCls = 'icon-doc-doc';
            break;
    }

    return fileCls;
};


const FileWidget = (props) => {
    const {t, i18n} = useTranslation();

    let [isUploading, setIsUploading] = useState(false);
    let [uploadFailed, setUploadFailed] = useState(false);

    let button = props.value ? <Col xs={4} md={8} className='text-right text-sm-left'>
        <Button
            onClick={() => {
                props.onChange(null)
            }}
        >
            {t('connect_bot.common.remove', 'Remove')}
        </Button>
    </Col> : '';

    const fileSrc = (isUploading || uploadFailed) ? undefined : props.value;
    const fileCls = (isUploading || uploadFailed) ? undefined : getFileIconClsByURL(props.value);

    return <FormGroup className='mb-4'>
        <Card>
            {
                isUploading && <img src={imageUploading} style={{width: 70}}/>
            }
            {
                fileSrc && !isUploading && <a
                    href={fileSrc}
                    target='_blank'
                    className='d-flex align-items-center justify-content-between py-2 card-body'>
                    {
                        !isUploading && <div
                            className={`${fileCls} bg-size-cover bg-center m-1 mr-3`}
                            style={{height: 30, width: 26}}
                        />
                    }
                    {
                        !isUploading && fileSrc
                    }
                </a>
            }
            <CardFooter className='py-2 pt-3 flex-column'>
                <Row style={{width: 'calc(100% + 30px)'}}>
                    <Col xs={button ? 8 : 12} md={button ? 4 : 12}>
                        <Input accept={props.options.accept}
                                type="file"
                               required={props.required}
                               onChange={(event) => {
                                   setIsUploading(true);

                                   const file = event.target.files[0];
                                   // Check the file type.
                                   if (file.type.match('image.*')) {
                                       props.onChange(null);
                                       setIsUploading(false);
                                       setUploadFailed(true);
                                       return;
                                   }
                                   let formData = new FormData();
                                   formData.append('file', file);

                                   uploadToCdnAxiosInstance.post(`${file.name}/`, formData).then(
                                       (request) => {
                                           props.onChange(request.data.result.cdn_filepath);
                                           setIsUploading(false);
                                           setUploadFailed(false);
                                       },
                                       error => {
                                           props.onChange(null);
                                           setIsUploading(false);
                                           setUploadFailed(true);
                                       },
                                   );
                               }}
                        />
                    </Col>
                    {button}
                </Row>
                <Row className='mt-2'>
                    <Col xs={12}>
                        <FormText color="muted">
                            {t('components.api_form.file_widget.hint.separate_message', 'File will be sent as a separate message. If text or image is specified, it will be sent first as a separate message.')}
                        </FormText>
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    </FormGroup>
};

export default FileWidget;
