import {toast} from "react-toastify";
import assign from "lodash/assign";
import get from "lodash/get";
import i18next from 'i18next';

import {store} from "../configureStore";
import {
    loadChatByIdRequestFail,
    loadChatByIdRequestStart,
    loadChatByIdRequestSuccess,

    updateChatRequestStart,
    updateChatRequestSuccess,
    updateChatRequestFail,

    loadGroupByIdRequestFail,
    loadGroupByIdRequestStart,
    loadGroupByIdRequestSuccess,

    loadChannelByIdRequestFail,
    loadChannelByIdRequestStart,
    loadChannelByIdRequestSuccess,

    loadGroupSchemaByIdRequestFail,
    loadGroupSchemaByIdRequestStart,
    loadGroupSchemaByIdRequestSuccess,

    loadChannelSchemaByIdRequestFail,
    loadChannelSchemaByIdRequestStart,
    loadChannelSchemaByIdRequestSuccess,

    loadChatsRequestFail,
    loadChatsRequestStart,
    loadChatsRequestSuccess,

    loadMessagesRequestFail,
    loadMessagesRequestStart,
    loadMessagesRequestSuccess,

    sendMessageRequestFail,
    sendMessageRequestStart,
    sendMessageRequestSuccess,
} from '../actions/Chats';
import {apiAxiosInstance} from "../helpers/api";


export const loadChatById = (chatId, callback) => {
    return function (dispatch) {
        dispatch(loadChatByIdRequestStart(chatId));

        apiAxiosInstance.get(`bot/chats/${chatId}/`).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(loadChatByIdRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(loadChatByIdRequestFail(response.data.errors));
                }
            },
            error => dispatch(loadChatByIdRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    }
};


export const loadGroupById = (chatId, callback) => {
    return function (dispatch) {
        dispatch(loadGroupByIdRequestStart(chatId));

        apiAxiosInstance.get(`bot/groups/${chatId}/`).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(loadGroupByIdRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(loadGroupByIdRequestFail(response.data.errors));
                }
            },
            error => dispatch(loadGroupByIdRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    }
};


export const loadChannelById = (chatId, callback) => {
    return function (dispatch) {
        dispatch(loadChannelByIdRequestStart(chatId));

        apiAxiosInstance.get(`bot/channels/${chatId}/`).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(loadChannelByIdRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(loadChannelByIdRequestFail(response.data.errors));
                }
            },
            error => dispatch(loadChannelByIdRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    }
};


export const loadGroupSchemaById = (chatId, successCallback, errorCallback) => {
    return function (dispatch) {
        dispatch(loadGroupSchemaByIdRequestStart(chatId));

        let data = {
            shop_id: store.getState().Shops.activeShop.id,
        };
        apiAxiosInstance.get(`bot/groups/${chatId}/form_schema/`, {params: data}).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(loadGroupSchemaByIdRequestSuccess(response.data.result));
                    successCallback && successCallback(response.data.result);
                } else {
                    dispatch(loadGroupSchemaByIdRequestFail(response.data.errors));
                    errorCallback && errorCallback(response.data.errors);
                }
            },
            error => {
                dispatch(loadGroupSchemaByIdRequestFail(error));
                errorCallback && errorCallback(error);
            },
        );
    }
};


export const loadChannelSchemaById = (chatId, successCallback, errorCallback) => {
    return function (dispatch) {
        dispatch(loadChannelSchemaByIdRequestStart(chatId));

        let data = {
            shop_id: store.getState().Shops.activeShop.id,
        };
        apiAxiosInstance.get(`bot/channels/${chatId}/form_schema/`, {params: data}).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(loadChannelSchemaByIdRequestSuccess(response.data.result));
                    successCallback && successCallback(response.data.result);
                } else {
                    dispatch(loadChannelSchemaByIdRequestFail(response.data.errors));
                    errorCallback && errorCallback(response.data.errors);
                }
            },
            error => {
                dispatch(loadChannelSchemaByIdRequestFail(error));
                errorCallback && errorCallback(error);
            },
        );
    }
};


export const updateChat = (chatId, data, callback, successMessage) => {
    return function (dispatch) {
        dispatch(updateChatRequestStart(chatId, data));

        apiAxiosInstance.patch(`bot/chats/${chatId}/`, data).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(updateChatRequestSuccess(chatId, response.data.result));
                    toast.success(successMessage);
                    callback && callback(response.data.result);
                } else {
                    dispatch(updateChatRequestFail(chatId, response.data.errors));
                }
            },
            error => dispatch(updateChatRequestFail(chatId, error)),
        );
    }
};


export const loadChats = (filters, callback) => {
    return function (dispatch) {
        let data = assign(
            filters,
            {
                shop_id: store.getState().Shops.activeShop.id,
            }
        );

        dispatch(loadChatsRequestStart(data));

        apiAxiosInstance.get('bot/chats/', {params: data}).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(loadChatsRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    dispatch(loadChatsRequestFail(response.data.errors));
                }
            },
            error => dispatch(loadChatsRequestFail(`Errors: ${get(error, 'response.data.errors')}`)),
        );
    };
};


export const loadMessages = (filters, successCallback, errorCallback) => {
    return function (dispatch) {
        let data = assign(
            filters,
            {
                shop_id: store.getState().Shops.activeShop.id,
            }
        );

        dispatch(loadMessagesRequestStart(data));

        apiAxiosInstance.get('bot/messages/', {params: data}).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(loadMessagesRequestSuccess(response.data.result));
                    successCallback && successCallback(response.data.result);
                } else {
                    dispatch(loadMessagesRequestFail(response.data.errors));
                    errorCallback && errorCallback(response.data.errors);
                }
            },
            (error) => {
                dispatch(loadMessagesRequestFail(error));
                errorCallback && errorCallback(error);
            },
        );
    };
};

export const sendMessage = (chat_id, contentType, payload, callback) => {
    return function (dispatch) {
        let data = {
            chat_id,
            payload,
            content_type: contentType,
            profile_id: get(store.getState().Shops, 'currentShopAdministrator.profile.id'),
        };

        dispatch(sendMessageRequestStart(data));

        apiAxiosInstance.post('/bot/messages/send_message/', data).then(
            (response) => {
                if (response.data.status === 'ok') {
                    dispatch(sendMessageRequestSuccess(response.data.result));
                    callback && callback(response.data.result);
                } else {
                    toast.error(i18next.t('errors.common.request_sent_short', 'Error sending request!'));
                    dispatch(sendMessageRequestFail(response.data.errors));
                }
            },
            error => {
                toast.error(i18next.t('errors.common.request_sent_short', 'Error sending request!'));
                dispatch(sendMessageRequestFail(error));
            },
        );
    };
};
