import React, {Fragment} from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import get from "lodash/get";
import {store} from "../../../../configureStore";
import {withTranslation} from "react-i18next";


class CatalogNodeWidget_ extends AbstractNodeWidget {
    constructor(props) {
        super(props);

        this.type = 'catalog-node';
    }

    // getHeaderClassName() {
    //     return 'bg-sunny-morning bg-opacity-3';
    // }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <i className="pe-7s-keypad"> </i> {options.step.name || this.props.t('pages.main.flow_builder.node.catalog.name', 'Catalog')}
        </>;
    }

    getBody() {
        const options = this.props.node.options;
        const text = get(options.step.text, store.getState().Shops.activeShop.lang_by_default);
        const imageUrl = options.step.image_url;
        const fileUrl = options.step.file_url;
        const videoUrl = options.step.video_url;

        return <>
            {
                imageUrl && <div className="text-center">
                    <img
                        className="img-fluid mb-3"
                        draggable={false}
                        src={imageUrl}
                    />
                </div>
            }
            {
                text && text.split('\n').map((item, key) => {
                    return <Fragment key={key}>{item}<br/></Fragment>
                })
            }
            {
                fileUrl && <div className="pb-1 pt-4">
                    <a href={fileUrl} target='_blank'>
                        {fileUrl}
                    </a>
                </div>
            }
            {
                videoUrl && <div className="pb-1 pt-4">
                    <a href={videoUrl} target='_blank'>
                        {videoUrl}
                    </a>
                </div>
            }
        </>;
    }getBody() {
        const options = this.props.node.options;
        const text = get(options.step.text, store.getState().Shops.activeShop.lang_by_default);
        const imageUrl = options.step.image_url;
        const text2 = get(options.step, 'state.text2');

        return <>
            {
                imageUrl && <div className="text-center">
                    <img
                        className="img-fluid mb-3"
                        draggable={false}
                        src={imageUrl}
                    />
                </div>
            }
            {
                text && text.split('\n').map((item, key) => {
                    return <Fragment key={key}>{item}<br/></Fragment>
                })
            }
            {
                text && text2 && <div className="p-2" />
            }
            {
                text2 && text2.split('\n').map((item, key) => {
                    return <Fragment key={key}>{item}<br/></Fragment>
                })
            }
        </>;
    }

    getErrors() {
        const options = this.props.node.options;
        const text = get(options.step.text, store.getState().Shops.activeShop.lang_by_default);
        const text2 = get(options.step, 'state.text2');
        const imageUrl = options.step.image_url;

        const errors = [];
        if (!text && !imageUrl) {
            errors.push(this.props.t('pages.main.flow_builder.node.catalog.error.add_text_or_image', 'Please provide text or image'));
        } else if (!text2) {
            errors.push(this.props.t('pages.main.flow_builder.node.catalog.error.add_text_for_categories', 'Please provide title (text 2) for the categories list step'));
        }

        return errors.map((error, idx) => <div className='py-2' key={idx}>
                <i className='text-danger opacity-9'>{error}</i>
            </div>
        );
    }
}

export const CatalogNodeWidget = withTranslation()(CatalogNodeWidget_);


export class CatalogNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'catalog-node',
            disableNextStepPort: true,
        });
    }
}


export class CatalogNodeFactory extends AbstractReactFactory {
    constructor() {
        super('catalog-node');
    }

    generateModel(event) {
        return new CatalogNodeModel();
    }

    generateReactWidget(event) {
        return <CatalogNodeWidget engine={this.engine} node={event.model}/>;
    }
}
