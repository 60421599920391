import React from "react";
import {connect} from "react-redux";
import get from 'lodash/get';


const PluginInstallationCard = (
    {
        activeShop,
        pluginInstallation,
    }
) => {
    const pluginName = get(pluginInstallation, 'plugin.name');
    const pluginImage = get(pluginInstallation, 'plugin.image_url');
    return <>
        <h5 className="d-flex align-items-center justify-content-center my-0" style={{height: 80}}>
            <img src={pluginImage} alt={pluginName} style={{width: 140}} className='mr-2 py-3'/>
        </h5>
        <h6 className='text-success mb-4'>
            {get(pluginInstallation, 'connection.metadata.name')}
        </h6>
        {/*<div className="menu-header-btn-pane">*/}
        {/*    <Button color="secondary" outline>*/}
        {/*        Отключить*/}
        {/*    </Button>*/}
        {/*</div>*/}
    </>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PluginInstallationCard);
