import get from 'lodash/get'

import {
    BOT_REQUEST_SUCCESS,
    BOT_STATISTIC_REQUEST_FAIL,
    BOT_STATISTIC_REQUEST_SUCCESS,
    BOT_STATUS_REQUEST_FAIL,
    BOT_STATUS_REQUEST_SUCCESS,
    BOTS_CLEAR_DATA,
    BOTS_REQUEST_FAIL,
    BOTS_REQUEST_START,
    BOTS_REQUEST_SUCCESS,
    QR_CODE_REQUEST_FAIL,
    QR_CODE_REQUEST_SUCCESS,
    RESTART_REQUEST_START,
    STOP_REQUEST_START,
    WHATSAPP_LOGOUT_REQUEST_FAIL,
    WHATSAPP_LOGOUT_REQUEST_SUCCESS,
} from '../actions/Dashboard'
import {
    ADAPTER_STATUS_ERROR,
    ADAPTER_STATUS_WAITING,
    ADAPTER_STATUS_STOPPED,
    BOT_STATUS_STOPPED,
} from "../constants";


const defaultState = {
    bots: undefined,
    bot: undefined,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case BOTS_REQUEST_START:
            return {
                ...state,
            };

        case BOTS_REQUEST_SUCCESS:
            return {
                ...state,
                bots: action.bots,
            };

        case BOTS_REQUEST_FAIL:
            console.log('Not implemented Exception: "BOTS_REQUEST_FAIL"');
            return {
                ...state
            };

        case BOT_REQUEST_SUCCESS:
            return {
                ...state,
                bot: action.bot,
            }

        case RESTART_REQUEST_START:
            return {
                ...state,
                bots: state.bots.map((bot) => {
                    if (bot.id === action.bot_id) {
                        return {
                            ...bot,
                            adapter_status: ADAPTER_STATUS_STOPPED,
                            qr_code: undefined,
                        }
                    }

                    return bot;
                }),
            };

        case STOP_REQUEST_START:
            return {
                ...state,
                bots: state.bots.map((bot) => {
                    if (bot.id === action.bot_id) {
                        return {
                            ...bot,
                            adapter_status: ADAPTER_STATUS_STOPPED,
                            status: BOT_STATUS_STOPPED,
                            qr_code: undefined,
                        }
                    }

                    return bot;
                }),
            };

        case BOT_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                bots: state.bots.map((bot) => {
                    if (bot.id === action.result.bot_id) {
                        return {
                            ...bot,
                            adapter_status: action.result.status_id,
                            qr_code: action.result.status_id === ADAPTER_STATUS_WAITING ? bot.qr_code : undefined,
                        }
                    }

                    return bot;
                }),
            };

        case BOT_STATUS_REQUEST_FAIL:
            return {
                ...state,
                bots: state.bots.map((bot) => {
                    if (bot.id === action.botId) {
                        return {
                            ...bot,
                            adapter_status: ADAPTER_STATUS_ERROR,
                        }
                    }

                    return bot;
                }),
            };

        case WHATSAPP_LOGOUT_REQUEST_SUCCESS:
            return {
                ...state,
                bots: state.bots.map((bot) => {
                    if (bot.id === action.result.bot_id) {
                        return {
                            ...bot,
                            qr_code: undefined,
                        }
                    }

                    return bot;
                }),
            };

        case WHATSAPP_LOGOUT_REQUEST_FAIL:
            console.log('Not implemented Exception: "WHATSAPP_LOGOUT_REQUEST_FAIL"');
            return {
                ...state
            };

        case BOT_STATISTIC_REQUEST_SUCCESS:
            return {
                ...state,
                bots: state.bots.map((bot) => {
                    if (bot.id === action.result.bot_id) {
                        return {
                            ...bot,
                            statistic: action.result,
                        }
                    }

                    return bot;
                }),
            };

        case BOT_STATISTIC_REQUEST_FAIL:
            console.log('Not implemented Exception: "BOT_STATISTIC_REQUEST_FAIL"');
            return {
                ...state
            };

        case QR_CODE_REQUEST_SUCCESS:
            return {
                ...state,
                bots: state.bots.map((bot) => {
                    if (bot.id === action.result.bot_id) {
                        return {
                            ...bot,
                            qr_code: get(action.result, 'qr_code.data_url'),
                        }
                    }

                    return bot;
                }),
            };

        case QR_CODE_REQUEST_FAIL:
            console.log('Not implemented Exception: "QR_CODE_REQUEST_FAIL"');
            return {
                ...state
            };

        case BOTS_CLEAR_DATA:
            return defaultState;
    }
    return state;
}
