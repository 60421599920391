import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import 'moment/locale/ru';
import {useTranslation} from "react-i18next";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faUpload} from "@fortawesome/free-solid-svg-icons";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import i18n from "../../../i18n";
import {store} from "../../../configureStore";
import {isMobileDevice} from "../../../index";
import {updateChat} from "../../../thunks/Chats";
import {updateActiveChatData} from "../../../actions/Chats";
import filter from "lodash/filter";


const CHAT_TYPE_PERSONAL = 1;
const CHAT_TYPE_GROUP = 2;
const CHAT_TYPE_CHANNEL = 3;

const emptyAva = <svg className='img-fluid' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212" width="212"
                      height="212">
    <path fill="#CCC"
          d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"/>
    <g fill="#FFF">
        <path
            d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z"/>
    </g>
</svg>;


export const getDisplayName = (shopAdministrator) => {
  if (get(store.getState(), 'Shops.activeShop.source') === 'chat_api') {
    return i18n.t('messages.top_panel.operator', 'operator');
  } else {
    return shopAdministrator.profile.user.first_name || shopAdministrator.profile.user.email;
  }
};


const TopPane = (
    {
        match,
        history,
        activeShop,
        activeChat,
        currentShopAdministrator,
        activeShopAdministrators,

        updateChat,
        updateActiveChatData,
    }
) => {
    const {t, i18n} = useTranslation();

    const isMobile = isMobileDevice();
    const activeChatType = parseInt(match.params.activeChatType);

    const toggleThreadOpenCloseButton = () => {
        return activeChat.is_thread_open
            ? closeChatThread()
            : assignChat(
                activeChat.assigned_to || currentShopAdministrator,
                true
            );
    }

    const closeChatThread = () => {
        if (activeChat.type !== CHAT_TYPE_PERSONAL) {
            throw `Chat #${activeChat.id} type is ${activeChat.type}, not PERSONAL`
        }

        const data = {
            is_thread_open: false,
        };

        updateChat(
            activeChat.id,
            data,
            response => {
                updateActiveChatData(response);
            },
            t('messages.top_panel.toast_chat_closed', 'Live chat closed'),
        );
    }

    const assignChat = (shopAdministrator, forceStartThread = false) => {
        if (activeChat.type !== CHAT_TYPE_PERSONAL) {
            throw `Chat #${activeChat.id} type is ${activeChat.type}, not PERSONAL`
        }

        const successMessage = shopAdministrator
            ? `${t('messages.top_panel.chat', 'Live chat')} ${!activeChat.is_thread_open ? t('messages.top_panel.chat_open', 'started and ') : ''}${t('messages.top_panel.chat_assigned_to', 'assigned to')} ${getDisplayName(shopAdministrator)}`
            : t('messages.top_panel.toast_chat_unassigned', 'Operator unassigned');

        const data = shopAdministrator
            ? {
                assigned_to: {id: shopAdministrator.id},
                is_thread_open: true,
            }
            : {
                assigned_to: null,
            };

        if (forceStartThread) {
            data.is_thread_open = true;
        }

        updateChat(
            activeChat.id,
            data,
            response => {
                updateActiveChatData(response);
            },
            successMessage,
        );
    }

    let assignedTo = undefined;
    if (activeChat) {
        assignedTo = activeChat.assigned_to
            ? getDisplayName(activeChat.assigned_to)
            : t('messages.top_panel.chat_is_not_unassigned', 'Not assigned');
    }

    let operatorDropdownItems = filter(
        activeShopAdministrators,
        (shopAdministrator) => {
            if (!shopAdministrator) {
                return false;
            }

            // Не выводим оператора, на кого уже назначен чат
            if (shopAdministrator.id === get(activeChat, 'assigned_to.id')) {
                return false;
            }

            // Выводим только операторов чата
            if (!shopAdministrator.role_live_chat_operator) {
                return false;
            }

            return true;
        },
    ).map(
        shopAdministrator => <DropdownItem
            onClick={() => assignChat(shopAdministrator)}
            key={shopAdministrator.id}
        >
            {getDisplayName(shopAdministrator)}
        </DropdownItem>
    );
    if (get(activeChat, 'assigned_to')) {
        operatorDropdownItems.push(
            <DropdownItem onClick={() => assignChat(null)} key={0}>
                {t('messages.top_panel.chat_is_not_unassigned', 'Not assigned')}
            </DropdownItem>
        );
    }

    return <div className="app-inner-layout__top-pane border-bottom px-3 py-0 chats__messages__top">
        <div className="pane-left">
            <div
                className="d-block d-md-none mobile-app-menu-btn"
                onClick={
                    () => history.push(`/${activeShop.id}/chats/`)
                }
            >
                <i className="lnr-arrow-left"/>
            </div>

            {
                isMobile && activeChat && <div
                    className='d-flex flex-row align-items-center'
                >
                    <img
                        className='mr-3'
                        src={activeChat.avatar_url}
                    />
                    <div>
                        <h4 className='mb-0'>{activeChat.name}</h4>
                        <span className="text-small text-secondary">
                            {assignedTo}
                        </span>
                    </div>
                </div>
            }

            {
                !isMobile
                && (get(activeChat, 'type') === CHAT_TYPE_PERSONAL)
                && (
                    !isEmpty(operatorDropdownItems)
                        ? <UncontrolledDropdown>
                            <DropdownToggle
                                className='p-0 chats__messages__top__assigned-to d-flex align-items-center'
                                color='null'
                            >
                                <i className="fa fas fa-user-circle"/> &nbsp; {assignedTo}
                                {
                                    assignedTo !== undefined && <i className="fa fas fa-angle-down ml-2 mt-1"/>
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                {operatorDropdownItems}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        : <span className='chats__messages__top__assigned-to d-flex align-items-center'>
                            {assignedTo}
                        </span>
                )
            }
        </div>

        {
            get(activeChat, 'type') === CHAT_TYPE_PERSONAL
            && <div
                className="pane-right border-left py-2 pl-3 pr-2 pr-md-0 chats__messages__top__check"
                onClick={toggleThreadOpenCloseButton}
            >
                <FontAwesomeIcon
                    icon={activeChat.is_thread_open ? faCheck : faUpload}
                />
            </div>
        }
    </div>
}


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    activeChat: state.Chats.activeChat,
    currentShopAdministrator: state.Shops.currentShopAdministrator,
    activeShopAdministrators: state.Shops.activeShopAdministrators,
});

const mapDispatchToProps = dispatch => ({
    updateChat: (chatId, data, callback, successMessage) => dispatch(updateChat(chatId, data, callback, successMessage)),
    updateActiveChatData: (data) => dispatch(updateActiveChatData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(TopPane)
);