import React, {Component} from "react";
import {Input, Label, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import {store} from "../../../configureStore";
import {renderErrorsAsHTML} from "../../utils";


export default class MultilangTextInputField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: store.getState().Shops.activeShop.lang_by_default,
            languages_allowed: store.getState().Shops.activeShop.languages_allowed,
            formData: this.props.formData,
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (!isEqual(prevProps.formData, this.props.formData)) {
            this.setState({formData: this.props.formData});
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    onLangFieldChange(lang, event) {
        let newFormData = this.state.formData;
        newFormData[lang] = event.target.value;
        this.setState(newFormData);
        this.props.onChange(this.state.formData);
    }

    getLangField(lang) {
        return <>
            <Input
                value={get(this.state.formData, lang, '')}
                readOnly={get(this.props, ['uiSchema', 'ui:readonly'])}
                onChange={(...args) => {
                    this.onLangFieldChange(lang, args[0]);
                }}
            />
        </>;
    }

    render() {
        let content = <></>;
        if (this.state.languages_allowed.length === 1) {
            content = this.getLangField(this.state.languages_allowed[0]);
        } else {
            const navItems = this.state.languages_allowed.map(
                (lang, idx) => <NavItem key={idx}>
                    <NavLink
                        className={classnames({'bg-light': this.state.activeTab === lang})}
                        onClick={() => {
                            this.toggle(lang);
                        }}
                    >
                        {lang}
                    </NavLink>
                </NavItem>
            );

            const navTabs = this.state.languages_allowed.map(
                (lang, idx) => <TabPane tabId={lang} key={idx}>
                    {this.getLangField(lang)}
                </TabPane>
            );

            content = <>
                <Nav tabs className={'mb-0'}>
                    {navItems}
                </Nav>
                <TabContent activeTab={this.state.activeTab} className={'p-3 bg-light'}>
                    {navTabs}
                </TabContent>
            </>;
        }

        const description = get(this.props.schema, 'description') || get(this.props.uiSchema, 'ui:description');

        return <>
            <Label className='mt-3'>
                {this.props.schema.title}
                {(this.props.schema.title && this.props.required) ? ' *' : ''}
            </Label>
            {
                !isEmpty(description) && <small className="form-text text-muted mb-3">
                    {description}
                </small>
            }
            {content}
            {renderErrorsAsHTML(this.props.errorSchema, false)}
            {renderErrorsAsHTML(this.props.rawErrors, false)}
        </>
    }
}
